import { useQuery, UseQueryOptions } from 'react-query';
import { useUserStore } from '../../../../stores/user/useUserStore';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchManagingCourseList } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';

const useFetchManagingCourseList = <T = FetchManagingCourseList[]>(
    options?: UseQueryOptions<FetchManagingCourseList[], unknown, T>
) => {
    const { accountId } = useUserStore();
    const { getManagingCourseListAPI} = useCourseAPIs();

    return useQuery({
        queryKey: QueryKeys.managingCourseList(accountId),
        queryFn: () => getManagingCourseListAPI(),
        ...options
    });
}

export default useFetchManagingCourseList;