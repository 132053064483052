import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FileHistory } from '../FileType';
import useFileAPI from '../useFileAPI';

const useFetchFileHistoryList = <T = FileHistory[]>(
  options?:UseQueryOptions<FileHistory[], AxiosError, T>
) => {
    
  const { getAllFileHistoryAPI } = useFileAPI();
  
  return useQuery({
    queryKey: QueryKeys.fileHistoryList(),
    queryFn: () => getAllFileHistoryAPI(),
    ...options
  });
}

export default useFetchFileHistoryList;