import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FolderListDto } from '../FolderType';
import useFolderAPI from '../useFolderAPI';

const useFetchSubFolderList = <T = FolderListDto[]>(
    folderId: number,
    options?:UseQueryOptions<FolderListDto[], AxiosError, T>
) =>  {

    const { getSubFolderListAPI } = useFolderAPI();
    
    return useQuery({
        queryKey: QueryKeys.subFolderList(folderId),
        queryFn: () => getSubFolderListAPI(folderId),
        ...options
    });
}

export default useFetchSubFolderList;