import { Skeleton } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const TableLoading = (rowNumber: number, colNumber: number) => {
    const content = [];
    for (let i = 0; i < rowNumber; i++) {
        const cells = [];
        for (let j = 0; j < colNumber; j++) {
            cells.push(
                <TableCell key={`cell-${j}`}>
                    <Skeleton animation="wave" variant='text' />
                </TableCell>
            );
        }
        content.push(<TableRow key={`row-${i}`}>{cells}</TableRow>);
    }
    return content;
}
