import { create } from "zustand";

interface folderHistoryStoreProps {
  currentFolderId: number;
  backStack: number[];
  forwardStack: number[];
  setCurrentFolderId: (folderId: number) => void;
  goBack: () => void;
  goForward: () => void;
  resetStore: () => void;
}

const initialState = {
  currentFolderId: Number(process.env.REACT_APP_ROOT_FOLDER_ID),
  backStack: [],
  forwardStack: [],
};

export const useFolderHistoryStore = create<folderHistoryStoreProps>((set) => ({
  ...initialState,

  setCurrentFolderId: (folderId) =>
    set((state) => {
      if (state.currentFolderId) {
        return {
          ...state,
          backStack: [...state.backStack, state.currentFolderId],
          currentFolderId: folderId,
          forwardStack: [],
        };
      } else {
        return { ...state, currentFolderId: folderId };
      }
    }),

  goBack: () =>
    set((state) => {
      if (state.backStack.length > 0) {
        const newBackStack = [...state.backStack];
        const prevFolderId = newBackStack.pop();

        return {
          ...state,
          forwardStack: [state.currentFolderId, ...state.forwardStack],
          currentFolderId: prevFolderId,
          backStack: newBackStack,
        };
      } else {
        return state;
      }
    }),

  goForward: () =>
    set((state) => {
      if (state.forwardStack.length > 0) {
        const newForwardStack = [...state.forwardStack];
        const nextFolderId = newForwardStack.shift();

        return {
          ...state,
          backStack: [...state.backStack, state.currentFolderId],
          currentFolderId: nextFolderId,
          forwardStack: newForwardStack,
        };
      } else {
        return state;
      }
    }),

  resetStore: () => set(initialState),
}));
