import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../../components/text/CustomText";
import useLoginHooks from "../../hooks/domains/useLoginHooks";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";
import { BorderGray, DisabledGray, InbodyRed } from "../../styles/GlobalStyle";
import Footer from "../../layouts/Footer";

export default function LoginPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const isLogin = useUserStore((state) => state.isLogin);

  const {
    ssoLogin,
    accountId,
    password,
    errorText,
    showPassword,
    setShowPassword,
    isSubmit,
    inputValidationCheck,
    handlePasswordChange,
    handleIdLogin,
    handleAccountIdChange,
  } = useLoginHooks();

  useEffect(() => {
    if (isLogin) {
      navigate(Urls.main);
    }
  }, [isLogin, navigate]);

  return (
    <Box
      flexDirection={"column"}
      justifyContent={"center"}
      position={"relative"}
      alignItems={"center"}
      width={500}
      margin={"0 auto"}
      padding={"10vh 0 20vh"}
    >
      <Box id="title-wrapper" mb={6}>
        <CustomText type="bigTitle" color={InbodyRed} bold600 align="center">
          {t("Knowledge Warehouse")}
        </CustomText>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{ border: `1px solid ${BorderGray}` }}
        p={6}
      >
        <Grid item id="input-wrapper" xs={12}>
          <TextField
            id="id"
            sx={{ mb: 1 }}
            fullWidth
            value={accountId}
            onBlur={inputValidationCheck}
            onChange={handleAccountIdChange}
            placeholder={t("아이디")}
          />
          <OutlinedInput
            id="pwd"
            fullWidth
            type={showPassword ? "text" : "password"}
            value={password}
            onBlur={inputValidationCheck}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleIdLogin();
              }
            }}
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((pre) => !pre)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                  sx={{ mr: "-8px" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={t("비밀번호")}
          />
          {isSubmit && (
            <CustomText type="body" color={"red"}>
              {errorText}
            </CustomText>
          )}
        </Grid>
        <Grid item id="login-button" xs={12}>
          <Button
            fullWidth
            onClick={handleIdLogin}
            sx={{
              bgcolor: DisabledGray,
              color: "black",
              height: 40,
              "&:hover": {
                bgcolor: DisabledGray,
                opacity: 0.9,
              },
            }}
          >
            {t("로그인")}
          </Button>
          <Divider sx={{ mt: 1, mb: 1 }}>
            {<CustomText type="body">{t("또는")}</CustomText>}
          </Divider>

          <Button
            fullWidth
            onClick={ssoLogin}
            sx={{
              bgcolor: InbodyRed,
              color: "white",
              height: 40,
              "&:hover": {
                bgcolor: InbodyRed,
                opacity: 0.9,
              },
            }}
          >
            {t("임직원 로그인")}
          </Button>
        </Grid>
        <Grid
          id="sign-up-button"
          item
          xs={12}
          container
          justifyContent={"space-between"}
        >
          <Grid item>
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "transparent",
                  fontWeight: 600,
                },
              }}
              onClick={() => navigate(Urls.signUp)}
            >
              {t("회원가입")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{
                "&:hover": {
                  bgcolor: "transparent",
                  fontWeight: 600,
                },
              }}
              // onClick={() => navigate(Urls.accountHelp)}
            >
              {t("아이디/비밀번호 찾기")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer hideDivider />
    </Box>
  );
}
