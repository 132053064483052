import * as yup from "yup";

export const notEmptyHtmlContent = (errorMessage: string) => yup.string()
    .trim()
    .test(
        'no-html-content',
        errorMessage,
        (value) => {
            if (!value) return false;
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, 'text/html');
            const textContent = dom.body.textContent || '';
            return textContent.trim() !== '';
        }
    ) 