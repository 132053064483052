import useGetApiInstance from '../../config/BasicApi';
import {
    FetchEmployeeTree,
    FetchEmployeeMailList,
    FetchEmployeeList
} from './EmployeeType';

const useEmployeeAPIs = () => {
    const api = useGetApiInstance('', 'HR');

    // 인사 Tree 조회
    const getEmployeeTreeAPI = (year: number): Promise<FetchEmployeeTree> =>
        api.get(`department/treeview/${year}/Employee`).then(res => res.data);

    // Mail, 이름 전체 조회
    const getEmployeeMailListAPI = (): Promise<FetchEmployeeMailList> =>
        api.get('/Mail').then(res => res.data);

    // 전체 조회
    const getEmployeeListAPI = (): Promise<FetchEmployeeList[]> =>
        api.get('/hremployee/list').then(res => res.data);
    
    return {
        getEmployeeTreeAPI,
        getEmployeeMailListAPI,
        getEmployeeListAPI
    }
}

export default useEmployeeAPIs;