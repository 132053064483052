import { useTranslation } from "react-i18next";

interface FolderNameLanguageSwitcherProps {
  koreanName: string;
  englishName: string;
  isSecretFolder?: boolean;
}

export default function FolderNameLanguageSwitcher({
  isSecretFolder,
  koreanName,
  englishName,
}: FolderNameLanguageSwitcherProps) {
  const { t, i18n } = useTranslation();

  return (
    <>
      {isSecretFolder && t("대외비")}
      {i18n.language === "ko" ? koreanName : englishName}
    </>
  );
}
