import { CourseTypeInterface } from "../../data/enums/CourseType";

export const QueryKeys = {
  // Course
  progressCourseList: (accountId: string) => ["progressCourseList", accountId],
  completeCourseList: (accountId: string) => ["completeCourseList", accountId],
  managingCourseList: (accountId: string) => ["managingCourseList", accountId],
  courseList: (courseType?: CourseTypeInterface) =>
    ["courseList", courseType].filter(Boolean),
  courseNameList: () => ["courseNameList"],
  courseDetail: (courseId?: number) =>
    ["courseDetail", courseId].filter(Boolean),

  // Quiz
  quizList: (userId: number) => ["quizList", userId],
  managingQuizList: (userId: number) => ["managingQuizList", userId],
  quizDetail: (quizId: number) => ["quizDetail", quizId],
  quizTargetList: (quizId: number) => ["quizTargetList", quizId],
  quizFinishTime: (quizId: number) => ["quizFinishTime", quizId],
  myQuizScoreList: (userId: number) => ["myQuizScoreList", userId],
  questionListAndAnswer: (quizId: number) => ["questionListWithAnswer", quizId],
  questionList: (quizId: number) => ["questionList", quizId],
  attemptDetail: (attemptId: number) => ["attemptDetail", attemptId],
  quizResultList: (quizId: number) => ["attemptList", quizId],

  quizVersionList: (quizId: number) => ["quizVersionList", quizId],

  //Document & Lecture
  documentList: (courseId: number) => ["documentList", courseId],
  lectureList: (courseId: number) => ["lectureList", courseId],

  //Notice & QnA
  mainNoticeList: () => ["mainNoticeList"],
  noticeDetail: (noticeId?: number) =>
    ["noticeDetail", noticeId].filter(Boolean),
  noticeList: () => ["noticeList"],
  qnaDetail: (qnaId?: number) => ["detailQna", qnaId].filter(Boolean),
  qnaList: () => ["qnaList"],
  qnaTypeList: () => ["qnaTypeList"],

  //User
  employeeList: () => ["employeeList"],
  employeeTreeList: () => ["employeeTreeList"],
  userStudyInfoList: () => ["userStudyInfoList"],
  userList: () => ["userList"],
  professorList: () => ["professorList"],

  //Main
  mainPageCourseList: (isNewCourseList: boolean, language: string) => [
    "mainPageCourseList",
    isNewCourseList,
    language,
  ],

  //Folder
  folderAuthTree: () => ["folderAuthTree"],
  folderAuthList: (folderId: number | null) => ["folderAuthList", folderId],
  folderPathList: (folderId: number | null) => ["folderPathList", folderId],
  subFolderList: (folderId: number | null) => ["subFolderList", folderId],
  folderDetail: (folderId: number | null) => ["folderDetail", folderId],
  folderAuthCheck: (folderId: number | null) => ["folderAuthCheck", folderId],

  //Tag
  allTagTextList: () => ["allTagTextList"],
  tagListInfolder: (folderId: number) => ["tagListInfolder", folderId],

  //File
  fileList: (folderId: number | null) => ["fileList", folderId],
  fileSearchList: (searchWords: string) => ["fileSearchList", searchWords],
  fileContainerSasToken: (folderId: number | null) => [
    "fileContainerSasToken",
    folderId,
  ],

  //FileHistory
  fileHistoryList: () => ["fileHistoryList"],
  folderHistoryList: () => ["folderHistoryList"],
};

export type QueryKeysType = (typeof QueryKeys)[keyof typeof QueryKeys];
