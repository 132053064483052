import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useLectureAPIs from "../../api/domains/lecture/useLectureAPIs";
import { UpdateLectureProgress } from "../../api/domains/lecture/LectureType";
import { CustomConfirmAlert } from "../../components/alert/CustomConfirmAlert";

export default function LectureReactPlayer() {
  const lectureId = Number(useParams().id);
  const [url, setUrl] = useState("");
  const [isDashUrl, setIsDashUrl] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [isContinue, setIsContinue] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);
  const { t } = useTranslation();

  const { playLectureAPI, progressCheckAPI } = useLectureAPIs();

  useEffect(() => {
    if (isNaN(lectureId)) {
      Swal.fire({
        icon: "error",
        heightAuto: false,
        title: "잘못된 접근입니다.",
      });
    } else {
      playLectureAPI(lectureId).then((lecture) => {
        if (lecture.dashUrl) {
          setUrl(lecture.dashUrl);
        } else if (lecture.hlsUrl) {
          setIsDashUrl(false);
          setUrl(lecture.hlsUrl);
        }

        if (lecture.timeStamp > 0) {
          const date = new Date(0);
          date.setSeconds(Math.floor(lecture.timeStamp));
          const timeString = date.toISOString().substring(12, 19);
          CustomConfirmAlert(
            `${timeString}${t(" 부터 이어서 보시겠습니까?")}`,
            "info",
            t("이어보기"),
            t("처음부터")
          ).then((result) => {
            if (result.isConfirmed) {
              setCurrentTime(lecture.timeStamp);
              setIsContinue(true);
            } else {
              playerRef.current?.getInternalPlayer().play();
            }
          });
        }
      });
    }
  }, [lectureId]);

  useEffect(() => {
    if (playerRef.current && isContinue) {
      playerRef.current.getInternalPlayer().play();
      setTimeout(() => {}, 1000);
      playerRef.current.seekTo(currentTime);
      setIsContinue(false);
    }
  }, [isContinue, currentTime]);

  // 10초마다 진도 체크
  const handleProgress = (state: OnProgressProps) => {
    const request: UpdateLectureProgress = {
      lectureId: lectureId,
      progressTime: 5 * playerRef.current?.getInternalPlayer().playbackRate,
      timeStamp: state.playedSeconds,
    };
    progressCheckAPI(request);
  };

  return (
    <Wrapper>
      <PlayerWrapper>
        {url && isDashUrl && (
          <ReactPlayer
            url={url}
            ref={playerRef}
            config={{
              file: { forceDASH: true },
            }}
            controls={true}
            width={"1280px"}
            height={"720px"}
            fallback={<div>loading now...</div>}
            progressInterval={5000}
            onProgress={handleProgress}
          />
        )}
        {url && !isDashUrl && (
          <ReactPlayer
            url={url}
            ref={playerRef}
            config={{
              file: { forceHLS: true },
            }}
            controls={true}
            width={"1280px"}
            height={"720px"}
            fallback={<div>loading now...</div>}
            progressInterval={5000}
            onProgress={handleProgress}
          />
        )}
      </PlayerWrapper>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: gray;
  position: fixed;
  top: 0;
  left: 0;
`;
const PlayerWrapper = styled.div`
  padding: 0;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  & video {
    height: auto;
  }
`;
