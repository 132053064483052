import { create } from "zustand";
import { FileDomain } from "../../api/domains/file/FileType";

export type FileDto = Pick<
  FileDomain,
  | "fileId"
  | "originFileName"
  | "uploadFileName"
  | "fileSize"
  | "thumbnailUrl"
  | "createdAt"
  | "updatedAt"
  | "tagIdList"
  | "folderPath"
> & {
  file: File | null;
  uploadProgress: number;
  requestResult: string;
};

interface fileStoreProps {
  fileList: FileDto[];
  addFile: (file: FileDto) => void;
  setFileList: (fileList: FileDto[]) => void;

  removeFile: (originFileName: string) => void;
  setTagIdList: (originFileName: string, tagIdList: number[]) => void;
  updateProgress: (originFileName: string, progress: number) => void;
  updateRequestResult: (originFileName: string, responseResult: string) => void;

  resetStore: () => void;
}

const initialState = {
  fileList: [],
};

export const useFileStore = create<fileStoreProps>((set) => ({
  ...initialState,

  addFile: (fileListDto) =>
    set((state) => {
      if (
        state.fileList.some(
          (file) => file.originFileName === fileListDto.originFileName
        )
      ) {
        return state;
      }
      return {
        ...state,
        fileList: [...state.fileList, fileListDto],
      };
    }),

  setFileList: (fileList) => set({ fileList: fileList }),

  removeFile: (originFileName) =>
    set((state) => {
      return {
        ...state,
        fileList: [
          ...state.fileList.filter(
            (file) => file.originFileName !== originFileName
          ),
        ],
      };
    }),

  setTagIdList: (originFileName, tagIdList) =>
    set((state) => {
      return {
        ...state,
        fileList: state.fileList.map((file) => {
          if (file.originFileName === originFileName) {
            return {
              ...file,
              tagIdList: tagIdList,
            };
          }
          return file;
        }),
      };
    }),

  updateProgress: (originFileName, progress) =>
    set((state) => {
      return {
        ...state,
        fileList: state.fileList.map((file) => {
          if (file.originFileName === originFileName) {
            return {
              ...file,
              uploadProgress: progress,
            };
          }
          return file;
        }),
      };
    }),

  updateRequestResult: (originFileName, responseResult) =>
    set((state) => {
      return {
        ...state,
        fileList: state.fileList.map((file) => {
          if (file.originFileName === originFileName) {
            return {
              ...file,
              requestResult: responseResult,
            };
          }
          return file;
        }),
      };
    }),

  resetStore: () => set(initialState),
}));
