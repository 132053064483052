import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { FocusEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FolderAuthListDto } from "../../../api/domains/folder/FolderType";
import useFetchUserList from "../../../api/domains/user/queries/useFetchUserList";
import { CustomText } from "../../../components/text/CustomText";
import {
  StoragePermission,
  StoragePermissionType,
} from "../../../data/enums/PermissionType";
import useColorHooks from "../../../hooks/common/useColorHooks";
import { useFolderModalStore } from "../../../stores/folder/useFolderModalStore";
import { useFolderStore } from "../../../stores/folder/useFolderStore";
import { BorderGray, BoxBlue } from "../../../styles/GlobalStyle";

interface PostFolderUserAuthProps {
  permissionType: StoragePermissionType;
}

export default function PostFolderUserAuth({
  permissionType,
}: PostFolderUserAuthProps) {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const [selectedFolderAuthUserList, setSelectedFolderAuthUserList] = useState<
    FolderAuthListDto[]
  >([]);
  const [searchInput, setSearchInput] = useState("");

  const { isAuthModalOpen, closeAuthModal } = useFolderModalStore((state) => ({
    isAuthModalOpen: state.isAuthModalOpen,
    closeAuthModal: state.closeAuthModal,
  }));

  const { folderAuthList, updateFolderAuthUserList } = useFolderStore(
    (state) => ({
      folderAuthList: state.folderAuthList,
      updateFolderAuthUserList: state.updateFolderAuthUserList,
    })
  );

  const { data: userList } = useFetchUserList();
  const { stringToColor } = useColorHooks();

  // 초기 상태 설정
  useEffect(() => {
    const initAuthUserList = folderAuthList.filter(
      (authUser) => authUser.permissionType === permissionType
    );

    setSelectedFolderAuthUserList(initAuthUserList);
  }, [folderAuthList, permissionType]);

  // 사용자 List
  const availableUserList = useMemo(() => {
    const filteredList = userList?.filter(
      (user) =>
        !selectedFolderAuthUserList.some(
          (authUser) => authUser.userId === user.userId
        )
    );

    if (filteredList) {
      return [...filteredList];
    }
    return [];
  }, [userList, selectedFolderAuthUserList]);

  const filteredUserList = availableUserList.filter((user) => {
    return (
      user.email.search(getRegExp(searchInput)) !== -1 ||
      user.userName.search(getRegExp(searchInput)) !== -1 ||
      (user.team && user.team.search(getRegExp(searchInput)) !== -1) ||
      (user.parentTeam && user.parentTeam.search(getRegExp(searchInput)) !== -1)
    );
  });

  // 검색 활성화
  const handleFocus = () => {
    setIsFocused(true);
  };

  // 검색 끝
  const handleBlur = (e: FocusEvent) => {
    if (listRef.current && !listRef.current.contains(e.relatedTarget)) {
      setIsFocused(false);
    }
  };

  // Select List 사용자 추가
  const addSelectedFolderAuthUser = (addFolderAuthUser: FolderAuthListDto) => {
    setSelectedFolderAuthUserList((pre) => [...pre, addFolderAuthUser]);
  };

  // Select List 사용자 제거
  const removeSelectedFolderAuthUser = (userId: number) => {
    setSelectedFolderAuthUserList((pre) =>
      pre.filter(
        (authUser) =>
          !(
            authUser.userId === userId &&
            authUser.permissionType === permissionType
          )
      )
    );
  };

  return (
    <Drawer
      anchor={"right"}
      open={isAuthModalOpen}
      sx={{ "& .MuiDrawer-paper": { width: "60%" }, zIndex: 1300 }}
    >
      <Wrapper>
        <Box sx={{ mb: 3 }}>
          <CustomText type="title">
            {permissionType === StoragePermission.MANAGER
              ? t("폴더 관리 권한 편집")
              : t("폴더 사용자 편집")}
          </CustomText>
        </Box>
        <Alert severity="info" sx={{ mb: 1 }}>
          <CustomText type="body" bold500>
            {permissionType === StoragePermission.MANAGER
              ? t("폴더 소유자 편집 안내")
              : t("폴더 사용자 편집 안내")}
          </CustomText>
        </Alert>
        <Box>
          <TextField
            fullWidth
            size="small"
            value={searchInput}
            autoComplete="off"
            ref={searchInputRef}
            onChange={(e) => setSearchInput(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={t("이름, 이메일, 파트, 팀으로 검색")}
          />
          {isFocused && searchInputRef.current && (
            <List
              sx={{
                position: "absolute",
                maxHeight: 320,
                overflow: "auto",
                bgcolor: "white",
                border: `1px solid ${BorderGray}`,
                zIndex: 500,
                width: searchInputRef.current.clientWidth - 5,
                boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.6)",
              }}
              ref={listRef}
              onMouseDown={(e) => e.preventDefault()}
            >
              {filteredUserList.length === 0 && (
                <ListItem>
                  <CustomText fullWidth type="body" align="center" bold500>
                    {t("조회된 데이터가 없습니다.")}
                  </CustomText>
                </ListItem>
              )}
              {filteredUserList.length !== 0 && (
                <ListItem
                  onClick={() => {
                    setSelectedFolderAuthUserList((pre) => [
                      ...pre,
                      ...filteredUserList.map((user) => ({
                        ...user,
                        permissionType: permissionType,
                        userId: user.userId,
                        folderAuthId: 0,
                      })),
                    ]);
                  }}
                  sx={{
                    p: "3px 15px",
                    "&:hover": {
                      bgcolor: BoxBlue,
                      cursor: "pointer",
                    },
                    borderTop: `1px solid ${BorderGray}`,
                    borderBottom: `1px solid ${BorderGray}`,
                  }}
                >
                  <Avatar
                    sx={{
                      mr: 1,
                      width: 40,
                      height: 40,
                      bgcolor: stringToColor("ALL"),
                    }}
                  >
                    <CustomText type="small">{"ALL"}</CustomText>
                  </Avatar>
                  <Box>
                    <CustomText type="body" bold500>
                      {t("검색된 임직원 모두 추가")}
                    </CustomText>
                  </Box>
                </ListItem>
              )}
              {filteredUserList.map((user) => (
                <ListItem
                  key={user.userId}
                  onClick={() =>
                    addSelectedFolderAuthUser({
                      ...user,
                      permissionType: permissionType,
                      userId: user.userId,
                      folderAuthId: 0,
                    })
                  }
                  sx={{
                    p: "3px 15px",
                    "&:hover": {
                      bgcolor: BoxBlue,
                      cursor: "pointer",
                    },
                    borderBottom: `1px solid ${BorderGray}`,
                  }}
                >
                  <Avatar
                    sx={{
                      mr: 1,
                      width: 40,
                      height: 40,
                      bgcolor: stringToColor(user.userName.slice(0, 3)),
                    }}
                  >
                    <CustomText type="small">
                      {user.userName.slice(0, 3)}
                    </CustomText>
                  </Avatar>
                  <Box>
                    <CustomText type="body" bold500>
                      {user.userName}
                    </CustomText>
                    <CustomText type="small">
                      {`${user.email} / ${user.parentTeam ?? "-"} / ${
                        user.team ?? "-"
                      } / ${user.jobTitle ?? "-"}`}
                    </CustomText>
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <SelectedManagerList>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell width={"50%"} align="center">
                  <CustomText type="body" bold500>
                    {t("이름")}
                  </CustomText>
                </TableCell>
                <TableCell width={"45%"} align="center">
                  <CustomText type="body" bold500>
                    {t("사용자 정보")}
                  </CustomText>
                </TableCell>
                <TableCell width={"5%"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFolderAuthUserList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CustomText type="body" sx={{ p: 1 }}>
                      {t("폴더 소유자가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {selectedFolderAuthUserList.map((user, index) => (
                <TableRow
                  key={user.userId}
                  sx={{ "&:hover": { bgcolor: BoxBlue } }}
                >
                  <TableCell>
                    <Box display={"flex"} ml={1}>
                      <Avatar
                        sx={{
                          mr: 1,
                          width: 40,
                          height: 40,
                          bgcolor: stringToColor(user.userName),
                        }}
                      >
                        <CustomText type="small">
                          {user.userName.slice(0, 3)}
                        </CustomText>
                      </Avatar>
                      <Box>
                        <CustomText type="body" bold500>
                          {user.userName}
                        </CustomText>
                        <CustomText type="small">{`${user.email}`}</CustomText>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <CustomText type="body">
                      {`${user.parentTeam ?? "-"} / ${user.team ?? "-"} / ${
                        user.jobTitle ?? "-"
                      }  / ${user.jobTitle ?? "-"}`}
                    </CustomText>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={{ p: 1 }}
                      onClick={() => removeSelectedFolderAuthUser(user.userId)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SelectedManagerList>
        <Button
          sx={{
            position: "absolute",
            left: 32,
            bottom: 20,
          }}
          variant="contained"
          onClick={() => {
            updateFolderAuthUserList(
              selectedFolderAuthUserList,
              permissionType
            );
            closeAuthModal();
          }}
        >
          <CustomText type="button">
            {`${t("저장")}(${selectedFolderAuthUserList.length})`}
          </CustomText>
        </Button>
        <Button
          sx={{
            position: "absolute",
            left: 132,
            bottom: 20,
          }}
          variant="contained"
          color="error"
          onClick={closeAuthModal}
        >
          <CustomText type="button">{t("취소")}</CustomText>
        </Button>
      </Wrapper>
    </Drawer>
  );
}
const Wrapper = styled.div`
  padding: 32px;
  height: calc(100% - 32px);
  position: "relative";
  overflow-y: auto;
`;

const SelectedManagerList = styled.div`
  margin-top: 10px;
  height: 70%;
  overflow-y: auto;
  border: 1px solid ${BorderGray};
  border-radius: 3px;
  & td,
  th {
    padding: 8px;
  }
`;
