import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { Dispatch, SetStateAction, useEffect } from "react";
import { FetchEmployeeTree } from "../../api/domains/employee/EmployeeType";

export const useAppendIconAndExpandAll = (
  setEmployeeTreeData: Dispatch<SetStateAction<FetchEmployeeTree>>,
  data?: FetchEmployeeTree
) => {
  useEffect(() => {
    const addIconAndExpandAll = (data: FetchEmployeeTree, index: number) => {
      if (!/^[a-zA-Z]/.test(data.id)) {
        data.icon = "user";
      } else {
        data.icon = "department";
        data.expanded = true;
      }

      if (data.subChild) {
        data.subChild.forEach((sub) => addIconAndExpandAll(sub, index + 1));
      }
    };

    if (data) {
      const fixedData = { ...data };
      fixedData.subChild?.forEach((node) => {
        node.icon = "department";
        node.expanded = true;
        addIconAndExpandAll(node, 1);
      });
      setEmployeeTreeData({ ...fixedData, expanded: true });
    }
  }, [data, setEmployeeTreeData]);
};

export const useTreeModalHooks = () => {
  //#region 검색 입력시 => 검색 결과에 맞게 dataSet변환
  const filterTreeData = (treeData: FetchEmployeeTree, searchText: string) => {
    let cloneData = null;

    if (treeData.name.includes(searchText)) {
      cloneData = { ...treeData };
    }

    if (treeData.subChild) {
      const filteredChildren: FetchEmployeeTree[] = [];

      treeData.subChild.forEach((sub) => {
        const result = filterTreeData(sub, searchText);
        if (result) {
          filteredChildren.push(result);
        }
      });

      if (filteredChildren.length > 0) {
        cloneData = cloneData ? cloneData : { ...treeData, subChild: [] };
        cloneData.subChild = filteredChildren;
      }
    }
    return cloneData;
  };

  let debounceTimer: NodeJS.Timeout | null = null;

  const searchNodes = (
    treeObj: TreeViewComponent,
    maskObj: MaskedTextBoxComponent,
    employeeTreeData: FetchEmployeeTree
  ) => {
    let _text = maskObj.element.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      if (_text === "") {
        treeObj.fields = {
          dataSource: [{ ...employeeTreeData }],
          id: "id",
          text: "name",
          child: "subChild",
          iconCss: "icon",
        };
      } else {
        const filteredList = filterTreeData(employeeTreeData, _text);

        treeObj.fields = {
          dataSource: [{ ...filteredList }],
          id: "id",
          text: "name",
          child: "subChild",
          iconCss: "icon",
        };
      }
    }, 100);
  };
  //#endregion

  return {
    searchNodes,
  };
};
