import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchMyQuizScoreList from "../../api/domains/quiz/queries/useFetchMyQuizScoreList";
import { TableLoading } from "../../components/loading/TableLoading";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { PageSize } from "../../data/enums/PageSize";
import useDateHooks from "../../hooks/common/useDateHooks";
import {
  BasicFontKR,
  ContentGray,
  TitleGreen,
  TitleRed,
} from "../../styles/GlobalStyle";
import {
  KeyWordWrapper,
  PaginationWrapper,
} from "../course-list/CourseListPage";

type QuizScoreCondition = "Name";

const QuizScoreSearchCondition: Record<QuizScoreCondition, QuizScoreCondition> =
  {
    Name: "Name",
  };

export default function QuizScorePage() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchCondition, setSearchCondition] = useState("Name");
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const { data: myQuizScoreList, isLoading } = useFetchMyQuizScoreList();

  const { getDateStringFormat } = useDateHooks();

  const renderMyQuizScoreList = myQuizScoreList?.filter((quizScore) => {
    switch (searchCondition) {
      case QuizScoreSearchCondition.Name:
        return quizScore.quizName.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("시험 결과 확인")} />
      </Grid>
      <Grid container justifyContent={"flex-end"}>
        <Select
          size="small"
          value={searchCondition}
          onChange={(event) => setSearchCondition(event.target.value)}
          displayEmpty
        >
          <MenuItem value={QuizScoreSearchCondition.Name}>
            <CustomText>{t("강의명")}</CustomText>
          </MenuItem>
        </Select>
        <KeyWordWrapper>
          <InputBase
            sx={{ ml: 1, flex: 1, fontFamily: BasicFontKR, fontSize: "14px" }}
            className="inputField"
            placeholder={t("검색어를 입력하세요.")}
            value={searchKeyWords}
            onChange={(event) => setSearchKeyWords(event.target.value)}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="small"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </KeyWordWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="40%">
                  <CustomText type="subtitle">{t("시험명")}</CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("응시일")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("객관식점수")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("주관식점수")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("총점")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("합격여부")}</CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 6)}
              {myQuizScoreList && myQuizScoreList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <CustomText type="subtitle">
                      {t("응시한 시험이 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {renderMyQuizScoreList &&
                renderMyQuizScoreList
                  .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                  .map((quizScore, index, array) => {
                    const totalScore =
                      Number(quizScore.objectiveGetScore) +
                      Number(quizScore.subjectiveGetScore);
                    const isPass = totalScore >= quizScore.quizPassScore;
                    return (
                      <TableRow key={quizScore.attemptId}>
                        <TableCell align="center">
                          <CustomText>{quizScore.quizName}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText>
                            {getDateStringFormat(
                              quizScore.quizFinishTime,
                              "YYYY-MM-DD"
                            )}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText>
                            {`${quizScore.objectiveGetScore ?? 0} / ${
                              quizScore.objectiveMaxScore
                            }`}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText>
                            {`${quizScore.subjectiveGetScore ?? 0} / ${
                              quizScore.subjectiveMaxScore
                            }`}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          {quizScore.isFinialScore && (
                            <CustomText>{totalScore}</CustomText>
                          )}
                          {!quizScore.isFinialScore && (
                            <Grid container justifyContent={"center"}>
                              <Tooltip
                                sx={{ mr: "3px" }}
                                title={t(
                                  "채점이 완료되지 않았습니다. 점수가 변동될 수 있습니다."
                                )}
                              >
                                <InfoOutlinedIcon
                                  color="warning"
                                  fontSize="small"
                                />
                              </Tooltip>
                              <CustomText blur>
                                {`${quizScore.objectiveGetScore ?? 0} / ${
                                  quizScore.objectiveMaxScore
                                }`}
                              </CustomText>
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {quizScore.isFinialScore && (
                            <CustomText
                              type="body"
                              color={isPass ? TitleGreen : TitleRed}
                            >
                              {isPass ? t("합격") : t("불합격")}
                            </CustomText>
                          )}
                          {!quizScore.isFinialScore && (
                            <CustomText type="body" color={ContentGray}>
                              {t("채점중")}
                            </CustomText>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
      <Grid item xs={12}>
        <PaginationWrapper id="pagination-wrapper">
          {myQuizScoreList && (
            <Pagination
              color="standard"
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(myQuizScoreList.length / PageSize.BASIC)}
            />
          )}
        </PaginationWrapper>
      </Grid>
    </Grid>
  );
}
