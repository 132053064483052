import { useParams } from "react-router-dom";
import useFetchNoticeDetail from "../../api/domains/notice/queries/useFetchNoticeDetail";
import NoticeForm from "./components/NoticeForm";

interface NoticeEditProps {
  isCreate: boolean;
}

export default function NoticePostPage({ isCreate }: NoticeEditProps) {
  const noticeId = Number(useParams().id);
  const { data: noticeDetail } = useFetchNoticeDetail(noticeId, {
    enabled: !isCreate,
  });

  return (
    <>
      {isCreate && <NoticeForm isCreate={true} />}
      {!isCreate && !!noticeDetail && (
        <NoticeForm
          isCreate={false}
          title={noticeDetail.title}
          content={noticeDetail.content}
        />
      )}
    </>
  );
}
