import useGetApiInstance from '../../config/BasicApi';
import { SendEmailRequest } from './EmailType';

const useEmailAPIs = () => {
    const api = useGetApiInstance('email');

    const sendEmailAPI = (request: SendEmailRequest): Promise<string[]> =>
        api.post('send', request).then(res => res.data.result);

    return {
        sendEmailAPI
    }
}

export default useEmailAPIs;