import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  ChangeFolderLocationDto,
  CreateFolderDto,
  FolderDetailDto,
  UpdateFolderDto,
} from "../../api/domains/folder/FolderType";
import useFetchFolderDetail from "../../api/domains/folder/queries/useFetchFolderDetail";
import useFolderAPI from "../../api/domains/folder/useFolderAPI";
import ToastAlert from "../../components/alert/ToastAlert";
import { useFolderHistoryStore } from "../../stores/folder/useFolderHistoryStore";
import {
  FolderModalType,
  useFolderModalStore,
} from "../../stores/folder/useFolderModalStore";
import { useFolderStore } from "../../stores/folder/useFolderStore";
import { useLoadingStore } from "../../stores/loading/useLoadingStore";
import { isFolderValid } from "../validation/isFolderValid";
import { CustomConfirmAlert } from "../../components/alert/CustomConfirmAlert";

const useFolderHooks = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    createFolderAPI,
    updateFolderAPI,
    deleteFolderAPI,
    changeFolderParentAPI,
  } = useFolderAPI();

  const currentFolderId = useFolderHistoryStore(
    (state) => state.currentFolderId
  );

  const closeFolderModal = useFolderModalStore(
    (state) => state.closeFolderModal
  );

  const { openLoading, closeLoading } = useLoadingStore((state) => ({
    openLoading: state.openLoading,
    closeLoading: state.closeLoading,
  }));

  const {
    createStore,
    updateValue,
    koreanName,
    englishName,
    isSecretFolder,
    parentFolderId,
    folderAuthList,
    tagList,
    setTagList,
    resetFolderStore,
  } = useFolderStore((state) => ({
    createStore: state.createStore,
    updateValue: state.updateValue,
    koreanName: state.koreanName,
    englishName: state.englishName,
    isSecretFolder: state.isSecretFolder,
    parentFolderId: state.parentFolderId,
    folderAuthList: state.folderAuthList,
    tagList: state.tagList,
    setTagList: state.setTagList,
    resetFolderStore: state.resetFolderStore,
  }));

  const { setCurrentFolderId } = useFolderHistoryStore((state) => ({
    setCurrentFolderId: state.setCurrentFolderId,
  }));

  //#region 폴더 생성
  /**
   *
   * @param parentFolderId
   * @returns
   */
  const createFolderMutation = useMutation((request: CreateFolderDto) =>
    createFolderAPI(request)
  );
  const createFolderRequest = (parentFolderId: number) => {
    if (
      createFolderMutation.isLoading ||
      !isFolderValid(koreanName, englishName, folderAuthList)
    ) {
      return;
    }

    openLoading();
    const createFolderDto: CreateFolderDto = {
      koreanName: koreanName,
      englishName: englishName,
      createFolderAuthList: folderAuthList.map((auth) => ({
        permissionType: auth.permissionType,
        userId: auth.userId,
      })),
      createTagList: tagList.map((tag) => ({ text: tag.text })),
      isSecretFolder: isSecretFolder,
      parentFolderId: parentFolderId,
    };

    createFolderMutation.mutate(createFolderDto, {
      onSuccess: () => {
        ToastAlert(t("폴더가 생성되었습니다."), "success");
        queryClient.invalidateQueries(QueryKeys.subFolderList(parentFolderId));
        queryClient.invalidateQueries(QueryKeys.folderPathList(parentFolderId));
        queryClient.invalidateQueries(QueryKeys.folderAuthTree());
        closeFolderModal();
        resetFolderStore();
      },
      onError: () => {
        ToastAlert(
          t("폴더 생성 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
          "error"
        );
      },
      onSettled: () => {
        closeLoading();
      },
    });
  };
  //#endregion

  //#region 폴더 수정
  /**
   * @param parentFolderId
   * @returns
   */
  const updateFolderMutation = useMutation((request: UpdateFolderDto) =>
    updateFolderAPI(request)
  );
  const updateFolderRequest = (currentFolderId: number) => {
    if (
      updateFolderMutation.isLoading ||
      !isFolderValid(koreanName, englishName, folderAuthList)
    ) {
      return;
    }

    openLoading();
    const updateFolderDto: UpdateFolderDto = {
      id: currentFolderId,
      koreanName: koreanName,
      englishName: englishName,
      parentFolderId: parentFolderId,
      updateFolderAuthList: folderAuthList.map((auth) => ({
        folderAuthId: auth.folderAuthId,
        permissionType: auth.permissionType,
        userId: auth.userId,
      })),
      updateTagList: tagList.map((tag) => ({
        id: tag.id,
        text: tag.text,
      })),
    };

    updateFolderMutation.mutate(updateFolderDto, {
      onSuccess: () => {
        ToastAlert(t("폴더가 수정되었습니다."), "success");
        queryClient.invalidateQueries(QueryKeys.folderDetail(currentFolderId));
        queryClient.invalidateQueries(QueryKeys.subFolderList(currentFolderId));
        queryClient.invalidateQueries(
          QueryKeys.tagListInfolder(currentFolderId)
        );
        queryClient.invalidateQueries(
          QueryKeys.folderPathList(currentFolderId)
        );
        queryClient.invalidateQueries(
          QueryKeys.folderAuthCheck(currentFolderId)
        );
        queryClient.invalidateQueries(QueryKeys.allTagTextList());
        queryClient.invalidateQueries(QueryKeys.folderAuthTree());
        closeFolderModal();
        resetFolderStore();
      },
      onError: () => {
        ToastAlert(
          t("폴더 수정 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
          "error"
        );
      },
      onSettled: () => {
        closeLoading();
      },
    });
  };
  //#endregion

  //#region 폴더 삭제
  const deleteFolderMutation = useMutation((deleteFolderId: number) =>
    deleteFolderAPI(deleteFolderId)
  );
  const deleteFolderRequest = (selectedFolderIdList: number[]) => {
    if (deleteFolderMutation.isLoading) {
      return;
    }

    openLoading();

    const deleteFolderPromiseList = selectedFolderIdList.map(
      (deleteFolderId) => {
        return new Promise((resolve, reject) => {
          deleteFolderMutation.mutate(deleteFolderId, {
            onSuccess: () => {
              resolve(true);
            },
            onError: (error) => {
              reject(error);
            },
          });
        });
      }
    );

    Promise.all(deleteFolderPromiseList)
      .then(() => {
        ToastAlert(t("폴더가 삭제되었습니다."), "success");
        queryClient.invalidateQueries(
          QueryKeys.folderPathList(currentFolderId)
        );
        queryClient.invalidateQueries(QueryKeys.folderAuthTree());
        queryClient.invalidateQueries(QueryKeys.subFolderList(currentFolderId));
      })
      .catch(() => {
        ToastAlert(
          t("폴더 삭제 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
          "error"
        );
      })
      .finally(() => {
        closeLoading();
      });
  };
  //#endregion

  //#region 폴더 위치 이동
  const changeFolderLocationMutation = useMutation(
    (request: ChangeFolderLocationDto) => changeFolderParentAPI(request)
  );
  const changeFolderLocationAsync = async (
    folderIdList: number[],
    targetParentFolderId: number
  ) => {
    if (changeFolderLocationMutation.isLoading) {
      return;
    }
    try {
      openLoading();

      await Promise.all(
        folderIdList.map((folderId) => {
          const requestDto: ChangeFolderLocationDto = {
            folderId: folderId,
            targetParentFolderId: targetParentFolderId,
          };
          return changeFolderLocationMutation.mutateAsync(requestDto);
        })
      );

      ToastAlert(t("정상적으로 이동되었습니다."), "success");
      queryClient.invalidateQueries(QueryKeys.fileList(currentFolderId));
      queryClient.invalidateQueries(QueryKeys.subFolderList(currentFolderId));
      queryClient.invalidateQueries(QueryKeys.folderAuthTree());
    } catch {
      ToastAlert(
        t("위치 이동 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
        "error"
      );
    } finally {
      closeLoading();
    }
  };
  //#endregion

  //#region 폴더 초기값 세팅
  const useCreateFolderStore = (
    modalType: FolderModalType,
    isFolderModalOpen: boolean,
    folderDetail?: FolderDetailDto
  ) => {
    useEffect(() => {
      if (folderDetail && isFolderModalOpen) {
        if (modalType === "create") {
          createStore({
            isSecretFolder: folderDetail.isSecretFolder,
            folderAuthList: folderDetail.folderAuthList,
          });
        } else if (modalType === "update") {
          createStore({
            koreanName: folderDetail.koreanName,
            englishName: folderDetail.englishName,
            parentFolderId: folderDetail.parentFolderId,
            isSecretFolder: folderDetail.isSecretFolder,
            folderAuthList: folderDetail.folderAuthList,
          });
          setTagList(folderDetail.tagList);
        }
      }
    }, [folderDetail, modalType, isFolderModalOpen]);
  };
  //#endregion
  return {
    createFolderRequest,
    updateFolderRequest,
    deleteFolderRequest,
    changeFolderLocationAsync,
    useCreateFolderStore,
  };
};
export default useFolderHooks;
