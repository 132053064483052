import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Divider, Grid, Switch } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchQuizDetail from "../../api/domains/quiz/queries/useFetchQuizDetail";
import TitleDiv from "../../components/atoms/TitleDiv";
import { CustomText } from "../../components/text/CustomText";
import useQuizHooks from "../../hooks/domains/useQuizHooks";
import usePostQuestionStore from "../../stores/domains/usePostQuestionStore";
import usePostQuizStore from "../../stores/domains/usePostQuizStore";
import { InbodyRed } from "../../styles/GlobalStyle";
import PostQuestionItem from "./components/PostQuestionItem";
import PostQuizFloatingButton from "./components/PostQuizFloatingButton";
import PostQuizInfo from "./components/PostQuizInfo";
import PostQuizVersion from "./components/PostQuizVersion";

export interface PostQuizPageProps {
  isCreate?: boolean;
}

export default function PostQuizPage({ isCreate = false }: PostQuizPageProps) {
  const { t } = useTranslation();
  const quizId = Number(useParams().quizId);

  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [useEditorQuestionIndex, setUseEditorQuestionIndex] = useState(-1);

  const { updatePostQuizValue, createNewQuestion } = usePostQuizStore(
    (state) => ({
      updatePostQuizValue: state.updateValue,
      createNewQuestion: state.createNewQuestion,
    })
  );

  const { questionList, appendNewQuestion } = usePostQuestionStore((state) => ({
    questionList: state.questionList,
    appendNewQuestion: state.appendNewQuestion,
  }));

  /** react-query */
  const { data: quizDetail } = useFetchQuizDetail(quizId);

  const { useCreateQuizStore, handleSubmitQuiz, navigatePostQuizListPage } =
    useQuizHooks();

  useCreateQuizStore(quizDetail);

  const totalScore = useMemo(
    () => questionList.reduce((total, q) => total + Number(q.maxScore), 0),
    [questionList]
  );

  return (
    <Grid container rowSpacing={1} mt={0}>
      <Grid item xs={12}>
        <TitleDiv title={isCreate ? t("시험 생성") : t("시험 수정")} />
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="text"
            color="success"
            onClick={() => {
              updatePostQuizValue({ isSubmit: true });
              handleSubmitQuiz(isCreate, quizId);
            }}
          >
            <CustomText type="buttonLarge">{t("저장하기")}</CustomText>
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={navigatePostQuizListPage}
          >
            <CustomText type="buttonLarge">{t("목록으로")}</CustomText>
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <PostQuizInfo />
      </Grid>

      <Grid item xs={12}>
        <Box display={"flex"} alignItems={"center"}>
          <CustomText type="subtitle">{t("문제 추가/편집")}</CustomText>
          <Switch
            disabled={isCreate}
            id={`create-question-switch`}
            color="success"
            checked={isCreate || createNewQuestion}
            onChange={(e) =>
              updatePostQuizValue({ createNewQuestion: e.target.checked })
            }
          />
        </Box>
      </Grid>

      {(isCreate || createNewQuestion) && (
        <>
          <Grid item xs={12}>
            <TitleDiv title={t("문제 등록")} />
            <PostQuizVersion totalScore={totalScore} isCreate={isCreate} />
          </Grid>

          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"end"} mt={1}>
              <CustomText type="subtitle">
                {`총 문제수: ${questionList.length} 문제`}
              </CustomText>
              <Divider sx={{ height: 15, margin: 1 }} orientation="vertical" />
              <CustomText type="subtitle">{`총 점수: ${totalScore} 점`}</CustomText>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {
              <Grid container spacing={5} mt={0}>
                {questionList.map((question, index) => (
                  <PostQuestionItem
                    key={question.questionId}
                    question={question}
                    totalScore={totalScore}
                    questionIndex={index}
                    setIsDragEnabled={setIsDragEnabled}
                    useEditorQuestionIndex={useEditorQuestionIndex}
                    setUseEditorQuestionIndex={setUseEditorQuestionIndex}
                  />
                ))}
              </Grid>
            }
            {questionList.length === 0 && (
              <Grid item xs={12}>
                <Box border={`1px solid ${InbodyRed}`} p={1}>
                  <CustomText type="subtitle" align="center">
                    {t("시험 문제가 없습니다. 문제를 추가해주세요.")}
                  </CustomText>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} mt={3}>
              <Button
                fullWidth
                sx={{
                  m: 0,
                  bgcolor: InbodyRed,
                  "&:hover": {
                    bgcolor: InbodyRed,
                  },
                }}
                variant="contained"
                onClick={() => appendNewQuestion()}
                startIcon={<AddCircleOutlineIcon />}
              >
                <CustomText type="button">{t("문제 추가")}</CustomText>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <PostQuizFloatingButton isCreate={isCreate} />
    </Grid>
  );
}
