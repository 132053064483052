import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import useFetchTagListInFolder from "../../api/domains/tag/queries/useFetchTagListInFolder";
import { CustomText } from "../../components/text/CustomText";
import CustomTextTooltip from "../../components/text/CustomTextTooltip";
import useDateHooks from "../../hooks/common/useDateHooks";
import useUnitHooks from "../../hooks/common/useUnitHooks";
import useFileHooks from "../../hooks/domains/useFileHooks";
import { useFileModalStore } from "../../stores/file/useFileModalStore";
import { useFileStore } from "../../stores/file/useFileStore";
import { useFolderHistoryStore } from "../../stores/folder/useFolderHistoryStore";
import {
  BorderGray,
  BorderPale,
  TitleGreen,
  TitleRed,
} from "../../styles/GlobalStyle";

export default function PostFileModal() {
  const { t } = useTranslation();
  const fileUploadButton = useRef<HTMLInputElement>(null);
  const folderUploadButton = useRef<HTMLInputElement>(null);
  const [isComplete, setIsComplete] = useState(false);

  //Store
  const { isFileModalOpen, fileModalType, closeModal } = useFileModalStore(
    (state) => ({
      isFileModalOpen: state.isFileModalOpen,
      fileModalType: state.fileModalType,
      closeModal: state.closeModal,
    })
  );
  const currentFolderId = useFolderHistoryStore(
    (state) => state.currentFolderId
  );

  const { fileList, setTagIdList, addFile, removeFile, resetStore } =
    useFileStore((state) => ({
      fileList: state.fileList,
      setTagIdList: state.setTagIdList,
      addFile: state.addFile,
      removeFile: state.removeFile,
      resetStore: state.resetStore,
    }));

  //Data Fetching and Set
  const { data: tagList } = useFetchTagListInFolder(currentFolderId);

  //Hooks
  const { createFileRequestAsync, updateFileRequestAsync } = useFileHooks();
  const { getDateStringFormat } = useDateHooks();
  const { fileSizeUnit } = useUnitHooks();

  return (
    <Modal open={isFileModalOpen}>
      <ModalContent>
        <TitleWrapper>
          <CustomText type="title">
            {fileModalType === "create"
              ? t("파일 업로드")
              : t("파일 태그 수정")}
          </CustomText>
        </TitleWrapper>
        <FileInputWrapper>
          {fileModalType === "create" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  top: 0,
                  position: "sticky",
                  borderBottom: `1px solid ${BorderPale}`,
                  bgcolor: "white",
                  zIndex: 100,
                }}
              >
                <Button
                  sx={{ borderRadius: 0 }}
                  fullWidth
                  variant="contained"
                  onClick={() => fileUploadButton.current?.click()}
                  startIcon={<UploadFileIcon />}
                >
                  <CustomText type="button">{t("파일 업로드")}</CustomText>
                </Button>
                <input
                  type="file"
                  multiple
                  ref={fileUploadButton}
                  onChange={(e) => {
                    if (e.target.files) {
                      Array.from(e.target.files).forEach((file) => {
                        addFile({
                          file: file,
                          fileId: 0,
                          fileSize: file.size,
                          originFileName: file.name,
                          uploadFileName: `${getDateStringFormat(
                            Date(),
                            "YYYY-MM-DD"
                          )}_${v4()}`,
                          thumbnailUrl: "",
                          createdAt: "",
                          updatedAt: "",
                          uploadProgress: 0,
                          tagIdList: [],
                          requestResult: "",
                          folderPath: "",
                        });
                      });
                    }
                  }}
                  style={{ opacity: 0, width: 0 }}
                />
                <Button
                  sx={{ borderRadius: 0 }}
                  fullWidth
                  variant="contained"
                  color="warning"
                  onClick={() => folderUploadButton.current?.click()}
                  startIcon={<DriveFolderUploadIcon />}
                >
                  <CustomText type="button">{t("폴더 업로드")}</CustomText>
                </Button>
                <input
                  type="file"
                  multiple
                  webkitdirectory=""
                  directory=""
                  ref={folderUploadButton}
                  onChange={(e) => {
                    const files = e.target.files;
                    if (files) {
                      Array.from(files).forEach((file) => {
                        const fileFullPath = file.webkitRelativePath;
                        addFile({
                          file: file,
                          fileId: 0,
                          fileSize: file.size,
                          originFileName: file.name,
                          uploadFileName: `${getDateStringFormat(
                            Date(),
                            "YYYY-MM-DD"
                          )}_${v4()}`,
                          thumbnailUrl: "",
                          createdAt: "",
                          updatedAt: "",
                          uploadProgress: 0,
                          tagIdList: [],
                          requestResult: "",
                          folderPath: fileFullPath.substring(
                            0,
                            fileFullPath.lastIndexOf("/")
                          ),
                        });
                      });
                    }
                  }}
                  style={{ opacity: 0, width: 0 }}
                />
              </Box>
            </>
          )}
          <List sx={{ pb: 0, pt: 0 }}>
            {fileList &&
              fileList.map((file) => {
                return (
                  <ListItem key={file.uploadFileName} sx={{ pr: 0 }} divider>
                    <FileInfoWrapper>
                      <Table sx={{ tableLayout: "fixed" }}>
                        <TableBody>
                          <TableRow>
                            <TableCell width="20%">
                              <CustomText type="body" bold500>
                                {t("파일명")}
                              </CustomText>
                            </TableCell>
                            <TableCell width="80%">
                              <CustomTextTooltip type="body">
                                {file.originFileName}
                              </CustomTextTooltip>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <CustomText type="body" bold500>
                                {t("파일 크기")}
                              </CustomText>
                            </TableCell>
                            <TableCell>
                              <CustomText type="body">
                                {fileSizeUnit(file.fileSize)}
                              </CustomText>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <CustomText type="body" bold500>
                                {t("태그 선택")}
                              </CustomText>
                            </TableCell>
                            <TableCell>
                              {tagList && (
                                <Autocomplete
                                  id={`tag-select-${file.originFileName}`}
                                  multiple
                                  size="small"
                                  options={tagList}
                                  getOptionLabel={(option) => option.text}
                                  onChange={(e, newTagList) => {
                                    const newTagIdList = newTagList.map(
                                      (tag) => tag.id
                                    );
                                    setTagIdList(
                                      file.originFileName,
                                      newTagIdList
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label={
                                        <CustomText type={"small"}>
                                          {t("태그 선택")}
                                        </CustomText>
                                      }
                                    />
                                  )}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          {fileModalType === "create" && (
                            <TableRow>
                              <TableCell>
                                <CustomText type="body" bold500>
                                  {t("업로드 현황")}
                                </CustomText>
                              </TableCell>
                              <TableCell>
                                <Box sx={{ width: "100%", pt: 1 }}>
                                  <LinearProgress
                                    variant="determinate"
                                    value={file.uploadProgress}
                                  />
                                  <Box
                                    sx={{
                                      height: "15px",
                                      width: "100%",
                                      color:
                                        file.requestResult ===
                                        t("파일이 정상적으로 업로드되었습니다.")
                                          ? TitleGreen
                                          : TitleRed,
                                    }}
                                  >
                                    {file.requestResult}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </FileInfoWrapper>
                    {fileModalType === "create" && (
                      <DeleteButtonWrapper>
                        <IconButton
                          color="error"
                          onClick={() => removeFile(file.originFileName)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </DeleteButtonWrapper>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </FileInputWrapper>
        <ButtonWrapper>
          {!isComplete && (
            <>
              <Button
                sx={{ mr: 2 }}
                color="inherit"
                variant="contained"
                onClick={() => {
                  if (fileModalType === "create") {
                    createFileRequestAsync(currentFolderId);
                  } else if (fileModalType === "update") {
                    updateFileRequestAsync(currentFolderId);
                  }
                  setIsComplete(true);
                }}
              >
                <CustomText type="button">{t("저장")}</CustomText>
              </Button>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => {
                  resetStore();
                  closeModal();
                }}
              >
                <CustomText type="button">{t("닫기")}</CustomText>
              </Button>
            </>
          )}
          {isComplete && (
            <Button
              color="inherit"
              variant="contained"
              onClick={() => {
                resetStore();
                closeModal();
                setIsComplete(false);
              }}
            >
              <CustomText type="button">{t("완료")}</CustomText>
            </Button>
          )}
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  height: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #ffffff;
  box-shadow: 24px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px;
`;
const FileInputWrapper = styled.div`
  margin: 16px;
  border: 1px solid ${BorderGray};
  border-radius: 3px;
  height: 80%;
  overflow-y: auto;
  position: relative;
`;
const FileInfoWrapper = styled.div`
  width: calc(90% - 30px);
  margin-right: 30px;
  & td,
  th {
    padding: 0px;
    border-bottom: none;
    height: 30px;
  }
`;
const DeleteButtonWrapper = styled.div`
  width: 10%;
  display: flex;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
