import { TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FetchManagingCourseList } from "../../../api/domains/course/CourseType";
import { CustomText } from "../../../components/text/CustomText";
import Urls from "../../../routes/Urls";
import { useUserStore } from "../../../stores/user/useUserStore";
import { TitleBlue, TitleRed } from "../../../styles/GlobalStyle";
import { Dispatch, SetStateAction, memo } from "react";

interface AdminCourseItemProps {
  course: FetchManagingCourseList;
  index: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedCourseName: Dispatch<SetStateAction<string>>;
  setSelectedCourseId: Dispatch<SetStateAction<number>>;
}

function AdminCourseItem({
  course,
  index,
  setOpen,
  setSelectedCourseId,
  setSelectedCourseName,
}: AdminCourseItemProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAdmin = useUserStore((state) => state.isAdmin);

  return (
    <TableRow key={course.courseId}>
      <TableCell align="center">{index + 1}</TableCell>
      <TableCell>
        <CustomText type="body">{course.courseName}</CustomText>
      </TableCell>
      <TableCell align="center">
        <CustomText type="body">{course.professorName ?? "-"}</CustomText>
      </TableCell>
      <TableCell
        sx={{ cursor: "pointer", color: TitleBlue }}
        className="course-management-button"
        align="center"
        onClick={() =>
          navigate(Urls.courseModify.replace(":id", String(course.courseId)))
        }
      >
        <CustomText type="body" bold500>
          {t("관리하기")}
        </CustomText>
      </TableCell>
      {isAdmin && (
        <TableCell
          sx={{
            cursor: "pointer",
            color: TitleRed,
          }}
          className="course-delete-button"
          align="center"
          onClick={() => {
            setOpen(true);
            setSelectedCourseName(course.courseName);
            setSelectedCourseId(course.courseId);
          }}
        >
          <CustomText type="body" bold500>
            {t("삭제하기")}
          </CustomText>
        </TableCell>
      )}
    </TableRow>
  );
}

export default memo(AdminCourseItem);
