import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Table, TableCell, TableRow, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { QueryKeys } from "../../../api/config/QueryKeys";
import {
  CreateNotice,
  UpdateNotice,
} from "../../../api/domains/notice/NoticeType";
import useNoticeAPIs from "../../../api/domains/notice/useNoticeAPIs";
import CustomButton from "../../../components/button/CustomButton";
import ErrorText from "../../../components/react-hook-form/ErrorText";
import RHFTextEditor from "../../../components/textEditor/RHFTextEditor";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import useHandlePostRequest from "../../../hooks/common-form-hooks/useHandlePostRequest";
import { notEmptyHtmlContent } from "../../../hooks/common-form-hooks/validate";
import Urls from "../../../routes/Urls";
import { FlexCenter } from "../../../styles/GlobalStyle";
import PageHeader from "../../../components/templates/PageHeader";

interface NoticeFormProps {
  isCreate: boolean;
  title?: string;
  content?: string;
}

interface DataProps {
  title: string;
  content: string;
}

export default function NoticeForm({
  isCreate,
  title = "",
  content = "",
}: NoticeFormProps) {
  const noticeId = Number(useParams().id);
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* react-query */
  const { createNoticeAPI, modifyNoticeAPI } = useNoticeAPIs();
  const { handleSuccess, handleError } = useHandlePostRequest();

  const noticeCreateQuery = useMutation((request: CreateNotice) =>
    createNoticeAPI(request)
  );
  const noticeModifyQuery = useMutation((request: UpdateNotice) =>
    modifyNoticeAPI(request)
  );

  /* react-hook-form */
  const schema = yup
    .object({
      title: yup.string().trim().required(t("제목이 비어있습니다.")),
      content: notEmptyHtmlContent(t("내용이 비어있습니다.")).required(
        t("내용이 비어있습니다.")
      ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<DataProps>({
    defaultValues: {
      title: title,
      content: content,
    },
    resolver: yupResolver(schema),
  });

  /** submit */
  const submit = (
    { title, content }: DataProps,
    isCreate: boolean,
    noticeId: number | undefined
  ) => {
    if (isCreate) {
      noticeCreateQuery.mutate(
        { title, content },
        {
          onSuccess: () => {
            handleSuccess(
              t("공지사항이 등록되었습니다."),
              QueryKeys.noticeList(),
              Urls.notice
            );
          },
          onError: () =>
            handleError(
              t(
                "공지사항 등록 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."
              )
            ),
        }
      );
      return;
    }

    if (!noticeId) return;
    noticeModifyQuery.mutate(
      { noticeId, title, content },
      {
        onSuccess: () => {
          handleSuccess(
            t("공지사항이 수정되었습니다."),
            QueryKeys.noticeList(),
            Urls.notice
          );
        },
        onError: () =>
          handleError(
            t(
              "공지사항 등록 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."
            )
          ),
      }
    );
  };

  return (
    <form onSubmit={handleSubmit((data) => submit(data, isCreate, noticeId))}>
      <Grid container rowSpacing={3} pt={0}>
        <Grid item xs={12}>
          <PageHeader title={t("공지사항")} />
        </Grid>
        <Grid item xs={12}>
          <CustomTableWrapper>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" component={"th"} width={"15%"}>
                    <CustomText type="subtitle">{t("제목")}</CustomText>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      margin="none"
                      fullWidth
                      placeholder={t("제목을 입력하세요.")}
                      {...register("title")}
                      variant="outlined"
                    />
                    <ErrorText message={errors.title?.message} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" component={"th"} width={"15%"}>
                    <CustomText type="subtitle">{t("내용")}</CustomText>
                  </TableCell>
                  <TableCell>
                    <RHFTextEditor name="content" control={control} />
                    <ErrorText message={errors.content?.message} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CustomTableWrapper>
        </Grid>
        <Grid item xs={12}>
          <ButtonWrapper>
            <CustomButton type="submit" colorType="inbodyRed">
              <CustomText type="button">{t("저장하기")}</CustomText>
            </CustomButton>
            <CustomButton
              colorType="black"
              onClick={() => navigate(Urls.notice)}
            >
              <CustomText type="button">{t("목록으로")}</CustomText>
            </CustomButton>
          </ButtonWrapper>
        </Grid>
      </Grid>
    </form>
  );
}

const TableBody = styled.tbody`
  font-size: 17px;
  & a {
    text-decoration: none;
    color: black;
  }
`;
const ButtonWrapper = styled.div`
  ${FlexCenter}
  & button {
    min-width: 100px;
    height: 40px;
    padding: 7px 20px;
  }
`;
