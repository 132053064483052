import styled from "@emotion/styled";
import ArchiveIcon from "@mui/icons-material/Archive";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ForumIcon from "@mui/icons-material/Forum";
import QuizIcon from "@mui/icons-material/Quiz";
import SchoolIcon from "@mui/icons-material/School";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomText } from "../components/text/CustomText";
import { MenuMaxWidth } from "../data/common/MenuSize";
import Urls from "../routes/Urls";
import { useLayoutStore } from "../stores/layout/useLayoutStore";
import { useUserStore } from "../stores/user/useUserStore";
import { Language } from "../data/common/Language";

export default function Menu() {
  const theme = createTheme({});
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMenuOpen, closeMenu } = useLayoutStore();
  const [isCourseMenuSelected, setIsCourseMenuSelected] = useState(true);
  const [isQuizMenuSelected, setIsQuizMenuSelected] = useState(true);
  const [isCommMenuSelected, setIsCommMenuSelected] = useState(true);
  const [isAdminMenuSelected, setIsAdminMenuSelected] = useState(true);
  const { isAdmin, isProfessor } = useUserStore((state) => ({
    isAdmin: state.isAdmin,
    isProfessor: state.isProfessor,
  }));

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        anchor={"left"}
        open={isMenuOpen}
        hideBackdrop={true}
        variant={"persistent"}
        onClose={closeMenu}
      >
        <LeftMenuWrapper>
          {/** 메인페이지 */}
          <ListItemButton
            sx={{ ...mainItemStyle }}
            onClick={() => navigate(Urls.main)}
            selected={location.pathname === Urls.main}
          >
            <IconWrapper>
              <DashboardIcon />
            </IconWrapper>
            <ItemTextWrapper>
              <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                {t("메인페이지")}
              </CustomText>
            </ItemTextWrapper>
          </ListItemButton>
          {/** 학습창고 */}
          <ListItemButton
            sx={{ ...mainItemStyle }}
            onClick={() => setIsCourseMenuSelected((pre) => !pre)}
            selected={location.pathname.includes(Urls.course)}
          >
            <IconWrapper>
              <SchoolIcon />
            </IconWrapper>
            <ItemTextWrapper>
              <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                {t("학습창고")}
              </CustomText>
            </ItemTextWrapper>
            {isCourseMenuSelected ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {/** Sub Menu (학습창고) */}
          <Collapse
            sx={{ ...collapseStyle }}
            in={isCourseMenuSelected}
            timeout="auto"
            unmountOnExit
          >
            {i18n.language === Language.KOREAN && (
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.freshmanCourseList)}
                  onClick={() => navigate(Urls.freshmanCourseList)}
                >
                  <CustomText type="body">{t("신규입사자교육")}</CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.jobCourseList)}
                  onClick={() => navigate(Urls.jobCourseList)}
                >
                  <CustomText type="body">{t("직무교육")}</CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.dutyCourseList)}
                  onClick={() => navigate(Urls.dutyCourseList)}
                >
                  <CustomText type="body">{t("법정의무교육")}</CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(
                    Urls.leadershipCourseList
                  )}
                  onClick={() => navigate(Urls.leadershipCourseList)}
                >
                  <CustomText type="body">{t("리더십교육")}</CustomText>
                </ListItemButton>
              </List>
            )}
            {i18n.language === Language.ENGLISH && (
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.productCourseList)}
                  onClick={() => navigate(Urls.productCourseList)}
                >
                  <CustomText type="body">{t("Product training")}</CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(
                    Urls.applicationCourseList
                  )}
                  onClick={() => navigate(Urls.applicationCourseList)}
                >
                  <CustomText type="body">
                    {t("Applications training")}
                  </CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.clinicalCourseList)}
                  onClick={() => navigate(Urls.clinicalCourseList)}
                >
                  <CustomText type="body">{t("Clinical training")}</CustomText>
                </ListItemButton>
                <ListItemButton
                  sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                  selected={location.pathname.includes(Urls.otherCourseList)}
                  onClick={() => navigate(Urls.otherCourseList)}
                >
                  <CustomText type="body">{t("Others")}</CustomText>
                </ListItemButton>
              </List>
            )}
          </Collapse>

          {/** 시험창구 */}
          <ListItemButton
            sx={{ ...mainItemStyle }}
            onClick={() => setIsQuizMenuSelected((pre) => !pre)}
            selected={location.pathname.includes(Urls.quiz)}
          >
            <IconWrapper>
              <QuizIcon />
            </IconWrapper>
            <ItemTextWrapper>
              <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                {t("시험창구")}
              </CustomText>
            </ItemTextWrapper>
            {isQuizMenuSelected ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {/** Sub Menu (시험창구) */}
          <Collapse
            sx={{ ...collapseStyle }}
            in={isQuizMenuSelected}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                selected={location.pathname.includes(Urls.quizList)}
                onClick={() => navigate(Urls.quizList)}
              >
                <CustomText type="body">{t("시험목록")}</CustomText>
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                selected={location.pathname.includes(Urls.quizScore)}
                onClick={() => navigate(Urls.quizScore)}
              >
                <CustomText type="body">{t("결과확인")}</CustomText>
              </ListItemButton>
            </List>
          </Collapse>

          {/** 자료창고 */}
          <ListItemButton
            sx={{ ...mainItemStyle }}
            onClick={() => navigate(Urls.archive)}
            selected={location.pathname.includes(Urls.archive)}
          >
            <IconWrapper>
              <ArchiveIcon />
            </IconWrapper>
            <ItemTextWrapper>
              <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                {t("자료창고")}
              </CustomText>
            </ItemTextWrapper>
          </ListItemButton>

          {/** 소통창구 */}
          <ListItemButton
            sx={{ ...mainItemStyle }}
            onClick={() => setIsCommMenuSelected((pre) => !pre)}
            selected={location.pathname.includes(Urls.communication)}
          >
            <IconWrapper>
              <ForumIcon />
            </IconWrapper>
            <ItemTextWrapper>
              <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                {t("소통창구")}
              </CustomText>
            </ItemTextWrapper>
            {isCommMenuSelected ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {/** Sub Menu (소통창구) */}
          <Collapse
            sx={{ ...collapseStyle }}
            in={isCommMenuSelected}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                selected={location.pathname.includes(Urls.notice)}
                onClick={() => navigate(Urls.notice)}
              >
                <CustomText type="body">{t("공지사항")}</CustomText>
              </ListItemButton>
              <ListItemButton
                sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                selected={location.pathname.includes(Urls.qna)}
                onClick={() => navigate(Urls.qna)}
              >
                <CustomText type="body">{t("문의사항")}</CustomText>
              </ListItemButton>
            </List>
          </Collapse>

          {/** 관리자 페이지 */}
          {
            // 강사 이상만 볼 수 있음.
            (isAdmin || isProfessor) && (
              <>
                <ListItemButton
                  sx={{ ...mainItemStyle }}
                  onClick={() => setIsAdminMenuSelected((pre) => !pre)}
                  selected={location.pathname.includes(Urls.admin)}
                >
                  <IconWrapper>
                    <SupervisorAccountIcon />
                  </IconWrapper>
                  <ItemTextWrapper>
                    <CustomText type="subtitle" bold400 paddingLeft={"6px"}>
                      {t("관리자페이지")}
                    </CustomText>
                  </ItemTextWrapper>
                  {isAdminMenuSelected ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  sx={{ ...collapseStyle }}
                  in={isAdminMenuSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                      selected={location.pathname.includes(Urls.mailManagement)}
                      onClick={() => navigate(Urls.mailManagement)}
                    >
                      <CustomText type="body">{t("메일발송")}</CustomText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                      selected={location.pathname.includes(
                        Urls.courseManagement
                      )}
                      onClick={() => navigate(Urls.courseManagement)}
                    >
                      <CustomText type="body">{t("강의관리")}</CustomText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                      selected={location.pathname.includes(
                        Urls.studentManagement
                      )}
                      onClick={() => navigate(Urls.studentManagement)}
                    >
                      <CustomText type="body">{t("수강생관리")}</CustomText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                      selected={location.pathname.includes(Urls.quizManagement)}
                      onClick={() => navigate(Urls.quizManagement)}
                    >
                      <CustomText type="body">{t("시험관리")}</CustomText>
                    </ListItemButton>
                    <ListItemButton
                      sx={{ paddingLeft: "70px", ...collapseItemStyle }}
                      selected={location.pathname.includes(Urls.fileManagement)}
                      onClick={() => navigate(Urls.fileManagement)}
                    >
                      <CustomText type="body">{t("자료관리")}</CustomText>
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )
          }
        </LeftMenuWrapper>
      </Drawer>
    </ThemeProvider>
  );
}
const LeftMenuWrapper = styled.div`
  z-index: 50;
  position: fixed;
  top: 64px;
  left: 0;
  height: 100%;
  width: ${MenuMaxWidth}px;
  background-color: rgb(60, 60, 59);
  & * {
    color: #ffffff;
  }
`;
const IconWrapper = styled.div`
  margin: 2px;
  margin-right: 15px;
  & svg {
    font-size: 24px;
    vertical-align: middle;
    margin: 6px 0;
  }
`;
const ItemTextWrapper = styled.div`
  width: calc(100% - 74px);
`;
const collapseStyle = {
  color: "rgb(221, 221, 221)",
  backgroundColor: "rgb(84, 84, 83)",
};
const collapseItemStyle = {
  "&&:hover": {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
};
const mainItemStyle = {
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
  "&&.Mui-selected:hover": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
};
