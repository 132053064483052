import useGetApiInstance from '../../config/BasicApi';
import { CreateReply } from './ReplyType';


export const useReplyAPIs = () => {
    const api = useGetApiInstance('reply');

    // Reply 생성
    const createReplyAPI = (createReply : CreateReply) => api.post('/create', createReply);
    
    return {
        createReplyAPI
    }
}

export default useReplyAPIs;