import { DocumentState } from "../../stores/domains/usePostDocumentStore";

export const isDocumentValid = (document: DocumentState) => {
  const isTitleValid = document.title.trim() !== "";
  const isFileValid = isDocumentFileValid(document);

  return isTitleValid && isFileValid;
};

export const isDocumentFileValid = (document: DocumentState) => {
  const isFileValid =
    document.documentId !== null ||
    (document.documentFile !== null && document.documentFile.size > 0);

  return isFileValid;
};
