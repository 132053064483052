import { useMutation } from "react-query";
import useEmailTargetStore from "../../stores/domains/useEmailTargetStore";
import { SendEmailRequest } from "../../api/domains/email/EmailType";
import { useTranslation } from "react-i18next";
import useEmailAPIs from "../../api/domains/email/useEmailAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import { isEmailValid } from "../validation/isEmailValid";
import CrossEditor from "../../components/textEditor/CrossEditor";
import { RefObject } from "react";

const useEmailHooks = () => {
  const { t } = useTranslation();
  const { sendEmailAPI } = useEmailAPIs();

  const { subject, body, to, cc, bcc, setIsSubmit, resetStore } =
    useEmailTargetStore((state) => ({
      subject: state.subject,
      body: state.body,
      to: state.to,
      cc: state.cc,
      bcc: state.bcc,
      setIsSubmit: state.setIsSubmit,
      resetStore: state.resetStore,
    }));

  //#region 메일 발송
  const sendEmailQuery = useMutation((request: SendEmailRequest) =>
    sendEmailAPI(request)
  );
  const sendEmail = (editorRef: RefObject<CrossEditor>) => {
    setIsSubmit(true);

    const request: SendEmailRequest = {
      subject: subject,
      body: body,
      to: Array.from(to),
      cc: Array.from(cc),
      bcc: Array.from(bcc),
    };

    if (to.size === 0 || !isEmailValid(subject, body)) {
      return;
    }
    const editor = editorRef.current?.editorRef;

    sendEmailQuery.mutate(request, {
      onSuccess: () => {
        ToastAlert(t("메일이 정상적으로 발송되었습니다."), "success");
      },
      onError: () => {
        ToastAlert(
          t("메일 발송중 에러가 발생했습니다. 관리자에게 문의해주세요."),
          "error"
        );
      },
      onSettled: () => {
        resetStore();
        editor.SetValue("");
      },
    });
  };
  //#endregion

  return {
    sendEmail,
  };
};

export default useEmailHooks;
