import authConfig from "./api/config/MsalConfig";
import App from "./App";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { registerLicense } from "@syncfusion/ej2-base";
import "./index.css";
import "./locales/i18n";
import { StrictMode } from "react";

const msalInstance = new PublicClientApplication(authConfig.msal);

registerLicense(
  "ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZRcUVgWn5bcXJXT2k="
);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <MsalProvider instance={msalInstance}>
    {process.env.NODE_ENV === "development" ? (
      <StrictMode>
        <App />
      </StrictMode>
    ) : (
      <App />
    )}
  </MsalProvider>
);
