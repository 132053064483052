import useGetApiInstance from '../../config/BasicApi';
import { CreateQna, FetchQnaDetail, FetchQnaList } from './QnaType';

const useQnaAPIs = () => {
    const api = useGetApiInstance('qna');

    // Qna Type 조회
    const getQnaTypeListAPI = (): Promise<string[]> =>
        api.get("/typeList").then(res => res.data.result);

    // Qna 전체 조회
    const getQnaListAPI = (): Promise<FetchQnaList[]> =>
        api.get("").then(res => res.data.result);

    // QnA 상세 조회
    const getDetailQnaAPI = (qnaId: number): Promise<FetchQnaDetail> =>
        api.get(`/${qnaId}`).then(res => res.data.result);

    // QnA 생성
    const createQnaAPI = (createQna: CreateQna) => api.post('/create', createQna);

    // QnA 삭제
    const deleteQnaAPI = (qnaId: number) => api.delete(`/${qnaId}`);
    
    return {
        getQnaTypeListAPI,
        getQnaListAPI,
        getDetailQnaAPI,
        createQnaAPI,
        deleteQnaAPI,
    }
}

export default useQnaAPIs;