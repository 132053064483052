import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchManagingCourseList from "../../api/domains/course/queries/useFetchManagingCourseList";
import CustomButton from "../../components/button/CustomButton";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { PageSize } from "../../data/enums/PageSize";
import {
  CourseSearchCondition,
  CourseSearchConditionType,
} from "../../data/enums/SearchCondition";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";
import { BasicFontKR, DividerGray } from "../../styles/GlobalStyle";
import AdminCourseItem from "./components/AdminCourseItem";
import CourseDeleteModal from "./components/CourseDeleteModal";

export default function AdminCourseListPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isAdmin = useUserStore((state) => state.isAdmin);

  const [open, setOpen] = useState(false);
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState(-1);

  // 페이지네이션
  const [page, setPage] = useState(1);
  const [searchCondition, setSearchCondition] =
    useState<CourseSearchConditionType>("Name");
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const { data: courseList } = useFetchManagingCourseList();

  const renderCourseList = courseList?.filter((course) => {
    switch (searchCondition) {
      case CourseSearchCondition.NAME:
        return course.courseName.search(getRegExp(searchKeyWords)) !== -1;
      case CourseSearchCondition.PROFESSOR:
        return course.professorName?.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title="강의관리" />
      </Grid>
      <Grid item xs={12}>
        {isAdmin && (
          <Grid container justifyContent={"flex-end"}>
            <CustomButton
              margin="10px 0"
              colorType="inbodyRed"
              onClick={() => navigate(Urls.courseCreate)}
            >
              <CustomText type="button">{t("신규 강의 추가")}</CustomText>
            </CustomButton>
          </Grid>
        )}
        <Grid container justifyContent={"flex-end"}>
          <SearchWrapper>
            <Select
              value={searchCondition}
              onChange={(event) =>
                setSearchCondition(
                  event.target.value as CourseSearchConditionType
                )
              }
              displayEmpty
            >
              <MenuItem value={CourseSearchCondition.NAME}>
                <CustomText>{t("강의명")}</CustomText>
              </MenuItem>
              <MenuItem value={CourseSearchCondition.PROFESSOR}>
                <CustomText>{t("강사명")}</CustomText>
              </MenuItem>
            </Select>
            <KeyWordWrapper>
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                className="inputField"
                placeholder={t("검색어를 입력하세요.")}
                value={searchKeyWords}
                onChange={(event) => setSearchKeyWords(event.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </KeyWordWrapper>
          </SearchWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle" bold600>
                    {t("번호")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width={isAdmin ? "55%" : "65%"}>
                  <CustomText type="subtitle" bold600>
                    {t("강의명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("강사명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle" bold600>
                    {t("강의관리")}
                  </CustomText>
                </TableCell>
                {isAdmin && (
                  <TableCell align="center" width="10%">
                    <CustomText type="subtitle" bold600>
                      {t("강의삭제")}
                    </CustomText>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderCourseList && renderCourseList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={isAdmin ? 5 : 4}>
                    <CustomText type="subtitle">
                      {t("조회된 강의가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {renderCourseList &&
                renderCourseList
                  .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                  .map((course, index) => {
                    return (
                      <AdminCourseItem
                        key={course.courseId}
                        course={course}
                        index={index}
                        setOpen={setOpen}
                        setSelectedCourseId={setSelectedCourseId}
                        setSelectedCourseName={setSelectedCourseName}
                      />
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
        <Grid item xs={12}>
          {courseList && (
            <PaginationWrapper id="pagination-wrapper">
              <Pagination
                color="standard"
                onChange={(e, page) => setPage(page)}
                count={Math.ceil(courseList.length / PageSize.BASIC)}
              />
            </PaginationWrapper>
          )}
        </Grid>
        {isAdmin && (
          <CourseDeleteModal
            courseName={selectedCourseName}
            open={open}
            setOpen={setOpen}
            courseId={selectedCourseId}
          />
        )}
      </Grid>
    </Grid>
  );
}
const SearchWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
`;
const KeyWordWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
