import useGetApiInstance from '../../config/BasicApi';
import { FetchAttemptList, FetchQuizResultList, SaveAnswer, UpdateAnswerScore } from './UserAnswerType';


export const useUserAnswerAPIs = () => {
    const api = useGetApiInstance('user-answer');

    // 답안 제출 정보 조회
    const getQuizResultListAPI = (quizId: number): Promise<FetchQuizResultList[]> =>
        api.get(`quiz-result-list/${quizId}`).then(res => res.data.result);

    // 응시정보 - 문제 - 답변 조회
    const getAttemptDetailAPI = (attemptId: number): Promise<FetchAttemptList> =>
        api.get(`user-answer/${attemptId}`).then(res => res.data.result);

    // 답변 저장
    const saveAnswerAPI = (request: SaveAnswer): Promise<string> =>
        api.post('save', request).then(res => res.data.result);

    // 직접 채점
    const scoredAnswerAPI = (request: UpdateAnswerScore) =>
        api.put('scored', request).then(res => res.data.result);

    return {
        getQuizResultListAPI,
        getAttemptDetailAPI,
        saveAnswerAPI,
        scoredAnswerAPI
    }
}

export default useUserAnswerAPIs;