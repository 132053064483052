import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchNoticeDetail } from '../NoticeType';
import useNoticeAPIs from '../useNoticeAPIs';

const useFetchNoticeDetail = <T = FetchNoticeDetail>(
    noticeId: number,
    options?: UseQueryOptions<FetchNoticeDetail, AxiosError, T>
) => {

    const { getDetailNoticeAPI} = useNoticeAPIs();

    return useQuery({
        queryKey: QueryKeys.noticeDetail(noticeId),
        queryFn: () => getDetailNoticeAPI(noticeId),
        enabled: !isNaN(noticeId) && noticeId > 0,
        ...options
    });
}

export default useFetchNoticeDetail;