import styled from "@emotion/styled";
import { Box, Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFileSearchStore } from "../../stores/file/useFileSearchStore";
import { BorderGray } from "../../styles/GlobalStyle";
import PostFileModal from "../archive-post-file-modal/PostFileModal";
import PostFolderModal from "../archive-post-folder-modal/PostFolderModal";
import ArchiveContent from "./components/ArchiveContent";
import ArchiveDirectory from "./components/ArchiveDirectory";
import ArchiveHeader from "./components/ArchiveHeader";
import ArchiveSearchContent from "./components/ArchiveSearchContent";
import ArchiveToolMenu from "./components/ArchiveToolMenu";

export default function ArchivePage() {
  const [width, setWidth] = useState(300);
  const resizeRef = useRef(null);

  const startResizing = (mouseDownEvent: React.MouseEvent<HTMLDivElement>) => {
    const startX = mouseDownEvent.clientX;
    const startWidth = width;

    const onMouseMove = (mouseMoveEvent: MouseEvent) => {
      const currentX = mouseMoveEvent.clientX;
      const newWidth = startWidth + currentX - startX;
      setWidth(newWidth);
    };
    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const { isSearchActive, resetFileSearchStore } = useFileSearchStore();

  useEffect(() => {
    return () => {
      resetFileSearchStore();
    };
  }, []);

  return (
    <>
      <Paper elevation={2} sx={{ border: `2px solid ${BorderGray}`, mt: 2 }}>
        <ArchiveHeader />
        <Box sx={{ height: "70vh", display: "flex" }}>
          <Box sx={{ width: `${width}px`, minWidth: "200px", display: "flex" }}>
            <ArchiveDirectory />
            <ResizeLine
              ref={resizeRef}
              onMouseDown={startResizing}
              onDragStart={(e) => e.preventDefault()}
            />
          </Box>
          <Box
            sx={{
              width: `calc(100% - ${width}px)`,
              minWidth: "950px",
            }}
          >
            <ArchiveToolMenu />
            {isSearchActive && <ArchiveSearchContent />}
            {!isSearchActive && <ArchiveContent />}
          </Box>
        </Box>
      </Paper>
      <PostFolderModal />
      <PostFileModal />
    </>
  );
}

const ResizeLine = styled.div`
  border-right: 2px solid ${BorderGray};
  height: 100%;
  width: 5px;
  cursor: col-resize;
`;
