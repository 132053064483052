import styled from "@emotion/styled";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../../api/config/QueryKeys";
import { UpdateUserRole } from "../../../api/domains/user/UserType";
import useFetchUserList from "../../../api/domains/user/queries/useFetchUserList";
import useUserAPIs from "../../../api/domains/user/useUserAPIs";
import { CustomConfirmAlert } from "../../../components/alert/CustomConfirmAlert";
import ToastAlert from "../../../components/alert/ToastAlert";
import { TableLoading } from "../../../components/loading/TableLoading";
import { CustomText } from "../../../components/text/CustomText";
import {
  UserSearchCondition,
  UserSearchConditionType,
} from "../../../data/enums/SearchCondition";
import {
  BasicFontKR,
  BorderGray,
  BoxBlue,
  BoxPale,
  DividerGray,
} from "../../../styles/GlobalStyle";
import { PageSize } from "../../../data/enums/PageSize";
import useDateHooks from "../../../hooks/common/useDateHooks";
import { RoleType, UserRole } from "../../../data/enums/Roles";

export default function AllUserList() {
  const { t } = useTranslation();
  const [searchCondition, setSearchCondition] =
    useState<UserSearchConditionType>(UserSearchCondition.NAME);
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [page, setPage] = useState(1);

  const { changeUserRoleAPI } = useUserAPIs();
  const { getDateStringFormat } = useDateHooks();

  const queryClient = useQueryClient();
  const { data: useListData, isLoading } = useFetchUserList();

  const renderUserList = useListData?.filter((user) => {
    switch (searchCondition) {
      case "Name":
        return user.userName.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  const changeRoleQuery = useMutation((request: UpdateUserRole) =>
    changeUserRoleAPI(request)
  );

  const changeRole = (
    preRole: RoleType,
    changeRole: RoleType,
    userId: number,
    userName: string
  ) => {
    if (preRole === changeRole) {
      return;
    }
    const alertText = `${userName}님의 권한을
      \'${preRole}'\ => \'${changeRole}'\ 으로
      변경 하시겠습니까?`;

    const request: UpdateUserRole = {
      changeRole: changeRole,
      userId: userId,
    };

    CustomConfirmAlert(alertText, "warning", t("변경하기"), t("취소하기")).then(
      (result) => {
        if (result.isConfirmed) {
          changeRoleQuery.mutate(request, {
            onSuccess: () => {
              queryClient.invalidateQueries(QueryKeys.userList());
              ToastAlert(t("권한이 변경 되었습니다."), "success");
            },
            onError: () => {
              ToastAlert(
                t(
                  "권한 변경 도중 에러가 발생했습니다. 관리자에게 문의해주세요."
                ),
                "error"
              );
            },
          });
        }
      }
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HelpWrapper>
          <InfoOutlinedIcon fontSize="small" />
          <CustomText type="body" sx={{ padding: "0 5px" }}>
            {t(
              "강사 권한이 제거되면, 해당 강사가 담당하고 있던 강의의 교수명은 관리자로 바뀝니다."
            )}
          </CustomText>
        </HelpWrapper>
      </Grid>
      <Grid item xs={12}>
        <SearchWrapper>
          <SearchIconWrapper>
            <Select
              value={searchCondition}
              onChange={(event) =>
                setSearchCondition(
                  event.target.value as UserSearchConditionType
                )
              }
              displayEmpty
            >
              <MenuItem value={UserSearchCondition.NAME}>
                <CustomText>{t("사용자명")}</CustomText>
              </MenuItem>
            </Select>
            <KeyWordWrapper>
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                className="inputField"
                placeholder={t("검색어를 입력하세요.")}
                value={searchKeyWords}
                onChange={(event) => setSearchKeyWords(event.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </KeyWordWrapper>
          </SearchIconWrapper>
        </SearchWrapper>
        <TableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%">
                  <CustomText type="body" bold500>
                    {t("번호")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("사용자명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="body" bold500>
                    {t("이메일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("가입일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("인바디 임직원")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("권한")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("마지막로그인")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("권한변경")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 8)}
              {renderUserList && renderUserList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CustomText type="body">
                      {t("조회된 데이터가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {renderUserList &&
                renderUserList
                  .slice((page - 1) * PageSize.FIFTEEN, page * PageSize.FIFTEEN)
                  .map((user, index) => {
                    return (
                      <TableRow key={user.accountId}>
                        <TableCell align="center">
                          <CustomText type="body">{index + 1}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.userName}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.email}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {getDateStringFormat(user.joined, "YYYY-MM-DD")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {user.isInbodyMember ? t("임직원") : t("일반회원")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.role}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {getDateStringFormat(
                              user.lastLogin,
                              "YYYY-MM-DD hh:mm"
                            )}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          {user.role === UserRole.ADMIN && (
                            <FormControl fullWidth size="small">
                              <Select disabled value={user.role} displayEmpty>
                                <MenuItem hidden value={"ADMIN"}>
                                  <CustomText>{t("관리자")}</CustomText>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                          {user.role !== UserRole.ADMIN && (
                            <FormControl fullWidth size="small">
                              <Select
                                value={user.role}
                                onChange={(event) =>
                                  changeRole(
                                    user.role,
                                    event.target.value,
                                    user.userId,
                                    user.userName
                                  )
                                }
                                displayEmpty
                              >
                                <MenuItem
                                  disabled={user.role === UserRole.PROFESSOR}
                                  value={UserRole.PROFESSOR}
                                >
                                  <CustomText>{t("강사")}</CustomText>
                                </MenuItem>
                                <MenuItem
                                  disabled={user.role === UserRole.EMPLOYEE}
                                  value={UserRole.EMPLOYEE}
                                >
                                  <CustomText>{t("임직원")}</CustomText>
                                </MenuItem>
                                <MenuItem
                                  disabled={user.role === UserRole.NORMAL}
                                  value={UserRole.NORMAL}
                                >
                                  <CustomText>{t("일반")}</CustomText>
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item container xs={12} justifyContent={"center"}>
        {renderUserList && (
          <Pagination
            count={Math.ceil(renderUserList.length / PageSize.FIFTEEN)}
            onChange={(_, curPage) => setPage(curPage)}
            page={page}
          />
        )}
      </Grid>
    </Grid>
  );
}
const HelpWrapper = styled.div`
  display: flex;
  background-color: ${BoxBlue};
  padding: 10px 20px;
  margin-bottom: 10px;
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;
const SearchIconWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
`;
const KeyWordWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
const TableWrapper = styled.div`
  & table {
    border: 1px solid ${BorderGray};
  }
  & th,
  td {
    padding: 10px;
  }
  & th {
    background-color: ${BoxPale};
  }
`;
