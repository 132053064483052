import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomConfirmAlert } from '../../components/alert/CustomConfirmAlert';
import Urls from '../../routes/Urls';

export default function usePageMove() {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const moveToDestination = (
        courseId: number,
        destination: 'course' | 'lecture' | 'document' | 'list'
    ) => {
        switch (destination) {
            case 'course':
                return navigate(Urls.courseModify.replace(':id', String(courseId)));
            case 'lecture':
                return navigate(Urls.postLecture.replace(':id', String(courseId)));
            case 'document':
                return navigate(Urls.postDocument.replace(':id', String(courseId)));
            default:
                return navigate(Urls.courseManagement);
        }
    } 
    const pageMove = (
        courseId: number,
        destination: 'course' | 'lecture' | 'document' | 'list',
        isDirty: boolean
    ) => {
        if (isDirty){
            CustomConfirmAlert(
                t('내용이 수정되었습니다. 저장하지 않고 이동 하시겠습니까?'),
                'warning',
                t('이동'),
                t('취소')
            ).then((result) => {
                if (result.isConfirmed) {
                    moveToDestination(courseId, destination)
                }
            })
            return;
        }
        moveToDestination(courseId, destination);
    }

    return {
        pageMove
    }
}
