import styled from "@emotion/styled";
import GroupsIcon from "@mui/icons-material/Groups";
import SchoolIcon from "@mui/icons-material/School";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { useUserStore } from "../../stores/user/useUserStore";
import { BorderGray, InbodyRed } from "../../styles/GlobalStyle";
import AllUserList from "./components/AllUserList";
import UserStudyInfoList from "./components/UserStudyInfoList";

export default function AdminUserPage() {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const isAdmin = useUserStore((state) => state.isAdmin);

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("수강생 관리")} />
      </Grid>
      {isAdmin && (
        <Grid item xs={12}>
          <TabTitle isActive={activeTab === 0} onClick={() => setActiveTab(0)}>
            <SchoolIcon sx={{ m: 1 }} />
            <CustomText type="subtitle2" bold500>
              {t("학습 진도 현황")}
            </CustomText>
          </TabTitle>
          <TabTitle isActive={activeTab === 1} onClick={() => setActiveTab(1)}>
            <GroupsIcon sx={{ m: 1 }} />
            <CustomText type="subtitle2" bold500>
              {t("사이트 권한 관리")}
            </CustomText>
          </TabTitle>
        </Grid>
      )}
      {activeTab === 0 && <UserStudyInfoList />}
      {activeTab === 1 && <AllUserList />}
    </Grid>
  );
}

const TabTitle = styled.button<{ isActive: boolean }>`
  width: calc(100% / 2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${BorderGray};
  text-align: center;
  line-height: 45px;
  background-color: ${(props) => (props.isActive ? InbodyRed : "white")};
  color: ${(props) => (props.isActive ? "white" : "black")};
  cursor: pointer;
  height: 45px;
`;
