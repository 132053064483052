import useGetApiInstance from '../../config/BasicApi';
import { TagListDto } from './TagType';

const useTagAPI = () => {
    const api = useGetApiInstance('tag');

    const getAllTagTextListAPI = () : Promise<string[]> =>
        api.get(`/all-text-list`).then(res => res.data.result);
    
    const getTagListInFolderAPI = (folderId: number) : Promise<TagListDto[]> =>
        api.get(`/list/${folderId}`).then(res => res.data.result);
    
    return {
        getAllTagTextListAPI,
        getTagListInFolderAPI,
    }
}

export default useTagAPI;