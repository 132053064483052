import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { QueryKeys } from "../../api/config/QueryKeys";
import { ToggleQuizTarget } from "../../api/domains/quiz/QuizType";
import useQuizAPIs from "../../api/domains/quiz/useQuizAPIs";
import {
  AttemptHistoryListExcel,
  FetchUserStudyInfoList,
  UserStudyInfoListExcel,
} from "../../api/domains/user/UserType";
import { CustomConfirmAlert } from "../../components/alert/CustomConfirmAlert";
import ToastAlert from "../../components/alert/ToastAlert";
import useDateHooks from "../common/useDateHooks";
import Urls from "../../routes/Urls";
import useEmailTargetStore from "../../stores/domains/useEmailTargetStore";

export default function useAdminUserHooks() {
  const { t } = useTranslation();
  const { toggleQuizTargetAPI } = useQuizAPIs();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { addValue } = useEmailTargetStore((state) => ({
    addValue: state.addValue,
  }));

  const { getDateStringFormat } = useDateHooks();

  // 검색 대상 인원 메일 발송 리스트에 입력후 메일 발송 페이지로
  const moveToMailSendPage = (
    userStudyInfo: FetchUserStudyInfoList[] | undefined
  ) => {
    if (!userStudyInfo) return;

    const newEmailList = userStudyInfo.map((user) => user.email);
    addValue("to", newEmailList);

    CustomConfirmAlert(
      t("메일 발송 페이지로 이동하시겠습니까?"),
      "question",
      t("이동"),
      t("취소")
    ).then((result) => {
      if (result.isConfirmed) {
        navigate(Urls.mailManagement);
      }
    });
  };

  // 시험 대상 지정/제외
  const ChangeQuizTargetQuery = useMutation((request: ToggleQuizTarget) =>
    toggleQuizTargetAPI(request)
  );
  const changeQuizTarget = (userId: number, quizId: number) => {
    const request: ToggleQuizTarget = {
      userId: userId,
      quizId: quizId,
    };
    ChangeQuizTargetQuery.mutate(request, {
      onSuccess: (isQuizTarget) => {
        isQuizTarget
          ? ToastAlert(t("시험 대상으로 지정되었습니다."), "info")
          : ToastAlert(t("시험 대상에서 제외되었습니다."), "error");
        queryClient.invalidateQueries(QueryKeys.userStudyInfoList());
      },
    });
  };

  // Excel 다운로드
  const downloadExcel = (studentList: FetchUserStudyInfoList[] | undefined) => {
    if (!studentList) {
      return;
    }
    const workbook = XLSX.utils.book_new();

    const userExcelList: UserStudyInfoListExcel[] = studentList.flatMap((s) => {
      return {
        부서: s.parentTeam,
        팀: s.team,
        수강생명: s.userName,
        이메일: s.email,
        입사일: getDateStringFormat(s.joined, "YYYY-MM-DD"),
        마지막로그인: getDateStringFormat(s.lastLogIn, "YYYY-MM-DD hh:mm:ss"),
        강의명: "-",
        수강률: "-",
        수료여부: "-",
      };
    });
    const ws1 = XLSX.utils.json_to_sheet(userExcelList);
    XLSX.utils.book_append_sheet(workbook, ws1, "userList");

    const studentExcelList: UserStudyInfoListExcel[] = studentList.flatMap(
      (s) => {
        if (!s.studyHistoryList) {
          return [];
        }
        return (
          s.studyHistoryList?.map((studyInfo) => ({
            부서: s.parentTeam,
            팀: s.team,
            수강생명: s.userName,
            이메일: s.email,
            입사일: s.joined?.toString(),
            마지막로그인: s.lastLogIn,
            강의명: studyInfo.courseName,
            수강률: `${studyInfo.completePercentage} %`,
            수료여부: studyInfo.isCompleted ? t("수료") : t("미수료"),
          })) || []
        );
      }
    );
    const ws2 = XLSX.utils.json_to_sheet(studentExcelList);
    XLSX.utils.book_append_sheet(workbook, ws2, "StudentList");

    const attemptExcelList: AttemptHistoryListExcel[] = studentList.flatMap(
      (s) => {
        if (!s.attemptHistoryList) {
          return [];
        }
        return (
          s.attemptHistoryList?.map((attempt) => ({
            강의명: attempt.courseName,
            시험명: attempt.quizName,
            수강생명: s.userName,
            객관식점수: `${attempt.objectiveGetScore ?? "-"} / ${
              attempt.objectiveMaxScore
            }`,
            주관식점수: `${attempt.subjectiveGetScore ?? "-"} /  ${
              attempt.subjectiveMaxScore
            }`,
            총점수: `${
              (attempt.objectiveGetScore ?? 0) +
              (attempt.subjectiveGetScore ?? 0)
            } / ${attempt.objectiveMaxScore + attempt.subjectiveMaxScore}`,
            최종_제출시간: attempt.lastSubmitTime ?? t("미제출"),
          })) || []
        );
      }
    );
    const ws3 = XLSX.utils.json_to_sheet(attemptExcelList);
    XLSX.utils.book_append_sheet(workbook, ws3, "TestList");

    const today = new Date();
    const dateFormat = `${today.getFullYear()}${String(
      today.getMonth() + 1
    ).padStart(2, "0")}${String(today.getDate()).padStart(2, "0")}`;
    XLSX.writeFile(workbook, `InbodyAcademy_학습별관리현황_${dateFormat}.xlsx`);
  };

  return {
    moveToMailSendPage,
    changeQuizTarget,
    downloadExcel,
  };
}
