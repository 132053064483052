import styled from "@emotion/styled";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function UnAuthPageWrapper({ children }: Props) {
  return <SectionWrapper id="section">{children}</SectionWrapper>;
}
const SectionWrapper = styled.section`
  height: 100vh;
  width: 100vw;
`;
