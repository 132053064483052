import styled from '@emotion/styled';
import { TextField, TextFieldProps } from '@mui/material';
import { ReactNode, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderPale, ContentGray, ContentRed, TitleBlue, TitleRed } from '../../styles/GlobalStyle';
import { CustomText } from '../text/CustomText';
import { useController } from 'react-hook-form';
import { CustomControllerProps } from './RHFCommonType';


export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        padding: 0,
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none'
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
    },
})


interface CustomProps {
    endDecorator?: ReactNode;
    maxLength?: number;
    minLength?: number;
}

export default function HFTextarea({ name, control, endDecorator, maxLength, minLength, ...props }
    : CustomProps & TextFieldProps & CustomControllerProps) {

    const { field: { ref, ...inputProps }, fieldState: { error } }
        = useController({ name, control, ...props })

    const [isFocused, setIsFocused] = useState(false);
    const { t } = useTranslation();

    return (
        <CustomTextareaWrapper
            isFocused={isFocused}
            error={
                (!!minLength && (props.value as string).length < (minLength ?? 1))
                || (!!maxLength && (props.value as string).length > (maxLength ?? Number.MAX_VALUE))
            }>
            <TextField
                {...inputProps}
                {...props}
                multiline
                inputRef={ref}
                error={!!error}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        padding: 0,
                        '& fieldset': {
                            border: 'none',
                        },
                        '&:hover fieldset': {
                            border: 'none'
                        },
                        '&.Mui-focused fieldset': {
                            border: 'none',
                        },
                    },
                }}
            />
            {
                maxLength &&
                <CustomText
                    type={'body'}
                    align='right'
                    mt={'15px'}
                    bold500={(props.value as string).length > maxLength}
                    color={(props.value as string).length > maxLength
                        ? ContentRed
                        : ContentGray}
                >
                    {`${(props.value as string).length}/${maxLength} ${t('자')}`}
                </CustomText>
            }
            {endDecorator}
        </CustomTextareaWrapper>
    );
}

const CustomTextareaWrapper = styled.div<{ isFocused: boolean, error: boolean }>`
    border:${props => props.isFocused
        ? `2px solid ${TitleBlue}`
        : `1px solid ${BorderPale}`};
    border-color: ${props => props.error ? TitleRed : null};
    padding: ${props => props.isFocused
        ? '7px 13px'
        : '8px 14px'};
    border-radius: 8px;
`