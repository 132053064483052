import styled from "@emotion/styled";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TitleDiv from "../../../components/atoms/TitleDiv";
import { CustomText } from "../../../components/text/CustomText";
import { MaxCourseShow } from "../../../data/common/NewCourse";
import { moveCarousel } from "../../../hooks/main/moveCarousel";
import { FlexCenter, InbodyRed } from "../../../styles/GlobalStyle";
import {
  Item,
  NewCourseContentWrapper,
  NoItem,
  Wrapper,
} from "./MainPageCourseList";
import data from "./recommendBookData.json";
import Urls from "../../../routes/Urls";

export default function MainPageRecommendBookList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);

  const courseCount = data.bookList.length ?? 0;
  const maxNumber = courseCount - MaxCourseShow;

  const RenderNoCourseImage = () => {
    let renderArr = [];

    if (data.bookList.length >= MaxCourseShow) {
      return;
    } else {
      for (let i = 0; i < MaxCourseShow - data.bookList.length; i++) {
        renderArr.push(<NoItem key={i} />);
      }
    }
    return renderArr;
  };

  return (
    <Wrapper id="newLectureBox">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <TitleDiv title={t("인바디 리더가 추천하는 책")} />
        <Grid item>
          <Button
            sx={{
              margin: "0 5px",
              color: InbodyRed,
              borderColor: InbodyRed,
            }}
            size="small"
            variant="outlined"
            color="inherit"
            disabled={idx === 0}
            onClick={() => moveCarousel(idx, setIdx, "-", maxNumber, 0)}
          >
            <NavigateBeforeIcon fontSize="small" />
          </Button>
          <Button
            sx={{
              margin: "0 5px",
              color: InbodyRed,
              borderColor: InbodyRed,
            }}
            size="small"
            variant="outlined"
            color="inherit"
            disabled={idx >= courseCount - MaxCourseShow}
            onClick={() => moveCarousel(idx, setIdx, "+", maxNumber, 0)}
          >
            <NavigateNextIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ overflow: "hidden" }}>
        <NewCourseContentWrapper offset={idx}>
          {data.bookList.map((book) => {
            return (
              <BookItem
                key={book.id}
                onClick={() =>
                  navigate(
                    Urls.recommendBookDetail.replace(":id", String(book.id))
                  )
                }
              >
                <ImageBox>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/${book.imageFile}`}
                    alt={book.title}
                    width={"90px"}
                    height={"130px"}
                  />
                </ImageBox>
                <TextBox>
                  <CustomText type="subtitle" bold600 wordBreak>
                    {`${book.title}`}
                  </CustomText>
                  {book.tagList.map((tag) => (
                    <CustomText key={`${book.id}-${tag}`} type="small">
                      {tag}
                    </CustomText>
                  ))}
                </TextBox>
              </BookItem>
            );
          })}
          {RenderNoCourseImage()}
        </NewCourseContentWrapper>
      </Box>
    </Wrapper>
  );
}

const BookItem = styled(Item)`
  flex-direction: row;
`;
const ImageBox = styled.div`
  width: 50%;
  padding: 5px;
  ${FlexCenter}
`;
const TextBox = styled.div`
  ${FlexCenter}
  flex-direction: column;
  padding: 10px 0;
  width: 50%;
`;
