import styled from "@emotion/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SaveIcon from "@mui/icons-material/Save";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FetchQuestionList } from "../../../api/domains/question/QuestionType";
import { CustomConfirmAlert } from "../../../components/alert/CustomConfirmAlert";
import HFTextarea from "../../../components/react-hook-form/HFTextarea";
import { CustomText } from "../../../components/text/CustomText";
import {
  QuestionType,
  QuestionTypeValue,
} from "../../../data/enums/QuestionType";
import { ContentBlue } from "../../../styles/GlobalStyle";
import useExamPageHooks from "../useExamPageHooks";
import ExamFloatingButton from "./ExamFloatingButton";

export interface ExamAnswerFormValues {
  answerList: {
    questionId: string | number;
    questionType: QuestionType;
    userAnswer: string;
  }[];
}

interface ExamFormProps {
  questionWithSubmitAnswerList: FetchQuestionList[];
  attemptId: number;
}

export default function ExamForm({
  questionWithSubmitAnswerList,
  attemptId,
}: ExamFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    register,
    formState: { isDirty },
    reset,
  } = useForm<ExamAnswerFormValues>({
    defaultValues: {
      answerList: questionWithSubmitAnswerList.map(
        (questionWithSubmitAnswer) => {
          return {
            questionId: questionWithSubmitAnswer.questionId,
            questionType: questionWithSubmitAnswer.questionType,
            userAnswer: questionWithSubmitAnswer.userAnswer,
          };
        }
      ),
    },
  });

  const { submit } = useExamPageHooks();

  return (
    <form
      onSubmit={handleSubmit((data) => {
        submit(data, attemptId);
        reset({}, { keepValues: true });
      })}
    >
      <Grid container justifyContent={"flex-end"} mb={2}>
        <Button
          type="submit"
          color="success"
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{ mr: 1 }}
        >
          <CustomText type="buttonLarge">{t("답안저장")}</CustomText>
        </Button>
        <Button
          color="info"
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            if (isDirty) {
              CustomConfirmAlert(
                t("답안이 수정되었습니다."),
                "warning",
                t("이전으로"),
                t("취소하기"),
                `${t("답안저장 버튼을 눌러야 답안이 저장됩니다.")}\n${t(
                  "이동하시겠습니까?"
                )}`
              ).then((res) => {
                if (res.isConfirmed) navigate(-1);
              });
            } else {
              navigate(-1);
            }
          }}
        >
          <CustomText type="buttonLarge">{t("이전으로")}</CustomText>
        </Button>
      </Grid>
      <Grid container spacing={3}>
        {questionWithSubmitAnswerList.map((question, questionIndex) => (
          <Grid key={question.questionId} item xs={12}>
            <Paper elevation={3} sx={{ p: 3 }} key={question.questionId}>
              <Grid container alignItems={"center"}>
                <Avatar
                  sx={{
                    bgcolor: ContentBlue,
                    width: 35,
                    height: 35,
                    justifyItems: "center",
                  }}
                >
                  {`${questionIndex + 1}`}
                </Avatar>
                <Question
                  dangerouslySetInnerHTML={{
                    __html: question.content,
                  }}
                />
                <CustomText type="subtitle">
                  {`(${question.maxScore} 점)`}
                </CustomText>
              </Grid>
              {question.questionType === QuestionTypeValue.주관식 && (
                <AnswerWrapper>
                  <HFTextarea
                    control={control}
                    name={`answerList.${questionIndex}.userAnswer`}
                    label={t("주관식 정답을 입력해주세요.")}
                    multiline
                    minRows={3}
                  />
                </AnswerWrapper>
              )}
              {question.questionType !== QuestionTypeValue.주관식 && (
                <Grid item p={"5px 25px"}>
                  {question.choiceList?.map((choice, choiceIndex) => {
                    return (
                      <ChoiceItemWrapper key={choiceIndex}>
                        {question.questionType ===
                        QuestionTypeValue.체크박스 ? (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`answerList.${questionIndex}.choiceList.${choiceIndex}`}
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                checked={watch(
                                  `answerList.${questionIndex}.userAnswer`
                                ).includes(String(choiceIndex))}
                                value={choiceIndex}
                                onChange={(e, checked) => {
                                  let userAnswer = watch(
                                    `answerList.${questionIndex}.userAnswer`
                                  ) as string;
                                  let newAnswerArr = userAnswer
                                    .split(",")
                                    .filter(Boolean);
                                  if (checked) {
                                    newAnswerArr.push(e.target.value);
                                    newAnswerArr.sort();
                                    setValue(
                                      `answerList.${questionIndex}.userAnswer`,
                                      newAnswerArr.join(","),
                                      { shouldDirty: true }
                                    );
                                  } else {
                                    newAnswerArr = newAnswerArr.filter(
                                      (answer) => answer !== e.target.value
                                    );
                                    setValue(
                                      `answerList.${questionIndex}.userAnswer`,
                                      newAnswerArr.join(","),
                                      { shouldDirty: true }
                                    );
                                  }
                                }}
                              />
                            }
                            label={
                              <CustomText type="subtitle">
                                {choice.content}
                              </CustomText>
                            }
                          />
                        ) : (
                          <FormControlLabel
                            control={
                              <Radio
                                name={`answerList.${questionIndex}.choiceList.${choiceIndex}`}
                                checked={
                                  watch(
                                    `answerList.${questionIndex}.userAnswer`
                                  ) === String(choiceIndex)
                                }
                                onChange={() =>
                                  setValue(
                                    `answerList.${questionIndex}.userAnswer`,
                                    String(choiceIndex),
                                    { shouldDirty: true }
                                  )
                                }
                              />
                            }
                            label={
                              <CustomText type="subtitle">
                                {choice.content}
                              </CustomText>
                            }
                          />
                        )}
                      </ChoiceItemWrapper>
                    );
                  })}
                </Grid>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ExamFloatingButton isDirty={isDirty} />
    </form>
  );
}
const Question = styled.div`
  padding-left: 20px;
`;
const ChoiceItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const AnswerWrapper = styled.div`
  padding: 5px 25px;
`;
