import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { FetchUserDto } from "../../api/domains/user/UserType";
import { UserRole } from "../../data/enums/Roles";

type State = FetchUserDto & {
  isAdmin: boolean;
  isProfessor: boolean;
  isLogin: boolean;
};
type Action = {
  logIn: (loginDto: FetchUserDto) => void;
  logOut: () => void;
};

const InitUser: State = {
  userId: 0,
  accountId: "",
  userName: "",
  role: UserRole.NONE,
  email: "",
  isInbodyMember: false,
  isActiveUser: false,
  jwtToken: "",
  joined: null,
  lastLogin: null,
  verifiedAt: null,
  workStatus: null,
  employeeId: null,
  employeeNo: null,
  gwName: null,
  phone: null,
  tel: null,
  team: null,
  parentTeam: null,
  workplace: null,
  jobTitle: null,
  jobPosition: null,
  isLogin: false,
  isAdmin: false,
  isProfessor: false,
};

export const useUserStore = create<State & Action>()(
  persist(
    (set) => ({
      ...InitUser,
      logIn: (loginDto) =>
        set({
          ...loginDto,
          isAdmin: loginDto.role === UserRole.ADMIN,
          isProfessor: loginDto.role === UserRole.PROFESSOR,
          isLogin: true,
        }),
      logOut: () => set(InitUser),
    }),
    {
      name: "academy-login-user",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
