import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from "./english/en.json";
import translationKo from "./korean/ko.json";
import { Language } from "../data/common/Language";

// 참고 사이트 : https://velog.io/@zlemzlem5656/react-i18next-typescript
const resources: Resource = {
  ko: {
    translation: translationKo,
  },
  en: {
    translation: translationEn,
  },
} as const;

i18n
  .use(LanguageDetector) // 자동 언어 감지
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: {
      ko: [Language.KOREAN],
      default: [Language.ENGLISH],
    },
    detection: { order: ["navigator", "path", "localStorage"] },
    resources: resources,
    keySeparator: false,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
