import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchProfessorList } from '../UserType';
import useUserAPIs from '../useUserAPIs';

const useFetchProfessorList = <T = FetchProfessorList[]>(
    options?: Omit<UseQueryOptions<FetchProfessorList[], AxiosError, T, string[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<T, AxiosError> => {
    
    const { getProfessorListAPI } = useUserAPIs();
    
    return useQuery({
        queryKey: QueryKeys.professorList(),
        queryFn: () => getProfessorListAPI(),
        ...options,
    });
}

export default useFetchProfessorList;