import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, SxProps } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomText } from '../../../components/text/CustomText';
import useCourseHooks from '../../../hooks/domains/useCourseHooks';
import { BorderBlue, BorderGray, BoxBlue, BoxGray } from '../../../styles/GlobalStyle';

const style: SxProps = {
  position: 'fixed',
  top: '0',
  left: '50%',
  transform: 'translate(-50%)',
  zIndex: 999,
  margin: '20vh auto',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
};

interface CourseDeleteModalProps {
  courseName: string;
  open: boolean;
  courseId: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function CourseDeleteModal({ open, courseName, courseId, setOpen }: CourseDeleteModalProps) {
  const { t } = useTranslation();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  const textInputHandler = (input: string) => {
    if (input.trim() === courseName.trim()) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
  }

  const { deleteCourse } = useCourseHooks();

  return (
    <Modal
      id="modal"
      open={open}
      onClose={handleClose}>
      <Box sx={style}>
        <CloseButtonWrapper>
          <CustomText type='body'>
            {t('정말로 삭제하시겠습니까?')}
          </CustomText>
          <CloseButton onClick={handleClose}>
            <CloseIcon fontSize='small' />
          </CloseButton>
        </CloseButtonWrapper>
        <TextWrapper>
          <CustomText type='body' color={'red'} bold500>
            {t('삭제시 자료 복구가 불가능합니다.')}
          </CustomText>
        </TextWrapper>
        <ContentWrapper >
          <CustomText type='body'>
            {t('강의 삭제시 동영상, 강의 자료, 시험, 수강생 정보 등')}
          </CustomText>
          <CustomText type='body'>
            {t('강의에 관련된 모든 정보가 제거 됩니다.')}
          </CustomText>
          <br />
          <CustomText type='body'>
            {t('백업을 진행하신 이후에 삭제할 것을 권장드립니다.')}
          </CustomText>
          <br />
          <CustomText type='body'>
            {t('삭제하시려면 확인을 위해')}
          </CustomText>
          <CustomText type='subtitle' >
            {courseName}
          </CustomText>
          <CustomText type='body' >
            {t('(을)를 입력해주세요.')}
          </CustomText>
        </ContentWrapper>
        <ButtonWrapper >
          <ConfirmInput onChange={(e) => textInputHandler(e.target.value)} />
        </ButtonWrapper>
        <ButtonWrapper>
          <ConfirmButton
            disabled={!isConfirm}
            onClick={() => {
              deleteCourse(courseId);
              handleClose();
              //TODO 여기!!! 수정!
            }}>
            <CustomText type='body'>
              {t('주의사항을 잘 읽었으며 삭제를 진행합니다.')}
            </CustomText>
          </ConfirmButton>
        </ButtonWrapper>
      </Box>
    </Modal>
  )
}
const CloseButton = styled.button`
    border: none;
    cursor: pointer;
`
const ConfirmInput = styled.input`
    padding: 5px 12px;
    width: calc(100% - 26px);
    border-radius: 5px;
    border: 1px solid ${BorderGray};
`
const ConfirmButton = styled.button`
    padding: 5px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${BorderGray};
    color: #cf222e;
    &:enabled:hover{
        background-color: #a40e26;
        color: #ffffff;
    }
    &:disabled{
        color: rgba(207,34,46,0.5);
    }
`
const CloseButtonWrapper = styled.div`
    background-color: ${BoxGray};
    padding: 16px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const TextWrapper = styled.div`
    background-color: ${BoxBlue};
    border-top:  1px solid ${BorderBlue};
    border-bottom: 1px solid ${BorderBlue};
    padding: 16px;
`
const ContentWrapper = styled.div`
    padding: 16px;
    line-height: 20px;
`
const ButtonWrapper = styled.div`
    margin: 5px;
`
