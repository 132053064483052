import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import ToastAlert from "../../../components/alert/ToastAlert";
import { CustomText } from "../../../components/text/CustomText";
import { isDocumentFileValid } from "../../../hooks/validation/isDocumentValid";
import usePostDocumentStore, {
  DocumentState,
} from "../../../stores/domains/usePostDocumentStore";
import { ContentGreen } from "../../../styles/GlobalStyle";

interface PostDocumentItemProps {
  document: DocumentState;
  documentIndex: number;
  isLastItem: boolean;
}

function PostDocumentItem({
  document,
  documentIndex,
  isLastItem,
}: PostDocumentItemProps) {
  const { t } = useTranslation();

  const {
    documentList,
    isSubmit,
    isUploading,
    swapDocument,
    updateDocument,
    deleteDocument,
  } = usePostDocumentStore((state) => ({
    documentList: state.documentList,
    isSubmit: state.isSubmit,
    isUploading: state.isUploading,
    swapDocument: state.swapDocument,
    updateDocument: state.updateDocument,
    deleteDocument: state.deleteDocument,
  }));

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <Grid container flexDirection="column">
            <IconButton
              sx={{ p: 0, m: "0 auto" }}
              onClick={() => swapDocument(documentIndex, documentIndex - 1)}
              disabled={documentIndex === 0}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              sx={{ p: 0, m: "0 auto" }}
              onClick={() => swapDocument(documentIndex, documentIndex + 1)}
              disabled={isLastItem}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>
        </TableCell>
        <TableCell align="center">
          <CustomText type="subtitle">{documentIndex + 1}</CustomText>
        </TableCell>
        <TableCell>
          <TextField
            id={`document-${documentIndex}-title`}
            fullWidth
            size="small"
            value={document.title}
            error={isSubmit && !document.title.trim()}
            helperText={
              isSubmit && !document.title.trim() ? t("필수 입력값입니다.") : ""
            }
            onChange={(e) =>
              updateDocument(document.documentId, { title: e.target.value })
            }
            placeholder={t("자료명을 입력해주세요.")}
          />
        </TableCell>
        <TableCell align="center">
          {document.originFileName ? (
            <CustomText type="body" bold500 overflowTooltip>
              {document.originFileName}
            </CustomText>
          ) : (
            <Button variant="outlined" component="label">
              <CustomText type="button" bold500>
                {t("자료 업로드")}
              </CustomText>
              <input
                type="file"
                hidden
                onChange={async (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];

                    const isDuplicateFileName = documentList.some(
                      (d) => d.originFileName === file.name
                    );

                    if (isDuplicateFileName) {
                      ToastAlert(
                        t("이미 동일한 이름의 파일이 존재합니다."),
                        "error"
                      );
                      return;
                    }

                    updateDocument(document.documentId, {
                      originFileName: file.name,
                      uploadFileName: v4(),
                      documentFile: file,
                    });
                  }
                }}
                accept=".docx, .xlsx, .pdf, image/*"
              />
            </Button>
          )}
          {isSubmit && !isDocumentFileValid(document) && (
            <CustomText type={"small"} color={"error"}>
              {t("자료 파일이 비어있습니다.")}
            </CustomText>
          )}
        </TableCell>
        <TableCell align="center">
          <Button
            onClick={() => deleteDocument(document.documentId)}
            variant="text"
            color="error"
          >
            <CustomText type="button">{t("삭제하기")}</CustomText>
          </Button>
        </TableCell>
      </TableRow>
      {isUploading && (
        <TableRow>
          <TableCell colSpan={2} align="center" component="th">
            <CustomText type="subtitle">{t("업로드 현황")}</CustomText>
          </TableCell>
          <TableCell colSpan={3} align="center">
            <Grid container>
              {document.progress !== 100 ? (
                <>
                  <BarWrapper>
                    <LinearProgress
                      variant="determinate"
                      value={document.progress}
                    />
                  </BarWrapper>
                  <CustomText>{document.progress}%</CustomText>
                </>
              ) : (
                <CustomText
                  type="subtitle"
                  bold600
                  fullWidth
                  color={ContentGreen}
                >
                  {t("강의 자료 업로드가 완료되었습니다.")}
                </CustomText>
              )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default memo(PostDocumentItem);

const BarWrapper = styled.div`
  width: 90%;
  padding: 10px 20px;
`;
