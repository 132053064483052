import {
  ColumnDirective,
  ColumnsDirective,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import i18next from "i18next";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import useFetchFileHistoryList from "../../../api/domains/file/queries/useFetchFileHistoryList";
import useDateHooks from "../../../hooks/common/useDateHooks";
import { FileHistory } from "../../../api/domains/file/FileType";

export default function AdminFileHistoryList() {
  const { t } = useTranslation();
  const gridRef = useRef<GridComponent>(null);

  const { data: fileHistoryListData } = useFetchFileHistoryList();

  const { getDateStringFormat } = useDateHooks();

  const fileHistoryList = useMemo(
    () => fileHistoryListData,
    [fileHistoryListData]
  );

  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridRef.current !== null) {
        gridRef.current?.excelExport();
      }
    }
  };

  const getFileTypeString = (data: FileHistory) => {
    switch (data.fileActionType) {
      case "DELETE":
        return t("파일 삭제");
      case "DOWNLOAD":
        return t("파일 다운로드");
      case "UPDATE":
        return t("파일 수정");
      case "UPLOAD":
        return t("파일 업로드");
    }
  };

  return (
    <>
      {fileHistoryList && (
        <GridComponent
          id="grid-component"
          dataSource={fileHistoryList}
          locale={i18next.language}
          pageSettings={{ pageSize: 20 }}
          allowSorting={true}
          allowPaging={true}
          allowFiltering={true}
          filterSettings={{ type: "CheckBox" }}
          allowExcelExport={true}
          toolbar={["Search", "ExcelExport"]}
          toolbarClick={toolbarClick}
          ref={gridRef}
        >
          <ColumnsDirective>
            <ColumnDirective
              field={`createdAt`}
              headerText={t("날짜")}
              valueAccessor={(_, data) =>
                getDateStringFormat(
                  (data as FileHistory).createdAt,
                  "YYYY-MM-DD hh:mm"
                )
              }
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              field={`fileActionType`}
              headerText={t("구분")}
              valueAccessor={(_, data) =>
                getFileTypeString(data as FileHistory)
              }
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              clipMode="EllipsisWithTooltip"
              field={`folderName`}
              headerText={t("폴더명")}
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              clipMode="EllipsisWithTooltip"
              field={`fileOriginName`}
              headerText={t("파일명")}
              textAlign="Center"
              width="150"
            />
            <ColumnDirective
              field={`fileSize`}
              headerText={t("파일크기")}
              textAlign="Center"
              width="100"
              visible={false}
            />
            <ColumnDirective
              field={`folderLevel`}
              headerText={t("폴더 깊이")}
              textAlign="Center"
              width="100"
              visible={false}
            />
            <ColumnDirective
              field={`userName`}
              headerText={t("이름")}
              textAlign="Center"
              width="100"
            />
            <ColumnDirective
              clipMode="EllipsisWithTooltip"
              field={`userEmail`}
              headerText={t("이메일")}
              textAlign="Center"
              width="150"
            />
          </ColumnsDirective>
          <Inject services={[Filter, Page, Sort, Toolbar, ExcelExport]} />
        </GridComponent>
      )}
    </>
  );
}
