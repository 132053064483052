import { Global, css } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import LoadingSpinnerModal from "./components/loading/LoadingSpinnerModal";
import Router from "./routes/Router";
import { BasicFontKR } from "./styles/GlobalStyle";

// !Fixme : query 기본 설정값 입력
const App = () => {
  dayjs.extend(utc);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
        cacheTime: 5 * 60 * 1000,
        retry: 3,
        retryDelay: (att) => att * 1000,
        refetchOnWindowFocus: false,
      },
    },
  });

  const theme = createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {},
      },
      MuiInputBase: {
        defaultProps: {
          spellCheck: false,
        },
        styleOverrides: {
          root: {
            fontSize: "14px",
          },
        },
      },
    },
    typography: {
      fontFamily: BasicFontKR,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Suspense fallback={<LoadingSpinnerModal />}>
            <BrowserRouter>
              <Global
                styles={css`
                  &.noto {
                    font-family: ${BasicFontKR};
                  }
                  &::-webkit-scrollbar {
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 6px;
                    background-color: rgba(255, 255, 255, 0.4);
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 6px;
                  }
                `}
              />
              <Router />
            </BrowserRouter>
            {/* 로딩 스피너 */}
            <LoadingSpinnerModal />
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
