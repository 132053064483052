import { create } from "zustand";
import {
  ChoiceDomain,
  QuestionDomain,
} from "../../api/domains/question/QuestionType";
import { swapItem } from "./usePostCourseStore";

export type PostQuestionState = Pick<
  QuestionDomain,
  "content" | "answer" | "questionType" | "maxScore" | "questionId"
> & {
  choiceList: PostChoiceState[] | null;
};

export type PostChoiceState = Pick<ChoiceDomain, "choiceId" | "content">;

type PostQuestionListState = {
  questionList: PostQuestionState[];
} & {
  isDirty: boolean;
};

type PostQuestionListAction = {
  appendNewQuestion: () => void;
  copyQuestion: (questionId: string | number) => void;
  swapQuestion: (index1: number, index2: number) => void;
  updateQuestion: (
    questionId: string | number,
    newState: Partial<PostQuestionState>
  ) => void;
  deleteQuestion: (questionId: string | number) => void;

  appendNewChoice: (questionId: string | number) => void;
  copyChoice: (questionId: string | number, choiceId: string | number) => void;
  swapChoice: (
    questionId: string | number,
    index1: number,
    index2: number
  ) => void;
  updateChoice: (
    questionId: string | number,
    choiceId: string | number,
    newState: Partial<PostChoiceState>
  ) => void;
  deleteChoice: (
    questionId: string | number,
    choiceId: string | number
  ) => void;

  createStore: (initStateList: Partial<PostQuestionState>[]) => void;
  resetStore: () => void;
};

const baseQuestion: PostQuestionState = {
  questionId: 0,
  content: "",
  answer: "",
  questionType: "객관식",
  maxScore: 0,
  choiceList: null,
};

const baseChoice: PostChoiceState = {
  choiceId: 0,
  content: "",
};

const baseQuestionList: PostQuestionListState = {
  questionList: [],
  isDirty: false,
};

const usePostQuestionStore = create<
  PostQuestionListState & PostQuestionListAction
>((set) => ({
  ...baseQuestionList,

  appendNewQuestion: () =>
    set((state) => ({
      questionList: [
        ...state.questionList,
        {
          ...baseQuestion,
          questionId: Date.now(),
          choiceList: [
            { content: "", choiceId: Date.now() + 1 },
            { content: "", choiceId: Date.now() + 2 },
            { content: "", choiceId: Date.now() + 3 },
          ],
        },
      ],
      isDirty: true,
    })),

  copyQuestion: (questionId) =>
    set((state) => {
      const copiedQuestion = state.questionList.find(
        (question) => question.questionId === questionId
      );
      if (!copiedQuestion) {
        return state;
      }

      return {
        ...state,
        questionList: [
          ...state.questionList,
          {
            ...copiedQuestion,
            questionId: Date.now(),
          },
        ],
      };
    }),

  swapQuestion: (index1, index2) =>
    set((state) => ({
      questionList: swapItem(state.questionList, index1, index2),
      isDirty: true,
    })),

  updateQuestion: (questionId, newState) =>
    set((state) => {
      const updatedQuestionList = state.questionList.map((question) => {
        if (question.questionId === questionId) {
          return { ...question, ...newState };
        }
        return question;
      });

      return {
        ...state,
        questionList: updatedQuestionList,
        isDirty: true,
      };
    }),

  deleteQuestion: (questionId) =>
    set((state) => ({
      questionList: state.questionList.filter(
        (question) => question.questionId !== questionId
      ),
      isDirty: true,
    })),

  appendNewChoice: (questionId) =>
    set((state) => {
      const newQuestionList = state.questionList.map((question) => {
        if (question.questionId === questionId) {
          const updatedChoices = [
            ...(question.choiceList ?? []),
            {
              ...baseChoice,
              choiceId: Date.now() + 1,
            },
          ];
          return { ...question, choiceList: updatedChoices };
        }
        return question;
      });

      return {
        questionList: newQuestionList,
        isDirty: true,
      };
    }),

  copyChoice: (questionId, choiceId) =>
    set((state) => {
      const newQuestionList = state.questionList.map((question) => {
        if (question.questionId === questionId) {
          const copyChoice = question.choiceList?.find(
            (c) => c.choiceId === choiceId
          );

          if (copyChoice) {
            const updatedChoices = [
              ...(question.choiceList ?? []),
              {
                ...copyChoice,
                choiceId: Date.now() + 1,
              },
            ];
            return { ...question, choiceList: updatedChoices };
          }
        }
        return question;
      });

      return {
        questionList: newQuestionList,
        isDirty: true,
      };
    }),

  swapChoice: (questionId, index1, index2) =>
    set((state) => {
      const newQuestionList = state.questionList.map((question) => {
        if (question.questionId === questionId && question.choiceList) {
          const newChoiceList = swapItem(question.choiceList, index1, index2);
          return { ...question, choiceList: newChoiceList };
        }
        return question;
      });

      return {
        questionList: newQuestionList,
        isDirty: true,
      };
    }),

  updateChoice: (questionId, choiceId, newState) =>
    set((state) => {
      const newQuestionList = state.questionList.map((question) => {
        if (question.questionId === questionId && question.choiceList) {
          const newChoiceList = question.choiceList.map((choice) => {
            if (choice.choiceId === choiceId) {
              return { ...choice, ...newState };
            }
            return choice;
          });
          return { ...question, choiceList: newChoiceList };
        }
        return question;
      });

      return {
        ...state,
        questionList: newQuestionList,
        isDirty: true,
      };
    }),

  deleteChoice: (questionId, choiceId) =>
    set((state) => ({
      questionList: state.questionList.map((question) => {
        if (question.questionId === questionId) {
          const filteredChoices =
            question.choiceList?.filter(
              (choice) => choice.choiceId !== choiceId
            ) || [];

          return { ...question, choiceList: filteredChoices };
        }
        return question;
      }),
      isDirty: true,
    })),

  createStore: (initStateList) =>
    set({
      questionList: initStateList.map((question) => ({
        ...baseQuestion,
        ...question,
      })),
      isDirty: false,
    }),

  resetStore: () => set(baseQuestionList),
}));

export default usePostQuestionStore;
