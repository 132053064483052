import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchLectureList } from '../LectureType';
import useLectureAPIs from '../useLectureAPIs';

const useFetchLectureList = <T = FetchLectureList[]>(
    courseId: number,
    options?: UseQueryOptions<FetchLectureList[], AxiosError, T>
) =>  {
    const { getLectureListAPI } = useLectureAPIs();
    
    return useQuery({
        queryKey: QueryKeys.lectureList(courseId),
        queryFn: () => getLectureListAPI(courseId),
        enabled: !isNaN(courseId) && courseId > 0,
        refetchOnWindowFocus: true,
        ...options
    });
}

export default useFetchLectureList;