import { Box, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { useEffect, useRef, useState } from "react";
import { CustomText, CustomTextProps } from './CustomText';
import { styled } from '@mui/material/styles';

interface CustomTextTooltipProps extends CustomTextProps {
  arrow?: boolean;
  placement?: "top" | "right" | "bottom" | "left" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});


export default function CustomTextTooltip({ arrow, placement = 'bottom', children, ...props }: CustomTextTooltipProps) {
  const textElement = useRef<HTMLDivElement | null>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const element = textElement.current;
    if (element) {
      setOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [children]);

  return (
    <CustomWidthTooltip
      placement={placement}
      arrow={arrow}
      title={
        overflow &&
        <CustomText
          overflowTooltip
          sx={{ fontSize: '14px' }}>
          {children}
        </CustomText>
      }>
      <Box
        sx={{
          width: '100%'
        }}
        ref={textElement}>
        {overflow ? (
          <CustomText {...props} overflowTooltip>
            {children}
          </CustomText>
        ) : (
          <CustomText {...props}>
            {children}
          </CustomText>
        )}
      </Box>
    </CustomWidthTooltip>
  );
}
