import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TitleDivWrapper } from "../../components/atoms/TitleDiv";
import { CustomText } from "../../components/text/CustomText";
import Urls from "../../routes/Urls";
import data from "../main/components/recommendBookData.json";

export default function RecommendBookDetailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bookId = Number(useParams().id);
  const [indexExpand, setIndexExpand] = useState(true);

  const bookDetail = data.bookList.find((book) => book.id === bookId);

  return (
    <Grid container mt={7}>
      <Grid item xs={12} mb={5}>
        <TitleDivWrapper>
          <CustomText type="title" bold500>
            {t("인바디 리더가 추천하는 책")}
          </CustomText>
        </TitleDivWrapper>
      </Grid>
      <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
        <Button color="info" onClick={() => navigate(Urls.main)}>
          <CustomText type="button">{t("메인 페이지로 돌아가기")}</CustomText>
        </Button>
      </Grid>
      {bookDetail && (
        <DetailContentWrapper>
          <BookImageWrapper>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/${bookDetail.imageFile}`}
              alt={bookDetail.title}
              width={"300px"}
              height={"400px"}
            />
          </BookImageWrapper>
          <BookContentWrapper>
            <BookTitle>
              <CustomText type="title">
                {`${bookDetail.title} ${bookDetail.subTitle}`}
              </CustomText>
            </BookTitle>
            <BookInfo>
              <CustomText type="subtitle">{bookDetail.author}</CustomText>
              <CustomText type="subtitle">{bookDetail.publisher}</CustomText>
            </BookInfo>
            <BookTagList>
              {bookDetail.tagList.map((tag) => (
                <CustomText type="body" sx={{ mr: 1 }}>
                  {tag}
                </CustomText>
              ))}
            </BookTagList>
            <BookDescription>
              <CustomText type="subtitle" sx={{ mb: 2 }}>
                {t("책 내용")}
              </CustomText>
              <CustomText wordBreak>{bookDetail.description}</CustomText>
            </BookDescription>
            <BookIndex expand={indexExpand}>
              <CustomText type="subtitle" sx={{ mb: 2 }}>
                {t("목차")}
              </CustomText>
              <CustomText wordBreak>{bookDetail.index}</CustomText>
            </BookIndex>
            <Button
              sx={{ mt: 2 }}
              onClick={() => setIndexExpand((pre) => !pre)}
            >
              <CustomText>{indexExpand ? t("숨기기") : t("더보기")}</CustomText>
            </Button>
          </BookContentWrapper>
        </DetailContentWrapper>
      )}
    </Grid>
  );
}

const DetailContentWrapper = styled.div`
  display: flex;
`;
const BookImageWrapper = styled.div`
  margin-right: 20px;
`;
const BookContentWrapper = styled.div`
  margin-left: 20px;
  padding: 10px;
`;
const BookTitle = styled.div`
  margin-bottom: 20px;
`;
const BookInfo = styled.div`
  margin-bottom: 20px;
`;
const BookTagList = styled.div`
  display: flex;
  margin-bottom: 50px;
`;
const BookDescription = styled.div`
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BookIndex = styled.div<{ expand: boolean }>`
  margin-top: 50px;
  max-height: ${(props) => (props.expand ? null : "100px")};
  overflow: hidden;
`;
