import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuizResultList } from '../UserAnswerType';
import useUserAnswerAPIs from '../useUserAnswerAPIs';

const useFetchQuizResultList = <T = FetchQuizResultList[]>(
    quizId: number,
    options?: UseQueryOptions<FetchQuizResultList[], AxiosError, T>
) => {
    const { getQuizResultListAPI } = useUserAnswerAPIs();

    return useQuery({
        queryKey: QueryKeys.quizResultList(quizId),
        queryFn: () => getQuizResultListAPI(quizId),
        enabled: quizId > 0,
        ...options
    });
}


export default useFetchQuizResultList;

