import { create } from "zustand";
import { QuizDomain } from "../../api/domains/quiz/QuizType";

type State = Pick<
  QuizDomain,
  | "quizName"
  | "description"
  | "quizOpenDate"
  | "quizCloseDate"
  | "isOptional"
  | "isActive"
  | "quizManagerIdList"
  | "examQuizVersionId"
> & {
  quizId: number | null;
  isDirty: boolean;
  isSubmit: boolean;
  createNewQuestion: boolean;
};

type Action = {
  createStore: (fetchedState: Partial<State>) => void;
  updateValue: (newState: Partial<State>) => void;

  setIsSubmit: (isSubmit: boolean) => void;
  resetStore: () => void;
};

const baseInitialState: State = {
  quizName: "",
  description: "",
  quizOpenDate: null,
  quizCloseDate: null,
  isOptional: false,
  isActive: false,
  quizId: null,
  isDirty: false,
  isSubmit: false,
  quizManagerIdList: [],
  examQuizVersionId: 0,
  createNewQuestion: false,
};

const usePostQuizStore = create<State & Action>((set) => ({
  ...baseInitialState,

  createStore: (fetchedState) => {
    set({ ...baseInitialState, ...fetchedState, isDirty: false });
  },

  updateValue: (newState) =>
    set((state) => {
      const updatedState = { ...state, ...newState };
      return { ...updatedState, isDirty: true };
    }),

  setIsSubmit: (isSubmit) =>
    set((state) => ({
      ...state,
      isSubmit: isSubmit,
    })),

  resetStore: () => {
    set(baseInitialState);
  },
}));

export default usePostQuizStore;
