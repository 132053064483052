import { create } from "zustand";

export type FileModalType = "create" | "update";

interface FileModalStoreType {
  isFileModalOpen: boolean;
  fileModalType: FileModalType;

  setFileModalState: (isOpen: boolean, modalType: FileModalType) => void;
  closeModal: () => void;
}

const initialState = {
  isFileModalOpen: false,
  fileModalType: "create" as FileModalType,
};
export const useFileModalStore = create<FileModalStoreType>((set) => ({
  ...initialState,

  setFileModalState: (isOpen, modalType) =>
    set({ isFileModalOpen: isOpen, fileModalType: modalType }),
  closeModal: () => set(initialState),
}));
