import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { QueryKeys } from "../../../config/QueryKeys";
import { FolderDetailDto } from "../FolderType";
import useFolderAPI from "../useFolderAPI";

const useFetchFolderDetail = <T = FolderDetailDto>(
  folderId: number,
  options?: UseQueryOptions<FolderDetailDto, AxiosError, T>
) => {
  const { getFolderDetailAPI } = useFolderAPI();

  return useQuery({
    queryKey: QueryKeys.folderDetail(folderId),
    queryFn: () => getFolderDetailAPI(folderId),
    ...options,
  });
};

export default useFetchFolderDetail;
