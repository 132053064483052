import { create } from "zustand";
import { QuizVersionDomain } from "../../api/domains/quiz/QuizType";

type State = Pick<
  QuizVersionDomain,
  "quizVersionId" | "quizPassScore" | "quizTimeMinute" | "quizVersionName"
> & {
  isDirty: boolean;
};

type Action = {
  createStore: (fetchedState: Partial<State>) => void;
  updateValue: (newState: Partial<State>) => void;

  setIsSubmit: (isSubmit: boolean) => void;
  resetStore: () => void;
};

const baseInitialState: State = {
  quizVersionId: 0,
  isDirty: false,
  quizVersionName: "",
  quizTimeMinute: 0,
  quizPassScore: 0,
};

const usePostQuizVersionStore = create<State & Action>((set) => ({
  ...baseInitialState,

  createStore: (fetchedState) => {
    set({
      ...baseInitialState,
      ...fetchedState,
      isDirty: false,
      quizVersionName: "",
    });
  },

  updateValue: (newState) =>
    set((state) => {
      const updatedState = { ...state, ...newState };
      return { ...updatedState, isDirty: true };
    }),

  setIsSubmit: (isSubmit) =>
    set((state) => ({
      ...state,
      isSubmit: isSubmit,
    })),

  resetStore: () => {
    set(baseInitialState);
  },
}));

export default usePostQuizVersionStore;
