import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchMyQuizScore } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';
import { useUserStore } from '../../../../stores/user/useUserStore';

const useFetchMyQuizScoreList = <T = FetchMyQuizScore[]>(
    options?: UseQueryOptions<FetchMyQuizScore[], AxiosError, T>
) => {
  const { getMyQuizScoreListAPI } = useQuizAPIs();
  const userId = useUserStore(state => state.userId);

  return useQuery({
    queryKey: QueryKeys.myQuizScoreList(userId),
    queryFn: () => getMyQuizScoreListAPI(),
    ...options
  });
}
export default useFetchMyQuizScoreList;