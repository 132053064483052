import styled from "@emotion/styled";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchCourseDetail from "../../api/domains/course/queries/useFetchCourseDetail";
import useFetchProfessorList from "../../api/domains/user/queries/useFetchProfessorList";
import TitleDiv from "../../components/atoms/TitleDiv";
import CourseStepper from "../../components/stepper/CourseStepper";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import { CustomText } from "../../components/text/CustomText";
import { CourseType, CourseTypeInterface } from "../../data/enums/CourseType";
import usePageMove from "../../hooks/common/usePageMove";
import useCourseHooks from "../../hooks/domains/useCourseHooks";
import usePostCourseStore from "../../stores/domains/usePostCourseStore";

interface PostCourseProps {
  isCreate?: boolean;
}
export default function PostCoursePage({ isCreate = false }: PostCourseProps) {
  const courseId = Number(useParams().id);
  const { t } = useTranslation();
  const { pageMove } = usePageMove();

  const { data: professorList } = useFetchProfessorList();
  const { data: courseDetail } = useFetchCourseDetail(courseId, {
    enabled: !isCreate && !isNaN(courseId),
  });

  const {
    courseName,
    description,
    courseType,
    professorId,
    courseOpenDate,
    courseCloseDate,
    isDirty,
    isSubmit,
    setIsSubmit,
    updateValue,
  } = usePostCourseStore((state) => ({
    courseName: state.courseName,
    description: state.description,
    courseType: state.courseType,
    professorId: state.professorId,
    courseOpenDate: state.courseOpenDate,
    courseCloseDate: state.courseCloseDate,
    isDirty: state.isDirty,
    isSubmit: state.isSubmit,
    setIsSubmit: state.setIsSubmit,
    updateValue: state.updateValue,
  }));

  /** hooks */
  const {
    handleSubmitCourse,
    useClearCourseDateIfNotDutyCourse,
    useCreateCourseStore,
  } = useCourseHooks();

  useCreateCourseStore(courseDetail);
  useClearCourseDateIfNotDutyCourse();

  return (
    <Grid container rowSpacing={3} mt={0}>
      <Grid item xs={12}>
        <CourseStepper
          step={0}
          ableToClick={!isCreate}
          courseId={courseId}
          isDirty={isDirty}
        />
      </Grid>
      <Grid item xs={12}>
        <TitleDiv title="강의 관리" />
        <Grid container justifyContent="flex-end" pb={2}>
          <Button
            onClick={() => {
              setIsSubmit(true);
              handleSubmitCourse(isCreate, courseId);
            }}
            variant="text"
            color="success"
          >
            <CustomText type="buttonLarge">{t("저장하기")}</CustomText>
          </Button>
          {!isCreate && (
            <Button
              variant="text"
              color="primary"
              onClick={() => pageMove(courseId, "lecture", isDirty)}
            >
              <CustomText type="buttonLarge">{t("다음단계")}</CustomText>
            </Button>
          )}
        </Grid>
        <CustomTableWrapper tdPadding="8px 16px">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="15%" component="th" align="center">
                  <CustomText type="subtitle">{t("강의 종류")}</CustomText>
                </TableCell>
                <TableCell width="25%">
                  <FormControl fullWidth error={isSubmit && !courseType}>
                    <InputLabel id="course-type-select-label">
                      {t("강의 종류를 선택해주세요.")}
                    </InputLabel>
                    <Select
                      id="course-type-select"
                      labelId="course-type-select-label"
                      label={t("강의 종류를 선택해주세요.")}
                      value={courseType}
                      onChange={(e) =>
                        updateValue({
                          courseType: e.target.value as CourseTypeInterface,
                        })
                      }
                    >
                      {Object.keys(CourseType).map((courseType) => (
                        <MenuItem key={courseType} value={courseType}>
                          <CustomText>{courseType}</CustomText>
                        </MenuItem>
                      ))}
                    </Select>
                    {isSubmit && !courseType && (
                      <FormHelperText>
                        {t("강의 종류를 선택해주세요.")}
                      </FormHelperText>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell width="15%" component="th" align="center">
                  <Grid container justifyContent="center" alignItems="center">
                    <CustomText type="subtitle">
                      {t("강의 기간 설정")}
                    </CustomText>
                    <TooltipComponent
                      content={t(
                        "강사만 지정 가능합니다. 찾는 강사가 없다면, 수강생 관리에서 강사 권한을 부여해주세요!"
                      )}
                      position="BottomCenter"
                    >
                      <IconButton>
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </TooltipComponent>
                  </Grid>
                </TableCell>
                <TableCell width="45%">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    <DatePicker
                      sx={{ width: "40%" }}
                      label={<CustomText>{t("시작일자")}</CustomText>}
                      maxDate={dayjs(courseCloseDate)}
                      format="YYYY-MM-DD"
                      disabled={courseType !== CourseType.법정의무교육}
                      value={dayjs(courseOpenDate)}
                      slotProps={{
                        field: { clearable: true },
                      }}
                      onChange={(newValue) =>
                        updateValue({
                          courseOpenDate: newValue?.toDate() ?? null,
                        })
                      }
                    />
                    <Tilde>~</Tilde>
                    <DatePicker
                      sx={{ width: "40%" }}
                      label={<CustomText>{t("종료일자")}</CustomText>}
                      minDate={dayjs(courseOpenDate)}
                      format="YYYY-MM-DD"
                      disabled={courseType !== CourseType.법정의무교육}
                      value={dayjs(courseCloseDate)}
                      slotProps={{
                        field: { clearable: true },
                      }}
                      onChange={(newValue) =>
                        updateValue({
                          courseCloseDate: newValue?.toDate() ?? null,
                        })
                      }
                    />
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Grid container justifyContent="center" alignItems="center">
                    <CustomText type="subtitle">{t("강사명")}</CustomText>
                    <TooltipComponent
                      content={t(
                        "강사만 지정 가능합니다. 찾는 강사가 없다면, 수강생 관리에서 강사 권한을 부여해주세요!"
                      )}
                      position="BottomCenter"
                    >
                      <IconButton>
                        <HelpOutlineIcon fontSize="small" />
                      </IconButton>
                    </TooltipComponent>
                  </Grid>
                </TableCell>
                <TableCell colSpan={3}>
                  {professorList && (
                    <FormControl fullWidth>
                      <InputLabel id="course-professor-select-label">
                        {t("담당 강사를 지정해주세요.")}
                      </InputLabel>
                      <Select
                        id="course-professor-select"
                        labelId="course-professor-select-label"
                        label={t("담당 강사를 지정해주세요.")}
                        value={professorId ?? 0}
                        onChange={(e) => {
                          const newValue = Number(e.target.value);
                          if (newValue === 0) {
                            updateValue({ professorId: 0 });
                          } else {
                            updateValue({ professorId: newValue });
                          }
                        }}
                      >
                        <MenuItem value={0}>{t("지정안함")}</MenuItem>
                        {professorList.map((professor) => (
                          <MenuItem
                            key={professor.userId}
                            value={professor.userId}
                          >
                            {`${professor.userName}(${professor.email})`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Grid container justifyContent="center" alignItems="center">
                    <CustomText type="subtitle">{t("강의명")}</CustomText>
                  </Grid>
                </TableCell>
                <TableCell colSpan={3}>
                  <TextField
                    id="course-name"
                    fullWidth
                    value={courseName}
                    error={isSubmit && !courseName.trim()}
                    helperText={
                      isSubmit && !courseName.trim()
                        ? t("필수 입력값입니다.")
                        : ""
                    }
                    onChange={(e) =>
                      updateValue({ courseName: e.target.value })
                    }
                    label={t("강의명을 입력해주세요.")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <Grid container justifyContent="center" alignItems="center">
                    <CustomText type="subtitle">{t("강의소개")}</CustomText>
                  </Grid>
                </TableCell>
                <TableCell colSpan={3}>
                  <TextField
                    id="description"
                    fullWidth
                    multiline
                    minRows={3}
                    value={description}
                    error={isSubmit && !description.trim()}
                    helperText={
                      isSubmit && !description.trim()
                        ? t("필수 입력값입니다.")
                        : ""
                    }
                    onChange={(e) =>
                      updateValue({ description: e.target.value })
                    }
                    label={t("강의 소개를 입력해주세요.")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
    </Grid>
  );
}

export const Tilde = styled.div`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;
