import { DragEvent } from "react";
import { useTranslation } from "react-i18next";
import { FileListDto } from "../../api/domains/file/FileType";
import { FolderPathListDto } from "../../api/domains/folder/FolderType";
import ToastAlert from "../../components/alert/ToastAlert";
import {
  StoragePermission,
  StoragePermissionType,
} from "../../data/enums/PermissionType";
import { useSelectionManagerStore } from "../../stores/folder/useSelectionManagerStore";
import useFileHooks from "./useFileHooks";
import useFolderHooks from "./useFolderHooks";

const useDragDropHooks = () => {
  const { t } = useTranslation();

  const { changeFileLocationAsync } = useFileHooks();
  const { changeFolderLocationAsync } = useFolderHooks();

  const { selectedFileIdSet, selectedFolderIdSet, resetFileAndFolderSelect } =
    useSelectionManagerStore((state) => ({
      selectedFileIdSet: state.selectedFileIdSet,
      selectedFolderIdSet: state.selectedFolderIdSet,
      resetFileAndFolderSelect: state.resetFileAndFolderSelect,
    }));

  // #region 파일 이동 시작
  const fileMoveStart = (
    e: DragEvent,
    dragFile: FileListDto,
    fileImageSrc: string
  ) => {
    let selectedFolderIdList: number[] = [];
    let selectedFileIdList = [];

    const isUnSelectedFile = selectedFileIdSet.has(dragFile.fileId);

    // 다른 파일 선택시 선택 풀림
    if (!isUnSelectedFile) {
      resetFileAndFolderSelect();
      selectedFileIdList.push(dragFile.fileId);
    } else {
      selectedFolderIdList = Array.from(selectedFolderIdSet);
      selectedFileIdList = Array.from(selectedFileIdSet);
    }

    const moveFileIdList = selectedFileIdList.join(",");
    const moveFolderIdList = selectedFolderIdList.join(",");

    e.dataTransfer.setData("application/move-file-id-list", moveFileIdList);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("application/move-folder-id-list", moveFolderIdList);
    e.dataTransfer.effectAllowed = "move";

    setDragImage(e, fileImageSrc);
  };
  //#endregion

  // 폴더 이동 시작
  const folderMoveStart = (e: DragEvent, dragFolder: FolderPathListDto) => {
    let selectedFolderIdList: number[] = [];
    let selectedFileIdList: number[] = [];

    // 다른 폴더 선택시 선택 풀림
    if (!selectedFolderIdSet.has(dragFolder.id)) {
      resetFileAndFolderSelect();
      selectedFolderIdList.push(dragFolder.id);
    } else {
      selectedFolderIdList = Array.from(selectedFolderIdSet);
      selectedFileIdList = Array.from(selectedFileIdSet);
    }

    const moveFileIdList = selectedFileIdList.join(",");
    const moveFolderIdList = selectedFolderIdList.join(",");

    e.dataTransfer.setData("application/move-file-id-list", moveFileIdList);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("application/move-folder-id-list", moveFolderIdList);
    e.dataTransfer.effectAllowed = "move";

    setDragImage(e, `${process.env.PUBLIC_URL}/assets/images/folder.svg`);
  };

  //#region @private 드래드 이미 setting
  const setDragImage = (e: DragEvent, imageSrc: string) => {
    const dragImage = document.createElement("img");
    dragImage.src = imageSrc;
    dragImage.width = 100;
    dragImage.height = 100;
    dragImage.style.padding = "10px";
    dragImage.style.border = "1px solid black";
    dragImage.style.position = "absolute";
    dragImage.style.top = "-1000px";
    document.body.appendChild(dragImage);

    e.dataTransfer.setDragImage(dragImage, 50, 50);
    setTimeout(() => document.body.removeChild(dragImage), 0);
  };
  //#endregion

  //#region 파일, 폴더 Drop 일때 동작하는 함수
  const dropToTargetFolder = (
    e: DragEvent,
    targetFolderId: number,
    folderAuth: StoragePermissionType
  ) => {
    e.preventDefault();

    const fileIdList = e.dataTransfer
      .getData("application/move-file-id-list")
      .split(",")
      .filter(Boolean)
      .map((fileId) => Number(fileId));

    const folderIdList = e.dataTransfer
      .getData("application/move-folder-id-list")
      .split(",")
      .filter(Boolean)
      .map((folderId) => Number(folderId));

    if (folderIdList.includes(targetFolderId)) {
      ToastAlert(
        t("선택된 폴더 중 동일한 폴더로 이동시킬 수 없습니다."),
        "warning"
      );
      return;
    }

    if (folderIdList.length > 0) {
      if (folderAuth === StoragePermission.MANAGER) {
        changeFolderLocationAsync(folderIdList, targetFolderId);
      } else {
        ToastAlert(t("폴더 이동권한이 없습니다."), "info");
      }
    }
    if (fileIdList.length > 0) {
      if (
        folderAuth === StoragePermission.UPLOADER ||
        folderAuth === StoragePermission.MANAGER
      ) {
        changeFileLocationAsync(fileIdList, targetFolderId);
      } else {
        ToastAlert(t("파일 이동권한이 없습니다."), "info");
      }
    }
  };
  //#endregion

  return {
    fileMoveStart,
    folderMoveStart,
    dropToTargetFolder,
  };
};

export default useDragDropHooks;
