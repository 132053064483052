import { Grid } from "@mui/material";
import useFetchMainPageCourseList from "../../api/domains/course/queries/useFetchMainPageCourseList";
import MainPageCourseList from "./components/MainPageCourseList";
import { useTranslation } from "react-i18next";
import MainPageRecommendBookList from "./components/MainPageRecommendBookList";

export default function MainPage() {
  const { t } = useTranslation();

  const { data: newCourseList, isLoading: newCourseIsLoading } =
    useFetchMainPageCourseList(true);

  const { data: allCourseList, isLoading: allCourseListIsLoading } =
    useFetchMainPageCourseList(false);

  return (
    <Grid container rowSpacing={2} mt={2}>
      {/* <Grid item xs={12}>
        <PageHeader title={t('메인페이지')} />
      </Grid> */}
      <Grid item xs={12}>
        <MainPageCourseList
          isNewCourse={true}
          courseList={newCourseList}
          isLoading={newCourseIsLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <MainPageCourseList
          isNewCourse={false}
          courseList={allCourseList}
          isLoading={allCourseListIsLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <MainPageRecommendBookList />
      </Grid>
    </Grid>
  );
}
