import { useTranslation } from "react-i18next";
import PageHeader from "../../components/templates/PageHeader";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import useFetchQuizList from "../../api/domains/quiz/queries/useFetchQuizList";
import { CustomText } from "../../components/text/CustomText";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import CustomTextTooltip from "../../components/text/CustomTextTooltip";
import { TableLoading } from "../../components/loading/TableLoading";
import Urls from "../../routes/Urls";
import { useNavigate } from "react-router-dom";
import {
  KeyWordWrapper,
  PaginationWrapper,
} from "../course-list/CourseListPage";
import { BasicFontKR } from "../../styles/GlobalStyle";
import { useState } from "react";
import { getRegExp } from "korean-regexp";
import SearchIcon from "@mui/icons-material/Search";
import { PageSize } from "../../data/enums/PageSize";
import useDateHooks from "../../hooks/common/useDateHooks";

type QuizListSearchCondition = "Name";

const QuizScoreSearchCondition: Record<
  QuizListSearchCondition,
  QuizListSearchCondition
> = {
  Name: "Name",
};

export default function QuizListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchCondition, setSearchCondition] = useState("Name");
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const { data: quizList, isLoading } = useFetchQuizList();

  const renderQuizList = quizList?.filter((quizScore) => {
    switch (searchCondition) {
      case QuizScoreSearchCondition.Name:
        return quizScore.quizName.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  const { getDateStringFormat } = useDateHooks();
  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("시험목록")} />
      </Grid>
      <Grid container justifyContent={"flex-end"}>
        <Select
          size="small"
          value={searchCondition}
          onChange={(event) => setSearchCondition(event.target.value)}
          displayEmpty
        >
          <MenuItem value={QuizScoreSearchCondition.Name}>
            <CustomText>{t("강의명")}</CustomText>
          </MenuItem>
        </Select>
        <KeyWordWrapper>
          <InputBase
            sx={{ ml: 1, flex: 1, fontFamily: BasicFontKR, fontSize: "14px" }}
            className="inputField"
            placeholder={t("검색어를 입력하세요.")}
            value={searchKeyWords}
            onChange={(event) => setSearchKeyWords(event.target.value)}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="small"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </KeyWordWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("시험명")}</CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("시험 소개")}</CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("응시 가능 기간")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("필수 응시")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("제한시간")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("시험응시")}</CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(2, 6)}
              {renderQuizList && renderQuizList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    <CustomText type="subtitle">
                      {t("조회된 시험이 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {renderQuizList &&
                renderQuizList
                  .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                  .map((quiz, index) => {
                    return (
                      <TableRow key={quiz.quizId}>
                        <TableCell align="center">
                          <CustomTextTooltip type="body">
                            {quiz.quizName}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CustomTextTooltip type="body">
                            {quiz.description}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          {quiz.quizOpenDate && quiz.quizCloseDate ? (
                            <CustomText type="body">
                              {`${getDateStringFormat(
                                quiz.quizOpenDate,
                                "YYYY-MM-DD hh:mm"
                              )} ~ ${getDateStringFormat(
                                quiz.quizCloseDate,
                                "YYYY-MM-DD hh:mm"
                              )}`}
                            </CustomText>
                          ) : (
                            <CustomText type="body">{"-"}</CustomText>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {quiz.isOptional ? t("선택") : t("필수")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {quiz.quizTimeMinute} 분
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          {/** TODO 여기 examQuizVersionId로 변경해야함. */}
                          {quiz.canStartQuiz ? (
                            <Button
                              onClick={() =>
                                navigate(
                                  Urls.exam.replace(":id", String(quiz.quizId))
                                )
                              }
                              variant="text"
                              color="primary"
                            >
                              <CustomText type="button">
                                {t("응시하기")}
                              </CustomText>
                            </Button>
                          ) : (
                            <Button disabled variant="text" color="error">
                              <CustomText type="button">
                                {"응시불가"}
                              </CustomText>
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
      <Grid item xs={12}>
        <PaginationWrapper id="pagination-wrapper">
          {renderQuizList && (
            <Pagination
              color="standard"
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(renderQuizList.length / PageSize.BASIC)}
            />
          )}
        </PaginationWrapper>
      </Grid>
    </Grid>
  );
}
