import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SaveAnswer } from '../../api/domains/answer/UserAnswerType';
import useUserAnswerAPIs from '../../api/domains/answer/useUserAnswerAPIs';
import ToastAlert from '../../components/alert/ToastAlert';
import { ExamAnswerFormValues } from './components/ExamForm';
import { QuestionTypeValue } from '../../data/enums/QuestionType';

export default function useExamPageHooks() {
    const { t } = useTranslation();
    const { saveAnswerAPI } = useUserAnswerAPIs();

    const saveAnswerQuery = useMutation((request: SaveAnswer) => saveAnswerAPI(request))
    const submit = (data: ExamAnswerFormValues, attemptId: number) => {
        const request: SaveAnswer = {
            attemptId: attemptId,
            answerList: data.answerList.map(question => {
                return {
                    questionId: question.questionId,
                    submitAnswer: question.userAnswer,
                    isObjectiveAnswer: question.questionType !== QuestionTypeValue.주관식,
                }
            })
        } 
        saveAnswerQuery.mutate(request, {
            onSuccess: () => {
                ToastAlert(t('답안이 저장되었습니다.'), 'success');
            }
        })
    }

    return {
        submit
    }
}
