import { EmitType } from '@syncfusion/ej2-base';
import { ChangeEventArgs } from '@syncfusion/ej2-buttons';
import { HtmlEditor, Image, Inject, Link, RichTextEditorComponent, Table, Toolbar } from '@syncfusion/ej2-react-richtexteditor';

const fontFamily = {
  default: "Noto Sans",
  items: [
    { text: "Noto Sans", value: "Noto Sans", command: "Font", subCommand: "FontName" },
  ]
};

const multiRowToolbarSetting: object = {
  items: ['FontName', 'FontSize', '|',
    'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
    'CreateTable', '|',
    'FontColor', 'BackgroundColor', '|',
    'Alignments', 'OrderedList', 'UnorderedList',
    'Outdent', 'Indent', '|',
    'CreateLink', 'Image', '|',
    'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
  ],
  type: 'MultiRow',
};
//type : Expand, Scrollable

const insertImageSettings: object = {
  path: 'https://inbodybizstorage.blob.core.windows.net/academy-imgs/',
  saveUrl: `${process.env.REACT_APP_API_URL}/api/question/upload/question-image`,
  removeUrl: `${process.env.REACT_APP_API_URL}/api`,
}

const onImageUploadSuccess = (args: any) => {
  if (args.e.currentTarget.getResponseHeader('name') != null) {
    args.file.name = args.e.currentTarget.getResponseHeader('name');
    let filename: any = document.querySelectorAll(".e-file-name")[0];
    filename.innerHTML = args.file.name.replace(document.querySelectorAll(".e-file-type")[0].innerHTML, '');
    filename.title = args.file.name;
  }
}
// const quickToolbarSettings: object = {
//     table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles'],
// }

interface RichTextEditorProps {
  minHeight?: string,
  height?: string | number,
  id?: string,
  name?: string,
  value?: string,
  placeholder?: string,
  change?: EmitType<ChangeEventArgs>,
  mobileExpandMenu?: boolean,
}


const RichTextEditor = ({ minHeight, height, id, name, value, change, placeholder, mobileExpandMenu }: RichTextEditorProps) => {

  return (
    <RichTextEditorComponent
      id={id}
      name={name}
      insertImageSettings={insertImageSettings}
      imageUploadSuccess={onImageUploadSuccess}
      value={value}
      height={height}
      fontFamily={fontFamily}
      placeholder={placeholder}
      change={change}
      style={{ minHeight: minHeight }}
      toolbarSettings={multiRowToolbarSetting}
    >
      <Inject services={[Toolbar, Image, Link, HtmlEditor, Table]} />
    </RichTextEditorComponent>
  );
}
export default RichTextEditor;
