import styled from "@emotion/styled";
import { Alert, Avatar, Box, Button, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";
import { CustomText } from "../../../components/text/CustomText";
import useFetchUserList from "../../../api/domains/user/queries/useFetchUserList";
import { BorderGray, BoxBlue } from "../../../styles/GlobalStyle";
import useColorHooks from "../../../hooks/common/useColorHooks";
import { useTranslation } from "react-i18next";
import { Language } from "../../../data/common/Language";

export interface AuthInfoModalState {
  isOpen: boolean;
  folderKoreaName: string;
  folderEnglishName: string;
  folderAuthUserIdList: number[];
}

export const initAuthInfoModalState: AuthInfoModalState = {
  isOpen: false,
  folderEnglishName: "",
  folderKoreaName: "",
  folderAuthUserIdList: [],
};

type ArchiveNotAuthInfoModalProps = AuthInfoModalState & {
  setAuthInfoModalState: Dispatch<SetStateAction<AuthInfoModalState>>;
};

export default function ArchiveNotAuthInfoModal({
  isOpen,
  setAuthInfoModalState,
  folderKoreaName,
  folderEnglishName,
  folderAuthUserIdList,
}: ArchiveNotAuthInfoModalProps) {
  const { t, i18n } = useTranslation();
  const { data: userList } = useFetchUserList();
  const { stringToColor } = useColorHooks();

  const hasAuthUserList = userList?.filter((user) => {
    return folderAuthUserIdList.includes(user.userId);
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setAuthInfoModalState(initAuthInfoModalState);
      }}
    >
      <ModalWrapper>
        <Alert severity="info">
          <CustomText>
            {t("이 파일은 접근 권한이 없는 비공개 폴더에 위치해 있습니다.")}
          </CustomText>
          <CustomText>
            {t("폴더 관리자에게 다운로드 권한을 문의해주세요.")}
          </CustomText>
        </Alert>
        <CustomText type="title" align="center" mt={2}>
          {`${t("폴더명")} : `}
          {i18n.language === Language.KOREAN
            ? folderKoreaName
            : folderEnglishName}
        </CustomText>
        <CustomText type="subtitle" mt={1} mb={1}>
          {t("폴더 관리자 목록")}
        </CustomText>
        <ContentWrapper>
          {hasAuthUserList &&
            hasAuthUserList.map((user) => (
              <Box
                key={user.userId}
                display={"flex"}
                alignItems={"center"}
                sx={{
                  p: "3px 15px",
                  position: "relative",
                  "&:hover": { bgcolor: BoxBlue },
                  borderBottom: `1px solid ${BorderGray}`,
                }}
              >
                <Avatar
                  sx={{
                    mr: 1,
                    width: 40,
                    height: 40,
                    bgcolor: stringToColor(user.userName),
                  }}
                >
                  <CustomText type="small">
                    {user.userName.slice(0, 3)}
                  </CustomText>
                </Avatar>
                <Box>
                  <CustomText type="body" bold500>
                    {user.userName}
                  </CustomText>
                  <CustomText type="small">{`${user.email}`}</CustomText>
                  <CustomText type="small">
                    {`${user.parentTeam ?? "-"} / ${user.team ?? "-"} / ${
                      user.jobTitle ?? "-"
                    }  / ${user.jobTitle ?? "-"}`}
                  </CustomText>
                </Box>
              </Box>
            ))}
        </ContentWrapper>
        <Box width={"100%"} display={"flex"} justifyContent={"center"} mt={1}>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              setAuthInfoModalState(initAuthInfoModalState);
            }}
          >
            <CustomText type="button">{t("확인")}</CustomText>
          </Button>
        </Box>
      </ModalWrapper>
    </Modal>
  );
}
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 16px;
`;
const ContentWrapper = styled.div`
  overflow-y: auto;
  border: 1px solid ${BorderGray};
  height: 385px;
`;
