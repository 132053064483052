import { ChangeEvent, FocusEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  accountIdRegExp,
  emailRegExp,
  pwdRegExp,
} from "../validation/useUserValidation";
import useUserAPIs from "../../api/domains/user/useUserAPIs";
import { CreateUser } from "../../api/domains/user/UserType";
import ToastAlert from "../../components/alert/ToastAlert";
import { useNavigate } from "react-router-dom";
import Urls from "../../routes/Urls";

const useSignUpHooks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [accountId, setAccountId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  //에러 문구
  const [accountIdError, setAccountIdError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  //이메일, 계정명 사용 가능 여부
  const [isAccountIdAvailable, setIsAccountIdAvailable] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState(false);
  //약관 동의
  const [allAgree, setAllAgree] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(false);

  const { getIsAccountIdAvailableAPI, getIsEmailAvailableAPI, signUpAPI } =
    useUserAPIs();

  //#region 아이디 입력 변경
  const handleAccountIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAccountId(value);
  };
  //#endregion

  //#region 성명 입력 변경
  const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUserName = e.target.value;
    setUserName(newUserName);
  };
  //#endregion

  //#region 비밀번호 입력 변경
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (!validatePassword(value)) {
      setPasswordError(t("비밀번호 규칙 설명"));
    } else {
      setPasswordError("");
    }
  };
  //#endregion

  //#region 비밀번호 확인 입력 변경
  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (!validateConfirmPassword(password, newConfirmPassword)) {
      setConfirmPasswordError(t("비밀번호가 일치하지 않습니다."));
    } else {
      setConfirmPasswordError("");
    }
  };
  //#endregion

  //#region 이메일 입력 변경
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!validateEmail(newEmail)) {
      setEmailError(t("유효한 이메일 주소를 입력하세요."));
    } else {
      setEmailError("");
    }
  };
  //#endregion

  //#region 아이디 blur
  const handleAccountIdBlur = (e: FocusEvent<HTMLInputElement>) => {
    const newAccountId = e.target.value;
    if (validateAccountId(newAccountId)) {
      getIsAccountIdAvailableAPI(newAccountId).then((isAccountIdUsed) => {
        if (isAccountIdUsed) {
          setIsAccountIdAvailable(true);
          setAccountIdError(t("사용 가능한 아이디입니다."));
        } else {
          setIsAccountIdAvailable(false);
          setAccountIdError(t("이미 사용중인 아이디입니다."));
        }
      });
    } else {
      setIsAccountIdAvailable(false);
      setAccountIdError(t("아이디 규칙 설명"));
    }
  };
  //#endregion

  //#region Email blur
  const handleEmailBlur = (e: FocusEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    if (validateEmail(newEmail)) {
      getIsEmailAvailableAPI(newEmail).then((isEmailUsed) => {
        if (isEmailUsed) {
          setIsEmailAvailable(true);
          setEmailError(t("사용 가능한 이메일입니다."));
        } else {
          setIsEmailAvailable(false);
          setEmailError(t("이미 사용중인 이메일입니다."));
        }
      });
    } else {
      setIsEmailAvailable(false);
      setEmailError(t("유효한 이메일 주소를 입력하세요."));
    }
  };
  //#endregion

  //#region 개인정보 수집 이용동의 버튼 클릭
  const handlePrivacyAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setPrivacyAgreement(checked);
    setAllAgree(checked);
  };
  //#endregion

  //#region 모두 동의 버튼 클릭
  const handleAllAgreeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setAllAgree(checked);
    setPrivacyAgreement(checked);
  };
  //#endregion

  //#region 회원가입 요청
  const handleSubmit = () => {
    if (
      !validateAccountId(accountId) ||
      !validatePassword(password) ||
      !validateEmail(email) ||
      !validateConfirmPassword(password, confirmPassword) ||
      !validateUserName(userName)
    ) {
      ToastAlert(t("입력값이 올바르지 않습니다."), "error");
      return;
    }

    const request: CreateUser = {
      accountId: accountId,
      email: email,
      userName: userName,
      password: password,
      confirmPassword: confirmPassword,
    };
    signUpAPI(request)
      .then((res) => {
        ToastAlert(t("가입을 환영합니다."), "success");
        navigate(Urls.login);
      })
      .catch((err) => {
        ToastAlert(
          "가입 중 에러가 발생하였습니다. 다시 시도해주세요.",
          "error"
        );
      });
  };
  //#endregion

  //#region validation
  // 아이디 validation
  const validateAccountId = (id: string) => {
    return accountIdRegExp.test(id);
  };

  // 비밀번호 validation
  const validatePassword = (pwd: string) => {
    return pwdRegExp.test(pwd);
  };

  // 이메일 validation
  const validateEmail = (email: string) => {
    return emailRegExp.test(email);
  };

  // 비밀번호 확인 validation
  const validateConfirmPassword = (pwd: string, confirmPwd: string) => {
    return pwd === confirmPwd;
  };

  //유저 이름 validation
  const validateUserName = (userName: string) => {
    return userName.trim().length > 0 && userName.length < 20;
  };

  //#endregion

  return {
    handleAccountIdChange,
    handleUserNameChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleEmailChange,

    handlePrivacyAgreementChange,
    handleAllAgreeChange,

    handleAccountIdBlur,
    handleEmailBlur,
    handleSubmit,

    accountId,
    userName,
    password,
    confirmPassword,
    email,
    accountIdError,
    passwordError,
    confirmPasswordError,
    emailError,
    allAgree,
    privacyAgreement,
    isAccountIdAvailable,
    isEmailAvailable,
  };
};

export default useSignUpHooks;
