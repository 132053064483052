import useGetApiInstance from "../../config/BasicApi";
import {
  CreateQuiz,
  FetchExamInfo,
  FetchManagingQuizList,
  FetchMyQuizScore,
  FetchQuizDetail,
  FetchQuizListForExam,
  FetchQuizTargetList,
  ModifyQuiz,
  ToggleQuizTarget,
} from "./QuizType";

export const useQuizAPIs = () => {
  const api = useGetApiInstance("quiz");

  // 응시 정보 조회
  const getAttemptInfoAPI = (quizVersionId: number): Promise<FetchExamInfo> =>
    api.get(`attempt-info/${quizVersionId}`).then((res) => res.data.result);

  // 관리용 퀴즈 리스트 조회
  const getManagingQuizListAPI = (): Promise<FetchManagingQuizList[]> =>
    api.get(`managing-quiz-list`).then((res) => res.data.result);

  // 사용자용 퀴즈 목록 조회
  const getQuizListAPI = (): Promise<FetchQuizListForExam[]> =>
    api.get(`quiz-list`).then((res) => res.data.result);

  // 본인 시험 결과 조회
  const getMyQuizScoreListAPI = (): Promise<FetchMyQuizScore[]> =>
    api.get("my-score").then((res) => res.data.result);

  // 퀴즈 상세 조회
  const getQuizDetailAPI = (quizId: number): Promise<FetchQuizDetail> =>
    api.get(`detail/${quizId}`).then((res) => res.data.result);

  // 퀴즈 생성
  const createQuizAPI = (request: CreateQuiz): Promise<number> =>
    api.post("create", request).then((res) => res.data.result);

  // 퀴즈 수정
  const modifyQuizAPI = (request: ModifyQuiz): Promise<number> =>
    api.put("modify", request).then((res) => res.data.result);

  // 퀴즈 삭제
  const deleteQuizAPI = (quizId: number): Promise<string> =>
    api.delete(`${quizId}`).then((res) => res.data.result);

  // 유저 + 퀴즈 대상여부 전체 조회
  const getUserListWithQuizTargetInfoAPI = (
    quizId: number
  ): Promise<FetchQuizTargetList[]> =>
    api
      .get(`users-with-quiz-target-info/${quizId}`)
      .then((res) => res.data.result);

  // 퀴즈 대상 토글
  const toggleQuizTargetAPI = (request: ToggleQuizTarget): Promise<boolean> =>
    api.put("quiz-target", request).then((res) => res.data.result);

  // 시험 활성화 여부 토글
  const toggleQuizActiveAPI = (quizId: number): Promise<boolean> =>
    api.put(`toggle-quiz-active/${quizId}`).then((res) => res.data.result);

  // 시험 활성화 여부 토글
  const changeExamQuizVersionAPI = (
    quizId: number,
    quizVersionId: number
  ): Promise<boolean> =>
    api
      .put(`change-exam-quiz-version/${quizId}/${quizVersionId}`)
      .then((res) => res.data.result);

  return {
    getAttemptInfoAPI,
    getManagingQuizListAPI,
    getQuizListAPI,
    getMyQuizScoreListAPI,
    getQuizDetailAPI,
    createQuizAPI,
    modifyQuizAPI,
    deleteQuizAPI,
    getUserListWithQuizTargetInfoAPI,
    toggleQuizTargetAPI,
    toggleQuizActiveAPI,
    changeExamQuizVersionAPI,
  };
};

export default useQuizAPIs;
