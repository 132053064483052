import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FolderTreeDto } from '../FolderType';
import useFolderAPI from '../useFolderAPI';

const useFetchFolderAuthTree = <T = FolderTreeDto>(
    options?:UseQueryOptions<FolderTreeDto, AxiosError, T>
) => {
    
    const { getFolderAuthTreeAPI } = useFolderAPI();
    
    return useQuery({
        queryKey: QueryKeys.folderAuthTree(),
        queryFn: () => getFolderAuthTreeAPI(),
        ...options
});
}

export default useFetchFolderAuthTree;