import useGetApiInstance from '../../config/BasicApi';
import { FetchDocumentList, PostDocumentList } from './DocumentType';

const useDocumentAPIs = () => {
  const api = useGetApiInstance('document');

  // 자료 업로드용 SAS Token 요청
  const getDocumentSasTokenAPI = (courseId: number): Promise<string> =>
    api.get(`/sas-token/${courseId}`).then(res => res.data.result);
  
  // 자료 다운로드용 SAS Token 요청
  const getDownloadDocumentSasTokenAPI = (documentId: number): Promise<string> =>
    api.get(`/download/${documentId}`).then(res => res.data.result);
  
  // 강의에 있는 자료 목록 조회
  const getDocumentListAPI = (courseId: number): Promise<FetchDocumentList[]> =>
    api.get(`/${courseId}`).then(res => res.data.result);

  // 자료 관리
  const postDocumentAPI = (request: PostDocumentList) =>
    api.post('/post', request).then(res => res.data.result);

  return {
    getDocumentSasTokenAPI,
    getDownloadDocumentSasTokenAPI,
    getDocumentListAPI,
    postDocumentAPI
  }   
}

export default useDocumentAPIs;