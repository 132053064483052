import useGetApiInstance from '../../config/BasicApi';
import { FetchLectureList, FetchPlayLecture, FetchPostLectureList, PostLectureList, UpdateLectureProgress } from './LectureType';


const useLectureAPIs = () => {
    const api = useGetApiInstance('lecture');

    // 강의 업로드용 SAS Token 요청
    const getLectureSasTokenAPI = (courseId: number): Promise<string> =>
        api.get(`/sas-token/${courseId}`).then(res => res.data.result);

    // 강의에 있는 영상 목록 조회
    const getLectureListAPI = (courseId: number): Promise<FetchLectureList[]> => 
        api.get(`/${courseId}`).then(res => res.data.result);

    // 강의 수정용 목록 조회
    const getPostLectureListAPI = (courseId: number): Promise<FetchPostLectureList[]> =>
        api.get(`/managed-lectures/${courseId}`).then(res => res.data.result);

    // 영상 URL 받아옴.
    const playLectureAPI = (lectureId: number): Promise<FetchPlayLecture> =>
        api.get(`/play/${lectureId}`).then(res => res.data.result);

    // 영상 진도 체크
    const progressCheckAPI = (progressCheckDto: UpdateLectureProgress) =>
        api.put(`/progress`, progressCheckDto);

    // 영상 업로드
    const postLectureListAPI = (request: PostLectureList) =>
        api.post('/post', request).then(res => res.data.result);
    
    return {
        getLectureSasTokenAPI,
        getLectureListAPI,
        getPostLectureListAPI,
        playLectureAPI,
        progressCheckAPI,
        postLectureListAPI
    }
}

export default useLectureAPIs;