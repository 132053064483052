import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import useQuizAPIs from "../../api/domains/quiz/useQuizAPIs";
import {
  CreateQuizVersion,
  FetchQuizVersionList,
} from "../../api/domains/quizVersion/QuizVersionType";
import useQuizVersionAPIs from "../../api/domains/quizVersion/useQuizVersionAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import usePostQuizVersionStore from "../../stores/domains/usePostQuizVersionStore";
import { isQuizVersionValid } from "../validation/isQuizVersionValid";
import useQuestionHooks from "./useQuestionHooks";

export default function useQuizVersionHooks() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { createQuizVersionAPI } = useQuizVersionAPIs();
  const { changeExamQuizVersionAPI } = useQuizAPIs();

  const {
    quizVersionName,
    quizPassScore,
    quizTimeMinute,
    createStore,
    resetStore,
  } = usePostQuizVersionStore((state) => ({
    quizVersionName: state.quizVersionName,
    quizPassScore: state.quizPassScore,
    quizTimeMinute: state.quizTimeMinute,
    createStore: state.createStore,
    resetStore: state.resetStore,
  }));

  const { createQuestionList } = useQuestionHooks();

  //#region 시험 버전 생성 => quizVersion 갱신 + question 생성
  const createQuizVersionQuery = useMutation((request: CreateQuizVersion) =>
    createQuizVersionAPI(request)
  );

  const createQuizVersion = (quizId: number) => {
    const createQuizVersionDto: CreateQuizVersion = {
      quizId: quizId,
      quizPassScore: quizPassScore,
      quizVersionName: quizVersionName,
      quizTimeMinute: quizTimeMinute,
    };
    createQuizVersionQuery.mutate(createQuizVersionDto, {
      onSuccess: (quizVersionId) => {
        changeExamQuizVersionAPI(quizId, quizVersionId);
        createQuestionList(quizVersionId);
        queryClient.invalidateQueries(QueryKeys.quizVersionList(quizId));
      },
      onError: () => {
        ToastAlert(t("에러가 발생했습니다. 관리자에게 문의해주세요."), "error");
      },
    });
  };
  //#endRegion

  //#region 데이터 초기 패칭
  const useCreateQuizVersionStore = (quizVersion?: FetchQuizVersionList) => {
    useEffect(() => {
      if (quizVersion) {
        createStore(quizVersion);
      }
      return () => resetStore();
    }, [quizVersion]);
  };
  //#endregion

  //#region quizVersion valid check
  const isQuizVersionValidCheck = () => {
    return isQuizVersionValid(quizVersionName, quizPassScore, quizTimeMinute);
  };
  //#endregion

  return {
    createQuizVersion,
    useCreateQuizVersionStore,
    isQuizVersionValidCheck,
  };
}
