import styled from '@emotion/styled';
import { Skeleton } from '@mui/material';

export const ImageLoading = (width: number, height: number, count: number) => {
    let renderArr = [];
    for (let i = 0; i < count; i++) {
        renderArr.push(
            <Item key={i} >
                <Skeleton
                    variant='rectangular'
                    width={`${width}px`}
                    height={`${height}px`}
                    sx={{ mb: '10px' }}
                />
                <Skeleton
                    variant='rectangular'
                    width={`${width}px`}
                />
            </Item>
        )
    }
    return renderArr;
}

const Item = styled.div`
    margin: 0 4px;
    text-align: center;
    cursor: pointer;
`