import { Dispatch, SetStateAction } from "react";

export const moveCarousel = (
  idx: number,
  setIdx: Dispatch<SetStateAction<number>>,
  sign: string,
  maxNumber: number,
  minNumber: number
) => {
  if (sign === "+" && idx < maxNumber) {
    setIdx((pre) => pre + 1);
  } else if (sign === "-" && idx > minNumber) {
    setIdx((pre) => pre - 1);
  }
};
