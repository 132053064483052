import styled from "@emotion/styled";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DehazeIcon from "@mui/icons-material/Dehaze";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFileList from "../../../api/domains/file/queries/useFetchFileList";
import useFetchFolderAuthCheck from "../../../api/domains/folder/queries/useFetchFolderAuthCheck";
import { CustomConfirmAlert } from "../../../components/alert/CustomConfirmAlert";
import DotIcon from "../../../components/icons/DotIcon";
import { CustomText } from "../../../components/text/CustomText";
import { StoragePermission } from "../../../data/enums/PermissionType";
import { ViewSizeOption } from "../../../data/enums/ViewSizeOption";
import useFileHooks from "../../../hooks/domains/useFileHooks";
import useFolderHooks from "../../../hooks/domains/useFolderHooks";
import { useFileModalStore } from "../../../stores/file/useFileModalStore";
import { useFileSearchStore } from "../../../stores/file/useFileSearchStore";
import { useFileStore } from "../../../stores/file/useFileStore";
import { useFolderHistoryStore } from "../../../stores/folder/useFolderHistoryStore";
import { useFolderModalStore } from "../../../stores/folder/useFolderModalStore";
import { useSelectionManagerStore } from "../../../stores/folder/useSelectionManagerStore";
import { BorderGray } from "../../../styles/GlobalStyle";

export default function ArchiveToolMenu() {
  const { t } = useTranslation();
  const [viewMenuAnchor, setViewMenuAnchor] =
    useState<HTMLButtonElement | null>(null);

  const setFolderModalState = useFolderModalStore(
    (state) => state.setFolderModalState
  );
  const setFileModalState = useFileModalStore(
    (state) => state.setFileModalState
  );
  const currentFolderId = useFolderHistoryStore(
    (state) => state.currentFolderId
  );

  const setFileList = useFileStore((state) => state.setFileList);

  const {
    selectedFileIdSet,
    selectedFolderIdSet,
    viewSize,
    setViewSize,
    resetFileAndFolderSelect,
  } = useSelectionManagerStore((state) => ({
    selectedFileIdSet: state.selectedFileIdSet,
    selectedFolderIdSet: state.selectedFolderIdSet,
    viewSize: state.viewSize,
    setViewSize: state.setViewSize,
    resetFileAndFolderSelect: state.resetFileAndFolderSelect,
  }));

  const isSearchActive = useFileSearchStore((state) => state.isSearchActive);

  const { data: folderAuth } = useFetchFolderAuthCheck(currentFolderId);
  const { data: fileList } = useFetchFileList(currentFolderId);

  const { deleteFileRequest, downloadSelectedFileList } = useFileHooks();
  const { deleteFolderRequest } = useFolderHooks();

  return (
    <ToolsWrapper>
      <Button
        color="inherit"
        onClick={(e) => setViewMenuAnchor(e.currentTarget)}
        startIcon={<FormatListBulletedIcon />}
        endIcon={viewMenuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        <CustomText type="body" bold500>
          {t("보기")}
        </CustomText>
      </Button>
      <Button
        color="inherit"
        disabled={selectedFileIdSet.size === 0}
        onClick={() => {
          if (fileList) {
            downloadSelectedFileList(fileList, selectedFileIdSet);
          }
        }}
        startIcon={<DownloadIcon />}
      >
        <CustomText type="body" bold500>
          {t("파일 다운로드")}
        </CustomText>
      </Button>
      {!isSearchActive &&
        folderAuth &&
        (folderAuth === StoragePermission.UPLOADER ||
          folderAuth === StoragePermission.MANAGER) && (
          <>
            <Button
              color="inherit"
              onClick={() => setFileModalState(true, "create")}
              startIcon={<FileUploadIcon />}
            >
              <CustomText type="body" bold500>
                {t("파일 업로드")}
              </CustomText>
            </Button>
            <Button
              color="inherit"
              disabled={selectedFileIdSet.size === 0}
              onClick={() => {
                if (fileList) {
                  setFileList(
                    fileList
                      .filter((file) => selectedFileIdSet.has(file.fileId))
                      .map((file) => {
                        return {
                          ...file,
                          file: null,
                          uploadProgress: 100,
                          requestResult: "",
                          folderPath: "",
                        };
                      })
                  );
                }
                resetFileAndFolderSelect();
                setFileModalState(true, "update");
              }}
              startIcon={<EditIcon />}
            >
              <CustomText type="body" bold500>
                {t("파일 태그 수정")}
              </CustomText>
            </Button>
            <Button
              color="inherit"
              disabled={
                selectedFileIdSet.size === 0 && selectedFolderIdSet.size === 0
              }
              onClick={() => {
                if (
                  selectedFolderIdSet.size > 0 &&
                  folderAuth === StoragePermission.MANAGER
                ) {
                  CustomConfirmAlert(
                    t("폴더 삭제 주의 문구"),
                    "warning",
                    t("삭제"),
                    t("취소")
                  ).then((res) => {
                    if (res.isConfirmed) {
                      if (selectedFileIdSet.size > 0) {
                        deleteFileRequest(Array.from(selectedFileIdSet));
                      }
                      deleteFolderRequest(Array.from(selectedFolderIdSet));
                    }
                  });
                } else {
                  deleteFileRequest(Array.from(selectedFileIdSet));
                }
              }}
              startIcon={<DeleteIcon />}
            >
              <CustomText type="body" bold500>
                {folderAuth === StoragePermission.MANAGER
                  ? t("파일/폴더 삭제")
                  : t("파일 삭제")}
              </CustomText>
            </Button>
          </>
        )}
      {!isSearchActive &&
        folderAuth &&
        folderAuth === StoragePermission.MANAGER && (
          <>
            <Button
              color="inherit"
              onClick={() => setFolderModalState(true, "create")}
              startIcon={<CreateNewFolderIcon />}
            >
              <CustomText type="body" bold500>
                {t("새 폴더 만들기")}
              </CustomText>
            </Button>
            <Button
              disabled={
                currentFolderId === Number(process.env.REACT_APP_ROOT_FOLDER_ID)
              }
              color="inherit"
              onClick={() => setFolderModalState(true, "update")}
              startIcon={<SettingsIcon />}
            >
              <CustomText type="body" bold500>
                {t("현재 폴더 관리")}
              </CustomText>
            </Button>
            {/* <Button
              disabled={
                !folderAuth ||
                folderAuth !== PermissionType.MANAGER ||
                selectedFolderIdSet.size === 0
              }
              color="inherit"
              onClick={() =>
                deleteFolderRequest(Array.from(selectedFolderIdSet))
              }
              startIcon={<FolderOffIcon />}
            >
              <CustomText type="body" bold500>
                {t("현재 폴더 삭제")}
              </CustomText>
            </Button> */}
          </>
        )}
      <Menu
        id="view-menu"
        anchorEl={viewMenuAnchor}
        keepMounted
        open={Boolean(viewMenuAnchor)}
        onClose={() => setViewMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            setViewMenuAnchor(null);
            setViewSize(ViewSizeOption.LARGE);
          }}
        >
          <ListItemIcon
            sx={{
              visibility:
                viewSize === ViewSizeOption.LARGE ? "visible" : "hidden",
            }}
          >
            <DotIcon />
          </ListItemIcon>
          <ListItemIcon sx={{ mr: "3px" }}>
            <ViewSidebarIcon />
          </ListItemIcon>
          {t("큰 아이콘")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setViewMenuAnchor(null);
            setViewSize(ViewSizeOption.MEDIUM);
          }}
        >
          <ListItemIcon
            sx={{
              visibility:
                viewSize === ViewSizeOption.MEDIUM ? "visible" : "hidden",
            }}
          >
            <DotIcon />
          </ListItemIcon>
          <ListItemIcon sx={{ mr: "3px" }}>
            <ViewQuiltIcon />
          </ListItemIcon>
          {t("보통 아이콘")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setViewMenuAnchor(null);
            setViewSize(ViewSizeOption.SMALL);
          }}
        >
          <ListItemIcon
            sx={{
              visibility:
                viewSize === ViewSizeOption.SMALL ? "visible" : "hidden",
            }}
          >
            <DotIcon />
          </ListItemIcon>
          <ListItemIcon sx={{ mr: "3px" }}>
            <ViewModuleIcon />
          </ListItemIcon>
          {t("작은 아이콘")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setViewMenuAnchor(null);
            setViewSize(ViewSizeOption.DETAIL);
          }}
        >
          <ListItemIcon
            sx={{
              visibility:
                viewSize === ViewSizeOption.DETAIL ? "visible" : "hidden",
            }}
          >
            <DotIcon />
          </ListItemIcon>
          <ListItemIcon sx={{ mr: "3px" }}>
            <DehazeIcon />
          </ListItemIcon>
          {t("자세히")}
        </MenuItem>
      </Menu>
    </ToolsWrapper>
  );
}

const ToolsWrapper = styled.div`
  border-bottom: 1px solid ${BorderGray};
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
`;
