import { CourseType } from "../../data/enums/CourseType";
import Urls from "../../routes/Urls";

export const getCourseTypeByPath = (pathname: string) => {
  switch (pathname) {
    case Urls.freshmanCourseList:
      return CourseType.신규입사자교육;
    case Urls.dutyCourseList:
      return CourseType.법정의무교육;
    case Urls.jobCourseList:
      return CourseType.직무교육;
    case Urls.leadershipCourseList:
      return CourseType.리더십교육;
    case Urls.productCourseList:
      return CourseType.ProductTraining;
    case Urls.applicationCourseList:
      return CourseType.ApplicationTraining;
    case Urls.clinicalCourseList:
      return CourseType.ClinicalTraining;
    case Urls.otherCourseList:
      return CourseType.Others;
    default:
      return CourseType.신규입사자교육;
  }
};
