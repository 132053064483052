import { StoragePermissionType } from "../../../data/enums/PermissionType";
import useGetApiInstance from "../../config/BasicApi";
import {
  ChangeFolderLocationDto,
  CreateFolderDto,
  FolderDetailDto,
  FolderHistory,
  FolderListDto,
  FolderPathListDto,
  FolderTreeDto,
  UpdateFolderDto,
} from "./FolderType";

const useFolderAPI = () => {
  const api = useGetApiInstance("folder");

  const folderAuthCheckAPI = (
    folderId: number
  ): Promise<StoragePermissionType> =>
    api.get(`/auth-check/${folderId}`).then((res) => res.data.result);

  const getFolderDetailAPI = (folderId: number): Promise<FolderDetailDto> =>
    api.get(`/detail/${folderId}`).then((res) => res.data.result);

  const getFolderAuthTreeAPI = (): Promise<FolderTreeDto> =>
    api.get(`/tree`).then((res) => res.data.result);

  const getSubFolderListAPI = (folderId: number): Promise<FolderListDto[]> =>
    api.get(`/sub-folder-list/${folderId}`).then((res) => res.data.result);

  const getFolderPathListAPI = (
    folderId: number
  ): Promise<FolderPathListDto[]> =>
    api.get(`/path/${folderId}`).then((res) => res.data.result);

  const getAllFolderHistoryAPI = (): Promise<FolderHistory[]> =>
    api.get("folder-history").then((res) => res.data.result);

  const createFolderAPI = (request: CreateFolderDto) =>
    api.post(`/create`, request).then((res) => res.data.result);

  const updateFolderAPI = (request: UpdateFolderDto) =>
    api.put(`/update`, request).then((res) => res.data.result);

  const deleteFolderAPI = (folderId: number) =>
    api.delete(`/delete/${folderId}`).then((res) => res.data.result);

  const changeFolderParentAPI = (request: ChangeFolderLocationDto) =>
    api.put(`/change-folder-location`, request).then((res) => res.data.result);

  return {
    folderAuthCheckAPI,
    getFolderDetailAPI,
    getSubFolderListAPI,
    getFolderAuthTreeAPI,
    getFolderPathListAPI,
    getAllFolderHistoryAPI,

    createFolderAPI,
    updateFolderAPI,
    deleteFolderAPI,
    changeFolderParentAPI,
  };
};

export default useFolderAPI;
