import FindInPageIcon from "@mui/icons-material/FindInPage";
import FolderIcon from "@mui/icons-material/Folder";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { TabTitle } from "../admin-management-quiz/AdminQuizPage";
import AdminFileHistoryList from "./components/AdminFileHistoryList";
import AdminFolderHistoryList from "./components/AdminFolderHistoryList";

export default function AdminFilePage() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Grid container rowSpacing={1} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("자료관리")} />
      </Grid>
      <Grid item xs={12}>
        <TabTitle
          isActive={activeTab === 0}
          tabCount={2}
          onClick={() => setActiveTab(0)}
        >
          <FindInPageIcon sx={{ m: 1 }} />
          <CustomText type="subtitle2" bold500>
            {t("파일 이력 조회")}
          </CustomText>
        </TabTitle>
        <TabTitle
          isActive={activeTab === 1}
          tabCount={2}
          onClick={() => setActiveTab(1)}
        >
          <FolderIcon sx={{ m: 1 }} />
          <CustomText type="subtitle2" bold500>
            {t("폴더 이력 조회")}
          </CustomText>
        </TabTitle>
      </Grid>
      <Grid item xs={12} mt={3}>
        {activeTab === 0 && <AdminFileHistoryList />}
        {activeTab === 1 && <AdminFolderHistoryList />}
      </Grid>
    </Grid>
  );
}
