import { create } from "zustand";
import {
  DocumentDomain,
  FetchDocumentList,
} from "../../api/domains/document/DocumentType";
import { swapItem } from "./usePostCourseStore";

export type DocumentState = Pick<
  DocumentDomain,
  | "documentId"
  | "title"
  | "originFileName"
  | "uploadFileName"
  | "documentFile"
  | "progress"
> & {
  isNew: boolean;
};

type DocumentListState = {
  documentList: DocumentState[];
} & {
  isDirty: boolean;
  isSubmit: boolean;
  isUploading: boolean;
};

type DocumentListAction = {
  appendNewDocument: () => void;
  swapDocument: (index1: number, index2: number) => void;
  updateDocument: (
    documentId: number,
    newState: Partial<DocumentState>
  ) => void;
  deleteDocument: (documentId: number) => void;
  setIsSubmit: (isSubmit: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;

  createStore: (fetchedDocumentList: FetchDocumentList[]) => void;
  resetStore: () => void;
};

const baseDocument: DocumentState = {
  documentId: 0,
  title: "",
  originFileName: "",
  uploadFileName: null,
  documentFile: null,
  progress: 0,
  isNew: false,
};

const baseDocumentList: DocumentListState = {
  documentList: [],
  isDirty: false,
  isSubmit: false,
  isUploading: false,
};

const usePostDocumentStore = create<DocumentListState & DocumentListAction>(
  (set) => ({
    ...baseDocumentList,

    appendNewDocument: () =>
      set((state) => ({
        documentList: [
          ...state.documentList,
          { ...baseDocument, documentId: Date.now(), isNew: true },
        ],
        isDirty: true,
      })),

    swapDocument: (index1, index2) =>
      set((state) => ({
        documentList: swapItem(state.documentList, index1, index2),
        isDirty: true,
      })),

    updateDocument: (targetDocumentId, newState) =>
      set((state) => {
        const updatedDocumentList = state.documentList.map((document) => {
          if (document.documentId === targetDocumentId) {
            return { ...document, ...newState };
          }
          return document;
        });

        return { ...state, documentList: updatedDocumentList, isDirty: true };
      }),

    setIsSubmit: (isSubmit) =>
      set((state) => ({
        ...state,
        isSubmit: isSubmit,
      })),

    setIsUploading: (isUploading) =>
      set((state) => ({
        ...state,
        isUploading: isUploading,
      })),

    deleteDocument: (targetDocumentId) =>
      set((state) => ({
        documentList: state.documentList.filter(
          (document) => document.documentId !== targetDocumentId
        ),
        isDirty: true,
      })),

    createStore: (fetchedDocumentList) =>
      set({
        documentList: fetchedDocumentList.map((document) => ({
          ...baseDocument,
          ...document,
        })),
        isDirty: false,
      }),

    resetStore: () => set(baseDocumentList),
  })
);

export default usePostDocumentStore;
