import { TextType } from "../../components/text/CustomText";
import {
  ViewSizeOption,
  ViewSizeOptionType,
} from "../../data/enums/ViewSizeOption";

interface ViewSizeStyleInterface {
  margin: number;
  padding: number;
  width: number;
  minHeight: number;
  ImgWidth: number;
  ImgHeight: number;
}

const useViewSizeStyleHooks = () => {
  const getViewSizeStyle = (
    viewSize: ViewSizeOptionType
  ): ViewSizeStyleInterface & TextType => {
    switch (viewSize) {
      case ViewSizeOption.LARGE:
        return {
          type: "subtitle",
          margin: 20,
          padding: 20,
          width: 200,
          minHeight: 200,
          ImgWidth: 180,
          ImgHeight: 180,
        };
      case ViewSizeOption.MEDIUM:
        return {
          type: "body",
          margin: 10,
          padding: 15,
          width: 100,
          minHeight: 100,
          ImgWidth: 80,
          ImgHeight: 80,
        };
      case ViewSizeOption.SMALL:
        return {
          type: "small",
          margin: 5,
          padding: 10,
          width: 50,
          minHeight: 60,
          ImgWidth: 45,
          ImgHeight: 45,
        };
      case ViewSizeOption.DETAIL:
        return {
          type: "body",
          margin: 3,
          padding: 6,
          width: 20,
          minHeight: 20,
          ImgWidth: 20,
          ImgHeight: 20,
        };
      default:
        return {
          type: "subtitle",
          margin: 10,
          padding: 15,
          width: 100,
          minHeight: 100,
          ImgWidth: 80,
          ImgHeight: 80,
        };
    }
  };
  return {
    getViewSizeStyle,
  };
};

export default useViewSizeStyleHooks;
