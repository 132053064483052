export const CourseSearchCondition = {
    NAME: 'Name',
    PROFESSOR: 'Professor',
} as const;

export type CourseSearchConditionType = typeof CourseSearchCondition[keyof typeof CourseSearchCondition];


export const NoticeSearchCondition = {
    TITLE: 'Title',
} as const;

export type NoticeSearchConditionType = typeof NoticeSearchCondition[keyof typeof NoticeSearchCondition];


export const UserSearchCondition = {
    NAME: 'Name',
} as const;

export type UserSearchConditionType = typeof UserSearchCondition[keyof typeof UserSearchCondition];

export const QuizTargetSearchCondition = {
    입사일: "입사일",
    이름: "이름",
    부서: "부서",
    아이디: "아이디",
    이메일: "이메일"
}
export type QuizTargetSearchConditionType = typeof QuizTargetSearchCondition[keyof typeof QuizTargetSearchCondition];

export const StudyInfoSearchCondition = {
    과목명: "과목명",
    시험명: "시험명",
    입사일: "입사일",
    이름: "이름",
    부서: "부서"
}

export type StudyInfoSearchConditionType = typeof StudyInfoSearchCondition[keyof typeof StudyInfoSearchCondition];
