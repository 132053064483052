import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import axios from "axios";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  FetchDocumentList,
  PostDocumentList,
} from "../../api/domains/document/DocumentType";
import useDocumentAPIs from "../../api/domains/document/useDocumentAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import usePostDocumentStore from "../../stores/domains/usePostDocumentStore";
import { isDocumentValid } from "../validation/isDocumentValid";
import { useEffect } from "react";

export default function useDocumentHooks() {
  const { t } = useTranslation();

  /** react-query */
  const queryClient = useQueryClient();
  const {
    getDocumentSasTokenAPI,
    getDownloadDocumentSasTokenAPI,
    postDocumentAPI,
  } = useDocumentAPIs();

  const {
    documentList,
    setIsSubmit,
    setIsUploading,
    updateDocument,
    createStore,
    resetStore,
  } = usePostDocumentStore((state) => ({
    documentList: state.documentList,
    setIsSubmit: state.setIsSubmit,
    setIsUploading: state.setIsUploading,
    updateDocument: state.updateDocument,
    createStore: state.createStore,
    resetStore: state.resetStore,
  }));

  //#region 문서 다운로드
  const downloadDocument = async (
    documentId: number,
    originFileName: string
  ) => {
    const blobSasUri = await getDownloadDocumentSasTokenAPI(documentId);

    axios.get(blobSasUri, { responseType: "blob" }).then((res) => {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      saveAs(blob, originFileName);
    });
  };
  //#endregion

  //#region 파일 업로드
  const uploadDocumentFileToBlob = async (courseId: number) => {
    const res = await getDocumentSasTokenAPI(courseId);
    const blobService = new BlobServiceClient(res);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = documentList.map((document) => {
      if (document.documentFile === null || document.uploadFileName === null) {
        return Promise.resolve();
      }

      const blockBlobClient = containerClient.getBlockBlobClient(
        document.uploadFileName
      );

      var blobUploadOptions: BlockBlobParallelUploadOptions = {
        blockSize: 4 * 1024 * 1024, // 4Mb
        concurrency: 20,
        maxSingleShotSize: 268435456, //0.268GB
        onProgress: (process) => {
          const percentage = Math.round(
            (process.loadedBytes / document.documentFile!.size) * 100
          );
          updateDocument(document.documentId, { progress: percentage });
        },
      };
      return blockBlobClient.uploadData(
        document.documentFile!,
        blobUploadOptions
      );
    });
    return Promise.all(uploadPromises);
  };
  //#endregion

  //#region Document 생성 / 수정
  const postDocumentQuery = useMutation((request: PostDocumentList) =>
    postDocumentAPI(request)
  );

  const handleSubmitDocument = async (courseId: number) => {
    setIsSubmit(true);

    if (
      postDocumentQuery.isLoading ||
      courseId === 0 ||
      documentList.some((document) => !isDocumentValid(document))
    ) {
      return;
    }

    try {
      setIsUploading(true);
      await uploadDocumentFileToBlob(courseId);

      const request: PostDocumentList = {
        courseId: courseId,
        documentPostList: documentList.map((document, index) => {
          return {
            documentId: document.isNew ? 0 : document.documentId,
            documentOrder: index,
            title: document.title,
            originFileName: document.originFileName,
            uploadFileName: document.uploadFileName,
          };
        }),
      };

      postDocumentQuery.mutate(request, {
        onSuccess: () => {
          ToastAlert(
            t("문서 변경 내용이 정상적으로 저장되었습니다."),
            "success"
          );
          queryClient.invalidateQueries(QueryKeys.documentList(courseId));
        },
        onError: () => {
          ToastAlert(
            t("자료 업로드 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
            "error"
          );
          queryClient.invalidateQueries(QueryKeys.documentList(courseId));
        },
      });
    } catch (error) {
      ToastAlert(
        t("자료 업로드 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
        "error"
      );
      queryClient.invalidateQueries(QueryKeys.documentList(courseId));
    }
  };
  //#endregion

  //#region 데이터 패칭 & 초기값 세팅 & 초기화
  const useCreateDocumentStore = (documentList?: FetchDocumentList[]) => {
    useEffect(() => {
      if (documentList) {
        createStore(documentList);
      }
      return () => resetStore();
    }, [documentList]);
  };
  //#endregion

  return {
    handleSubmitDocument,
    downloadDocument,
    useCreateDocumentStore,
  };
}
