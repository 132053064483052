import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  CreateQuestionList,
  FetchQuestionWithAnswerList,
} from "../../api/domains/question/QuestionType";
import useQuestionAPIs from "../../api/domains/question/useQuestionAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import Urls from "../../routes/Urls";
import usePostQuestionStore, {
  PostChoiceState,
  PostQuestionState,
} from "../../stores/domains/usePostQuestionStore";
import { isQuestionValid } from "../validation/isQuestionValid";

function useQuestionHooks() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { createQuestionListAPI } = useQuestionAPIs();

  const { questionList, isDirty, createStore, resetStore } =
    usePostQuestionStore((state) => ({
      questionList: state.questionList,
      isDirty: state.isDirty,
      createStore: state.createStore,
      resetStore: state.resetStore,
    }));

  //#region 데이터 초기 패칭
  const useCreateQuestionStore = (
    questionList?: FetchQuestionWithAnswerList[]
  ) => {
    useEffect(() => {
      if (questionList && !isDirty) {
        const fetchQuestionList: PostQuestionState[] = questionList.map(
          (question) => {
            const choiceList: PostChoiceState[] | null =
              question.choiceList?.map((choice) => ({
                content: choice.content,
                choiceId: Date.now(),
              })) ?? null;

            return {
              ...question,
              choiceList: choiceList,
            };
          }
        );
        createStore(fetchQuestionList);
      }
      return () => resetStore();
    }, [questionList]);
  };
  //#endRegion

  //#region 시험 문제 등록
  const createQuizListQuery = useMutation((request: CreateQuestionList) =>
    createQuestionListAPI(request)
  );

  const createQuestionList = (quizVersionId: number) => {
    const createQuestionListDto: CreateQuestionList = {
      quizVersionId: quizVersionId,
      questionList: questionList.map((question, index) => {
        const choiceList = question.choiceList?.map((choice, index) => ({
          choiceOrder: index,
          content: choice.content,
        }));

        return {
          questionType: question.questionType,
          answer: question.answer,
          content: question.content,
          maxScore: question.maxScore,
          questionOrder: index,
          choiceList: choiceList ?? null,
        };
      }),
    };

    createQuizListQuery.mutate(createQuestionListDto, {
      onSuccess: () => {
        ToastAlert(t("시험 문제가 정상적으로 등록되었습니다."), "success");
        queryClient.invalidateQueries(
          QueryKeys.questionListAndAnswer(quizVersionId)
        );
        navigate(Urls.quizManagement);
      },
      onError: () => {
        ToastAlert(t("에러가 발생했습니다. 관리자에게 문의해주세요."), "error");
      },
    });
  };
  //#endRegion

  //#region 문제 List Valid
  const isQuestionListValid = () => {
    return questionList.every((question) => isQuestionValid(question));
  };
  //#endregion

  return {
    useCreateQuestionStore,
    createQuestionList,
    isQuestionListValid,
  };
}

export default useQuestionHooks;
