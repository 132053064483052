import Swal, { SweetAlertIcon } from 'sweetalert2'
import { ContentGray, InbodyRed } from '../../styles/GlobalStyle'

export const CustomConfirmAlert = (
    title: string,
    icon: SweetAlertIcon,
    confirmButtonText: string,
    cancelButtonText: string,
    text? :string,
) => {
    return Swal.fire({
        position: 'center',
        heightAuto: false,
        icon: icon,
        text: text,
        showCancelButton: true,
        confirmButtonColor: InbodyRed,
        cancelButtonColor: ContentGray,
        title: title,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
    })
}