import styled from "@emotion/styled";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchQuestionListAndAnswerList from "../../../api/domains/question/queries/useFetchQuestionListAndAnswerList";
import useFetchQuizVersionList from "../../../api/domains/quizVersion/queries/useFetchQuizVersionList";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import useQuestionHooks from "../../../hooks/domains/useQuestionHooks";
import useQuizVersionHooks from "../../../hooks/domains/useQuizVersionHooks";
import usePostQuizStore from "../../../stores/domains/usePostQuizStore";
import usePostQuizVersionStore from "../../../stores/domains/usePostQuizVersionStore";
import { FlexCenter } from "../../../styles/GlobalStyle";

interface PostQuizVersionProps {
  totalScore: number;
  isCreate?: boolean;
}

export default function PostQuizVersion({
  totalScore,
  isCreate,
}: PostQuizVersionProps) {
  const { t } = useTranslation();
  const quizId = Number(useParams().quizId);
  const [selectedQuizVersionId, setSelectedQuizVersionId] = useState<number>(0);

  const { data: quizVersionList } = useFetchQuizVersionList(quizId);
  const { data: questionListData } = useFetchQuestionListAndAnswerList(
    selectedQuizVersionId
  );

  const isSubmit = usePostQuizStore((state) => state.isSubmit);

  const {
    quizVersionName,
    quizPassScore,
    quizTimeMinute,
    updateQuizVersionValue: updateValue,
  } = usePostQuizVersionStore((state) => ({
    quizVersionName: state.quizVersionName,
    quizPassScore: state.quizPassScore,
    quizTimeMinute: state.quizTimeMinute,
    updateQuizVersionValue: state.updateValue,
  }));

  const { useCreateQuizVersionStore } = useQuizVersionHooks();
  const targetQuizVersion = quizVersionList?.find(
    (q) => q.quizVersionId === selectedQuizVersionId
  );
  useCreateQuizVersionStore(targetQuizVersion);

  const { useCreateQuestionStore } = useQuestionHooks();
  useCreateQuestionStore(questionListData);

  return (
    <CustomTableWrapper>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableBody>
          <TableRow>
            <TableCell width="15%" component="th" align="center">
              <CustomText type="subtitle">{t("기존 문제 불러오기")}</CustomText>
            </TableCell>
            <TableCell width="35%" align="center">
              {quizVersionList && (
                <FormControl fullWidth>
                  <Select
                    disabled={isCreate}
                    id="quiz-version-select"
                    size="small"
                    value={selectedQuizVersionId}
                    onChange={(e) => {
                      setSelectedQuizVersionId(Number(e.target.value));
                    }}
                  >
                    <MenuItem value={0}>
                      <CustomText
                        fullWidth
                        type="subtitle"
                        align="center"
                        bold400
                      >
                        {t("선택 안함")}
                      </CustomText>
                    </MenuItem>
                    {quizVersionList.map((quizVersion) => (
                      <MenuItem
                        key={quizVersion.quizVersionId}
                        value={quizVersion.quizVersionId}
                      >
                        <CustomText
                          fullWidth
                          type="subtitle"
                          align="center"
                          bold400
                        >
                          {quizVersion.quizVersionName}
                        </CustomText>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </TableCell>
            <TableCell width="15%" component="th" align="center">
              <CustomText type="subtitle">{t("문제 버전 이름")}</CustomText>
            </TableCell>
            <TableCell width="35%">
              <TextField
                id="quiz-version-name"
                size="small"
                fullWidth
                value={quizVersionName}
                error={isSubmit && !quizVersionName.trim()}
                helperText={
                  isSubmit && !quizVersionName.trim() && t("필수 입력값입니다.")
                }
                onChange={(e) =>
                  updateValue({ quizVersionName: e.target.value })
                }
                label={t("문제 버전 이름을 입력해주세요.")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" align="center">
              <CustomText type="subtitle">{t("시험시간")}</CustomText>
            </TableCell>
            <TableCell align="center">
              <NumberInputWrapper>
                <TextField
                  id="quiz-time-minute"
                  value={quizTimeMinute}
                  onChange={(e) =>
                    updateValue({ quizTimeMinute: Number(e.target.value) })
                  }
                  type="number"
                  size="small"
                  variant="standard"
                  inputProps={{ min: 0 }}
                  error={isSubmit && quizTimeMinute <= 0}
                  helperText={
                    isSubmit && quizTimeMinute <= 0 && t("필수 입력값입니다.")
                  }
                />
                <CustomText type="subtitle" sx={{ ml: 1 }}>
                  {t("분")}
                </CustomText>
              </NumberInputWrapper>
            </TableCell>
            <TableCell component="th">
              <CustomText type="subtitle" align="center">
                {t("합격점수")}
              </CustomText>
            </TableCell>
            <TableCell>
              <NumberInputWrapper>
                <TextField
                  id="quiz-pass-score"
                  value={quizPassScore}
                  onChange={(e) =>
                    updateValue({ quizPassScore: Number(e.target.value) })
                  }
                  type="number"
                  size="small"
                  variant="standard"
                  inputProps={{ min: 0, max: totalScore }}
                  error={isSubmit && quizPassScore <= 0}
                  helperText={
                    isSubmit && quizPassScore <= 0 && t("필수 입력값입니다.")
                  }
                />
                <CustomText type="subtitle" sx={{ ml: 1 }}>
                  {`${t("점")} / ${totalScore} ${t("점")}`}
                </CustomText>
              </NumberInputWrapper>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CustomTableWrapper>
  );
}

const NumberInputWrapper = styled.div`
  & div.MuiFormControl-root.MuiTextField-root {
    width: 20%;
  }
  ${FlexCenter}
`;
