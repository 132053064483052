import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { DataManager } from "@syncfusion/ej2-data";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { QueryKeys } from "../../api/config/QueryKeys";
import useFetchCourseNameList from "../../api/domains/course/queries/useFetchCourseNameList";
import {} from "../../api/domains/course/useCourseAPIs";
import { CreateQna } from "../../api/domains/qna/QnaType";
import useFetchQnaTypeList from "../../api/domains/qna/queries/useFetchQnaTypeList";
import useQnaAPIs from "../../api/domains/qna/useQnaAPIs";
import ErrorText from "../../components/react-hook-form/ErrorText";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import RHFTextEditor from "../../components/textEditor/RHFTextEditor";
import useHandlePostRequest from "../../hooks/common-form-hooks/useHandlePostRequest";
import { notEmptyHtmlContent } from "../../hooks/common-form-hooks/validate";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";

export default function QnaCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userName } = useUserStore();

  /** react-query */
  const { data: courseNameList } = useFetchCourseNameList();
  const { data: qnaTypeList } = useFetchQnaTypeList();

  const { createQnaAPI } = useQnaAPIs();
  const { handleSuccess, handleError } = useHandlePostRequest();

  const qnaCreateQuery = useMutation((request: CreateQna) =>
    createQnaAPI(request)
  );

  /* react-hook-form */
  const schema = yup
    .object({
      title: yup.string().trim().required(t("제목이 비어있습니다.")),
      content: notEmptyHtmlContent(t("내용이 비어있습니다.")).required(
        t("내용이 비어있습니다.")
      ),
      type: yup.string().trim().required(t("문의 분류가 비어있습니다.")),
      courseId: yup
        .number()
        .positive()
        .integer()
        .nullable()
        .when("type", ([type], schema) => {
          return type === "강의"
            ? schema.required(t("강의가 비어있습니다."))
            : schema;
        }),
    })
    .required();

  const {
    register,
    watch,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateQna>({
    defaultValues: {
      content: "",
      title: "",
      type: "",
      courseId: null,
    },
    resolver: yupResolver(schema) as any,
  });

  /** submit */
  const createQnaHandle = (data: CreateQna) => {
    qnaCreateQuery.mutate(data, {
      onSuccess: () => {
        handleSuccess(
          t("문의가 등록되었습니다."),
          QueryKeys.qnaList(),
          Urls.qna
        );
      },
      onError: () =>
        handleError(
          t(
            "문의사항 등록 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."
          )
        ),
    });
  };

  return (
    <form onSubmit={handleSubmit((data) => createQnaHandle(data))}>
      <Grid container rowSpacing={2} pt={0}>
        <Grid item xs={12}>
          <PageHeader title="문의사항" />
        </Grid>
        <Grid item xs={12}>
          <CustomTableWrapper tdPadding="8px 16px">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component={"th"} align="center" width="15%">
                    <CustomText type="subtitle">{t("분류")}</CustomText>
                  </TableCell>
                  <TableCell width="35%">
                    {qnaTypeList && (
                      <DropDownListComponent
                        cssClass="e-outline"
                        {...register("type")}
                        change={(e) => {
                          if (e.value !== "강의") {
                            reset({ courseId: null });
                          }
                        }}
                        dataSource={qnaTypeList}
                        placeholder={t("문의사항 분류를 선택해주세요.")}
                      />
                    )}
                    <ErrorText message={errors.type?.message} />
                  </TableCell>
                  <TableCell component={"th"} align="center" width="15%">
                    <CustomText type="subtitle">{t("강의명")}</CustomText>
                  </TableCell>
                  <TableCell width="35%">
                    {courseNameList && (
                      <DropDownListComponent
                        cssClass="e-outline"
                        {...register("courseId")}
                        fields={{
                          text: "courseName",
                          value: "courseId",
                        }}
                        enabled={watch("type") === "강의"}
                        dataSource={new DataManager(courseNameList)}
                        placeholder={t("문의할 과목을 선택해주세요.")}
                      />
                    )}
                    <ErrorText message={errors.courseId?.message} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={"th"} align="center">
                    <CustomText type="subtitle">{t("작성자")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <CustomText pl={2} type="subtitle">
                      {userName}
                    </CustomText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={"th"} align="center">
                    <CustomText type="subtitle">{t("제목")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <TextBoxComponent
                      cssClass="e-outline"
                      {...register("title")}
                      placeholder={t("제목을 입력하세요.")}
                    />
                    <ErrorText message={errors.title?.message} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component={"th"} align="center">
                    <CustomText type="subtitle">{t("내용")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <RHFTextEditor name="content" control={control} />
                    <ErrorText message={errors.content?.message} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CustomTableWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <SubmitButton type="submit">{t("작성")}</SubmitButton>
            <ListButton onClick={() => navigate(Urls.qna)}>
              {t("목록")}
            </ListButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const SubmitButton = styled.button`
  min-width: 100px;
  height: 40px;
  padding: 7px 20px;
  border: 0;
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  background: #193b6a;
  font-weight: 500;
  cursor: pointer;
`;
const ListButton = styled(SubmitButton)`
  background: #ffffff;
  border: 1px solid black;
  color: black;
`;
