import styled from "@emotion/styled";
import { ReactNode, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { MenuMaxWidth, MenuMinWidth } from "../data/common/MenuSize";
import Urls from "../routes/Urls";
import { DividerGray } from "../styles/GlobalStyle";
import Footer from "./Footer";
import Header from "./header/Header";
import { useLayoutStore } from "../stores/layout/useLayoutStore";
import { useUserStore } from "../stores/user/useUserStore";

interface AuthPageWrapperProps {
  children: ReactNode;
}

export default function AuthPageWrapper({ children }: AuthPageWrapperProps) {
  const { isMenuOpen } = useLayoutStore();

  const palyLectureUrl = Urls.playLecture.replace(":id", "");
  const lecturePlay = useLocation().pathname.includes(palyLectureUrl);

  const examPageUrl = Urls.exam.replace(":id", "");
  const examPage = useLocation().pathname.includes(examPageUrl);

  const isLogin = useUserStore((state) => state.isLogin);

  if (!isLogin) {
    return <Navigate to={Urls.login} replace />;
  }

  return (
    <>
      {!lecturePlay && (
        <>
          {!examPage && (
            <HeaderWrapper id="header">
              <Header />
            </HeaderWrapper>
          )}
          <SectionWrapper id="section" isOpen={!examPage && isMenuOpen}>
            {children}
          </SectionWrapper>
          <Footer />
        </>
      )}
      {lecturePlay && children}
    </>
  );
}
const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${DividerGray};
`;
const SectionWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  padding-top: 64px;
  padding-left: ${(props) =>
    props.isOpen ? `${MenuMaxWidth}px` : `${MenuMinWidth}px`};
  transition: padding 0.5s;
  width: 1280px;
  margin: auto;
  min-height: 700px;
`;
