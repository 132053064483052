import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuestionList } from '../QuestionType';
import useQuestionAPIs from '../useQuestionAPIs';

const useFetchExamQuestionList = <T = FetchQuestionList[]>(
    quizVersionId: number,
    options?: UseQueryOptions<FetchQuestionList[], AxiosError, T>
) => {
    const { getQuestionListForExamAPI } = useQuestionAPIs();

    return useQuery({
        queryKey: QueryKeys.questionList(quizVersionId),
        queryFn: () => getQuestionListForExamAPI(quizVersionId),
        enabled: !isNaN(quizVersionId) && quizVersionId > 0,
        ...options
    });
}
export default useFetchExamQuestionList;