import styled from '@emotion/styled';
import { getColorByCourseType } from '../../hooks/course/getColorByCourseType';

const CustomCourseTag = styled.span<{ courseType: string }>`
    padding: 5px 10px;
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    background-color: ${props => getColorByCourseType(props.courseType)};
`

export default CustomCourseTag;