import { Box } from "@mui/material";
import React from "react";
import { CustomText } from "../../components/text/CustomText";

export default function PrivacyAgreement() {
  return (
    <Box display={"flex"} justifyContent={"center"} mt={10}>
      <CustomText type="title">개인정보 수집 및 이용동의(준비중)</CustomText>
    </Box>
  );
}
