import { Button } from '@mui/material';
import { ContentOrange, ContentPale, InbodyRed, PRIMARY, TitleRed } from '../../styles/GlobalStyle';
import { ButtonProps } from '@mui/material';

export interface ButtonCustomProps {
    rectangle?: boolean;
    margin?: string;
    width?: string;
    border?: string;
    padding?: string;
    colorType: 'black' | 'gray' | 'inbodyRed' | 'red' | 'white' | 'orange' | 'primary'
}

export type ButtonCommonProps = ButtonCustomProps & ButtonProps;

export default function CustomButton({ colorType, rectangle, border, padding, width, margin, ...props }: ButtonCommonProps) {

    const buttonColorMap = {
        black: 'black',
        gray: ContentPale,
        inbodyRed: InbodyRed,
        white: '#fff',
        red: TitleRed,
        orange: ContentOrange,
        primary: PRIMARY,
    };

    const textColorMap = {
        black: '#fff',
        inbodyRed: '#fff',
        gray: '#fff',
        white: 'black',
        red: '#fff',
        orange: '#fff',
        primary: '#fff',
    };

    const backgroundColor = buttonColorMap[colorType];
    const color = textColorMap[colorType];

    return (
        <Button
            sx={{
                backgroundColor: backgroundColor,
                color: color,
                ...(margin ? { margin: margin } : { margin: '0px 5px' }),
                ...(width ? { width: width } : {}),
                ...(border ? { border: border } : {}),
                ...(padding ? { padding: padding } : {}),
                ...(rectangle ? { borderRadius: 0 } : {}),
                ":disabled": {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                    color: 'rgba(0, 0, 0, 0.26)',
                },
                ":hover": {
                    backgroundColor: backgroundColor
                }
            }}
            variant='contained'
            {...props}>
            {props.children}
        </Button>
    );
}
