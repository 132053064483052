import styled from '@emotion/styled'
import { CustomText } from '../text/CustomText'

interface PageHeaderProps {
  title: string
}

export default function PageHeader({ title }: PageHeaderProps) {
  return (
    <Wrapper>
      <CustomText type='superTitle' bold400>
        {title}
      </CustomText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 40px auto;
  text-align: center;
  border-bottom: 2px solid black;
  padding-bottom: 20px;
`