import { QuestionTypeValue } from "../../data/enums/QuestionType";
import { PostQuestionState } from "../../stores/domains/usePostQuestionStore";

export const isQuestionValid = (question: PostQuestionState) => {
  if (!question.content.trim()) {
    return false;
  }
  if (question.questionType === QuestionTypeValue.객관식) {
    if (!question.choiceList) {
      return false;
    }
    if (question.choiceList.some((choice) => !choice.content.trim())) {
      return false;
    }
    if (!question.answer.trim()) {
      return false;
    }
  }
  if (question.maxScore === 0) {
    return false;
  }
  return true;
};
