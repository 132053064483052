import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchCompleteCourseList } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';
import { useUserStore } from '../../../../stores/user/useUserStore';

const useFetchCompleteCourseList = <T = FetchCompleteCourseList[]>(
    options?: UseQueryOptions<FetchCompleteCourseList[], unknown, T>
) => {
    const { accountId } = useUserStore();
    const { getCompleteCourseListAPI} = useCourseAPIs();

    return useQuery({
        queryKey: QueryKeys.completeCourseList(accountId),
        queryFn: () => getCompleteCourseListAPI(),
        ...options
    });
}

export default useFetchCompleteCourseList;