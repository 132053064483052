import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import { UpdateAnswerScore } from "../../api/domains/answer/UserAnswerType";
import useUserAnswerAPIs from "../../api/domains/answer/useUserAnswerAPIs";
import { ToggleQuizTarget } from "../../api/domains/quiz/QuizType";
import useQuizAPIs from "../../api/domains/quiz/useQuizAPIs";
import ToastAlert from "../../components/alert/ToastAlert";

export default function useAdminQuizHooks() {
  const { t } = useTranslation();
  const { toggleQuizTargetAPI } = useQuizAPIs();
  const { scoredAnswerAPI } = useUserAnswerAPIs();
  const queryClient = useQueryClient();

  /**
   * 시험 대상 지정/제외
   */
  const ChangeQuizTargetQuery = useMutation((request: ToggleQuizTarget) =>
    toggleQuizTargetAPI(request)
  );
  const changeQuizTarget = (userId: number, quizId: number) => {
    const request: ToggleQuizTarget = {
      userId: userId,
      quizId: quizId,
    };
    ChangeQuizTargetQuery.mutate(request, {
      onSuccess: (isQuizTarget) => {
        queryClient.invalidateQueries(QueryKeys.quizTargetList(request.quizId));
        isQuizTarget
          ? ToastAlert(t("시험 대상으로 지정되었습니다."), "info")
          : ToastAlert(t("시험 대상에서 제외되었습니다."), "info");
      },
    });
  };

  const changeScoreQuery = useMutation((request: UpdateAnswerScore) =>
    scoredAnswerAPI(request)
  );
  /**
   * 유저 점수 변경 함수
   * @param attemptId
   * @param score
   * @param answerId
   */
  const changeUserScore = (
    attemptId: number,
    score: number,
    answerId: number,
    quizId: number
  ) => {
    const request: UpdateAnswerScore = {
      attemptId: attemptId,
      answerId: score,
      score: answerId,
    };
    changeScoreQuery.mutate(request, {
      onSuccess: () => {
        ToastAlert(t("점수가 정상적으로 입력되었습니다."), "success");
      },
    });
  };

  return {
    changeQuizTarget,
    changeUserScore,
  };
}
