import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Dispatch, SetStateAction, memo } from "react";
import { useTranslation } from "react-i18next";
import ToastAlert from "../../../components/alert/ToastAlert";
import { CustomText } from "../../../components/text/CustomText";
import RichTextEditor from "../../../components/textEditor/RichTextEditor";
import {
  QuestionType,
  QuestionTypeValue,
} from "../../../data/enums/QuestionType";
import usePostQuestionStore, {
  PostQuestionState,
} from "../../../stores/domains/usePostQuestionStore";
import usePostQuizStore from "../../../stores/domains/usePostQuizStore";
import { BorderGray, ContentBlue } from "../../../styles/GlobalStyle";
import PostChoiceItem from "./PostChoiceItem";

interface PostQuestionItemProps {
  question: PostQuestionState;
  totalScore: number;
  questionIndex: number;
  setIsDragEnabled: Dispatch<SetStateAction<boolean>>;
  useEditorQuestionIndex: number;
  setUseEditorQuestionIndex: Dispatch<SetStateAction<number>>;
}

function PostQuestionItem({
  question,
  totalScore,
  questionIndex,
  setIsDragEnabled,
  useEditorQuestionIndex,
  setUseEditorQuestionIndex,
}: PostQuestionItemProps) {
  const { t } = useTranslation();

  const { isSubmit } = usePostQuizStore();

  const {
    questionList,
    swapQuestion,
    appendNewQuestion,
    copyQuestion,
    deleteQuestion,
    updateQuestion,
    appendNewChoice,
  } = usePostQuestionStore((state) => ({
    questionList: state.questionList,
    swapQuestion: state.swapQuestion,
    appendNewQuestion: state.appendNewQuestion,
    copyQuestion: state.copyQuestion,
    deleteQuestion: state.deleteQuestion,
    updateQuestion: state.updateQuestion,
    appendNewChoice: state.appendNewChoice,
  }));

  const handleQuestionTypeChange = (e: SelectChangeEvent<string>) => {
    const selectedQuestionType = e.target.value as QuestionType;
    updateQuestion(question.questionId, { questionType: selectedQuestionType });
    updateQuestion(question.questionId, { answer: "" });

    if (selectedQuestionType === QuestionTypeValue.주관식) {
      updateQuestion(question.questionId, { choiceList: null });
    } else {
      updateQuestion(question.questionId, {
        choiceList: [
          { content: "", choiceId: Date.now() + 1 },
          { content: "", choiceId: Date.now() + 2 },
          { content: "", choiceId: Date.now() + 3 },
        ],
      });
    }
  };

  return (
    <Grid xs={12} item>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          // bgcolor: snapshot.isDragging ? BoxGreen : null
        }}
      >
        <Grid xs={12} m={1} item container alignItems={"center"}>
          <Grid item xs={1} container direction={"row"} alignItems={"center"}>
            <ArrowButtonWrapper>
              <IconButton
                onClick={() => swapQuestion(questionIndex, questionIndex - 1)}
                disabled={questionIndex === 0}
                size="small"
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                onClick={() => swapQuestion(questionIndex, questionIndex + 1)}
                disabled={questionIndex === questionList.length - 1}
                size="small"
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </ArrowButtonWrapper>
            <Avatar
              sx={{
                bgcolor: ContentBlue,
                width: 30,
                height: 30,
                justifyItems: "center",
              }}
            >
              {questionIndex + 1}
            </Avatar>
          </Grid>
          <Grid item xs={3} container alignItems={"center"}>
            <Grid container ml={4}>
              <Select
                size="small"
                fullWidth
                id={`question-type-select-${question.questionId}`}
                value={question.questionType}
                onChange={handleQuestionTypeChange}
              >
                <MenuItem value={QuestionTypeValue.주관식}>
                  <Grid container>
                    <NotesOutlinedIcon />
                    <CustomText type="subtitle" ml={1}>
                      {QuestionTypeValue.주관식}
                    </CustomText>
                  </Grid>
                </MenuItem>
                <MenuItem value={QuestionTypeValue.객관식}>
                  <Grid container>
                    <RadioButtonCheckedOutlinedIcon />
                    <CustomText type="subtitle" ml={1}>
                      {QuestionTypeValue.객관식}
                    </CustomText>
                  </Grid>
                </MenuItem>
                <MenuItem value={QuestionTypeValue.체크박스}>
                  <Grid container>
                    <CheckBoxOutlinedIcon />
                    <CustomText type="subtitle" ml={1}>
                      {QuestionTypeValue.체크박스}
                    </CustomText>
                  </Grid>
                </MenuItem>
                {/* <MenuItem value={QuestionType.실기형}>
                                  <Grid container>
                                      <BoyIcon />
                                      <CustomText type='subtitle' ml={1}>
                                          {QuestionType.실기형}
                                      </CustomText>
                                  </Grid>
                              </MenuItem> */}
              </Select>
            </Grid>
          </Grid>
          <Grid
            xs={3}
            item
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            p={"0 10px"}
          >
            <CustomText type="subtitle" m={1} align="center">
              {t("문제 점수")} :
            </CustomText>
            <Grid item xs={2}>
              <TextField
                id={`question-maxScore-${question.questionId}`}
                value={question.maxScore}
                onChange={(e) =>
                  updateQuestion(question.questionId, {
                    maxScore: Number(e.target.value),
                  })
                }
                type="number"
                size="small"
                variant="standard"
                inputProps={{ min: 0 }}
                error={isSubmit && question.maxScore <= 0}
                helperText={
                  isSubmit && question.maxScore <= 0 && t("필수 입력값입니다.")
                }
              />
            </Grid>
            <CustomText type="subtitle">
              {t("점")} / {`${t("총점")} : ${totalScore} ${t("점")}`}
            </CustomText>
          </Grid>
          <Grid
            xs={5}
            item
            container
            wrap="nowrap"
            justifyContent={"flex-end"}
            spacing={1}
          >
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                color="warning"
                onClick={() => {
                  setIsDragEnabled(true);
                  ToastAlert(t("문제를 드래그하여 이동가능합니다."), "info");
                }}
                startIcon={<OpenWithIcon />}
              >
                <CustomText type="button">{t("문제이동")}</CustomText>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                color="success"
                onClick={appendNewQuestion}
                startIcon={<AddCircleOutlineIcon />}
              >
                <CustomText type="button">{t("문제추가")}</CustomText>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                onClick={() => copyQuestion(question.questionId)}
                startIcon={<CopyrightIcon />}
              >
                <CustomText type="button">{t("문제복사")}</CustomText>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                type="button"
                color="error"
                onClick={() => deleteQuestion(question.questionId)}
                startIcon={<DeleteIcon />}
              >
                <CustomText type="button">{t("문제삭제")}</CustomText>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 3, mb: 3 }} />
        </Grid>
        <Grid item xs={12}>
          {useEditorQuestionIndex === questionIndex ? (
            <RichTextEditor
              placeholder={t("문제를 입력해주세요.")}
              value={question.content}
              change={(e) =>
                updateQuestion(question.questionId, { content: e.value })
              }
            />
          ) : (
            <QuestionContentDiv
              dangerouslySetInnerHTML={{ __html: question.content }}
              onClick={() => setUseEditorQuestionIndex(questionIndex)}
            />
          )}
          {isSubmit && question.content.trim() === "" && (
            <CustomText type="small" color={"error"}>
              {t("필수 입력값입니다.")}
            </CustomText>
          )}
        </Grid>
        {/* {
                  watch(`questionList.${questionIndex}.questionType`) === QuestionType.주관식
                  &&
                  <>
                      <Grid item xs={12}>
                          <Divider sx={{ mt: 3, mb: 3 }} />
                      </Grid>
                      <Grid item xs={12}>
                          <TextBoxComponent
                              cssClass='e-outline'
                              {...register(`questionList.${questionIndex}.answer`)}
                              placeholder={t('주관식 정답을 입력해주세요.')} />
                          <ErrorText message={errors.questionList?.[questionIndex]?.answer?.message} />
                      </Grid>
                  </>
              } */}
        {question.questionType !== QuestionTypeValue.주관식 && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ mt: 5, mb: 5 }} />
            </Grid>
            <Grid item xs={12}>
              {question.choiceList?.map((choice, choiceIndex, arr) => (
                <PostChoiceItem
                  key={choice.choiceId}
                  question={question}
                  choice={choice}
                  choiceIndex={choiceIndex}
                  indexSize={arr.length}
                  isCheckBox={
                    question.questionType === QuestionTypeValue.체크박스
                  }
                />
              ))}
              <Button
                fullWidth
                variant="outlined"
                type="button"
                color="success"
                onClick={() => appendNewChoice(question.questionId)}
                startIcon={<AddCircleOutlineIcon />}
              >
                <CustomText type="button">{t("보기 추가")}</CustomText>
              </Button>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
}

export default memo(PostQuestionItem);

const ArrowButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const QuestionContentDiv = styled.div`
  border: 1px solid ${BorderGray};
  padding: 10px 12px 9px;
  border-radius: 3px;
  min-height: 24px;
  cursor: text;
  &:hover {
    border: 1px solid black;
  }
`;
