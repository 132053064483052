import styled from "@emotion/styled";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import CopyrightIcon from "@mui/icons-material/Copyright";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Radio,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import usePostQuestionStore, {
  PostChoiceState,
  PostQuestionState,
} from "../../../stores/domains/usePostQuestionStore";
import usePostQuizStore from "../../../stores/domains/usePostQuizStore";

interface ChoiceProps {
  question: PostQuestionState;
  choice: PostChoiceState;
  indexSize: number;
  choiceIndex: number;
  isCheckBox: boolean;
}

export default function PostChoiceItem({
  question,
  choice,
  indexSize,
  choiceIndex,
  isCheckBox,
}: ChoiceProps) {
  const { t } = useTranslation();

  const isSubmit = usePostQuizStore((state) => state.isSubmit);
  const { copyChoice, swapChoice, updateChoice, deleteChoice, updateQuestion } =
    usePostQuestionStore((state) => ({
      copyChoice: state.copyChoice,
      swapChoice: state.swapChoice,
      updateChoice: state.updateChoice,
      deleteChoice: state.deleteChoice,
      updateQuestion: state.updateQuestion,
    }));

  return (
    <Grid
      container
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <ChoiceSwapButtonWrapper>
        <IconButton
          onClick={() =>
            swapChoice(question.questionId, choiceIndex, choiceIndex - 1)
          }
          disabled={choiceIndex === 0}
          size="small"
        >
          <KeyboardArrowUpIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            swapChoice(question.questionId, choiceIndex, choiceIndex + 1)
          }
          disabled={choiceIndex === indexSize - 1}
          size="small"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </ChoiceSwapButtonWrapper>
      {isCheckBox ? (
        <Checkbox
          id={`is-multiple-choice-${choice.choiceId}`}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          checked={question.answer.includes(String(choiceIndex))}
          value={choiceIndex}
          onChange={(e, checked) => {
            let answer = question.answer;

            let newAnswerArr = answer.split(",").filter(Boolean);
            if (checked) {
              newAnswerArr.push(e.target.value);
              newAnswerArr.sort();
              updateQuestion(question.questionId, {
                answer: newAnswerArr.join(","),
              });
            } else {
              newAnswerArr = newAnswerArr.filter(
                (answer) => answer !== e.target.value
              );
              updateQuestion(question.questionId, {
                answer: newAnswerArr.join(","),
              });
            }
          }}
        />
      ) : (
        <Radio
          id={`is-radio-choice-${choice.choiceId}`}
          sx={{ p: 0 }}
          checked={question.answer === String(choiceIndex)}
          onChange={() =>
            updateQuestion(question.questionId, { answer: String(choiceIndex) })
          }
        />
      )}
      <Grid item xs={10}>
        <TextField
          fullWidth
          value={choice.content}
          onChange={(e) =>
            updateChoice(question.questionId, choice.choiceId, {
              content: e.target.value,
            })
          }
          label={t("객관식 보기를 입력해주세요.")}
          error={isSubmit && !choice.content.trim()}
          helperText={
            isSubmit && !choice.content.trim() && t("필수 입력값입니다.")
          }
        />
      </Grid>
      <Box>
        <IconButton
          type="button"
          onClick={() => copyChoice(question.questionId, choice.choiceId)}
        >
          <CopyrightIcon />
        </IconButton>
        <IconButton
          type="button"
          onClick={() => deleteChoice(question.questionId, choice.choiceId)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Grid>
  );
}

const ChoiceSwapButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
