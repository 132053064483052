import { Grid, Pagination } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchQnaList from "../../api/domains/qna/queries/useFetchQnaList";
import CustomButton from "../../components/button/CustomButton";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { PageSize } from "../../data/enums/PageSize";
import Urls from "../../routes/Urls";
import QnaList from "./components/QnaList";

export default function QnaListPage() {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const qnaListQuery = useFetchQnaList();

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("문의사항")} />
      </Grid>
      <Grid item xs={12}>
        <QnaList />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"flex-end"}>
          <CustomButton
            colorType="inbodyRed"
            onClick={() => navigate(Urls.qnaCreate)}
          >
            <CustomText type="button">{t("글작성")}</CustomText>
          </CustomButton>
        </Grid>
        <Grid container justifyContent={"center"}>
          {qnaListQuery.data && (
            <Pagination
              color="standard"
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(qnaListQuery.data.length / PageSize.BASIC)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
