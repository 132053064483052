export const isQuizVersionValid = (
  quizVersionName: string,
  quizPassScore: number,
  quizTimeMinute: number
) => {
  if (!quizVersionName.trim()) {
    return false;
  }
  if (quizPassScore === 0) {
    return false;
  }
  if (quizTimeMinute === 0) {
    return false;
  }
  return true;
};
