import styled from "@emotion/styled";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InbodyLogo from "../../assets/images/logo.png";
import { CustomText } from "../../components/text/CustomText";
import { InbodyRed } from "../../styles/GlobalStyle";
import Menu from "../Menu";
import HeaderIconList from "./HeaderIconList";
import { useLayoutStore } from "../../stores/layout/useLayoutStore";

export default function Header() {
  const { t } = useTranslation();
  const toggleMenuOpen = useLayoutStore((state) => state.toggleMenuOpen);
  const navigate = useNavigate();

  return (
    <Box flexGrow={1}>
      <AppBar color="inherit" position="fixed">
        <HeaderContentWrapper>
          {/* Hamburger */}
          <SideMenu>
            <IconButton
              onClick={toggleMenuOpen}
              color="inherit"
              aria-label="menu"
              size="large"
              sx={{
                "& svg": {
                  fontSize: "30px",
                },
                verticalAlign: "baseline",
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* logo */}
            <HeaderLogo onClick={() => navigate("/")}>
              <LogoImage src={InbodyLogo} alt="logo" />
            </HeaderLogo>
            <CustomText
              onClick={() => navigate("/")}
              type="subtitle"
              ml={"10px"}
              color={InbodyRed}
              bold600
              sx={{ letterSpacing: -0.5 }}
            >
              {t("Knowledge Warehouse")}
            </CustomText>
          </SideMenu>
          {/* icon List */}
          <HeaderIconList />
        </HeaderContentWrapper>
      </AppBar>
      {/* left side menu */}
      <Menu></Menu>
    </Box>
  );
}
/** Desktop */
const HeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  height: 64px;
  position: relative;
`;
const SideMenu = styled.div`
  padding-left: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const HeaderLogo = styled.div`
  flex-grow: 1;
  margin-left: 12px;
  margin-right: auto;
  width: 100px;
  height: 30px;
  position: relative;
  cursor: pointer;
`;
const LogoImage = styled.img`
  vertical-align: baseline;
  width: 100px;
  height: 28.58px;
`;
