import {
  ContentBlue,
  ContentGray,
  ContentGreen,
  ContentOrange,
  InbodyRed,
} from "../../styles/GlobalStyle";
import { CourseType, CourseTypeInterface } from "../../data/enums/CourseType";

const courseTypeColorList = [
  InbodyRed,
  ContentGray,
  ContentBlue,
  ContentGreen,
  ContentOrange,
];

export const getColorByCourseType = (
  courseType: CourseTypeInterface | string
) => {
  switch (courseType) {
    case CourseType.신규입사자교육:
    case CourseType.ProductTraining:
      return courseTypeColorList[0];
    case CourseType.직무교육:
    case CourseType.ClinicalTraining:
      return courseTypeColorList[1];
    case CourseType.법정의무교육:
    case CourseType.ApplicationTraining:
      return courseTypeColorList[2];
    case CourseType.리더십교육:
    case CourseType.Others:
      return courseTypeColorList[3];
  }
};
