import styled from "@emotion/styled";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchCompleteCourseList from "../../../api/domains/course/queries/useFetchCompleteCourseList";
import TitleDiv from "../../../components/atoms/TitleDiv";
import { TableLoading } from "../../../components/loading/TableLoading";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import getCourseUrlByCourseType from "../../../hooks/course/getCourseDetailUrlByCourseType";
import { BorderGray } from "../../../styles/GlobalStyle";
import useDateHooks from "../../../hooks/common/useDateHooks";

export default function MyCompleteCourse() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: completeCourseList, isLoading } = useFetchCompleteCourseList();

  const { getDateStringFormat } = useDateHooks();

  return (
    <Wrapper>
      <TitleDiv title={"학습종료된 수업"}></TitleDiv>
      <CustomTableWrapper>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="10%">
                <CustomText type="subtitle">{t("번호")}</CustomText>
              </TableCell>
              <TableCell align="center" width="50%">
                <CustomText type="subtitle">{t("강의명")}</CustomText>
              </TableCell>
              <TableCell align="center" width="15%">
                <CustomText type="subtitle">{t("강사명")}</CustomText>
              </TableCell>
              <TableCell align="center" width="15%">
                <CustomText type="subtitle">{t("마지막 수강일")}</CustomText>
              </TableCell>
              <TableCell align="center" width="10%">
                <CustomText type="subtitle">{t("복습하기")}</CustomText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && TableLoading(4, 10)}
            {completeCourseList && completeCourseList.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <CustomText type="subtitle">
                    {t("학습 종료된 수업이 없습니다.")}
                  </CustomText>
                </TableCell>
              </TableRow>
            )}
            {completeCourseList &&
              completeCourseList.map((course, index, array) => {
                return (
                  <TableRow key={course.courseId}>
                    <TableCell align="center">
                      <CustomText type="body">{index + 1}</CustomText>
                    </TableCell>
                    <TableCell>
                      <CustomTextTooltip type="body">
                        {course.courseName}
                      </CustomTextTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">
                        {course.professorName}
                      </CustomText>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">
                        {getDateStringFormat(
                          course.lastProgressUpdatedAt,
                          "YYYY-MM-DD hh:mm"
                        )}
                      </CustomText>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() =>
                          navigate(
                            getCourseUrlByCourseType(
                              course.courseType,
                              "detail"
                            ).replace(":id", String(course.courseId))
                          )
                        }
                      >
                        <DesktopWindowsOutlinedIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell align='center'>
                                            <IconButton>
                                                <RateReviewIcon fontSize='large' />
                                            </IconButton>
                                        </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CustomTableWrapper>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  padding: 30px;
  border: 1px solid ${BorderGray};
  & button {
    padding: 0;
  }
`;
