import styled from '@emotion/styled';
import { InbodyRed } from '../../styles/GlobalStyle';
import { CustomText } from '../text/CustomText';

interface TitleDivProps {
  title: string;
}

function TitleDiv({ title }: TitleDivProps) {
  return (
    <TitleDivWrapper>
      <CustomText type='subtitle2'>
        {title}
      </CustomText>
    </TitleDivWrapper>
  );
}

export default TitleDiv;

export const TitleDivWrapper = styled.h3`
    letter-spacing: -0.5px;
    padding-left: 10px;
    border-left: 10px solid ${InbodyRed};
    margin-bottom: 15px;
`