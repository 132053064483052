import { FolderAuthListDto } from "../../api/domains/folder/FolderType";

export const isFolderValid = (
  koreanName: string,
  englishName: string,
  folderAuthList: FolderAuthListDto[]
) => {
  if (!koreanName.trim()) {
    return false;
  }
  if (!englishName.trim()) {
    return false;
  }
  if (folderAuthList.length === 0) {
    return false;
  }
  return true;
};
