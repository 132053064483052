import styled from "@emotion/styled";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FetchMainPageCourseList } from "../../../api/domains/course/CourseType";
import TitleDiv from "../../../components/atoms/TitleDiv";
import { ImageLoading } from "../../../components/loading/ImageLoading";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import { MaxCourseShow } from "../../../data/common/NewCourse";
import getCourseUrlByCourseType from "../../../hooks/course/getCourseDetailUrlByCourseType";
import { moveCarousel } from "../../../hooks/main/moveCarousel";
import {
  Border2Gray,
  BorderGray,
  BoxBlue,
  DisabledGray,
  InbodyRed,
} from "../../../styles/GlobalStyle";
import useCourseNameHooks from "../../../hooks/common/useCourseNameHooks";

interface MainPageCourseListProps {
  isNewCourse: boolean;
  courseList: FetchMainPageCourseList[] | undefined;
  isLoading: boolean;
}

export default function MainPageCourseList({
  isNewCourse,
  courseList,
  isLoading,
}: MainPageCourseListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [idx, setIdx] = useState(0);

  const courseCount = courseList?.length ?? 0;
  const maxNumber = courseCount - MaxCourseShow;

  const RenderNoCourseImage = () => {
    let renderArr = [];

    if (!courseList || courseList.length >= MaxCourseShow) {
      return;
    } else {
      for (let i = 0; i < MaxCourseShow - courseList?.length; i++) {
        renderArr.push(<NoCourseItem key={i} />);
      }
    }
    return renderArr;
  };

  const NoCourseItem = () => (
    <NoItem>
      {/* <NoBox>
        <CustomText type='subtitle' color='#ffffff'>
          {t('준비 중')}
        </CustomText>
      </NoBox> */}
    </NoItem>
  );

  const { getCourseName } = useCourseNameHooks();

  return (
    <Wrapper id="newLectureBox">
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <TitleDiv title={isNewCourse ? t("신규강의") : t("전체강의")} />
        <Grid item>
          <Button
            sx={{
              margin: "0 5px",
              color: InbodyRed,
              borderColor: InbodyRed,
            }}
            size="small"
            variant="outlined"
            color="inherit"
            disabled={idx === 0}
            onClick={() => moveCarousel(idx, setIdx, "-", maxNumber, 0)}
          >
            <NavigateBeforeIcon fontSize="small" />
          </Button>
          <Button
            sx={{
              margin: "0 5px",
              color: InbodyRed,
              borderColor: InbodyRed,
            }}
            size="small"
            variant="outlined"
            color="inherit"
            disabled={idx >= courseCount - MaxCourseShow}
            onClick={() => moveCarousel(idx, setIdx, "+", maxNumber, 0)}
          >
            <NavigateNextIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ overflow: "hidden" }}>
        {/** Course Item List*/}
        <NewCourseContentWrapper offset={idx}>
          {courseList?.map((course, index) => {
            return (
              <Item
                key={index}
                onClick={() =>
                  navigate(
                    getCourseUrlByCourseType(
                      course.courseType,
                      "detail"
                    ).replace(":id", String(course.courseId))
                  )
                }
              >
                <Box pt={1}>
                  <CustomText type="body" bold600>
                    {`[${getCourseName(course.courseType)}]`}
                  </CustomText>
                </Box>
                <Box pt={2}>
                  <CustomTextTooltip type="subtitle">
                    {course.courseName}
                  </CustomTextTooltip>
                </Box>
                {(course.courseOpenDate || course.courseCloseDate) && (
                  <Box pt={1}>
                    <CustomText type="body">
                      {`${
                        course.courseOpenDate?.toString().split("T")[0] ?? ""
                      } ~ ${
                        course.courseCloseDate?.toString().split("T")[0] ?? ""
                      }`}
                    </CustomText>
                  </Box>
                )}
              </Item>
            );
          })}
          {isLoading && ImageLoading(240, 150, MaxCourseShow)}
          {RenderNoCourseImage()}
        </NewCourseContentWrapper>
      </Box>
    </Wrapper>
  );
}
export const Wrapper = styled.div`
  border: 1px solid ${BorderGray};
  border-radius: 3px;
  padding: 20px;
`;
export const NewCourseContentWrapper = styled.div<{ offset: number }>`
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  transform: translate(${(props) => -props.offset * 20}%);
  transition: transform 0.5s;
`;
export const Item = styled.div`
  min-width: 198px;
  max-width: 198px;
  height: 140px;
  box-shadow: 2px 2px 2px 2px ${Border2Gray};
  border-radius: 3px;
  margin: 5px;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: ${BoxBlue};
  }
`;
export const NoItem = styled.div`
  min-width: 238px;
  max-width: 238px;
  height: 140px;
  box-shadow: 2px 2px 2px 2px ${Border2Gray};
  border-radius: 3px;
  margin: 5px;
  padding-left: 20px;
  cursor: default;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NoBox = styled.div`
  background-color: ${DisabledGray};
  padding: 3px 13px;
  box-shadow: 2px 2px 2px 2px ${Border2Gray};
  border-radius: 3px;
`;
