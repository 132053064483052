import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import useQnaAPIs from '../useQnaAPIs';

const useFetchQnaTypeList = <T = string[]> (
    options?: UseQueryOptions<string[], AxiosError, T>
) => {
    const { getQnaTypeListAPI } = useQnaAPIs();

    return useQuery({
        queryKey: QueryKeys.qnaTypeList(),
        queryFn: () => getQnaTypeListAPI(),
        ...options
    });
}

export default useFetchQnaTypeList;