import styled from "@emotion/styled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import ToastAlert from "../../../components/alert/ToastAlert";
import { CustomText } from "../../../components/text/CustomText";
import useLectureHooks from "../../../hooks/domains/useLectureHooks";
import { isLectureFileValid } from "../../../hooks/validation/isLectureValid";
import usePostLectureStore, {
  LectureState,
} from "../../../stores/domains/usePostLectureStore";
import { ContentGreen } from "../../../styles/GlobalStyle";

interface PostLectureItemProps {
  lecture: LectureState;
  isDutyCourse: boolean;
  lectureIndex: number;
  isLastIndex: boolean;
}

function PostLectureItem({
  lecture,
  isDutyCourse,
  lectureIndex,
  isLastIndex,
}: PostLectureItemProps) {
  const { t } = useTranslation();
  const [isSeumLecture, setIsSeumLecture] = useState(
    lecture.lectureUrl === null
  );

  const {
    lectureList,
    isSubmit,
    isUploading,
    swapLecture,
    updateLecture,
    deleteLecture,
  } = usePostLectureStore((state) => ({
    lectureList: state.lectureList,
    isSubmit: state.isSubmit,
    isUploading: state.isUploading,
    swapLecture: state.swapLecture,
    updateLecture: state.updateLecture,
    deleteLecture: state.deleteLecture,
  }));

  const { getDurationFromFile } = useLectureHooks();

  return (
    <>
      <TableRow>
        <TableCell align="center">
          <Grid container direction={"column"}>
            <IconButton
              sx={{ p: 0, m: "0 auto" }}
              onClick={() => swapLecture(lectureIndex, lectureIndex - 1)}
              disabled={lectureIndex === 0}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              sx={{ p: 0, m: "0 auto" }}
              onClick={() => swapLecture(lectureIndex, lectureIndex + 1)}
              disabled={isLastIndex}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>
        </TableCell>
        <TableCell align="center">
          <CustomText type="subtitle">{lectureIndex + 1}</CustomText>
        </TableCell>
        <TableCell>
          <TextField
            id={`lecture-${lectureIndex}-title`}
            fullWidth
            size="small"
            value={lecture.title}
            error={isSubmit && !lecture.title.trim()}
            helperText={
              isSubmit && !lecture.title.trim() ? t("필수 입력값입니다.") : ""
            }
            onChange={(e) =>
              updateLecture(lecture.lectureId, { title: e.target.value })
            }
            placeholder={t("영상 제목을 입력해주세요.")}
          />
        </TableCell>
        {isDutyCourse && (
          <>
            <TableCell align="center">
              <FormControl>
                <FormGroup>
                  <Checkbox
                    checked={isSeumLecture}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setIsSeumLecture(true);
                        updateLecture(lecture.lectureId, { lectureUrl: null });
                      } else {
                        setIsSeumLecture(false);
                        updateLecture(lecture.lectureId, { lectureUrl: "" });
                      }
                    }}
                  />
                </FormGroup>
              </FormControl>
            </TableCell>
            <TableCell align="center">
              <TextField
                id={`lecture-${lecture.lectureId}-url`}
                disabled={isSeumLecture}
                fullWidth
                size="small"
                value={lecture.lectureUrl ?? ""}
                onChange={(e) =>
                  updateLecture(lecture.lectureId, {
                    lectureUrl: e.target.value,
                  })
                }
                placeholder={t("영상 URL을 입력해주세요.")}
              />
            </TableCell>
          </>
        )}
        {!isDutyCourse && (
          <TableCell align="center">
            {lecture.originFileName ? (
              <CustomText type="body" bold500 overflowTooltip>
                {lecture.originFileName}
              </CustomText>
            ) : (
              <Button variant="outlined" component="label">
                <CustomText type="button" bold500>
                  {t("영상 업로드")}
                </CustomText>
                <input
                  type="file"
                  hidden
                  onChange={async (e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];

                      const isDuplicateFileName = lectureList.some(
                        (l) => l.originFileName === file.name
                      );

                      if (isDuplicateFileName) {
                        ToastAlert(
                          t("이미 동일한 이름의 파일이 존재합니다."),
                          "error"
                        );
                        return;
                      }
                      const duration = (await getDurationFromFile(
                        file
                      )) as number;

                      const uploadFileName = `${v4()}`;

                      updateLecture(lecture.lectureId, {
                        duration: duration,
                        originFileName: file.name,
                        lectureFile: file,
                        uploadFileName: uploadFileName,
                      });
                    }
                  }}
                  accept="video/mp4, video/x-m4v, video/*"
                />
              </Button>
            )}
            {isSubmit && !isLectureFileValid(lecture) && (
              <CustomText type={"small"} color={"error"}>
                {t("영상 파일이 비어있습니다.")}
              </CustomText>
            )}
          </TableCell>
        )}
        <TableCell align="center">
          <Button
            onClick={() => {
              deleteLecture(lecture.lectureId);
            }}
            variant="text"
            color="error"
          >
            <CustomText type="button">{t("삭제하기")}</CustomText>
          </Button>
        </TableCell>
      </TableRow>
      {!isDutyCourse && isUploading && (
        <TableRow>
          <TableCell colSpan={2} align="center" component="th">
            <CustomText type="subtitle">{t("업로드 현황")}</CustomText>
          </TableCell>
          <TableCell colSpan={3} align="center">
            <Grid container>
              {lecture.progress !== 100 ? (
                <>
                  <BarWrapper>
                    <LinearProgress
                      variant="determinate"
                      value={lecture.progress}
                    />
                  </BarWrapper>
                  <CustomText>{lecture.progress}%</CustomText>
                </>
              ) : (
                <CustomText
                  type="subtitle"
                  bold600
                  fullWidth
                  color={ContentGreen}
                >
                  {t("동영상 업로드가 완료되었습니다.")}
                </CustomText>
              )}
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default memo(PostLectureItem);

const BarWrapper = styled.div`
  width: 90%;
  padding: 10px 20px;
`;
