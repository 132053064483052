import styled from '@emotion/styled';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/templates/PageHeader';
import { CustomText } from '../../components/text/CustomText';
import { BorderGray, InbodyRed } from '../../styles/GlobalStyle';
import QuizResultList from './components/AdminQuizResultList';
import AdminQuizTargetList from './components/AdminQuizTargetList';
import AdminQuizList from './components/AdminQuizList';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

export default function AdminQuizPage() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t('시험관리')} />
      </Grid>
      <Grid item xs={12}>
        <TabTitle
          isActive={activeTab === 0}
          tabCount={3}
          onClick={() => setActiveTab(0)}>
          <FormatListNumberedIcon sx={{ m: 1 }} />
          <CustomText type='subtitle2' bold500>
            {t('시험 목록')}
          </CustomText>
        </TabTitle>
        <TabTitle
          isActive={activeTab === 1}
          tabCount={3}
          onClick={() => setActiveTab(1)}>
          <HowToRegIcon sx={{ m: 1 }} />
          <CustomText type='subtitle2' bold500>
            {t('시험 대상 관리')}
          </CustomText>
        </TabTitle>
        <TabTitle
          isActive={activeTab === 2}
          tabCount={3}
          onClick={() => setActiveTab(2)}>
          <EditNoteIcon sx={{ m: 1 }} />
          <CustomText type='subtitle2' bold500>
            {t('시험 결과 관리')}
          </CustomText>
        </TabTitle>
      </Grid>
      {activeTab === 0 && <AdminQuizList /> }
      { activeTab === 1 && <AdminQuizTargetList /> }
      { activeTab === 2 && <QuizResultList /> }
    </Grid>
  )
}
export const TabTitle = styled.button<{ isActive: boolean, tabCount: number }>`
    width: ${props => `calc(100% / ${props.tabCount})`};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${BorderGray};
    text-align: center;
    line-height: 45px;
    background-color: ${props => props.isActive ? InbodyRed : 'white'} ;
    color: ${props => props.isActive ? 'white' : 'black'} ;
    cursor: pointer;
    height: 45px;
`