import useGetApiInstance from "../../config/BasicApi";
import {
  ChangeFileLocationDto,
  CreateFileDto,
  FileHistory,
  FileListDto,
  FileSearchListDto,
  UpdateFileDto,
} from "./FileType";

const useFileAPI = () => {
  const api = useGetApiInstance("file");

  const getFileListAPI = (folderId: number): Promise<FileListDto[]> =>
    api.get(`list/${folderId}`).then((res) => res.data.result);

  const getSearchFileListAPI = (
    searchWords: string
  ): Promise<FileSearchListDto[]> =>
    api
      .get(`list?searchWords=${encodeURIComponent(searchWords)}`)
      .then((res) => res.data.result);

  const getFileContainerSasTokenAPI = (folderId: number): Promise<string> =>
    api.get(`container/sas-token/${folderId}`).then((res) => res.data.result);

  const getThumbnailContainerSasTokenAPI = (): Promise<string> =>
    api.get(`thumbnail-container/sas-token`).then((res) => res.data.result);

  const getDownloadFileSasTokenAPI = (fileId: number): Promise<string> =>
    api.get(`download/sas-token/${fileId}`).then((res) => res.data.result);

  const getAllFileHistoryAPI = (): Promise<FileHistory[]> =>
    api.get("file-history").then((res) => res.data.result);

  const createFileAPI = (request: CreateFileDto) =>
    api.post(`create`, request).then((res) => res.data.result);

  const updateFileAPI = (request: UpdateFileDto) =>
    api.put(`update-file-tag`, request).then((res) => res.data.result);

  const deleteFileAPI = (folderId: number) =>
    api.delete(`delete/${folderId}`).then((res) => res.data.result);

  const changeFileLocationAPI = (request: ChangeFileLocationDto) =>
    api.put(`change-file-location`, request).then((res) => res.data.result);

  return {
    getFileListAPI,
    getSearchFileListAPI,
    getFileContainerSasTokenAPI,
    getThumbnailContainerSasTokenAPI,
    getDownloadFileSasTokenAPI,
    getAllFileHistoryAPI,
    createFileAPI,
    updateFileAPI,
    deleteFileAPI,
    changeFileLocationAPI,
  };
};

export default useFileAPI;
