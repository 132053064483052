import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import DOMPurify from "dompurify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FetchQnaList } from "../../../api/domains/qna/QnaType";
import useFetchQnaList from "../../../api/domains/qna/queries/useFetchQnaList";
import { TableLoading } from "../../../components/loading/TableLoading";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import useDateHooks from "../../../hooks/common/useDateHooks";
import useCourseHooks from "../../../hooks/domains/useCourseHooks";
import Urls from "../../../routes/Urls";
import { useUserStore } from "../../../stores/user/useUserStore";
import {
  BoxGray,
  TitleBlue,
  TitleGray,
  TitleGreen,
  TitleRed,
} from "../../../styles/GlobalStyle";

export default function QnaList() {
  const { t } = useTranslation();
  const { data: qnaList, isLoading } = useFetchQnaList();

  return (
    <CustomTableWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="10%">
              <CustomText type="subtitle">{t("번호")}</CustomText>
            </TableCell>
            <TableCell align="center" width="45%">
              <CustomText type="subtitle">{t("제목")}</CustomText>
            </TableCell>
            <TableCell align="center" width="10%">
              <CustomText type="subtitle">{t("작성자")}</CustomText>
            </TableCell>
            <TableCell align="center" width="15%">
              <CustomText type="subtitle">{t("작성일")}</CustomText>
            </TableCell>
            <TableCell align="center" width="10%">
              <CustomText type="subtitle">{t("답변상태")}</CustomText>
            </TableCell>
            <TableCell align="center" width="10%">
              <CustomText type="subtitle">{t("문의삭제")}</CustomText>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && TableLoading(5, 6)}
          {qnaList && qnaList.length === 0 && (
            <TableRow>
              <TableCell colSpan={6} align="center">
                <CustomText type="subtitle">
                  {t("등록된 문의가 없습니다.")}
                </CustomText>
              </TableCell>
            </TableRow>
          )}
          {qnaList &&
            qnaList.map((qna, index) => {
              return (
                <QnaListItem key={qna.qnaId} index={index + 1} qna={qna} />
              );
            })}
        </TableBody>
      </Table>
    </CustomTableWrapper>
  );
}

interface QnaListItemProps {
  qna: FetchQnaList;
  index: number;
}

function QnaListItem({ qna, index }: QnaListItemProps) {
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { accountId, isAdmin, isProfessor } = useUserStore();

  const { getDateStringFormat } = useDateHooks();
  const { deleteCourse } = useCourseHooks();

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={() => setIsShow((pre) => !pre)}
      >
        <TableCell align="center">
          <CustomText type="body">{index}</CustomText>
        </TableCell>
        <TableCell>
          <CustomTextTooltip type="body">{qna.title}</CustomTextTooltip>
        </TableCell>
        <TableCell align="center">
          <CustomTextTooltip type="body">{qna.creatorName}</CustomTextTooltip>
        </TableCell>
        <TableCell align="center">
          <CustomText type="body">
            {getDateStringFormat(qna.createdAt, "YYYY-MM-DD hh:mm")}
          </CustomText>
        </TableCell>
        <TableCell align="center">
          {qna.answerContent ? (
            <CustomText type="body" color={TitleGreen} bold500>
              {t("답변완료")}
            </CustomText>
          ) : (
            <CustomText type="body" color={TitleGray} bold500>
              {t("답변대기")}
            </CustomText>
          )}
        </TableCell>
        {qna.creatorAccountId === accountId ? (
          <TableCell align="center" onClick={() => deleteCourse(qna.qnaId)}>
            <CustomText type="body" color={TitleRed} bold500>
              {t("삭제하기")}
            </CustomText>
          </TableCell>
        ) : (
          <TableCell onClick={() => setIsShow((pre) => !pre)}>
            <CustomText align="center" type="body">
              -
            </CustomText>
          </TableCell>
        )}
      </TableRow>
      {isShow && (
        <>
          <TableRow
            key={qna.qnaId}
            style={{
              backgroundColor: BoxGray,
              position: "relative",
            }}
          >
            <TableCell width="10%" align="center">
              <CustomText type="body" color={TitleBlue} bold500>
                {t("문의 내용")}
              </CustomText>
            </TableCell>
            <TableCell colSpan={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(qna.content),
                }}
              />
            </TableCell>
          </TableRow>
          {qna.answerContent && (
            <TableRow sx={{ backgroundColor: BoxGray }}>
              <TableCell width="10%" align="center">
                <CustomText type="body" color={TitleGreen} bold500>
                  {t("답변 내용")}
                </CustomText>
              </TableCell>
              <TableCell width="10%" colSpan={5}>
                <div
                  dangerouslySetInnerHTML={{ __html: qna.answerContent }}
                ></div>
              </TableCell>
            </TableRow>
          )}
          {!qna.answerContent && (isProfessor || isAdmin) && (
            <TableRow
              sx={{
                backgroundColor: TitleGreen,
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(Urls.replyCreate.replace(":id", String(qna.qnaId)))
              }
            >
              <TableCell align="center" colSpan={6}>
                <CustomText type="body" color="#ffffff" bold500>
                  {t("답변을 등록해주세요!")}
                </CustomText>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
}
