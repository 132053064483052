import { create } from "zustand";
import {
  FetchPostLectureList,
  LectureDomain,
} from "../../api/domains/lecture/LectureType";
import { swapItem } from "./usePostCourseStore";

export type LectureState = Pick<
  LectureDomain,
  | "lectureId"
  | "title"
  | "originFileName"
  | "uploadFileName"
  | "duration"
  | "lectureFile"
  | "lectureUrl"
  | "progress"
> & {
  isNew: boolean;
};

type LectureListState = {
  lectureList: LectureState[];
} & {
  isDirty: boolean;
  isSubmit: boolean;
  isUploading: boolean;
};

type LectureListAction = {
  appendNewLecture: () => void;
  swapLecture: (index1: number, index2: number) => void;
  updateLecture: (lectureId: number, newState: Partial<LectureState>) => void;
  deleteLecture: (lectureId: number) => void;
  setIsSubmit: (isSubmit: boolean) => void;
  setIsUploading: (isUploading: boolean) => void;

  createStore: (fetchedLectureList: FetchPostLectureList[]) => void;
  resetStore: () => void;
};

const baseLecture: LectureState = {
  lectureId: 0,
  title: "",
  originFileName: null,
  uploadFileName: null,
  duration: null,
  lectureFile: null,
  lectureUrl: null,
  progress: 0,
  isNew: false,
};

const baseLectureList: LectureListState = {
  lectureList: [],
  isDirty: false,
  isSubmit: false,
  isUploading: false,
};
const usePostLectureStore = create<LectureListState & LectureListAction>(
  (set) => ({
    ...baseLectureList,

    appendNewLecture: () =>
      set((state) => ({
        lectureList: [
          ...state.lectureList,
          { ...baseLecture, lectureId: Date.now(), isNew: true },
        ],
        isDirty: true,
      })),

    swapLecture: (index1, index2) =>
      set((state) => ({
        lectureList: swapItem(state.lectureList, index1, index2),
        isDirty: true,
      })),

    updateLecture: (targetId, newState) =>
      set((state) => {
        const updatedLectureList = state.lectureList.map((lecture) => {
          if (lecture.lectureId === targetId) {
            return { ...lecture, ...newState };
          }
          return lecture;
        });

        return {
          ...state,
          lectureList: updatedLectureList,
          isDirty: true,
        };
      }),

    deleteLecture: (targetId) =>
      set((state) => ({
        lectureList: state.lectureList.filter(
          (lecture) => lecture.lectureId !== targetId
        ),
        isDirty: true,
      })),

    setIsSubmit: (isSubmit) =>
      set((state) => ({
        ...state,
        isSubmit: isSubmit,
      })),

    setIsUploading: (isUploading) =>
      set((state) => ({
        ...state,
        isUploading: isUploading,
      })),

    createStore: (fetchedLectureList) =>
      set({
        lectureList: fetchedLectureList.map((lecture) => ({
          ...baseLecture,
          ...lecture,
          progress: 100,
        })),
        isDirty: false,
      }),

    resetStore: () => set(baseLectureList),
  })
);

export default usePostLectureStore;
