import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuizDetail } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';

const useFetchQuizDetail = <T = FetchQuizDetail>(
    quizId: number,
    options?: UseQueryOptions<FetchQuizDetail, AxiosError, T>
) => {
    const { getQuizDetailAPI } = useQuizAPIs();

    return useQuery({
        queryKey: QueryKeys.quizDetail(quizId),
        queryFn: () => getQuizDetailAPI(quizId),
        enabled: !isNaN(quizId) && quizId > 0,
        ...options
    });
}

export default useFetchQuizDetail;