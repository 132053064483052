import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchPostLectureList } from '../LectureType';
import useLectureAPIs from '../useLectureAPIs';

const useFetchPostLectureList = <T = FetchPostLectureList[]>(
    courseId: number,
    options?: UseQueryOptions<FetchPostLectureList[], AxiosError, T>
) => {
    const { getPostLectureListAPI } = useLectureAPIs();
    
    return useQuery({
        queryKey: QueryKeys.lectureList(courseId),
        queryFn: () => getPostLectureListAPI(courseId),
        enabled: !isNaN(courseId) && courseId > 0,
        ...options
    });
}

export default useFetchPostLectureList;