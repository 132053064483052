import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useTranslation } from "react-i18next";
import useFetchProfessorList from "../../../api/domains/user/queries/useFetchProfessorList";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import usePostQuizStore from "../../../stores/domains/usePostQuizStore";
import dayjs from "dayjs";

export default function PostQuizInfo() {
  const { t } = useTranslation();

  const {
    quizName,
    description,
    quizCloseDate,
    quizOpenDate,
    isOptional,
    isActive,
    isSubmit,
    quizManagerIdList,
    updatePostQuizValue,
  } = usePostQuizStore((state) => ({
    quizName: state.quizName,
    description: state.description,
    quizCloseDate: state.quizCloseDate,
    quizOpenDate: state.quizOpenDate,
    isOptional: state.isOptional,
    isActive: state.isActive,
    isSubmit: state.isSubmit,
    quizManagerIdList: state.quizManagerIdList,
    updatePostQuizValue: state.updateValue,
  }));

  const { data: professorList } = useFetchProfessorList();

  return (
    <CustomTableWrapper>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableBody>
          <TableRow>
            <TableCell width="20%" component="th">
              <CustomText type="subtitle" align="center">
                {t("필수 응시")}
              </CustomText>
            </TableCell>
            <TableCell width="30%">
              <Stack
                direction="row"
                spacing={1}
                justifyContent={"center"}
                alignItems="center"
              >
                <CustomText type="subtitle">{t("선택")}</CustomText>
                <Switch
                  id={`quiz-isOptional-switch`}
                  color="success"
                  checked={!isOptional}
                  onChange={(e) =>
                    updatePostQuizValue({ isOptional: !e.target.checked })
                  }
                />
                <CustomText type="subtitle">{t("필수")}</CustomText>
              </Stack>
            </TableCell>
            <TableCell width="20%" component="th">
              <CustomText type="subtitle" align="center">
                {t("시험 활성화")}
              </CustomText>
            </TableCell>
            <TableCell width="30%">
              <Stack
                direction="row"
                flexWrap={"nowrap"}
                justifyContent={"center"}
                alignItems="center"
              >
                <CustomText type="subtitle">{t("응시불가")}</CustomText>
                <Switch
                  id={`quiz-active-switch`}
                  color="success"
                  checked={isActive}
                  onChange={(e) =>
                    updatePostQuizValue({ isActive: e.target.checked })
                  }
                />
                <CustomText type="subtitle">{t("응시가능")}</CustomText>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">
              <CustomText type="subtitle" align="center">
                {t("시험 시작 시간")}
              </CustomText>
            </TableCell>
            <TableCell>
              <Grid
                container
                justifyContent="space-between"
                alignContent="center"
              >
                <DateTimePicker
                  sx={{ width: "100%" }}
                  label={<CustomText>{t("시험 시작 시간")}</CustomText>}
                  ampm={false}
                  format="YYYY-MM-DD HH:mm"
                  value={quizOpenDate ? dayjs(quizOpenDate) : null}
                  onChange={(newValue) =>
                    updatePostQuizValue({
                      quizOpenDate: dayjs(newValue).utc().toDate(),
                    })
                  }
                />
              </Grid>
            </TableCell>
            <TableCell component="th">
              <CustomText type="subtitle" align="center">
                {t("시험 종료 시간")}
              </CustomText>
            </TableCell>
            <TableCell>
              <Grid
                container
                justifyContent="space-between"
                alignContent="center"
              >
                <DateTimePicker
                  sx={{ width: "100%" }}
                  label={<CustomText>{t("시험 종료 시간")}</CustomText>}
                  ampm={false}
                  format="YYYY-MM-DD HH:mm"
                  value={quizCloseDate ? dayjs(quizCloseDate) : null}
                  onChange={(newValue) =>
                    updatePostQuizValue({
                      quizCloseDate: dayjs(newValue).utc().toDate(),
                    })
                  }
                />
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CustomText type="subtitle">{t("시험 관리자")}</CustomText>
                <TooltipComponent
                  content={t(
                    "강사만 지정 가능합니다. 찾는 강사가 없다면, 수강생 관리에서 강사 권한을 부여해주세요!"
                  )}
                  position="BottomCenter"
                >
                  <IconButton>
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </TooltipComponent>
              </Box>
            </TableCell>
            <TableCell colSpan={3}>
              {professorList && (
                <FormControl fullWidth>
                  <InputLabel id="course-professor-select-label">
                    {t("담당 강사를 지정해주세요.")}
                  </InputLabel>
                  <Select
                    multiple
                    id="course-professor-select"
                    labelId="course-professor-select-label"
                    label={t("담당 강사를 지정해주세요.")}
                    value={quizManagerIdList}
                    onChange={(e) => {
                      const newValue = e.target.value as number[];
                      updatePostQuizValue({ quizManagerIdList: newValue });
                    }}
                    renderValue={(selected) =>
                      professorList
                        .filter((p) => selected.includes(p.userId))
                        .map((p) => p.userName)
                        .join(", ")
                    }
                  >
                    {professorList.map((professor) => (
                      <MenuItem key={professor.userId} value={professor.userId}>
                        <Checkbox
                          checked={
                            quizManagerIdList.indexOf(professor.userId) > -1
                          }
                        />
                        <ListItemText
                          primary={`${professor.userName}(${professor.email})`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">
              <CustomText type="subtitle" align="center">
                {t("시험명")}
              </CustomText>
            </TableCell>
            <TableCell colSpan={3}>
              <TextField
                id="quizName"
                fullWidth
                value={quizName}
                error={isSubmit && !quizName.trim()}
                helperText={
                  isSubmit && !quizName.trim() && t("필수 입력값입니다.")
                }
                onChange={(e) =>
                  updatePostQuizValue({ quizName: e.target.value })
                }
                label={t("시험명을 입력해주세요.")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">
              <CustomText type="subtitle" align="center">
                {t("시험 소개")}
              </CustomText>
            </TableCell>
            <TableCell colSpan={3}>
              <TextField
                id="description"
                fullWidth
                multiline
                minRows={3}
                value={description}
                error={isSubmit && !description.trim()}
                helperText={
                  isSubmit && !description.trim() && t("필수 입력값입니다.")
                }
                onChange={(e) =>
                  updatePostQuizValue({ description: e.target.value })
                }
                label={t("시험 소개 내용을 입력해주세요.")}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CustomTableWrapper>
  );
}
