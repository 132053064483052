import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import axios from "axios";
import { saveAs } from "file-saver";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  ChangeFileLocationDto,
  CreateFileDto,
  FileListDto,
  UpdateFileDto,
} from "../../api/domains/file/FileType";
import useFileAPI from "../../api/domains/file/useFileAPI";
import ToastAlert from "../../components/alert/ToastAlert";
import { THUMBNAIL_SIZE } from "../../data/common/ThumbnailSize";
import { useFileStore } from "../../stores/file/useFileStore";
import { useFolderHistoryStore } from "../../stores/folder/useFolderHistoryStore";
import { useLoadingStore } from "../../stores/loading/useLoadingStore";
import { BasicFontKR, ContentGray } from "../../styles/GlobalStyle";

const useFileHooks = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    createFileAPI,
    updateFileAPI,
    deleteFileAPI,
    getDownloadFileSasTokenAPI,
    getFileContainerSasTokenAPI,
    getThumbnailContainerSasTokenAPI,
    changeFileLocationAPI,
  } = useFileAPI();

  const currentFolderId = useFolderHistoryStore(
    (state) => state.currentFolderId
  );

  const { openLoading, closeLoading } = useLoadingStore((state) => ({
    openLoading: state.openLoading,
    closeLoading: state.closeLoading,
  }));

  const { fileList, updateProgress, updateRequestResult, resetFileStore } =
    useFileStore((state) => ({
      fileList: state.fileList,
      updateProgress: state.updateProgress,
      updateRequestResult: state.updateRequestResult,
      resetFileStore: state.resetStore,
    }));

  //#region 파일 아이콘 이미지 경로 찾기
  const getFileIconPath = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLocaleLowerCase();

    switch (extension) {
      case "xlsx":
        return `${process.env.PUBLIC_URL}/assets/images/excel2.svg`;
      case "docx":
        return `${process.env.PUBLIC_URL}/assets/images/docs2.svg`;
      case "pptx":
        return `${process.env.PUBLIC_URL}/assets/images/powerpoint.svg`;
      case "zip":
        return `${process.env.PUBLIC_URL}/assets/images/zip.svg`;
      default:
        return `${process.env.PUBLIC_URL}/assets/images/default.svg`;
    }
  };
  //#endregion

  //#region 파일 다운로드(더블클릭)
  const downloadFileWhenDoubleClick = async (
    fileId: number,
    originFileName: string
  ) => {
    const blobSasToken = await getDownloadFileSasTokenAPI(fileId);

    axios.get(blobSasToken, { responseType: "blob" }).then((res) => {
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      saveAs(blob, originFileName);
    });
  };
  //#endregion

  //#region 선택된 파일들 다운로드
  const downloadSelectedFileList = async (
    fileList: FileListDto[],
    selectedFileIdSet: Set<number>
  ) => {
    const selectedFileList = fileList.filter((file) =>
      selectedFileIdSet.has(file.fileId)
    );

    for (const file of selectedFileList) {
      const blobSasToken = await getDownloadFileSasTokenAPI(file.fileId);

      axios.get(blobSasToken, { responseType: "blob" }).then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        saveAs(blob, file.originFileName);
      });
    }

    resetFileStore();
  };
  //#endregion

  //#region 파일 삭제
  const deleteFileMutation = useMutation((deleteFileId: number) =>
    deleteFileAPI(deleteFileId)
  );
  const deleteFileRequest = async (selectedFileIdList: number[]) => {
    if (deleteFileMutation.isLoading) {
      return;
    }

    openLoading();

    const deleteFilePromiseList = selectedFileIdList.map((deleteFileId) => {
      return new Promise((resolve, reject) => {
        deleteFileMutation.mutate(deleteFileId, {
          onSuccess: () => {
            resolve(true);
          },
          onError: (error) => {
            reject(error);
          },
        });
      });
    });

    Promise.all(deleteFilePromiseList)
      .then(() => {
        ToastAlert(t("파일을 삭제했습니다."), "success");
      })
      .catch(() => {
        ToastAlert(
          t("파일 삭제 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
          "error"
        );
      })
      .finally(() => {
        resetFileStore();
        closeLoading();
        queryClient.invalidateQueries(QueryKeys.fileList(currentFolderId));
      });
  };
  //#endregion

  //#region @private pdf 썸네일 파일 생성
  GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs-dist/pdf.worker.mjs`;
  const createPDFThumbnail = (pdfFile: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = async (event) => {
        try {
          const typedArray = new Uint8Array(
            event.target?.result as ArrayBufferLike
          );
          const pdf = await getDocument(typedArray).promise;
          const page = await pdf.getPage(1);
          const viewport = page.getViewport({ scale: 1 });

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.height = THUMBNAIL_SIZE;
          canvas.width = THUMBNAIL_SIZE;

          if (ctx) {
            const scale = THUMBNAIL_SIZE / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });

            await page.render({ canvasContext: ctx, viewport: scaledViewport })
              .promise;

            // makeWaterMark(ctx);

            canvas.toBlob(
              (blob) => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error("Blob creation failed"));
                }
              },
              "image/jpeg",
              1
            );
          }
        } catch (error) {
          reject(error);
        }
      };

      fileReader.onerror = () => {
        reject(new Error("FileReader error"));
      };

      fileReader.readAsArrayBuffer(pdfFile);
    });
  };
  //#endregion

  //#region @private 워터마크 생성함수
  const makeWaterMark = (ctx: CanvasRenderingContext2D) => {
    ctx.save();
    ctx.translate(THUMBNAIL_SIZE / 2, THUMBNAIL_SIZE / 2);
    ctx.fillStyle = ContentGray;
    ctx.font = `20px ${BasicFontKR}`;
    ctx.fillText("Thumbnail", -10, -10);
    ctx.restore();
  };
  //#endregion

  //#region @private 이미지 썸네일 파일 생성
  const createImgThumbnail = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Canvas Error"));
        return;
      }

      const img = new Image();

      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        makeWaterMark(ctx);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            }
          },
          "image/jpeg",
          1
        );
      };

      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };
  //#endregion

  //#region 파일 생성 요청
  const createFileMutation = useMutation((request: CreateFileDto) =>
    createFileAPI(request)
  );
  const createFileRequestAsync = async (folderId: number) => {
    if (createFileMutation.isLoading) return;

    try {
      openLoading();

      const sasToken = await getFileContainerSasTokenAPI(folderId);
      const blobService = new BlobServiceClient(sasToken);
      const containerClient = blobService.getContainerClient("");

      const uploadPromises = fileList.map(async (file) => {
        let thumbnailBlobClient;
        let thumbnailURL = "";
        let thumbnailBlob = null;

        //Thumbnail
        if (file.file) {
          if (file.file.type === "application/pdf") {
            thumbnailBlob = await createPDFThumbnail(file.file);
          } else if (file.file.type.startsWith("image/")) {
            thumbnailBlob = await createImgThumbnail(file.file);
          }

          if (thumbnailBlob) {
            const thumbnailSasToken = await getThumbnailContainerSasTokenAPI();
            const thumbnailBlobService = new BlobServiceClient(
              thumbnailSasToken
            );
            const thumbnailContainerClient =
              thumbnailBlobService.getContainerClient("");

            const thumbnailName = `thumbnail_${file.uploadFileName}`;
            thumbnailBlobClient =
              thumbnailContainerClient.getBlockBlobClient(thumbnailName);
            await thumbnailBlobClient.uploadData(thumbnailBlob);
            thumbnailURL = `${process.env.REACT_APP_THUMBNAIL_STORAGE_URL}/${thumbnailName}`;
          }
        }

        const blobClient = containerClient.getBlockBlobClient(
          file.uploadFileName
        );

        var blobUploadOptions: BlockBlobParallelUploadOptions = {
          blockSize: 4 * 1024 * 1024,
          concurrency: 20,
          maxSingleShotSize: 268435456,
          onProgress: (process) => {
            const percentage = Math.round(
              (process.loadedBytes / file.fileSize) * 100
            );
            updateProgress(file.originFileName, percentage);
          },
          blobHTTPHeaders: { blobContentType: file.file?.type },
        };

        //File upload
        try {
          await blobClient.uploadData(file.file!, blobUploadOptions);

          const createFileDto: CreateFileDto = {
            folderId: folderId,
            fileSize: file.fileSize,
            originFileName: file.originFileName,
            uploadFileName: file.uploadFileName,
            thumbnailUrl: thumbnailURL,
            tagIdList: file.tagIdList,
            uploaderName: file.uploadFileName,
            folderPath: file.folderPath,
          };

          return {
            success: true,
            data: createFileDto,
          };
        } catch (ex) {
          updateRequestResult(
            file.originFileName,
            t("파일 업로드에 실패했습니다.")
          );
          if (thumbnailBlobClient) {
            await thumbnailBlobClient.delete();
          }
          await blobClient.delete();
          return {
            success: false,
          };
        }
      });

      const result = await Promise.all(uploadPromises);
      const successFiles = result
        .filter((r) => r.success)
        .map((r) => r.data) as CreateFileDto[];

      for (const createFileDto of successFiles) {
        try {
          await createFileMutation.mutateAsync(createFileDto);
          updateRequestResult(
            createFileDto.originFileName,
            t("파일이 정상적으로 업로드되었습니다.")
          );
        } catch (ex) {
          updateRequestResult(
            createFileDto.originFileName,
            t("파일 정보를 저장하지 못했습니다.")
          );
        }
      }
      queryClient.invalidateQueries(QueryKeys.fileList(folderId));
      queryClient.invalidateQueries(QueryKeys.subFolderList(folderId));
      queryClient.invalidateQueries(QueryKeys.folderPathList(folderId));
      queryClient.invalidateQueries(QueryKeys.folderAuthTree());
    } finally {
      closeLoading();
    }
  };
  //#endregion

  //#region 파일 수정 요청
  const updateFileMutation = useMutation((request: UpdateFileDto) =>
    updateFileAPI(request)
  );
  const updateFileRequestAsync = async (folderId: number) => {
    if (updateFileMutation.isLoading) return;

    try {
      openLoading();
      await Promise.all(
        fileList.map((file) => {
          const updateFileDto: UpdateFileDto = {
            fileId: file.fileId,
            folderId: folderId,
            tagIdList: file.tagIdList,
          };
          return updateFileMutation.mutateAsync(updateFileDto);
        })
      );

      ToastAlert(t("파일 태그 정보가 수정되었습니다."), "success");
      queryClient.invalidateQueries(QueryKeys.fileList(folderId));
    } catch {
      ToastAlert(
        t(
          "파일 태그 정보 수정 중 에러가 발생했습니다. 관리자에게 문의해주세요."
        ),
        "error"
      );
    } finally {
      closeLoading();
    }
  };
  //#endregion

  //#region 파일 위치 변경
  const changeFileLocationMutation = useMutation(
    (request: ChangeFileLocationDto) => changeFileLocationAPI(request)
  );
  const changeFileLocationAsync = async (
    fileIdList: number[],
    targetFolderId: number
  ) => {
    if (changeFileLocationMutation.isLoading) {
      return;
    }

    try {
      openLoading();

      await Promise.all(
        fileIdList.map((fileId) => {
          const requestDto: ChangeFileLocationDto = {
            fileId: fileId,
            targetFolderId: targetFolderId,
          };
          return changeFileLocationMutation.mutateAsync(requestDto);
        })
      );

      ToastAlert(t("정상적으로 이동되었습니다."), "success");
      queryClient.invalidateQueries(QueryKeys.fileList(currentFolderId));
      queryClient.invalidateQueries(QueryKeys.subFolderList(currentFolderId));
      queryClient.invalidateQueries(QueryKeys.folderAuthTree());
    } catch {
      ToastAlert(
        t("위치 이동 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
        "error"
      );
    } finally {
      closeLoading();
    }
  };
  //#endregion

  return {
    downloadFileWhenDoubleClick,
    downloadSelectedFileList,
    getFileIconPath,
    createFileRequestAsync,
    updateFileRequestAsync,
    deleteFileRequest,
    changeFileLocationAsync,
  };
};
export default useFileHooks;
