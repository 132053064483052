export type StringFormat = 'YYYY-MM-DD' | 'YYYY-MM-DD hh:mm:ss' | 'YYYY-MM-DD hh:mm' | 'MM-DD' | 'MM/DD' | 'hh:mm';

const useDateHooks = () => {

    const getDateStringFormat = (date: Date | string | undefined | null, format: StringFormat): string => { 
        if (!date) return '-';

        const dateFormatValue = typeof (date) === 'string' ? new Date(date) : date;

        const yyyy = dateFormatValue.getFullYear();
        const MM = String(dateFormatValue.getMonth() + 1).padStart(2, '0');
        const dd = String(dateFormatValue.getDate()).padStart(2, '0');
        const hh = String(dateFormatValue.getHours()).padStart(2, '0');
        const mm = String(dateFormatValue.getMinutes()).padStart(2, '0');
        const ss = String(dateFormatValue.getSeconds()).padStart(2, '0');

        switch (format) {
            case 'hh:mm':
                return `${hh}:${mm}`;
            case 'MM-DD':
                return `${MM}-${dd}`;
            case 'MM/DD':
                return `${MM}/${dd}`;
            case 'YYYY-MM-DD':
                return `${yyyy}-${MM}-${dd}`;
            case 'YYYY-MM-DD hh:mm':
                return `${yyyy}-${MM}-${dd} ${hh}:${mm}`;
            case 'YYYY-MM-DD hh:mm:ss':
                return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
            default:
                return '';
        }
    }

    return {
        getDateStringFormat
    }
}

export default useDateHooks;