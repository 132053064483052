import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataManager } from "@syncfusion/ej2-data";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchManagingQuizList from "../../../api/domains/quiz/queries/useFetchManagingQuizList";
import useFetchUserListWithQuizTargetInfo from "../../../api/domains/quiz/queries/useFetchQuizTargetList";
import { TableLoading } from "../../../components/loading/TableLoading";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import { PageSize } from "../../../data/enums/PageSize";
import {
  QuizTargetSearchCondition,
  QuizTargetSearchConditionType,
} from "../../../data/enums/SearchCondition";
import useDateHooks from "../../../hooks/common/useDateHooks";
import useAdminQuizHooks from "../../../hooks/domains/useAdminQuizHooks";
import {
  BasicFontKR,
  BorderGray,
  BoxPale,
  DividerGray,
} from "../../../styles/GlobalStyle";

export default function AdminQuizTargetList() {
  const { t } = useTranslation();
  const [selectQuizId, setSelectQuizId] = useState<number>(0);

  const [searchCondition, setSearchCondition] =
    useState<QuizTargetSearchConditionType>(QuizTargetSearchCondition.이름);
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [page, setPage] = useState(1);

  const { data: managingQuizList } = useFetchManagingQuizList();
  const { data: userListWithQuizTargetInfo, isLoading } =
    useFetchUserListWithQuizTargetInfo(selectQuizId);

  const { changeQuizTarget } = useAdminQuizHooks();
  const { getDateStringFormat } = useDateHooks();

  const filteredQuizTargetList = userListWithQuizTargetInfo?.filter((user) => {
    switch (searchCondition) {
      case QuizTargetSearchCondition.이름:
        return user.userName.search(getRegExp(searchKeyWords)) !== -1;
      case QuizTargetSearchCondition.부서:
        if (!user.belong) return false;
        return user.belong.search(getRegExp(searchKeyWords)) !== -1;
      case QuizTargetSearchCondition.입사일:
        if (!user.joined) return false;
        return user.joined.search(getRegExp(searchKeyWords)) !== -1;
      case QuizTargetSearchCondition.아이디:
        if (!user.accountId) return false;
        return user.accountId.search(getRegExp(searchKeyWords)) !== -1;
      case QuizTargetSearchCondition.이메일:
        if (!user.email) return false;
        return user.email.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  return (
    <>
      <Grid item xs={12} mt={3}>
        <TableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" align="center">
                  <CustomText type="subtitle">{t("시험명")}</CustomText>
                </TableCell>
                <TableCell colSpan={2}>
                  {managingQuizList && (
                    <DropDownListComponent
                      cssClass="e-outline"
                      dataSource={new DataManager(managingQuizList)}
                      placeholder={
                        managingQuizList.length > 0
                          ? t("시험을 선택해주세요.")
                          : t("해당 강의에 시험이 없습니다.")
                      }
                      enabled={managingQuizList.length > 0}
                      value={selectQuizId}
                      fields={{
                        text: "quizName",
                        value: "quizId",
                      }}
                      change={(e) => setSelectQuizId(e.value)}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="20%" component="th" align="center">
                  <CustomText type="subtitle">{t("검색어")}</CustomText>
                </TableCell>
                <TableCell width="20%">
                  <Select
                    fullWidth
                    size="small"
                    value={searchCondition}
                    onChange={(event) => setSearchCondition(event.target.value)}
                    displayEmpty
                  >
                    <MenuItem value={QuizTargetSearchCondition.이름}>
                      <CustomText>{t("이름")}</CustomText>
                    </MenuItem>
                    <MenuItem value={QuizTargetSearchCondition.부서}>
                      <CustomText>{t("부서")}</CustomText>
                    </MenuItem>
                    <MenuItem value={QuizTargetSearchCondition.입사일}>
                      <CustomText>{t("입사일")}</CustomText>
                    </MenuItem>
                    <MenuItem value={QuizTargetSearchCondition.아이디}>
                      <CustomText>{t("아이디")}</CustomText>
                    </MenuItem>
                    <MenuItem value={QuizTargetSearchCondition.이메일}>
                      <CustomText>{t("이메일")}</CustomText>
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell width="60%">
                  <KeyWordWrapper>
                    <InputBase
                      fullWidth
                      size="small"
                      inputProps={{ style: { padding: 0 } }}
                      sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                      className="inputField"
                      placeholder={t("검색어를 입력하세요.")}
                      value={searchKeyWords}
                      onChange={(event) =>
                        setSearchKeyWords(event.target.value)
                      }
                    />
                    <Divider
                      sx={{ height: 25, m: 0.5 }}
                      orientation="vertical"
                    />
                    <IconButton type="button" sx={{ p: 0 }}>
                      <SearchIcon />
                    </IconButton>
                  </KeyWordWrapper>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%">
                  <CustomText type="body" bold500>
                    {t("번호")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("아이디")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("인바디 임직원")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("소속")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("직위")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("사용자명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("이메일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("입사일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("시험 대상 지정")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(8, PageSize.FIFTEEN)}
              {selectQuizId === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <CustomText type="body">
                      {t("시험을 선택해주세요.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {filteredQuizTargetList &&
                filteredQuizTargetList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CustomText type="body">
                        {t("검색 조건에 맞는 대상이 없습니다.")}
                      </CustomText>
                    </TableCell>
                  </TableRow>
                )}
              {selectQuizId !== 0 &&
                filteredQuizTargetList &&
                filteredQuizTargetList
                  .slice((page - 1) * PageSize.FIFTEEN, page * PageSize.FIFTEEN)
                  .map((user, index) => {
                    return (
                      <TableRow key={user.userId}>
                        <TableCell align="center">
                          <CustomText type="body">{index + 1}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomTextTooltip type="body">
                            {user.accountId}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CustomTextTooltip type="body">
                            {user.isInbodyMember ? "Y" : "N"}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.belong}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {user.jobTitle ?? "-"}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.userName}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">{user.email}</CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {getDateStringFormat(user.joined, "YYYY-MM-DD")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            id={`quiz-target-switch-${user.userId}`}
                            color="success"
                            checked={user.isQuizTarget}
                            onChange={() =>
                              changeQuizTarget(user.userId, user.quizId)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
      <Grid item container xs={12} justifyContent={"center"}>
        {filteredQuizTargetList && (
          <Pagination
            count={Math.ceil(filteredQuizTargetList.length / PageSize.FIFTEEN)}
            onChange={(_, curPage) => setPage(curPage)}
            page={page}
          />
        )}
      </Grid>
    </>
  );
}

const KeyWordWrapper = styled.div`
  margin: 0 5px;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
const TableWrapper = styled.div`
  & th,
  td {
    padding: 10px;
    border-top: 1px solid ${BorderGray};
    border-right: 1px solid ${BorderGray};
    border-left: 1px solid ${BorderGray};
  }
  & th {
    background-color: ${BoxPale};
  }
`;
