import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React, { useRef, useState } from "react";
import useFetchSearchFileList from "../../../api/domains/file/queries/useFetchSearchFileList";
import { CustomText } from "../../../components/text/CustomText";
import { ViewSizeOption } from "../../../data/enums/ViewSizeOption";
import useDateHooks from "../../../hooks/common/useDateHooks";
import useUnitHooks from "../../../hooks/common/useUnitHooks";
import useViewSizeStyleHooks from "../../../hooks/common/useViewSizeStyleHooks";
import useFileHooks from "../../../hooks/domains/useFileHooks";
import { useFileSearchStore } from "../../../stores/file/useFileSearchStore";
import { useSelectionManagerStore } from "../../../stores/folder/useSelectionManagerStore";
import { BorderGray } from "../../../styles/GlobalStyle";
import {
  DetailViewHeader,
  DragArea,
  FileAndFolderWrapper,
  ItemComponent,
  ItemDetailViewWrapper,
  initMousePosition,
} from "./ArchiveContent";
import ArchiveNotAuthInfoModal, {
  AuthInfoModalState,
  initAuthInfoModalState,
} from "./ArchiveNotAuthInfoModal";

export default function ArchiveSearchContent() {
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] = useState(initMousePosition);
  const [authInfoModalState, setAuthInfoModalState] =
    useState<AuthInfoModalState>(initAuthInfoModalState);
  const contentRef = useRef<HTMLDivElement>(null);

  const { selectedFileIdSet, viewSize, setSelectedFileIdSet } =
    useSelectionManagerStore((state) => ({
      selectedFileIdSet: state.selectedFileIdSet,
      viewSize: state.viewSize,
      setSelectedFileIdSet: state.setSelectedFileIdSet,
    }));
  const { searchWordSnapShot } = useFileSearchStore((state) => ({
    searchWordSnapShot: state.searchWordSnapShot,
  }));

  const { data: searchFileList } = useFetchSearchFileList(searchWordSnapShot);

  const { getFileIconPath, downloadFileWhenDoubleClick } = useFileHooks();
  const { getDateStringFormat } = useDateHooks();
  const { fileSizeUnit } = useUnitHooks();
  const { getViewSizeStyle } = useViewSizeStyleHooks();

  const selectAreaItem = (e: React.MouseEvent) => {
    setIsDragging(false);
    const folderIdSet = new Set<number>();
    const fileIdSet = new Set<number>();
    const paperRect = e.currentTarget.getBoundingClientRect();

    if (
      mousePosition.startPositionX !== null &&
      mousePosition.startPositionY !== null &&
      mousePosition.endPositionX !== null &&
      mousePosition.endPositionY !== null
    ) {
      const dragRect = {
        left: Math.min(
          mousePosition.startPositionX,
          mousePosition.endPositionX
        ),
        top: Math.min(mousePosition.startPositionY, mousePosition.endPositionY),
        right: Math.max(
          mousePosition.startPositionX,
          mousePosition.endPositionX
        ),
        bottom: Math.max(
          mousePosition.startPositionY,
          mousePosition.endPositionY
        ),
      };
      document.querySelectorAll(".item-component").forEach((el) => {
        const rect = el.getBoundingClientRect();
        const itemLeft = rect.left - paperRect.left;
        const itemRight = rect.right - paperRect.left;
        const itemTop =
          rect.top - paperRect.top + (contentRef.current?.scrollTop ?? 0);
        const itemBottom =
          rect.bottom - paperRect.top + (contentRef.current?.scrollTop ?? 0);

        if (
          itemRight >= dragRect.left &&
          itemLeft <= dragRect.right &&
          itemBottom >= dragRect.top &&
          itemTop <= dragRect.bottom
        ) {
          const id = Number(el.getAttribute("data-id"));
          const isFolder = el.getAttribute("data-type") === "folder";
          isFolder ? folderIdSet.add(id) : fileIdSet.add(id);
        }
      });
    }
    setSelectedFileIdSet(fileIdSet);
    setMousePosition(initMousePosition);
  };

  return (
    <>
      <FileAndFolderWrapper
        ref={contentRef}
        onMouseDown={(e) => {
          const mousePoint = e.target as Element;
          if (!mousePoint.closest(".item-component")) {
            const rect = e.currentTarget.getBoundingClientRect();
            setMousePosition({
              startPositionX: e.clientX - rect.left,
              startPositionY:
                e.clientY - rect.top + (contentRef.current?.scrollTop ?? 0),
              endPositionX: null,
              endPositionY: null,
            });
            setIsDragging(true);
          }
        }}
        onMouseMove={(e) => {
          if (isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            setMousePosition((preState) => ({
              ...preState,
              endPositionX: e.clientX - rect.left,
              endPositionY:
                e.clientY - rect.top + (contentRef.current?.scrollTop ?? 0),
            }));
          }
        }}
        onMouseLeave={(e) => {
          if (isDragging) {
            selectAreaItem(e);
          }
        }}
        onMouseUp={selectAreaItem}
      >
        <DragArea
          style={{
            position: "absolute",
            left: `${Math.min(
              mousePosition.startPositionX ?? 0,
              mousePosition.endPositionX ?? 0
            )}px`,
            top: `${Math.min(
              mousePosition.startPositionY ?? 0,
              mousePosition.endPositionY ?? 0
            )}px`,
            width: `${Math.abs(
              (mousePosition.startPositionX ?? 0) -
                (mousePosition.endPositionX ?? 0)
            )}px`,
            height: `${Math.abs(
              (mousePosition.startPositionY ?? 0) -
                (mousePosition.endPositionY ?? 0)
            )}px`,
            display:
              mousePosition.startPositionX !== null &&
              mousePosition.startPositionY !== null &&
              mousePosition.endPositionX !== null &&
              mousePosition.endPositionY !== null
                ? "block"
                : "none",
          }}
        />
        {viewSize === ViewSizeOption.DETAIL && (
          <DetailViewHeader>
            <CustomText
              width={"50%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"이름"}
            </CustomText>
            <CustomText
              width={"10%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"크기"}
            </CustomText>
            <CustomText
              width={"20%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"생성한 날짜"}
            </CustomText>
            <CustomText width={"20%"} align="center" type={"subtitle"} bold400>
              {"수정한 날짜"}
            </CustomText>
          </DetailViewHeader>
        )}
        {searchFileList &&
          searchFileList
            .filter((file) => file.hasAuth)
            .map((file) => {
              let fileIconSrc = "";

              if (
                file.thumbnailUrl !== "" &&
                viewSize !== ViewSizeOption.DETAIL
              ) {
                fileIconSrc = file.thumbnailUrl;
              } else {
                fileIconSrc = getFileIconPath(file.originFileName);
              }

              return (
                <ItemComponent
                  key={file.fileId}
                  className="item-component"
                  data-id={file.fileId}
                  data-type={"file"}
                  margin={getViewSizeStyle(viewSize).margin}
                  padding={getViewSizeStyle(viewSize).padding}
                  width={getViewSizeStyle(viewSize).width}
                  minHeight={getViewSizeStyle(viewSize).minHeight}
                  isFullWidth={viewSize === ViewSizeOption.DETAIL}
                  isSelected={selectedFileIdSet.has(file.fileId)}
                  onDoubleClick={() =>
                    downloadFileWhenDoubleClick(
                      file.fileId,
                      file.originFileName
                    )
                  }
                  onDragOver={(e) => e.preventDefault()}
                  onClick={() =>
                    setSelectedFileIdSet(new Set<number>([file.fileId]))
                  }
                >
                  <img
                    src={fileIconSrc}
                    alt={file.originFileName}
                    width={getViewSizeStyle(viewSize).ImgWidth}
                    height={getViewSizeStyle(viewSize).ImgHeight}
                  />
                  {viewSize !== ViewSizeOption.DETAIL && (
                    <Box
                      sx={{
                        width: getViewSizeStyle(viewSize).ImgWidth,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      <CustomText
                        fullWidth
                        type={getViewSizeStyle(viewSize).type}
                        bold400
                        align="center"
                        wordBreak
                        sx={{
                          cursor: "default",
                          lineHeight: 1.3,
                          overflowWrap: "break-word",
                        }}
                      >
                        {file.originFileName}
                      </CustomText>
                    </Box>
                  )}
                  {viewSize === ViewSizeOption.DETAIL && (
                    <ItemDetailViewWrapper>
                      <CustomText
                        width={"50%"}
                        type={getViewSizeStyle(viewSize).type}
                      >
                        {file.originFileName}
                      </CustomText>
                      <CustomText
                        width={"10%"}
                        type={getViewSizeStyle(viewSize).type}
                      >
                        {fileSizeUnit(file.fileSize)}
                      </CustomText>
                      <CustomText
                        width={"20%"}
                        type={getViewSizeStyle(viewSize).type}
                        align="center"
                      >
                        {getDateStringFormat(
                          file.updatedAt,
                          "YYYY-MM-DD hh:mm:ss"
                        )}
                      </CustomText>
                      <CustomText
                        width={"20%"}
                        type={getViewSizeStyle(viewSize).type}
                        align="center"
                      >
                        {getDateStringFormat(
                          file.createdAt,
                          "YYYY-MM-DD hh:mm:ss"
                        )}
                      </CustomText>
                    </ItemDetailViewWrapper>
                  )}
                </ItemComponent>
              );
            })}
        {searchFileList &&
          searchFileList
            .filter((file) => !file.hasAuth)
            .map((file) => {
              let fileIconSrc = "";
              if (
                file.thumbnailUrl !== "" &&
                viewSize !== ViewSizeOption.DETAIL
              ) {
                fileIconSrc = file.thumbnailUrl;
              } else {
                fileIconSrc = getFileIconPath(file.originFileName);
              }

              return (
                <NotAuthItemComponent
                  key={file.fileId}
                  margin={getViewSizeStyle(viewSize).margin}
                  padding={getViewSizeStyle(viewSize).padding}
                  width={getViewSizeStyle(viewSize).width}
                  minHeight={getViewSizeStyle(viewSize).minHeight}
                  isFullWidth={viewSize === ViewSizeOption.DETAIL}
                  isSelected={selectedFileIdSet.has(file.fileId)}
                  onDragOver={(e) => e.preventDefault()}
                  onMouseDown={() =>
                    setAuthInfoModalState({
                      folderAuthUserIdList: file.folderAuthUserIdList,
                      folderKoreaName: file.folderKoreanName,
                      folderEnglishName: file.folderEnglishName,
                      isOpen: true,
                    })
                  }
                >
                  <img
                    src={fileIconSrc}
                    alt={file.originFileName}
                    width={getViewSizeStyle(viewSize).ImgWidth}
                    height={getViewSizeStyle(viewSize).ImgHeight}
                    style={{
                      opacity: 0.7,
                    }}
                  />
                  <Box
                    position={"absolute"}
                    top={viewSize === ViewSizeOption.DETAIL ? 5 : 0}
                    left={viewSize === ViewSizeOption.DETAIL ? 5 : 0}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/lock2.png`}
                      alt={"lock"}
                      width={getViewSizeStyle(viewSize).ImgWidth / 2}
                      height={getViewSizeStyle(viewSize).ImgHeight / 2}
                    />
                  </Box>
                  {viewSize !== ViewSizeOption.DETAIL && (
                    <Box
                      sx={{
                        width: getViewSizeStyle(viewSize).ImgWidth,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      <CustomText
                        fullWidth
                        type={getViewSizeStyle(viewSize).type}
                        bold400
                        align="center"
                        wordBreak
                        sx={{
                          lineHeight: 1.3,
                          overflowWrap: "break-word",
                        }}
                      >
                        {file.originFileName}
                      </CustomText>
                    </Box>
                  )}
                  {viewSize === ViewSizeOption.DETAIL && (
                    <NotAuthItemDetailViewWrapper>
                      <CustomText
                        width={"50%"}
                        type={getViewSizeStyle(viewSize).type}
                      >
                        {file.originFileName}
                      </CustomText>
                      <CustomText
                        width={"10%"}
                        type={getViewSizeStyle(viewSize).type}
                      >
                        {fileSizeUnit(file.fileSize)}
                      </CustomText>
                      <CustomText
                        width={"20%"}
                        type={getViewSizeStyle(viewSize).type}
                        align="center"
                      >
                        {getDateStringFormat(
                          file.updatedAt,
                          "YYYY-MM-DD hh:mm:ss"
                        )}
                      </CustomText>
                      <CustomText
                        width={"20%"}
                        type={getViewSizeStyle(viewSize).type}
                        align="center"
                      >
                        {getDateStringFormat(
                          file.createdAt,
                          "YYYY-MM-DD hh:mm:ss"
                        )}
                      </CustomText>
                    </NotAuthItemDetailViewWrapper>
                  )}
                </NotAuthItemComponent>
              );
            })}
        <ArchiveNotAuthInfoModal
          isOpen={authInfoModalState.isOpen}
          setAuthInfoModalState={setAuthInfoModalState}
          folderAuthUserIdList={authInfoModalState.folderAuthUserIdList}
          folderKoreaName={authInfoModalState.folderKoreaName}
          folderEnglishName={authInfoModalState.folderEnglishName}
        />
      </FileAndFolderWrapper>
    </>
  );
}
const NotAuthItemComponent = styled(ItemComponent)`
  position: relative;
  cursor: pointer;
`;
const NotAuthItemDetailViewWrapper = styled(ItemDetailViewWrapper)`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-top: 1px dashed black;
    transform: translateY(-50%);
  }
`;
