import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchNoticeList from "../../api/domains/notice/queries/useFetchNoticeList";
import CustomButton from "../../components/button/CustomButton";
import { TableLoading } from "../../components/loading/TableLoading";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import CustomTextTooltip from "../../components/text/CustomTextTooltip";
import { PageSize } from "../../data/enums/PageSize";
import {
  NoticeSearchCondition,
  NoticeSearchConditionType,
} from "../../data/enums/SearchCondition";
import useDateHooks from "../../hooks/common/useDateHooks";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";
import { BasicFontKR, DividerGray } from "../../styles/GlobalStyle";

export default function NoticeListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [searchCondition, setSearchCondition] =
    useState<NoticeSearchConditionType>("Title");
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const isAdmin = useUserStore((state) => state.isAdmin);

  const { data: noticeList, isLoading } = useFetchNoticeList();

  const renderNoticeList = noticeList?.filter((notice) => {
    switch (searchCondition) {
      case NoticeSearchCondition.TITLE:
        return notice.title.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });
  const { getDateStringFormat } = useDateHooks();

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title="공지사항" />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"flex-end"}>
          <SearchWrapper>
            <Select
              value={searchCondition}
              onChange={(event) =>
                setSearchCondition(
                  event.target.value as NoticeSearchConditionType
                )
              }
            >
              <MenuItem value={NoticeSearchCondition.TITLE}>
                <CustomText>{t("공지명")}</CustomText>
              </MenuItem>
            </Select>
            <KeyWordWrapper>
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  fontFamily: BasicFontKR,
                  fontSize: "14px",
                }}
                className="inputField"
                placeholder={t("검색어를 입력하세요.")}
                value={searchKeyWords}
                onChange={(event) => setSearchKeyWords(event.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </KeyWordWrapper>
          </SearchWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper pointer hoverColor>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle" bold600>
                    {t("번호")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="60%">
                  <CustomText type="subtitle" bold600>
                    {t("제목")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("작성일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("조회수")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 4)}
              {renderNoticeList && renderNoticeList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CustomText type="subtitle">
                      {t("조회된 공지가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {renderNoticeList &&
                renderNoticeList
                  .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                  .map((notice) => {
                    return (
                      <TableRow
                        key={notice.noticeId}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            Urls.noticeDetail.replace(
                              ":id",
                              String(notice.noticeId)
                            )
                          )
                        }
                      >
                        <TableCell align="center">
                          <CustomText type="body" color="red">
                            {t("공지")}
                          </CustomText>
                        </TableCell>
                        <TableCell>
                          <CustomTextTooltip type="body">
                            {notice.title}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {getDateStringFormat(
                              notice.createdAt,
                              "YYYY-MM-DD hh:mm"
                            )}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {notice.viewCount}
                          </CustomText>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
        {isAdmin && (
          <ButtonWrapper>
            <CustomButton
              colorType="inbodyRed"
              onClick={() => navigate(Urls.noticeCreate)}
            >
              <CustomText type="button">{t("글작성")}</CustomText>
            </CustomButton>
          </ButtonWrapper>
        )}
      </Grid>
      <Grid item xs={12}>
        {noticeList && (
          <PaginationWrapper id="pagination-wrapper">
            <Pagination
              color="standard"
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(noticeList.length / PageSize.BASIC)}
            />
          </PaginationWrapper>
        )}
      </Grid>
    </Grid>
  );
}
const SearchWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
`;
const KeyWordWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -20px;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
