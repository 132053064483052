import { Control, Controller } from 'react-hook-form';
import CrossEditor from './CrossEditor';

interface RHFTextEditorProps {
  name: string;
  control: Control<any, any>;
}
export default function RHFTextEditor({ name, control }: RHFTextEditorProps) {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <CrossEditor
          params={{
            Width: '100%',
            Height: 500,
          }}
          name={field.name}
          value={field.value}
          onLoaded={(_, editor) => {
            if (editor) {
              editor.SetValue(field.value);
              const bodyElement: HTMLBodyElement = editor.GetEditorDocument();
              bodyElement.oninput = () => {
                field.onChange(editor.GetBodyValue());
              }
            }
          }}
        />
      )}
    />
  );
}
