import { LectureState } from "../../stores/domains/usePostLectureStore";

export const isLectureValid = (lecture: LectureState) => {
  const isTitleValid = lecture.title.trim() !== "";
  const isFileValid = isLectureFileValid(lecture);

  return isTitleValid && isFileValid;
};

export const isLectureFileValid = (lecture: LectureState) => {
  const isFileValid =
    lecture.lectureId !== null ||
    lecture.lectureUrl !== null ||
    (lecture.lectureFile !== null && lecture.lectureFile.size > 0);

  return isFileValid;
};
