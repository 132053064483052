import { CourseTypeInterface } from "../../data/enums/CourseType";

export const isCourseValid = (
  courseName: string,
  description: string,
  courseType: CourseTypeInterface
) => {
  if (!courseName.trim()) {
    return false;
  }
  if (!description.trim()) {
    return false;
  }
  if (!courseType) {
    return false;
  }
  return true;
};
