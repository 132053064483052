import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import useFetchQnaDetail from "../../api/domains/qna/queries/useFetchQnaDetail";
import { CreateReply } from "../../api/domains/reply/ReplyType";
import useReplyAPIs from "../../api/domains/reply/useReplyAPIs";
import RHFTextEditor from "../../components/textEditor/RHFTextEditor";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { notEmptyHtmlContent } from "../../hooks/common-form-hooks/validate";
import Urls from "../../routes/Urls";
import useHandlePostRequest from "../../hooks/common-form-hooks/useHandlePostRequest";
import { QueryKeys } from "../../api/config/QueryKeys";
import ErrorText from "../../components/react-hook-form/ErrorText";

export default function ReplyCreatePage() {
  const qnaId = Number(useParams().id);
  const navigate = useNavigate();
  const { t } = useTranslation();

  /** react-query */
  const { data: qnaDetail } = useFetchQnaDetail(qnaId);

  const { createReplyAPI } = useReplyAPIs();
  const { handleSuccess, handleError } = useHandlePostRequest();
  const createReplyQuery = useMutation((request: CreateReply) =>
    createReplyAPI(request)
  );

  /** react-hook-form */
  const schema = yup
    .object({
      content: notEmptyHtmlContent(t("내용이 비어있습니다.")).required(
        t("내용이 비어있습니다.")
      ),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content: "",
    },
    resolver: yupResolver(schema),
  });

  /** submit */
  const createReplyHandle = (content: string) => {
    if (!qnaId) return;
    createReplyQuery.mutate(
      { qnaId, content },
      {
        onSuccess: () => {
          handleSuccess(
            t("답변이 등록되었습니다."),
            QueryKeys.qnaList(),
            Urls.qna
          );
        },
        onError: () =>
          handleError(t("문의사항 답변 등록 중 에러가 발생했습니다.")),
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(({ content }) => createReplyHandle(content))}>
      <Grid container rowSpacing={3} pt={0}>
        <Grid item xs={12}>
          <PageHeader title={t("문의사항 답변작성")} />
        </Grid>
        <Grid item xs={12}>
          <CustomTableWrapper tdPadding={"8px 16px"}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell align="center" component={"th"} width={"15%"}>
                    <CustomText type="subtitle">{t("작성자")}</CustomText>
                  </TableCell>
                  <TableCell width={"35%"}>
                    <CustomText type="body">
                      {qnaDetail?.creatorName}
                    </CustomText>
                  </TableCell>
                  <TableCell align="center" component={"th"} width={"15%"}>
                    <CustomText type="subtitle">{t("교육과정")}</CustomText>
                  </TableCell>
                  <TableCell width={"35%"}>
                    <CustomText type="body">{qnaDetail?.courseName}</CustomText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" component={"th"}>
                    <CustomText type="subtitle">{t("제목")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <CustomText type="body">{qnaDetail?.title}</CustomText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" component={"th"}>
                    <CustomText type="subtitle">{t("내용")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    {qnaDetail && (
                      <div
                        dangerouslySetInnerHTML={{ __html: qnaDetail.content }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" component={"th"}>
                    <CustomText type="subtitle">{t("답변작성")}</CustomText>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <RHFTextEditor name="content" control={control} />
                    <ErrorText message={errors.content?.message} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CustomTableWrapper>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <SubmitButton type="submit">{t("작성")}</SubmitButton>
            <ListButton onClick={() => navigate(Urls.qna)}>
              {t("목록")}
            </ListButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const SubmitButton = styled.button`
  min-width: 100px;
  height: 40px;
  padding: 7px 20px;
  border: 0;
  display: inline-block;
  vertical-align: top;
  color: #ffffff;
  font-size: 17px;
  text-align: center;
  background: #193b6a;
  font-weight: 500;
  cursor: pointer;
`;
const ListButton = styled(SubmitButton)`
  background: #ffffff;
  border: 1px solid black;
  color: black;
`;
