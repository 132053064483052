import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { useTranslation } from "react-i18next";
import { CustomText } from "../../../components/text/CustomText";
import usePostQuestionStore from "../../../stores/domains/usePostQuestionStore";
import { InbodyRed } from "../../../styles/GlobalStyle";
import useQuizHooks from "../../../hooks/domains/useQuizHooks";
import usePostQuizStore from "../../../stores/domains/usePostQuizStore";
import { useParams } from "react-router-dom";
import { PostQuizPageProps } from "../PostQuizPage";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

export default function PostQuizFloatingButton({
  isCreate = false,
}: PostQuizPageProps) {
  const { t } = useTranslation();
  const quizId = Number(useParams().quizId);

  const appendNewQuestion = usePostQuestionStore(
    (state) => state.appendNewQuestion
  );

  const { handleSubmitQuiz, navigatePostQuizListPage } = useQuizHooks();

  const { updatePostQuizValue, createNewQuestion } = usePostQuizStore(
    (state) => ({
      updatePostQuizValue: state.updateValue,
      createNewQuestion: state.createNewQuestion,
    })
  );

  return (
    <SpeedDial
      ariaLabel="speedDial"
      sx={{ position: "fixed", bottom: 50, right: 50 }}
      icon={<SpeedDialIcon />}
      FabProps={{ color: "primary" }}
      open={true}
    >
      <SpeedDialAction
        onClick={navigatePostQuizListPage}
        FabProps={{
          sx: {
            bgcolor: "primary.main",
            color: "white",
            "&:hover": {
              bgcolor: "primary.main",
            },
          },
        }}
        icon={<FormatListNumberedIcon />}
        tooltipTitle={<CustomText type="body">{t("목록으로")}</CustomText>}
      />
      <SpeedDialAction
        onClick={() => {
          updatePostQuizValue({ isSubmit: true });
          handleSubmitQuiz(isCreate, quizId);
        }}
        FabProps={{
          sx: {
            bgcolor: "success.main",
            color: "white",
            "&:hover": {
              bgcolor: "success.main",
            },
          },
          type: "submit",
        }}
        icon={<SaveIcon />}
        tooltipTitle={<CustomText type="body">{t("저장하기")}</CustomText>}
      />
      {createNewQuestion && (
        <SpeedDialAction
          FabProps={{
            sx: {
              bgcolor: InbodyRed,
              color: "white",
              "&:hover": {
                bgcolor: InbodyRed,
              },
            },
          }}
          icon={<AddCircleOutlineIcon />}
          onClick={appendNewQuestion}
          tooltipTitle={<CustomText type="body">{t("문제 추가")}</CustomText>}
        />
      )}
    </SpeedDial>
  );
}
