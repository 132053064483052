import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useFetchDocumentList from "../../api/domains/document/queries/useFetchDocumentList";
import TitleDiv from "../../components/atoms/TitleDiv";
import CustomButton from "../../components/button/CustomButton";
import CourseStepper from "../../components/stepper/CourseStepper";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import { CustomText } from "../../components/text/CustomText";
import usePageMove from "../../hooks/common/usePageMove";
import useDocumentHooks from "../../hooks/domains/useDocumentHooks";
import Urls from "../../routes/Urls";
import usePostDocumentStore from "../../stores/domains/usePostDocumentStore";
import PostDocumentItem from "./components/PostDocumentItem";

export default function PostDocumentPage() {
  const courseId = Number(useParams().id);
  const { t } = useTranslation();
  const { pageMove } = usePageMove();
  const navigate = useNavigate();

  const { data } = useFetchDocumentList(courseId);
  const documentListData = useMemo(() => data, [data]);

  const { isDirty, documentList, appendNewDocument } = usePostDocumentStore(
    (state) => ({
      isDirty: state.isDirty,
      documentList: state.documentList,
      appendNewDocument: state.appendNewDocument,
    })
  );

  /** hooks */
  const { handleSubmitDocument, useCreateDocumentStore } = useDocumentHooks();

  useCreateDocumentStore(documentListData);

  return (
    <Grid container rowSpacing={3} mt={0}>
      <Grid item xs={12}>
        <CourseStepper
          step={2}
          ableToClick={true}
          courseId={courseId}
          isDirty={isDirty}
        />
      </Grid>
      <Grid item xs={12}>
        <TitleDiv title="자료 관리" />
        <Grid container justifyContent="flex-end" pb={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => pageMove(courseId, "lecture", isDirty)}
          >
            <CustomText type="buttonLarge">{t("이전단계")}</CustomText>
          </Button>
          <Button
            variant="text"
            color="success"
            onClick={() => handleSubmitDocument(courseId)}
          >
            <CustomText type="buttonLarge">{t("저장하기")}</CustomText>
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate(Urls.courseManagement)}
          >
            <CustomText type="buttonLarge">{t("목록으로")}</CustomText>
          </Button>
        </Grid>
        <CustomTableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%" />
                <TableCell align="center" width="5%">
                  <CustomText type="subtitle">{t("번호")}</CustomText>
                </TableCell>
                <TableCell align="center" width="60%">
                  <CustomText type="subtitle">{t("강의 자료명")}</CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("파일명")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("삭제하기")}</CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <CustomText type="subtitle">
                      {t("등록된 자료가 없습니다. 자료를 추가해주세요.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {documentList.map((document, index, arr) => {
                return (
                  <PostDocumentItem
                    key={document.documentId}
                    document={document}
                    isLastItem={index === arr.length - 1}
                    documentIndex={index}
                  />
                );
              })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
        <Grid container justifyContent={"flex-end"}>
          <CustomButton
            margin="10px 0"
            colorType="inbodyRed"
            onClick={() => appendNewDocument()}
          >
            <CustomText type="button">{t("신규 자료 추가")}</CustomText>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
