import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchCourseDetail } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';

const useFetchCourseDetail = <T = FetchCourseDetail>(
    courseId: number,
    options?: UseQueryOptions<FetchCourseDetail, FetchCourseDetail, T>
) => {
    const { getCourseDetailAPI } = useCourseAPIs();

    return useQuery({
        queryKey: QueryKeys.courseDetail(courseId),
        queryFn: () => getCourseDetailAPI(courseId),
        enabled: !isNaN(courseId) && courseId > 0,
        ...options
    });
}

export default useFetchCourseDetail;