import styled from "@emotion/styled";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { List, ListItem, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FetchCourseList } from "../../../api/domains/course/CourseType";
import CustomCourseTag from "../../../components/tag/CustomCourseTag";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import { CourseTypeInterface } from "../../../data/enums/CourseType";
import getCourseUrlByCourseType from "../../../hooks/course/getCourseDetailUrlByCourseType";
import { BorderGray, BoxGray, TitlePale } from "../../../styles/GlobalStyle";
import useCourseNameHooks from "../../../hooks/common/useCourseNameHooks";

interface FreshmanCourseListItemProps {
  course: FetchCourseList;
  courseType: CourseTypeInterface;
}
export default function CourseTableItem({
  course,
  courseType,
}: FreshmanCourseListItemProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getCourseName } = useCourseNameHooks();

  return (
    <TableRow>
      {/* <TableCell width='18%'>
                <ImageWrapper>
                    <img
                        width={'220x'}
                        height={'132px'}
                        src={course.thumbnailPath}
                        alt={course.courseName} />
                </ImageWrapper>
            </TableCell> */}
      <TableCell>
        <List disablePadding>
          <ListItem sx={{ pt: 0 }}>
            <CustomCourseTag courseType={course.courseType}>
              <CustomText type="body" bold500>
                {getCourseName(course.courseType)}
              </CustomText>
            </CustomCourseTag>
          </ListItem>
          <ListItem>
            <CustomTextTooltip type="title">
              {course.courseName}
            </CustomTextTooltip>
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <CustomText type="body" bold500 color={TitlePale}>
              {t("강사명")} : {course.professorName}
            </CustomText>
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }}>
            <CustomText type="body" bold500 color={TitlePale}>
              {t("강의수")} : {t("총")} {course.lectureCount} {t("차시")}
            </CustomText>
          </ListItem>
        </List>
      </TableCell>
      <TableCell width="15%" sx={{ p: 0 }}>
        <CourseIconWrapper
          id="course-detail-icon"
          onClick={() =>
            navigate(
              getCourseUrlByCourseType(courseType, "detail").replace(
                ":id",
                String(course.courseId)
              )
            )
          }
        >
          <AssignmentOutlinedIcon fontSize="large" />
          <CustomText type="subtitle">{t("상세보기")}</CustomText>
        </CourseIconWrapper>
      </TableCell>
    </TableRow>
  );
}
const CourseIconWrapper = styled.div`
  height: 100%;
  border-left: 1px solid ${BorderGray};
  background-color: ${BoxGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & svg {
    font-size: 90px;
  }
`;
