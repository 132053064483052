import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ToastAlert from "../../components/alert/ToastAlert";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";

type UrlType = "HR" | "BE";

const getUrlByType = (type: UrlType) => {
  switch (type) {
    case "BE":
      return `${process.env.REACT_APP_API_URL}`;
    case "HR":
      return `${process.env.REACT_APP_HR_URL}`;
  }
};

// const getNewToken = (
//   instance: IPublicClientApplication,
//   accounts: AccountInfo[]
// ) => {
//   const getToken = async () => {
//     try {
//       let access_token;
//       // lookinbody.com 메일 있는 경우 inbody.com 계정 찾는 부분
//       const account = accounts.find((x) => x.username.includes("@inbody.com"));

//       // eslint-disable-next-line
//       if (access_token === undefined) {
//         const response = await instance.acquireTokenSilent({
//           ...loginRequest,
//           account: account,
//         });

//         access_token = response.accessToken;
//         window.sessionStorage.setItem("access_token", access_token);
//       }
//       return access_token;
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return getToken();
// };

const useGetApiInstance = (url: string = "", type: UrlType = "BE") => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const logOut = useUserStore((state) => state.logOut);

  const apiInstance = axios.create({
    baseURL: `${getUrlByType(type)}/api/${url}`,
    withCredentials: true,
  });

  apiInstance.interceptors.request.use(
    (request) => {
      if (!!sessionStorage.getItem("jwtToken") && request.headers) {
        request.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
          "jwtToken"
        )}`;
      }
      return request;
    },
    (err) => Promise.reject(err)
  );

  apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const originalRequest = error.config;
      // TODO 리프레시 토큰 나올때 다시...
      if (error.response.status === 401) {
        sessionStorage.clear();
        logOut();
        return null;
      }

      if (error.response && error.response.status === 403) {
        ToastAlert(t("접근 권한이 없습니다."), "error");
        navigate(Urls.main);
        return null;
      }
      // If the error is not due to an expired token, reject the promise
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export default useGetApiInstance;
