import { create } from "zustand";
import { ViewSizeOption } from "../../data/enums/ViewSizeOption";

interface selectionManagerStoreProps {
  selectedTagIdSet: Set<number>;
  toggleSelectedTagId: (tagId: number) => void;

  selectedFileIdSet: Set<number>;
  setSelectedFileIdSet: (newSelectedFileIdSet: Set<number>) => void;
  toggleSelectFileId: (selectedFileId: number) => void;

  selectedFolderIdSet: Set<number>;
  setSelectedFolderIdSet: (newSelectedFolderIdSet: Set<number>) => void;
  toggleSelectedFolderId: (selectedFolderId: number) => void;

  dragEnterFolderName: string;
  setDragEnterFolderName: (folderName: string) => void;

  viewSize: number;
  setViewSize: (viewSize: number) => void;

  resetFileAndFolderSelect: () => void;
  resetSelectInfo: () => void;
  resetStore: () => void;
}

const initialState = {
  selectedTagIdSet: new Set<number>(),

  selectedFileIdSet: new Set<number>(),
  selectedFolderIdSet: new Set<number>(),

  dragEnterFolderName: "",
  viewSize: ViewSizeOption.MEDIUM,
};

export const useSelectionManagerStore = create<selectionManagerStoreProps>(
  (set) => ({
    ...initialState,

    setSelectedFileIdSet: (selectedFileIdSet) => set({ selectedFileIdSet }),
    toggleSelectFileId: (selectedFileId) =>
      set((state) => {
        const newSet = new Set(state.selectedFileIdSet);

        if (state.selectedFileIdSet.has(selectedFileId)) {
          newSet.delete(selectedFileId);
          return { ...state, selectedFileIdSet: newSet };
        }

        newSet.add(selectedFileId);
        return { ...state, selectedFileIdSet: newSet };
      }),

    setSelectedFolderIdSet: (selectedFolderIdSet) =>
      set({ selectedFolderIdSet }),
    toggleSelectedFolderId: (selectedFolderId) =>
      set((state) => {
        const newSet = new Set(state.selectedTagIdSet);

        if (state.selectedFolderIdSet.has(selectedFolderId)) {
          newSet.delete(selectedFolderId);
          return { ...state, selectedFolderIdSet: newSet };
        }
        newSet.add(selectedFolderId);
        return { ...state, selectedFolderIdSet: newSet };
      }),

    toggleSelectedTagId: (tagId) =>
      set((state) => {
        const newSet = new Set(state.selectedTagIdSet);

        if (state.selectedTagIdSet.has(tagId)) {
          newSet.delete(tagId);
          return {
            ...state,
            selectedTagIdSet: newSet,
          };
        }

        newSet.add(tagId);
        return {
          ...state,
          selectedTagIdSet: newSet,
        };
      }),

    setDragEnterFolderName: (folderName) =>
      set({ dragEnterFolderName: folderName }),
    setViewSize: (viewSize) => set({ viewSize: viewSize }),

    resetFileAndFolderSelect: () =>
      set({
        selectedFileIdSet: new Set(),
        selectedFolderIdSet: new Set(),
      }),

    resetSelectInfo: () =>
      set({
        selectedFileIdSet: new Set(),
        selectedFolderIdSet: new Set(),
        selectedTagIdSet: new Set<number>(),
      }),

    resetStore: () => set(initialState),
  })
);
