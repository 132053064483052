import styled from "@emotion/styled";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchProgressCourseList from "../../../api/domains/course/queries/useFetchProgressCourseList";
import TitleDiv from "../../../components/atoms/TitleDiv";
import { TableLoading } from "../../../components/loading/TableLoading";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import getCourseUrlByCourseType from "../../../hooks/course/getCourseDetailUrlByCourseType";
import { BorderGray, BoxBlue } from "../../../styles/GlobalStyle";

export default function MyProgressCourse() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: progressCourseList, isLoading } = useFetchProgressCourseList();

  return (
    <Wrapper>
      <TitleDiv title={"학습중인 수업"}></TitleDiv>
      <CustomTableWrapper>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="10%">
                <CustomText type="subtitle">{t("번호")}</CustomText>
              </TableCell>
              <TableCell align="center" width="50%">
                <CustomText type="subtitle">{t("강의명")}</CustomText>
              </TableCell>
              <TableCell align="center" width="15%">
                <CustomText type="subtitle">{t("강사명")}</CustomText>
              </TableCell>
              <TableCell align="center" width="10%">
                <CustomText type="subtitle">{t("진도율")}</CustomText>
              </TableCell>
              <TableCell align="center" width="15%">
                <CustomText type="subtitle">{t("마지막 수강일")}</CustomText>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && TableLoading(5, 5)}
            {progressCourseList && progressCourseList.length === 0 && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CustomText type="subtitle">
                    {t("학습 중인 수업이 없습니다.")}
                  </CustomText>
                </TableCell>
              </TableRow>
            )}
            {progressCourseList &&
              progressCourseList.map((course, index, array) => {
                return (
                  <TableRow
                    key={course.courseId}
                    sx={{ cursor: "pointer", ":hover": { bgcolor: BoxBlue } }}
                    onClick={() =>
                      navigate(
                        getCourseUrlByCourseType(
                          course.courseType,
                          "detail"
                        ).replace(":id", String(course.courseId))
                      )
                    }
                  >
                    <TableCell align="center">
                      <CustomText type="body">{index + 1}</CustomText>
                    </TableCell>
                    <TableCell>
                      <CustomTextTooltip type="body">
                        {course.courseName}
                      </CustomTextTooltip>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">
                        {course.professorName}
                      </CustomText>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">
                        {`${course.completePercentage}%`}
                      </CustomText>
                    </TableCell>
                    <TableCell align="center">
                      <CustomText type="body">{course.updatedAt}</CustomText>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CustomTableWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 30px;
  border: 1px solid ${BorderGray};
`;
