import styled from "@emotion/styled";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchManagingQuizList from "../../../api/domains/quiz/queries/useFetchManagingQuizList";
import CustomButton from "../../../components/button/CustomButton";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import { PageSize } from "../../../data/enums/PageSize";
import useQuizHooks from "../../../hooks/domains/useQuizHooks";
import Urls from "../../../routes/Urls";
import { FlexCenter } from "../../../styles/GlobalStyle";
import { PaginationWrapper } from "../../course-list/CourseListPage";

export default function AdminQuizList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);

  const { data: managingQuizList } = useFetchManagingQuizList();

  const { deleteQuiz, changeQuizActive, changeExamQuizVersion } =
    useQuizHooks();

  return (
    <Grid container rowSpacing={2} mt={3}>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%">
                  <CustomText type="subtitle">{t("번호")}</CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle">{t("시험명")}</CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle">{t("시험 소개")}</CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle">{t("시험 문제 버전")}</CustomText>
                </TableCell>
                <TableCell align="center" width="5%">
                  <CustomText type="subtitle">{t("필수 응시")}</CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("시험 활성화")}</CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="subtitle">{t("시험편집")}</CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {managingQuizList && managingQuizList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    <CustomText type="subtitle">
                      {t("등록된 시험이 없습니다. 시험을 추가해주세요.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {managingQuizList &&
                managingQuizList
                  .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                  .map((quiz, index) => {
                    return (
                      <TableRow key={quiz.quizId}>
                        <TableCell align="center">
                          <CustomText type="body">{index + 1}</CustomText>
                        </TableCell>
                        <TableCell>
                          <CustomTextTooltip type="body">
                            {quiz.quizName}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell>
                          <CustomTextTooltip type="body">
                            {quiz.description}
                          </CustomTextTooltip>
                        </TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            <Select
                              id="quiz-version-select"
                              size="small"
                              value={quiz.examQuizVersionId}
                              onChange={(e) => {
                                changeExamQuizVersion(
                                  quiz.quizId,
                                  Number(e.target.value)
                                );
                              }}
                            >
                              {quiz.quizVersionList.map((quizVersion) => (
                                <MenuItem
                                  key={quizVersion.quizVersionId}
                                  value={quizVersion.quizVersionId}
                                >
                                  <CustomText
                                    fullWidth
                                    type="subtitle"
                                    align="center"
                                    bold400
                                  >
                                    {quizVersion.quizVersionName}
                                  </CustomText>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">
                          <CustomText type="body">
                            {quiz.isOptional ? t("선택") : t("필수")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            id={`quiz-active-switch-${quiz.quizId}`}
                            color="success"
                            checked={quiz.isActive}
                            onChange={() => changeQuizActive(quiz.quizId)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <ButtonListWrapper>
                            <Button
                              onClick={() =>
                                navigate(
                                  Urls.modifyQuiz.replace(
                                    ":quizId",
                                    String(quiz.quizId)
                                  )
                                )
                              }
                              variant="text"
                              color="warning"
                            >
                              <CustomText type="button">
                                {t("수정하기")}
                              </CustomText>
                            </Button>
                            <Divider
                              sx={{ height: 28, m: 0.5 }}
                              orientation="vertical"
                            />
                            <Button
                              onClick={() => deleteQuiz(quiz.quizId)}
                              variant="text"
                              color="error"
                            >
                              <CustomText type="button">
                                {t("삭제하기")}
                              </CustomText>
                            </Button>
                          </ButtonListWrapper>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
        <Grid container justifyContent="flex-end" pt={1}>
          <CustomButton
            colorType="inbodyRed"
            onClick={() => navigate(Urls.createQuiz)}
          >
            <CustomText type="button">{t("신규 시험 추가")}</CustomText>
          </CustomButton>
        </Grid>
        <Grid item xs={12}>
          <PaginationWrapper id="pagination-wrapper">
            {managingQuizList && (
              <Pagination
                color="standard"
                onChange={(e, page) => setPage(page)}
                count={Math.ceil(managingQuizList.length / PageSize.BASIC)}
              />
            )}
          </PaginationWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
}
const ButtonListWrapper = styled.div`
  ${FlexCenter}
`;
