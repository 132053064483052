import { create } from "zustand";
import {
  FolderAuthListDto,
  FolderDomain,
} from "../../api/domains/folder/FolderType";
import { TagListDto } from "../../api/domains/tag/TagType";
import { StoragePermissionType } from "../../data/enums/PermissionType";

type State = Pick<
  FolderDomain,
  | "koreanName"
  | "englishName"
  | "isSecretFolder"
  | "folderAuthList"
  | "tagList"
  | "parentFolderId"
> & {
  isSubmit: boolean;
  isDirty: boolean;
};

type Action = {
  createStore: (fetchedState: Partial<State>) => void;
  updateValue: (newState: Partial<State>) => void;

  updateFolderAuthUserList: (
    users: FolderAuthListDto[],
    permissionType: StoragePermissionType
  ) => void;
  appendFolderAuthUser: (user: FolderAuthListDto) => void;
  removeFolderAuthUser: (userId: number) => void;

  setTagList: (newTagList: TagListDto[]) => void;
  deleteTag: (deleteTagText: TagListDto) => void;

  resetFolderStore: () => void;
};

const initialState: State = {
  koreanName: "",
  englishName: "",
  isSecretFolder: false,
  folderAuthList: [],
  tagList: [],
  parentFolderId: null,
  isSubmit: false,
  isDirty: false,
};

export const useFolderStore = create<State & Action>((set) => ({
  ...initialState,

  createStore: (fetchedState) => {
    set({ ...initialState, ...fetchedState, isDirty: false });
  },

  updateValue: (newState) =>
    set((state) => {
      const updatedState = { ...state, ...newState };
      return { ...updatedState, isDirty: true };
    }),

  setTagList: (newTagList) =>
    set((state) => {
      const existTagTextSet = new Set<string>();
      const filteredNewTagList = newTagList.filter((tag) => {
        if (tag.id === 0) {
          const isAlreadyExist = existTagTextSet.has(tag.text);
          existTagTextSet.add(tag.text);
          return !isAlreadyExist;
        }
        existTagTextSet.add(tag.text);
        return true;
      });

      return {
        ...state,
        tagList: filteredNewTagList,
      };
    }),

  updateFolderAuthUserList: (userList, permissionType) =>
    set((state) => {
      const updateFolderAuthUserList = [
        ...userList,
        ...state.folderAuthList.filter(
          (authUser) => authUser.permissionType !== permissionType
        ),
      ];

      return {
        ...state,
        folderAuthList: updateFolderAuthUserList,
      };
    }),

  appendFolderAuthUser: (user) =>
    set((state) => {
      return {
        ...state,
        folderAuthList: [...state.folderAuthList, user],
      };
    }),

  removeFolderAuthUser: (userId) =>
    set((state) => {
      const newFolderAuthUserList = state.folderAuthList.filter(
        (authUser) => authUser.userId !== userId
      );

      return {
        ...state,
        folderAuthList: newFolderAuthUserList,
      };
    }),

  deleteTag: (deleteTagText) =>
    set((state) => {
      const newTagList = [
        ...state.tagList.filter((tagText) => tagText !== deleteTagText),
      ];
      return {
        ...state,
        tagList: newTagList,
      };
    }),

  resetFolderStore: () => set(initialState),
}));
