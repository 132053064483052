import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { QueryKeys } from "../../../config/QueryKeys";
import { FileSearchListDto } from "../FileType";
import useFileAPI from "../useFileAPI";

const useFetchSearchFileList = <T = FileSearchListDto[]>(
  searchWords: string,
  options?: UseQueryOptions<FileSearchListDto[], AxiosError, T>
) => {
  const { getSearchFileListAPI } = useFileAPI();

  return useQuery({
    queryKey: QueryKeys.fileSearchList(searchWords),
    queryFn: () => getSearchFileListAPI(searchWords),
    enabled: searchWords.trim().length > 0,
    ...options,
  });
};

export default useFetchSearchFileList;
