import { CourseType, CourseTypeInterface } from "../../data/enums/CourseType";
import Urls from "../../routes/Urls";

const getCourseUrlByCourseType = (
  courseType: CourseTypeInterface,
  urlType: "detail" | "list"
) => {
  switch (courseType) {
    case CourseType.신규입사자교육:
      if (urlType === "detail") {
        return Urls.freshmanCourseDetail;
      } else {
        return Urls.freshmanCourseList;
      }
    case CourseType.직무교육:
      if (urlType === "detail") {
        return Urls.jobCourseDetail;
      } else {
        return Urls.jobCourseList;
      }
    case CourseType.법정의무교육:
      if (urlType === "detail") {
        return Urls.dutyCourseDetail;
      } else {
        return Urls.dutyCourseList;
      }
    case CourseType.리더십교육:
      if (urlType === "detail") {
        return Urls.leadershipCourseDetail;
      } else {
        return Urls.leadershipCourseList;
      }
    case CourseType.ProductTraining:
      if (urlType === "detail") {
        return Urls.productCourseDetail;
      } else {
        return Urls.productCourseList;
      }
    case CourseType.ApplicationTraining:
      if (urlType === "detail") {
        return Urls.applicationCourseDetail;
      } else {
        return Urls.applicationCourseList;
      }
    case CourseType.ClinicalTraining:
      if (urlType === "detail") {
        return Urls.clinicalCourseDetail;
      } else {
        return Urls.clinicalCourseList;
      }
    case CourseType.Others:
      if (urlType === "detail") {
        return Urls.otherCourseDetail;
      } else {
        return Urls.otherCourseList;
      }
    default:
      return Urls.freshmanCourseDetail;
  }
};

export default getCourseUrlByCourseType;
