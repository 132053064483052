import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import useTagAPI from '../useTagAPI';
import { StaleTime } from '../../../config/StaleTime';

const useFetchAllTagTextList = <T = string[]>(
    options?:UseQueryOptions<string[], AxiosError, T>
) => {
    
    const { getAllTagTextListAPI } = useTagAPI();
    
    return useQuery({
        queryKey: QueryKeys.allTagTextList(),
        queryFn: () => getAllTagTextListAPI(),
        refetchOnWindowFocus: false,
        staleTime: StaleTime.FIVE,
        ...options
    });
}

export default useFetchAllTagTextList;