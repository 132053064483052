import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchDocumentList from "../../../api/domains/document/queries/useFetchDocumentList";
import TitleDiv from "../../../components/atoms/TitleDiv";
import CustomButton from "../../../components/button/CustomButton";
import { TableLoading } from "../../../components/loading/TableLoading";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import useDocumentHooks from "../../../hooks/domains/useDocumentHooks";

export default function DocumentList() {
  const courseId = Number(useParams().id);
  const { t } = useTranslation();
  const { data: documentList, isLoading } = useFetchDocumentList(courseId);

  const { downloadDocument } = useDocumentHooks();

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <TitleDiv title="자료" />
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="80%">
                  <CustomText type="subtitle" bold600>
                    {t("강의 자료명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle" bold600>
                    {t("다운로드")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 2)}
              {documentList && documentList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={2} sx={{ height: "35px" }}>
                    <CustomText type="subtitle" fontWeight={500}>
                      {t("조회된 자료가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {documentList &&
                documentList.map((document) => {
                  return (
                    <TableRow key={document.documentId}>
                      <TableCell align="center">
                        <CustomTextTooltip type="body" bold500>
                          {document.title}
                        </CustomTextTooltip>
                      </TableCell>
                      <TableCell align="center">
                        <CustomButton
                          colorType="inbodyRed"
                          onClick={() =>
                            downloadDocument(
                              document.documentId,
                              document.originFileName
                            )
                          }
                        >
                          <CustomText type="subtitle" fontWeight={500}>
                            {t("자료받기")}
                          </CustomText>
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
    </Grid>
  );
}
