import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoadingStore } from '../../stores/loading/useLoadingStore';
import { CustomText } from '../text/CustomText';

export default function LoadingSpinnerModal() {
  const { isLoadingOpen, loadingText } = useLoadingStore();

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: 9999,
          flexDirection: 'column'
        }}
        open={isLoadingOpen}>
        {
          loadingText &&
          <CustomText type={'subtitle'} mb={2}>
            {loadingText}
          </CustomText>
        }
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
