import { useQuery, UseQueryOptions } from 'react-query';
import { useUserStore } from '../../../../stores/user/useUserStore';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchProgressCourseList } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';

const useFetchProgressCourseList = <T = FetchProgressCourseList[]>(
    options?: UseQueryOptions<FetchProgressCourseList[], unknown, T>
) =>  {
    const { accountId } = useUserStore();
    const { getProgressCourseListAPI } = useCourseAPIs();

    return useQuery({
        queryKey: QueryKeys.progressCourseList(accountId),
        queryFn: () => getProgressCourseListAPI(),
        ...options
    });
}

export default useFetchProgressCourseList;