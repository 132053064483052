import useGetApiInstance from '../../config/BasicApi';
import { CreateNotice, FetchNoticeDetail, FetchNoticeList, UpdateNotice } from './NoticeType';

const useNoticeAPIs = () => {
    const api = useGetApiInstance('notice');

    // 공지사항 전체 조회
    const getNoticeListAPI = (): Promise<FetchNoticeList[]> =>
        api.get("").then(res => res.data.result);

    // 공지사항 상세 조회
    const getDetailNoticeAPI = (noticeId: number): Promise<FetchNoticeDetail> => api.get(`/${noticeId}`).then(res => res.data.result);

    // 공지사항 생성
    const createNoticeAPI = (createNotice: CreateNotice) => api.post('/create', createNotice);

    // 공지사항 수정
    const modifyNoticeAPI = (modifyNotice: UpdateNotice) => api.put('/modify', modifyNotice);

    // 공지사항 삭제
    const deleteNoticeAPI = (noticeId: number) => api.delete(`/${noticeId}`);
    
    return {
        getNoticeListAPI,
        getDetailNoticeAPI,
        createNoticeAPI,
        modifyNoticeAPI,
        deleteNoticeAPI,
    }

}

export default useNoticeAPIs;