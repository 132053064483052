import { create } from "zustand";

type State = {
  to: Set<string>;
  cc: Set<string>;
  bcc: Set<string>;
  subject: string;
  body: string;
  isSubmit: boolean;
};

export type SendType = "to" | "cc" | "bcc";

type Action = {
  toggleValue: (type: SendType, email: string) => void;

  setValue: (type: SendType, newEmailList: string[]) => void;
  addValue: (type: SendType, newEmailList: string[]) => void;
  resetValue: (type: SendType) => void;
  deleteValue: (type: SendType, email: string) => void;

  updateSubject: (newValue: string) => void;
  updateBody: (newValue: string) => void;

  setIsSubmit: (isSubmit: boolean) => void;
  resetStore: () => void;
};

const baseInitialState: State = {
  to: new Set(),
  cc: new Set(),
  bcc: new Set(),
  subject: "",
  body: "",
  isSubmit: false,
};

const useEmailTargetStore = create<State & Action>((set) => ({
  ...baseInitialState,

  toggleValue: (type, email) =>
    set((state) => {
      const newSet = new Set(state[type]);
      if (newSet.has(email)) {
        newSet.delete(email);
      } else {
        newSet.add(email);
      }
      return { ...state, [type]: newSet };
    }),

  setValue: (type, newEmailList) => set({ [type]: new Set(newEmailList) }),

  addValue: (type, newEmailList) =>
    set((state) => {
      const newSet = new Set(state[type]);
      newEmailList.forEach((email) => newSet.add(email));

      return { ...state, [type]: newSet };
    }),

  resetValue: (type) =>
    set((state) => {
      return { ...state, [type]: new Set() };
    }),

  deleteValue: (type, email) =>
    set((state) => {
      const newSet = new Set(state[type]);
      newSet.delete(email);

      return { ...state, [type]: newSet };
    }),

  updateSubject: (newValue) =>
    set((state) => ({
      ...state,
      subject: newValue,
    })),

  updateBody: (newValue) =>
    set((state) => ({
      ...state,
      body: newValue,
    })),

  setIsSubmit: (isSubmit) =>
    set((state) => ({
      ...state,
      isSubmit: isSubmit,
    })),

  resetStore: () => {
    set(baseInitialState);
  },
}));

export default useEmailTargetStore;
