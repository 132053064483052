import { useQuery, UseQueryOptions } from "react-query";
import { useUserStore } from "../../../../stores/user/useUserStore";
import { QueryKeys } from "../../../config/QueryKeys";
import { StaleTime } from "../../../config/StaleTime";
import { FetchMainPageCourseList } from "../CourseType";
import useCourseAPIs from "../useCourseAPIs";
import { useTranslation } from "react-i18next";

const useFetchMainPageCourseList = <T = FetchMainPageCourseList[]>(
  isNewCourseList: boolean,
  options?: UseQueryOptions<FetchMainPageCourseList[], unknown, T>
) => {
  const { i18n } = useTranslation();
  const { isLogin } = useUserStore();
  const { getMainPageCourseListAPI } = useCourseAPIs();

  return useQuery({
    queryKey: QueryKeys.mainPageCourseList(isNewCourseList, i18n.language),
    queryFn: () => getMainPageCourseListAPI(isNewCourseList, i18n.language),
    staleTime: StaleTime.HOUR,
    enabled: isLogin,
    ...options,
  });
};

export default useFetchMainPageCourseList;
