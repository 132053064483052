import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  CreateQuiz,
  FetchQuizDetail,
  ModifyQuiz,
} from "../../api/domains/quiz/QuizType";
import useQuizAPIs from "../../api/domains/quiz/useQuizAPIs";
import { CustomConfirmAlert } from "../../components/alert/CustomConfirmAlert";
import ToastAlert from "../../components/alert/ToastAlert";
import Urls from "../../routes/Urls";
import usePostQuizStore from "../../stores/domains/usePostQuizStore";
import { useUserStore } from "../../stores/user/useUserStore";
import { isQuizValid } from "../validation/isQuizValid";
import useQuestionHooks from "./useQuestionHooks";
import useQuizVersionHooks from "./useQuizVersionHooks";

export default function useQuizHooks() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {
    createQuizAPI,
    modifyQuizAPI,
    deleteQuizAPI,
    toggleQuizActiveAPI,
    changeExamQuizVersionAPI,
  } = useQuizAPIs();

  const {
    quizName,
    description,
    isOptional,
    isActive,
    quizOpenDate,
    quizCloseDate,
    quizManagerIdList,
    isDirty,
    examQuizVersionId,
    createNewQuestion,
    createPostQuizStore,
    resetPostQuizStore,
    setIsSubmit,
  } = usePostQuizStore((state) => ({
    quizName: state.quizName,
    description: state.description,
    isOptional: state.isOptional,
    isActive: state.isActive,
    quizOpenDate: state.quizOpenDate,
    quizCloseDate: state.quizCloseDate,
    quizManagerIdList: state.quizManagerIdList,
    isDirty: state.isDirty,
    examQuizVersionId: state.examQuizVersionId,
    createNewQuestion: state.createNewQuestion,
    createPostQuizStore: state.createStore,
    resetPostQuizStore: state.resetStore,
    setIsSubmit: state.setIsSubmit,
  }));

  const { userId } = useUserStore((state) => ({ userId: state.userId }));

  const { createQuizVersion, isQuizVersionValidCheck } = useQuizVersionHooks();
  const { isQuestionListValid } = useQuestionHooks();

  //#region @private 시험 생성
  const createQuizQuery = useMutation((request: CreateQuiz) =>
    createQuizAPI(request)
  );
  const createQuiz = () => {
    if (createQuizQuery.isLoading) {
      return;
    }

    const createQuizDto: CreateQuiz = {
      description: description,
      isOptional: isOptional,
      isActive: isActive,
      quizName: quizName,
      quizOpenDate: quizOpenDate ? dayjs(quizOpenDate).utc().toDate() : null,
      quizCloseDate: quizCloseDate ? dayjs(quizCloseDate).utc().toDate() : null,
      quizManagerIdList: quizManagerIdList,
    };

    createQuizQuery.mutate(createQuizDto, {
      onSuccess: (quizId) => {
        createQuizVersion(quizId);
        queryClient.invalidateQueries(QueryKeys.managingQuizList(userId));
        queryClient.invalidateQueries(QueryKeys.quizDetail(quizId));
      },
      onError: () => {
        ToastAlert(t("에러가 발생했습니다. 관리자에게 문의해주세요."), "error");
      },
    });
  };
  //#endRegion

  //#region @private 시험 수정
  const modifyQuizQuery = useMutation((request: ModifyQuiz) =>
    modifyQuizAPI(request)
  );
  const updateQuiz = (quizId: number) => {
    if (modifyQuizQuery.isLoading) {
      return;
    }

    const modifyRequest: ModifyQuiz = {
      quizId: quizId,
      description: description,
      isOptional: isOptional,
      isActive: isActive,
      quizName: quizName,
      quizOpenDate: quizOpenDate ? dayjs(quizOpenDate).utc().toDate() : null,
      quizCloseDate: quizCloseDate ? dayjs(quizCloseDate).utc().toDate() : null,
      quizManagerIdList: quizManagerIdList,
      examQuizVersionId: examQuizVersionId,
    };

    modifyQuizQuery.mutate(modifyRequest, {
      onSuccess: (quizId) => {
        queryClient.invalidateQueries(QueryKeys.managingQuizList(userId));
        queryClient.invalidateQueries(QueryKeys.quizDetail(quizId));
        if (createNewQuestion) {
          createQuizVersion(quizId);
        }
      },
      onError: () => {
        ToastAlert(t("에러가 발생했습니다. 관리자에게 문의해주세요."), "error");
      },
    });
  };
  //#endRegion

  //#region 시험 삭제
  const quizDeleteQuery = useMutation((quizId: number) =>
    deleteQuizAPI(quizId)
  );

  const deleteQuiz = (quizId: number) => {
    CustomConfirmAlert(
      t("정말로 삭제하시겠습니까?"),
      "warning",
      t("삭제하기"),
      t("취소하기")
    ).then((result) => {
      if (result.isConfirmed) {
        quizDeleteQuery.mutate(quizId, {
          onSuccess: () => {
            ToastAlert(t("시험이 삭제되었습니다."), "success");
            queryClient.invalidateQueries(QueryKeys.managingQuizList(userId));
          },
          onError: () => {
            ToastAlert(
              t("시험 삭제 도중 에러가 발생했습니다. 관리자에게 문의해주세요."),
              "error"
            );
          },
        });
      }
    });
  };
  //#endRegion

  //#region 시험 생성 / 수정
  const handleSubmitQuiz = (isCreate: boolean, quizId?: number) => {
    setIsSubmit(true);

    if (isCreate || createNewQuestion) {
      if (
        !isQuizValid(quizName, description) ||
        !isQuizVersionValidCheck() ||
        !isQuestionListValid()
      ) {
        return;
      }
    } else {
      if (!isQuizValid(quizName, description)) {
        return;
      }
    }

    if (isCreate) {
      createQuiz();
    } else if (quizId) {
      updateQuiz(quizId);
    }
  };
  //#endRegion

  //#region 데이터 초기 패칭
  const useCreateQuizStore = (quizDetail?: FetchQuizDetail) => {
    useEffect(() => {
      if (quizDetail && !isDirty) {
        createPostQuizStore(quizDetail);
      }
      return () => resetPostQuizStore();
    }, [quizDetail]);
  };
  //#endRegion

  //#region Quiz 목록 페이지로 이동
  const navigatePostQuizListPage = () => {
    if (isDirty) {
      CustomConfirmAlert(
        "변경사항이 저장되지 않습니다.\n 그래도 이동하시겠습니까?",
        "warning",
        t("목록으로"),
        t("취소하기")
      ).then((res) => {
        if (res.isConfirmed) {
          navigate(Urls.quizManagement);
        }
      });
      return;
    }
    navigate(Urls.quizManagement);
  };
  //#endRegion

  //#region 퀴즈 활성화 토글
  const changeQuizActiveMutation = useMutation((quizId: number) =>
    toggleQuizActiveAPI(quizId)
  );

  const changeQuizActive = (quizId: number) => {
    changeQuizActiveMutation.mutate(quizId, {
      onSuccess: (isActive) => {
        ToastAlert(
          isActive
            ? t("시험이 활성화 되었습니다.")
            : t("시험이 비활성화 되었습니다."),
          "success"
        );
        queryClient.invalidateQueries(QueryKeys.managingQuizList(userId));
      },
    });
  };
  //#endRegion

  //#region 시험 문제 버전 변경
  const changeExamQuizVersionMutation = useMutation(
    ({ quizId, quizVersionId }: { quizId: number; quizVersionId: number }) =>
      changeExamQuizVersionAPI(quizId, quizVersionId)
  );

  const changeExamQuizVersion = (quizId: number, quizVersionId: number) => {
    changeExamQuizVersionMutation.mutate(
      { quizId, quizVersionId },
      {
        onSuccess: () => {
          ToastAlert(t("시험 문제 버전이 변경되었습니다."), "success");
          queryClient.invalidateQueries(QueryKeys.managingQuizList(userId));
        },
      }
    );
  };
  //#endRegion

  return {
    deleteQuiz,
    handleSubmitQuiz,
    useCreateQuizStore,
    navigatePostQuizListPage,
    changeQuizActive,
    changeExamQuizVersion,
  };
}
