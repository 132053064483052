import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuestionWithAnswerList } from '../QuestionType';
import useQuestionAPIs from '../useQuestionAPIs';
import { StaleTime } from '../../../config/StaleTime';

const useFetchQuestionListAndAnswerList = <T = FetchQuestionWithAnswerList[]>(
    quizVersionId: number,
    options?: UseQueryOptions<FetchQuestionWithAnswerList[], AxiosError, T>
) => {
    const { getQuestionListAndAnswerAPI } = useQuestionAPIs();

    return useQuery({
        queryKey: QueryKeys.questionListAndAnswer(quizVersionId),
        queryFn: () => getQuestionListAndAnswerAPI(quizVersionId),
        enabled: !isNaN(quizVersionId) && quizVersionId > 0,
        staleTime: StaleTime.HOUR,
        ...options
    });
}

export default useFetchQuestionListAndAnswerList;