import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchUserList } from '../UserType';
import useUserAPIs from '../useUserAPIs';
import { StaleTime } from '../../../config/StaleTime';

const useFetchUserList = <T = FetchUserList[]>(
    options?: UseQueryOptions<FetchUserList[], AxiosError, T>
) => {
    const { getUserListAPI } = useUserAPIs();

    return useQuery({
        queryKey: QueryKeys.userList(),
        queryFn: () => getUserListAPI(),
        refetchOnWindowFocus: false,
        staleTime: StaleTime.HOUR,
        ...options
    });
}

export default useFetchUserList;