import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchDocumentList } from '../DocumentType';
import useDocumentAPIs from '../useDocumentAPIs';

const useFetchDocumentList = <T = FetchDocumentList[]>(
    courseId: number,
    options?: UseQueryOptions<FetchDocumentList[], AxiosError, T>
) => {
    const { getDocumentListAPI} = useDocumentAPIs();

    return useQuery({
        queryKey: QueryKeys.documentList(courseId),
        queryFn: () => getDocumentListAPI(courseId),
        enabled: !isNaN(courseId) && courseId > 0,
        refetchOnWindowFocus: false,
        ...options
    });
}

export default useFetchDocumentList;