import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchExamInfo } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';

const useFetchExamInfo = <T = FetchExamInfo>(
    quizVersionId: number,
    options?: UseQueryOptions<FetchExamInfo, AxiosError, T>
) => {

    const { getAttemptInfoAPI } = useQuizAPIs();

    return useQuery({
        queryKey: QueryKeys.quizFinishTime(quizVersionId),
        queryFn: () => getAttemptInfoAPI(quizVersionId),
        enabled: !isNaN(quizVersionId) && quizVersionId > 0,
        ...options
    });
}

export default useFetchExamInfo;