import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DotIcon({ ...props }: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
            </svg>
        </SvgIcon>
    )
}
