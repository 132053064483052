import Swal, { SweetAlertPosition } from 'sweetalert2'

const ToastAlert = (
    title: string,
    type: 'error' | 'info' | 'question' | 'success' | 'warning',
    position?: SweetAlertPosition ,
    timer?: number
) => {

    const alert = Swal.mixin({
        toast: true,
        position: position?? 'top',
        showConfirmButton: false,
        timer: timer?? 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    return alert.fire({icon: type, title: title})
}
export default ToastAlert;