enum Urls {
  //로그인
  login = "/login",
  //회원가입
  signUp = "/signup",
  //아이디,비밀번호 찾기
  accountHelp = "/account-help",

  //개인정보 수집 동의
  privacyAgreement = "privacy-agreement",
  //메인페이지
  main = "/",
  recommendBookDetail = "/recommend-book-detail/:id",

  /**
   * 학습창고
   */
  course = "/course/",
  // 1.신입사원 강의
  freshmanCourseList = "/course/freshman",
  freshmanCourseDetail = "/course/freshman/:id",
  // 2.직무 교육
  jobCourseList = "/course/job",
  jobCourseDetail = "/course/job/:id",
  // 3.법정의무교육
  dutyCourseList = "/course/duty",
  dutyCourseDetail = "/course/duty/:id",
  // 4.리더십교육
  leadershipCourseList = "/course/leadership",
  leadershipCourseDetail = "/course/leadership/:id",

  // 5.Product training
  productCourseList = "/course/product",
  productCourseDetail = "/course/product/:id",
  // 6.Applications training
  applicationCourseList = "/course/applications",
  applicationCourseDetail = "/course/application/:id",
  // 7.Clinical training
  clinicalCourseList = "/course/clinical",
  clinicalCourseDetail = "/course/clinical/:id",
  // 8.Others
  otherCourseList = "/course/others",
  otherCourseDetail = "/course/others/:id",

  // 학습 영상
  playLecture = "/play/lecture/:id",

  // 시험창구
  quiz = "/quiz",
  quizList = "/quiz/list",
  quizScore = "/quiz/score",

  // 자료창고
  archive = "/archive",

  // 소통창구
  communication = "communication",
  // 공지사항
  notice = "/communication/notice",
  noticeDetail = "/communication/notice/:id",
  noticeCreate = "/communication/notice/create",
  noticeModify = "/communication/notice/modify/:id",

  // 문의사항
  qna = "/communication/qna",
  qnaCreate = "/communication/qna/create",
  // 답변 달기
  replyCreate = "/communication/reply/:id",

  // 내 강의실
  myPage = "/mypage",

  // 수료증
  certification = "/certification/:id",

  // 사이트맵
  siteMap = "/site-map/",
  inbodyHomepage = "/site-map/inbody-home",

  // 관리페이지
  admin = "/admin",

  //강의 관리 페이지
  courseManagement = "/admin/course-management",
  //(1)강의 관리
  courseCreate = "/admin/course-management/create",
  courseModify = "/admin/course-management/modify/:id",
  //(2)영상 관리
  postLecture = "/admin/course-management/lecture/post/:id",
  //(3)자료 관리
  postDocument = "/admin/course-management/document/post/:id",

  studentManagement = "/admin/student-management",
  mailManagement = "/admin/mail-management",

  // 시험 관리 페이지
  quizManagement = "/admin/quiz-management",
  createQuiz = "/admin/quiz-management/quiz/create/",
  modifyQuiz = "/admin/quiz-management/quiz/modify/:quizId",
  postQuestion = "/admin/quiz-management/question/post/:id",

  //자료 관리 페이지
  fileManagement = "/admin/file-management",

  // 시험 응시 페이지
  exam = "/exam/:id",
}

export default Urls;
