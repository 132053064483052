import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  CreateCourse,
  FetchCourseDetail,
  UpdateCourse,
} from "../../api/domains/course/CourseType";
import useCourseAPIs from "../../api/domains/course/useCourseAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import { CourseType } from "../../data/enums/CourseType";
import Urls from "../../routes/Urls";
import usePostCourseStore from "../../stores/domains/usePostCourseStore";
import { useUserStore } from "../../stores/user/useUserStore";
import { isCourseValid } from "../validation/isCourseValid";

const useCourseHooks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { createCourseAPI, modifyCourseAPI, deleteCourseAPI } = useCourseAPIs();

  //#region store
  const accountId = useUserStore((state) => state.accountId);

  const {
    courseName,
    description,
    professorId,
    courseOpenDate,
    courseCloseDate,
    courseType,
    isDirty,
    createStore,
    resetStore,
  } = usePostCourseStore((state) => ({
    courseName: state.courseName,
    description: state.description,
    professorId: state.professorId,
    courseOpenDate: state.courseOpenDate,
    courseCloseDate: state.courseCloseDate,
    courseType: state.courseType,
    isDirty: state.isDirty,
    createStore: state.createStore,
    resetStore: state.resetStore,
  }));

  //#endregion

  //#region 강의 생성
  const createCourseQuery = useMutation((request: CreateCourse) =>
    createCourseAPI(request)
  );
  const createCourse = () => {
    if (
      createCourseQuery.isLoading ||
      !isCourseValid(courseName, description, courseType)
    ) {
      return;
    }

    const createDto: CreateCourse = {
      courseName: courseName,
      courseType: courseType,
      description: description,
      professorId: professorId !== 0 ? professorId : null,
      courseOpenDate: courseOpenDate
        ? dayjs(courseOpenDate).utc().toDate()
        : null,
      courseCloseDate: courseCloseDate
        ? dayjs(courseCloseDate).utc().toDate()
        : null,
    };

    createCourseQuery.mutate(createDto, {
      onSuccess: (courseId: number) => {
        ToastAlert(t("강의가 등록되었습니다."), "success");
        queryClient.invalidateQueries(QueryKeys.managingCourseList(accountId));
        navigate(Urls.postLecture.replace(":id", String(courseId)));
      },
      onError: () => {
        ToastAlert(
          t("강의 등록 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."),
          "error"
        );
      },
      onSettled: () => {
        resetStore();
      },
    });
  };
  //#endregion

  //#region 강의 수정
  const modifyCourseQuery = useMutation((request: UpdateCourse) =>
    modifyCourseAPI(request)
  );
  const updateCourse = (courseId: number) => {
    if (
      modifyCourseQuery.isLoading ||
      courseId === 0 ||
      !isCourseValid(courseName, description, courseType)
    ) {
      return;
    }

    const updateDto: UpdateCourse = {
      courseId: courseId,
      courseName: courseName,
      courseType: courseType,
      description: description,
      professorId: professorId !== 0 ? professorId : null,
      courseOpenDate: courseOpenDate
        ? dayjs(courseOpenDate).utc().toDate()
        : null,
      courseCloseDate: courseCloseDate
        ? dayjs(courseCloseDate).utc().toDate()
        : null,
    };

    modifyCourseQuery.mutate(updateDto, {
      onSuccess: () => {
        ToastAlert(t("강의가 수정되었습니다."), "success");
        queryClient.invalidateQueries(QueryKeys.managingCourseList(accountId));
        queryClient.invalidateQueries(QueryKeys.courseDetail(courseId));
        navigate(Urls.postLecture.replace(":id", String(courseId)));
      },
      onError: () => {
        ToastAlert(
          t("강의 등록 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."),
          "error"
        );
      },
      onSettled: () => {
        resetStore();
      },
    });
  };
  //#endregion

  //#region 강의 삭제
  const courseDeleteQuery = useMutation((courseId: number) =>
    deleteCourseAPI(courseId)
  );
  const deleteCourse = (courseId: number) => {
    courseDeleteQuery.mutate(courseId, {
      onSuccess: () => {
        ToastAlert(t("강의가 삭제 되었습니다."), "success");
        queryClient.invalidateQueries(QueryKeys.managingCourseList(accountId));
      },
      onError: () => {
        ToastAlert(
          t("강의 삭제 중 에러가 발생했습니다. 인프라개발파트에 문의해주세요."),
          "error"
        );
      },
    });
  };
  //#endregion

  //#region 강의 생성, 수정 요청 제출
  const handleSubmitCourse = (isCreate: boolean, courseId: number) => {
    if (isCreate) {
      createCourse();
    } else {
      updateCourse(courseId);
    }
  };
  //#endregion

  //#region 법정의무교육이 아닐시 courseDate 초기화
  const useClearCourseDateIfNotDutyCourse = () => {
    const { courseType, updateValue } = usePostCourseStore();

    useEffect(() => {
      if (
        (courseCloseDate !== null || courseOpenDate !== null) &&
        courseType !== CourseType.법정의무교육
      ) {
        updateValue({ courseCloseDate: null, courseOpenDate: null });
      }
    }, [courseType, updateValue]);
  };
  //#endregion

  //#region 데이터 초기 패칭
  const useCreateCourseStore = (courseDetail?: FetchCourseDetail) => {
    useEffect(() => {
      if (courseDetail && !isDirty) {
        createStore(courseDetail);
      }
      return () => resetStore();
    }, [courseDetail]);
  };
  //#endregion

  return {
    createCourse,
    updateCourse,
    deleteCourse,
    handleSubmitCourse,

    useClearCourseDateIfNotDutyCourse,
    useCreateCourseStore,
  };
};

export default useCourseHooks;
