import useGetApiInstance from '../../config/BasicApi';
import { CreateQuestionList, FetchQuestionList, FetchQuestionWithAnswerList } from './QuestionType';


export const useQuestionAPIs = () => {
  const api = useGetApiInstance('question');

  // 강사용 문제 및 답변 조회
  const getQuestionListAndAnswerAPI = (quizVersionId: number): Promise<FetchQuestionWithAnswerList[]> =>
    api.get(`question-with-answer/${quizVersionId}`).then(res => res.data.result);
  
  // 시험 문제 조회
  const getQuestionListForExamAPI = (quizVersionId: number): Promise<FetchQuestionList[]> =>
    api.get(`exam/${quizVersionId}`).then(res => res.data.result);
      
  // 시험 문제 생성
  const createQuestionListAPI = (request: CreateQuestionList) =>
    api.post('create', request).then(res => res.data.result);
  
  return {
    getQuestionListAndAnswerAPI,
    getQuestionListForExamAPI,
    createQuestionListAPI
  }
}

export default useQuestionAPIs;