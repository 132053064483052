import styled from "@emotion/styled";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchUserList from "../../api/domains/user/queries/useFetchUserList";
import InbodyTreeModal from "../../components/modal/InbodyTreeModal";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import CrossEditor from "../../components/textEditor/CrossEditor";
import useEmailHooks from "../../hooks/domains/useEmailHooks";
import useEmailTargetStore, {
  SendType,
} from "../../stores/domains/useEmailTargetStore";
import { BorderGray } from "../../styles/GlobalStyle";
import MailAutocomplete from "./components/MailAutocomplete";

export default function AdminEmailPage() {
  const { t } = useTranslation();
  const [isTreeModalOpen, setIsTreeModalOpen] = useState(false);
  const [treeModalType, setTreeModalType] = useState<SendType>("to");

  const editorRef = useRef<CrossEditor>(null);

  const {
    to,
    cc,
    bcc,
    subject,
    body,
    isSubmit,
    updateBody,
    updateSubject,
    resetStore,
  } = useEmailTargetStore((state) => ({
    to: state.to,
    cc: state.cc,
    bcc: state.bcc,
    subject: state.subject,
    body: state.body,
    isSubmit: state.isSubmit,
    updateBody: state.updateBody,
    updateSubject: state.updateSubject,
    resetStore: state.resetStore,
  }));

  /** react-query */
  const { data: userList } = useFetchUserList();

  const { sendEmail } = useEmailHooks();

  useEffect(() => {
    return () => resetStore();
  }, []);

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title="메일발송" />
      </Grid>
      <Grid item xs={12}>
        <ButtonListWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => sendEmail(editorRef)}
          >
            <CustomText type="button">{t("보내기")}</CustomText>
          </Button>
        </ButtonListWrapper>
        <TableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="10%" component="th">
                  <HeadTextWrapper>
                    <CustomText type="subtitle" align="center">
                      {t("받는 사람")}
                    </CustomText>
                  </HeadTextWrapper>
                </TableCell>
                <TableCell width="90%">
                  <Box display={"flex"}>
                    {userList && (
                      <MailAutocomplete
                        options={userList}
                        sendType={"to"}
                        selectedValue={to}
                      />
                    )}
                    <IconButton
                      sx={{
                        width: 45,
                        height: 45,
                        borderRadius: 0,
                        border: `1px solid ${BorderGray}`,
                        margin: "auto",
                      }}
                      onClick={() => {
                        setTreeModalType("to");
                        setIsTreeModalOpen(true);
                      }}
                    >
                      <AccountTreeOutlinedIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <HeadTextWrapper>
                    <CustomText type="subtitle" align="center">
                      {t("참조")}
                    </CustomText>
                  </HeadTextWrapper>
                </TableCell>
                <TableCell width="80%">
                  <Box display={"flex"}>
                    {userList && (
                      <MailAutocomplete
                        options={userList}
                        sendType={"cc"}
                        selectedValue={cc}
                      />
                    )}
                    <IconButton
                      sx={{
                        width: 45,
                        height: 45,
                        borderRadius: 0,
                        border: `1px solid ${BorderGray}`,
                        margin: "auto",
                      }}
                      onClick={() => {
                        setTreeModalType("cc");
                        setIsTreeModalOpen(true);
                      }}
                    >
                      <AccountTreeOutlinedIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <HeadTextWrapper>
                    <CustomText type="subtitle" align="center">
                      {t("숨은 참조")}
                    </CustomText>
                  </HeadTextWrapper>
                </TableCell>
                <TableCell className="not-noto-sans">
                  <Box display={"flex"}>
                    {userList && (
                      <MailAutocomplete
                        options={userList}
                        sendType={"bcc"}
                        selectedValue={bcc}
                      />
                    )}
                    <IconButton
                      sx={{
                        width: 45,
                        height: 45,
                        borderRadius: 0,
                        border: `1px solid ${BorderGray}`,
                        margin: "auto",
                      }}
                      onClick={() => {
                        setTreeModalType("bcc");
                        setIsTreeModalOpen(true);
                      }}
                    >
                      <AccountTreeOutlinedIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <HeadTextWrapper>
                    <CustomText type="subtitle" align="center">
                      {t("제목")}
                    </CustomText>
                  </HeadTextWrapper>
                </TableCell>
                <TableCell>
                  <TextField
                    id="email-subject"
                    fullWidth
                    value={subject}
                    error={isSubmit && !subject.trim()}
                    helperText={
                      isSubmit && !subject.trim() ? t("필수 입력값입니다.") : ""
                    }
                    onChange={(e) => updateSubject(e.target.value)}
                    label={t("제목을 입력해주세요.")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th">
                  <HeadTextWrapper>
                    <CustomText type="subtitle" align="center">
                      {t("내용")}
                    </CustomText>
                  </HeadTextWrapper>
                </TableCell>
                <TableCell>
                  <CrossEditor
                    params={{ Width: "100%", Height: 500 }}
                    ref={editorRef}
                    name={"email-body"}
                    value={body}
                    onLoaded={(_, editor) => {
                      if (editor) {
                        editor.SetValue(body);
                        const bodyElement: HTMLBodyElement =
                          editor.GetEditorDocument();
                        bodyElement.oninput = () => {
                          updateBody(editor.GetBodyValue());
                        };
                      }
                    }}
                  />
                  {isSubmit && !body.trim() && (
                    <CustomText type="small" color={"error"}>
                      {t("필수 입력값입니다.")}
                    </CustomText>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      {isTreeModalOpen && (
        <InbodyTreeModal
          isOpen={isTreeModalOpen}
          setIsOpen={setIsTreeModalOpen}
          modalType={treeModalType}
        />
      )}
    </Grid>
  );
}
const ButtonListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  & button {
    margin-left: 5px;
  }
`;
const TableWrapper = styled.div`
  border: 1px solid ${BorderGray};
  border-radius: 5px;
  & th {
    border-right: 1px solid ${BorderGray};
  }
  & th,
  td {
    padding: 6px 10px;
  }
`;
const HeadTextWrapper = styled.div`
  border: 1px solid ${BorderGray};
  padding: 5px 0;
  border-radius: 3px;
`;
