import styled from '@emotion/styled';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomText } from '../text/CustomText';
import Urls from '../../routes/Urls';
import { useNavigate } from 'react-router-dom';
import { CustomConfirmAlert } from '../alert/CustomConfirmAlert';

interface CourseStepperProps {
  step: number;
  ableToClick: boolean;
  isDirty: boolean;
  courseId: number;
}

export default function CourseStepper({ step, ableToClick, isDirty, courseId }: CourseStepperProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const steps = [
    t('강의 정보 관리'),
    t('강의 영상 관리'),
    t('강의 자료 관리')
  ];

  const urls = [
    Urls.courseModify,
    Urls.postLecture,
    Urls.postDocument,
  ]

  const handleMove = (index: number, isDirty: boolean) => {

    if (isDirty) {
      CustomConfirmAlert(
        t('변경사항이 저장되지 않습니다. 그래도 이동 하시겠습니까?'),
        'warning',
        t('이동'),
        t('취소'),
      ).then((result) => {
        if (result.isConfirmed) {
          navigate(urls[index].replace(':id', String(courseId)));
        }
      })
    } else {
      navigate(urls[index].replace(':id', String(courseId)));
    }
  }

  return (
    <CustomStepperWrapper>
      <Stepper activeStep={step}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: ReactNode; } = {};
          if (index === 3) {
            labelProps.optional = (
              <CustomText type='body' align='center'>
                {t('선택사항')}
              </CustomText>
            );
          }
          return (
            <Step key={label} {...stepProps} >
              <StepLabel
                {...labelProps}
                sx={{ cursor: ableToClick ? 'pointer !important' : null }}
                onClick={() => { ableToClick ? handleMove(index, isDirty) : void (0) }}>
                <CustomText type='subtitle' align='center'>
                  {label}
                </CustomText>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </CustomStepperWrapper>
  );
}

const CustomStepperWrapper = styled.div`
    padding: 50px 20px 50px 20px;
`