import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItem,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchCourseDetail from "../../../api/domains/course/queries/useFetchCourseDetail";
import TitleDiv from "../../../components/atoms/TitleDiv";
import CustomCourseTag from "../../../components/tag/CustomCourseTag";
import { CustomText } from "../../../components/text/CustomText";
import { BorderGray, BoxPale, TitlePale } from "../../../styles/GlobalStyle";

export default function CourseInfo() {
  const courseId = Number(useParams().id);
  const { t } = useTranslation();

  const { data: courseDetail, isLoading } = useFetchCourseDetail(courseId);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <TitleDiv title="소개" />
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          p={3}
          pl={8}
          sx={{
            border: `1px solid ${BorderGray}`,
            backgroundColor: BoxPale,
          }}
          container
          direction="row"
        >
          {courseDetail && (
            <List disablePadding>
              <ListItem>
                <CustomCourseTag courseType={courseDetail.courseType}>
                  <CustomText type="body">{courseDetail.courseType}</CustomText>
                </CustomCourseTag>
              </ListItem>
              <ListItem sx={{ pt: 2, pb: 2 }}>
                <CustomText type="title" overflowTooltip>
                  {courseDetail.courseName}
                </CustomText>
              </ListItem>
              <ListItem>
                <CustomText type="body" color={TitlePale}>
                  {t("총")} {courseDetail.lectureCount}
                  {t("차시")}
                </CustomText>
              </ListItem>
            </List>
          )}
          {isLoading && (
            <List disablePadding>
              <ListItem>
                <Skeleton variant="rectangular" width={100} height={30} />
              </ListItem>
              <ListItem sx={{ pt: 2, pb: 2 }}>
                <Skeleton variant="rectangular" width={500} height={30} />
              </ListItem>
              <ListItem>
                <Skeleton variant="rectangular" width={80} height={30} />
              </ListItem>
            </List>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CourseMid>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="15%" component={"th"} align="center">
                  <CustomText type="subtitle">{t("강사명")}</CustomText>
                </TableCell>
                <TableCell width="85%">
                  <CustomText type="subtitle">
                    {courseDetail?.professorName} {t("강사님")}
                  </CustomText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component={"th"} align="center">
                  <CustomText type="subtitle">{t("강의소개")}</CustomText>
                </TableCell>
                <TableCell>
                  <CustomText type="subtitle" wordBreak>
                    {courseDetail?.description}
                  </CustomText>
                </TableCell>
              </TableRow>
              {(courseDetail?.courseOpenDate ||
                courseDetail?.courseCloseDate) && (
                <TableRow>
                  <TableCell component={"th"} align="center">
                    <CustomText type="subtitle">{t("강의 기간")}</CustomText>
                  </TableCell>
                  <TableCell>
                    <CustomText type="subtitle">
                      {`${
                        courseDetail.courseOpenDate?.toString().split("T")[0] ??
                        ""
                      } ~ ${
                        courseDetail.courseCloseDate
                          ?.toString()
                          .split("T")[0] ?? ""
                      }`}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CourseMid>
      </Grid>
    </Grid>
  );
}
/* 소개 */
const CourseMid = styled.div`
  padding: 20px;
  padding-bottom: 40px;
  border: 1px solid #cccccc;
  border-top: 0;
  & td,
  th {
    border-bottom: 1px dashed ${BorderGray};
  }
`;
