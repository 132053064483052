import styled from "@emotion/styled";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LanguageIcon from "@mui/icons-material/Language";
import { Button, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../../components/text/CustomText";
import Urls from "../../routes/Urls";
import { useUserStore } from "../../stores/user/useUserStore";
import { InbodyRed } from "../../styles/GlobalStyle";
import { Language } from "../../data/common/Language";

export default function HeaderIconList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const logOut = useUserStore((state) => state.logOut);

  const changeLocaleButtonHandle = () => {
    if (i18n.language === Language.KOREAN) {
      i18n.changeLanguage(Language.ENGLISH);
    } else {
      i18n.changeLanguage(Language.KOREAN);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Local을 지우면 insatance도 없어져서 로그아웃 안됨
  const logout = () => {
    logOut();
    window.sessionStorage.clear();
    // instance.logout();
  };

  // const changeAccount = () => {
  //   logOut();
  //   window.localStorage.clear();
  //   window.location.reload();
  // }

  return (
    <HeaderIconWrapper>
      <IconWrapper mobile>
        <IconWrapper>
          <IconButton size="large" onClick={changeLocaleButtonHandle}>
            <LanguageIcon fontSize="large" sx={{ color: "black" }} />
          </IconButton>
          <IconButton size="large" onClick={handleClick}>
            <AccountBoxIcon fontSize="large" sx={{ color: "black" }} />
          </IconButton>
        </IconWrapper>
      </IconWrapper>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 150,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Button
            fullWidth
            variant="text"
            color="inherit"
            onClick={() => navigate(Urls.myPage)}
          >
            <CustomText type="subtitle" color={InbodyRed} bold600>
              {t("내 강의실")}
            </CustomText>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            variant="text"
            color="inherit"
            onClick={() => navigate("#")}
          >
            <CustomText type="subtitle" bold600>
              {t("정보 수정")}
            </CustomText>
          </Button>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={handleClose}>
            <Button
                fullWidth
                variant='text'
                color='warning'
                onClick={changeAccount}>
                <CustomText type='button'>
                    {t('계정변경')}
                </CustomText>
            </Button>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <Button fullWidth variant="text" onClick={() => logout()}>
            <CustomText type="button">{t("로그아웃")}</CustomText>
          </Button>
        </MenuItem>
      </Menu>
    </HeaderIconWrapper>
  );
}

const HeaderIconWrapper = styled.div`
  position: relative;
`;
const IconWrapper = styled.div<{ mobile?: boolean }>`
  margin-right: ${(props) => (props.mobile ? null : "20px")};
  display: flex;
  align-items: center;
  & svg,
  img {
    vertical-align: middle;
  }
`;
