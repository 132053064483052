import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomText } from "../../components/text/CustomText";
import useSignUpHooks from "../../hooks/domains/useSignUpHooks";
import { BorderGray, ContentBlue, InbodyRed } from "../../styles/GlobalStyle";
import { useState } from "react";
import Urls from "../../routes/Urls";
import Footer from "../../layouts/Footer";

export default function SignUpPage() {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    accountId,
    userName,
    password,
    confirmPassword,
    email,
    accountIdError,
    passwordError,
    confirmPasswordError,
    emailError,
    allAgree,
    privacyAgreement,

    isAccountIdAvailable,
    isEmailAvailable,

    handleAccountIdChange,
    handleUserNameChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleEmailChange,

    handlePrivacyAgreementChange,
    handleAllAgreeChange,

    handleAccountIdBlur,
    handleEmailBlur,

    handleSubmit,
  } = useSignUpHooks();

  return (
    <Box
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}
      width={700}
      margin={"0 auto"}
      pt={"10vh"}
    >
      <Box id="title-wrapper" mb={6}>
        <CustomText type="bigTitle" color={InbodyRed} bold600 align="center">
          {t("Knowledge Warehouse")}
        </CustomText>
      </Box>
      <Grid
        container
        sx={{ border: `1px solid ${BorderGray}` }}
        p={"48px 80px"}
      >
        <Grid item id="title" xs={12} mb={4}>
          <CustomText type="bigTitle" align="center">
            {t("회원가입")}
          </CustomText>
        </Grid>
        <Grid item id="account-id" xs={12} mb={accountIdError ? 1 : 3}>
          <CustomText type="body">
            {t("아이디")}
            <EssentialMark />
          </CustomText>
          <TextField
            id="id"
            sx={{ mb: 1 }}
            fullWidth
            value={accountId}
            onBlur={handleAccountIdBlur}
            onChange={handleAccountIdChange}
            placeholder={t("아이디를 입력해주세요.")}
          />
          {accountIdError && (
            <CustomText
              type="small"
              color={isAccountIdAvailable ? "green" : "red"}
            >
              {accountIdError}
            </CustomText>
          )}
        </Grid>
        <Grid item id="user-name" xs={12} mb={3}>
          <CustomText type="body">
            {t("성명")}
            <EssentialMark />
          </CustomText>
          <TextField
            id="user-name"
            sx={{ mb: 1 }}
            fullWidth
            value={userName}
            onChange={handleUserNameChange}
            placeholder={t("성명을 입력해주세요.")}
          />
        </Grid>
        <Grid item id="pwd" xs={12} mb={confirmPasswordError ? 1 : 3}>
          <CustomText type="body">
            {t("비밀번호")}
            <EssentialMark />
          </CustomText>
          <Box mb={1}>
            <OutlinedInput
              id="pwd"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((pre) => !pre)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    sx={{ mr: "-8px" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={t("비밀번호를 입력해주세요.")}
            />
            {passwordError && (
              <CustomText type="small" color={"red"}>
                {passwordError}
              </CustomText>
            )}
          </Box>
          <Box>
            <OutlinedInput
              id="confirm-pwd"
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword((pre) => !pre)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    sx={{ mr: "-8px" }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder={t("비밀번호 확인")}
            />
            {confirmPasswordError && (
              <CustomText type="small" color={"red"}>
                {confirmPasswordError}
              </CustomText>
            )}
          </Box>
        </Grid>
        <Grid item id="email" xs={12} mb={emailError ? 1 : 3}>
          <CustomText type="body">
            {t("이메일")}
            <EssentialMark />
          </CustomText>
          <TextField
            id="email"
            type="email"
            sx={{ mb: 1 }}
            fullWidth
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            placeholder={t("이메일을 입력해주세요.")}
          />
          {emailError && (
            <CustomText type="small" color={isEmailAvailable ? "green" : "red"}>
              {emailError}
            </CustomText>
          )}
        </Grid>
        <Grid item id="confirm-box" xs={12} mb={3}>
          <Box border={`1px solid ${BorderGray}`} borderRadius={2}>
            <Box borderBottom={`1px solid ${BorderGray}`} p={"8px 24px"}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allAgree}
                      onChange={handleAllAgreeChange}
                    />
                  }
                  label={
                    <CustomText type="subtitle">
                      {t("모두 동의합니다.")}
                    </CustomText>
                  }
                />
              </FormGroup>
            </Box>
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                p={"0 24px"}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={privacyAgreement}
                        onChange={handlePrivacyAgreementChange}
                        disableRipple
                        icon={<CheckIcon />}
                        checkedIcon={<CheckIcon color="primary" />}
                      />
                    }
                    label={
                      <Box display={"flex"}>
                        <CustomText>
                          {t("개인정보 수집 및 이용 동의")}
                        </CustomText>
                        <CustomText ml={1} color={ContentBlue} bold500>
                          {t("(필수)")}
                        </CustomText>
                      </Box>
                    }
                  />
                </FormGroup>
                <Button
                  variant="text"
                  href={Urls.privacyAgreement}
                  target="_blank"
                  disableRipple
                >
                  <CustomText>{t("내용보기")}</CustomText>
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item id="login-button" xs={12} mb={3}>
          <Button
            fullWidth
            onClick={handleSubmit}
            size="large"
            sx={{
              bgcolor: InbodyRed,
              color: "white",
              "&:hover": {
                bgcolor: InbodyRed,
                opacity: 0.9,
              },
            }}
          >
            <CustomText type="subtitle">{t("가입하기")}</CustomText>
          </Button>
        </Grid>
      </Grid>
      <Footer hideDivider />
    </Box>
  );
}

const EssentialMark = styled.span`
  &:after {
    content: "*";
    color: red;
    font-size: 20px;
    vertical-align: middle;
  }
`;
