import { create } from "zustand";

interface useLoadingStoreProps {
  isLoadingOpen: boolean;
  loadingText: string;

  openLoading: (text?: string) => void;
  closeLoading: () => void;
}

const initialState = {
  isLoadingOpen: false,
  loadingText: "",
};

export const useLoadingStore = create<useLoadingStoreProps>((set) => ({
  ...initialState,

  openLoading: (text?) =>
    set((state) => {
      return {
        ...state,
        isLoadingOpen: true,
        loadingText: text ?? "",
      };
    }),

  closeLoading: () => set(initialState),
}));
