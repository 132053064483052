import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchLectureList from "../../../api/domains/lecture/queries/useFetchLectureList";
import TitleDiv from "../../../components/atoms/TitleDiv";
import CustomButton from "../../../components/button/CustomButton";
import { TableLoading } from "../../../components/loading/TableLoading";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import Urls from "../../../routes/Urls";
import useDateHooks from "../../../hooks/common/useDateHooks";

export default function LectureList() {
  const { t } = useTranslation();
  const courseId = Number(useParams().id);

  const { getDateStringFormat } = useDateHooks();

  const { data: lectureList, isLoading } = useFetchLectureList(courseId);

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <TitleDiv title={t("강의")} />
      </Grid>
      <Grid item xs={12}>
        <CustomTableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle" bold600>
                    {t("차시")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="40%">
                  <CustomText type="subtitle" bold600>
                    {t("강의명")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle" bold600>
                    {t("마지막 수강일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle" bold600>
                    {t("진도율")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="subtitle" bold600>
                    {t("수강하기")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 5)}
              {lectureList && lectureList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <CustomText type="subtitle">
                      {t("조회된 강의가 없습니다.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {lectureList &&
                lectureList.map((lecture) => {
                  return (
                    <TableRow key={lecture.lectureId}>
                      <TableCell align="center">
                        <CustomText type="body">
                          {lecture.lectureOrder + 1}
                        </CustomText>
                      </TableCell>
                      <TableCell>
                        <CustomTextTooltip type="body" bold500>
                          {lecture.title}
                        </CustomTextTooltip>
                      </TableCell>
                      <TableCell align="center">
                        <CustomText type="body">
                          {getDateStringFormat(
                            lecture.progressDto?.updatedAt,
                            "YYYY-MM-DD"
                          )}
                        </CustomText>
                      </TableCell>
                      <TableCell align="center">
                        <CustomText type="body">
                          {lecture.progressDto
                            ? `${lecture.progressDto?.completePercentage}%`
                            : "-"}
                        </CustomText>
                      </TableCell>
                      <TableCell align="center">
                        <CustomButton
                          colorType="inbodyRed"
                          onClick={() => {
                            window.open(
                              Urls.playLecture.replace(
                                ":id",
                                String(lecture.lectureId)
                              ),
                              "",
                              "left=0,width=1280,height=720"
                            );
                          }}
                        >
                          <CustomText type="button" fontWeight={500}>
                            {t("수강하기")}
                          </CustomText>
                        </CustomButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
    </Grid>
  );
}
