import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import useQuizVersionAPIs from '../useQuizVersionAPIs';
import { FetchQuizVersionList } from '../QuizVersionType';

const useFetchQuizVersionList = <T = FetchQuizVersionList[]>(
  quizId: number,
  options?: UseQueryOptions<FetchQuizVersionList[], AxiosError, T>
) => {

  const { getQuizVersionListAPI } = useQuizVersionAPIs();

  return useQuery({
    queryKey: QueryKeys.quizVersionList(quizId),
    queryFn: () => getQuizVersionListAPI(quizId),
    ...options
  });
}

export default useFetchQuizVersionList;