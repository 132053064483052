import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuizListForExam } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';
import { useUserStore } from '../../../../stores/user/useUserStore';

const useFetchQuizList = <T = FetchQuizListForExam[]>(
    options?: UseQueryOptions<FetchQuizListForExam[], AxiosError, T>
) => {

  const { getQuizListAPI } = useQuizAPIs();
  const userId = useUserStore(state => state.userId);

  return useQuery({
    queryKey: QueryKeys.quizList(userId),
    queryFn: () => getQuizListAPI(),
    ...options
  });
}

export default useFetchQuizList;