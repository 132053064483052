import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchEmployeeTree } from '../EmployeeType';
import useEmployeeAPIs from '../useEmployeeAPIs';

const useFetchEmployeeTree = <T = FetchEmployeeTree>(
    options?: UseQueryOptions<FetchEmployeeTree, AxiosError, T >
) => {
    const { getEmployeeTreeAPI } = useEmployeeAPIs();

    return useQuery({
        queryKey: QueryKeys.employeeTreeList(),
        queryFn: () => getEmployeeTreeAPI(new Date().getFullYear()),
        staleTime: Infinity,
        ...options
    });
}

export default useFetchEmployeeTree;