import SaveIcon from "@mui/icons-material/Save";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../../../components/text/CustomText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomConfirmAlert } from "../../../components/alert/CustomConfirmAlert";

export default function ExamFloatingButton({ isDirty }: { isDirty: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDialOpen, setIsDialOpen] = useState(true);

  return (
    <SpeedDial
      ariaLabel="speedDial"
      sx={{ position: "fixed", bottom: 50, right: 50 }}
      icon={<SpeedDialIcon />}
      FabProps={{ color: "primary" }}
      open={isDialOpen}
      onClick={() => setIsDialOpen((pre) => !pre)}
    >
      <SpeedDialAction
        key={t("답안저장")}
        FabProps={{
          sx: {
            bgcolor: "success.main",
            color: "white",
            "&:hover": {
              bgcolor: "success.main",
            },
          },
          type: "submit",
        }}
        icon={<SaveIcon />}
        tooltipTitle={<CustomText type="body">{t("답안저장")}</CustomText>}
      />
      <SpeedDialAction
        key={t("이전으로")}
        FabProps={{
          sx: {
            bgcolor: "primary.main",
            color: "white",
            "&:hover": {
              bgcolor: "primary.main",
            },
          },
        }}
        icon={<ArrowBackIcon />}
        tooltipTitle={<CustomText type="body">{t("이전으로")}</CustomText>}
        onClick={() => {
          if (isDirty) {
            CustomConfirmAlert(
              t("답안이 수정되었습니다."),
              "warning",
              t("이전으로"),
              t("취소하기"),
              `${t("답안저장 버튼을 눌러야 답안이 저장됩니다.")}\n${t(
                "이동하시겠습니까?"
              )}`
            ).then((res) => {
              if (res.isConfirmed) navigate(-1);
            });
          } else {
            navigate(-1);
          }
        }}
      />
    </SpeedDial>
  );
}
