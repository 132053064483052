import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FolderPathListDto } from '../FolderType';
import useFolderAPI from '../useFolderAPI';

const useFetchFolderPathList = <T = FolderPathListDto[]>(
    folderId: number,
    options?:UseQueryOptions<FolderPathListDto[], AxiosError, T>
) =>  {
    const { getFolderPathListAPI } = useFolderAPI();
    
    return useQuery({
        queryKey: QueryKeys.folderPathList(folderId),
        queryFn: () => getFolderPathListAPI(folderId),
        ...options
    });
}

export default useFetchFolderPathList;