import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Alert, Box, Chip, IconButton, Snackbar, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFileList from "../../../api/domains/file/queries/useFetchFileList";
import useFetchFolderAuthCheck from "../../../api/domains/folder/queries/useFetchFolderAuthCheck";
import useFetchFolderDetail from "../../../api/domains/folder/queries/useFetchFolderDetail";
import useFetchSubFolderList from "../../../api/domains/folder/queries/useFetchSubFolderList";
import FolderNameLanguageSwitcher from "../../../components/FolderNameLanguageSwitcher";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import { ViewSizeOption } from "../../../data/enums/ViewSizeOption";
import useDateHooks from "../../../hooks/common/useDateHooks";
import useUnitHooks from "../../../hooks/common/useUnitHooks";
import useViewSizeStyleHooks from "../../../hooks/common/useViewSizeStyleHooks";
import useDragDropHooks from "../../../hooks/domains/useDragDropHooks";
import useFileHooks from "../../../hooks/domains/useFileHooks";
import { useFolderHistoryStore } from "../../../stores/folder/useFolderHistoryStore";
import { useSelectionManagerStore } from "../../../stores/folder/useSelectionManagerStore";
import {
  Border2Blue,
  BorderBlue,
  BorderGray,
  BoxBlue,
} from "../../../styles/GlobalStyle";

export interface MousePositionProps {
  startPositionX: number | null;
  startPositionY: number | null;
  endPositionX: number | null;
  endPositionY: number | null;
}
export const initMousePosition: MousePositionProps = {
  startPositionX: null,
  startPositionY: null,
  endPositionX: null,
  endPositionY: null,
};

export default function ArchiveContent() {
  const { t } = useTranslation();
  const [isDragging, setIsDragging] = useState(false);
  const [mousePosition, setMousePosition] =
    useState<MousePositionProps>(initMousePosition);
  const [scrollX, setScrollX] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const stackRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { currentFolderId, setCurrentFolderId } = useFolderHistoryStore(
    (state) => ({
      currentFolderId: state.currentFolderId,
      setCurrentFolderId: state.setCurrentFolderId,
    })
  );

  const {
    selectedTagIdSet,
    toggleSelectedTagId,
    dragEnterFolderName,
    setDragEnterFolderName,
    selectedFileIdSet,
    setSelectedFileIdSet,
    selectedFolderIdSet,
    setSelectedFolderIdSet,
    viewSize,
    resetSelectInfo,
  } = useSelectionManagerStore((state) => ({
    selectedTagIdSet: state.selectedTagIdSet,
    toggleSelectedTagId: state.toggleSelectedTagId,
    dragEnterFolderName: state.dragEnterFolderName,
    setDragEnterFolderName: state.setDragEnterFolderName,
    selectedFileIdSet: state.selectedFileIdSet,
    setSelectedFileIdSet: state.setSelectedFileIdSet,
    selectedFolderIdSet: state.selectedFolderIdSet,
    setSelectedFolderIdSet: state.setSelectedFolderIdSet,
    viewSize: state.viewSize,
    resetSelectInfo: state.resetSelectInfo,
  }));

  const { data: folderAuth } = useFetchFolderAuthCheck(currentFolderId);
  const { data: folderDetail } = useFetchFolderDetail(currentFolderId);
  const { data: folderList } = useFetchSubFolderList(currentFolderId);
  const { data: fileList } = useFetchFileList(currentFolderId);

  const { getFileIconPath, downloadFileWhenDoubleClick } = useFileHooks();
  const { dropToTargetFolder, folderMoveStart, fileMoveStart } =
    useDragDropHooks();

  const { getDateStringFormat } = useDateHooks();
  const { fileSizeUnit } = useUnitHooks();
  const { getViewSizeStyle } = useViewSizeStyleHooks();

  //현재 folderId 바뀌면 => 선택 Tag 초기화
  useEffect(() => {
    resetSelectInfo();
  }, [currentFolderId, resetSelectInfo]);

  // Data filtering
  const filteredFileList = fileList?.filter((file) => {
    if (selectedTagIdSet.size !== 0) {
      return Array.from(selectedTagIdSet).every((selectedTagId) =>
        file.tagIdList.includes(selectedTagId)
      );
    }
    return true;
  });

  const scrollAmount = 100;

  const scrollLeft = () => {
    setScrollX((prev) => Math.max(prev - scrollAmount, 0));
  };

  const scrollRight = () => {
    const stackWidth = stackRef.current ? stackRef.current.offsetWidth : 0;
    const containerWidth = containerRef.current
      ? containerRef.current.offsetWidth
      : 0;
    const maxScrollWidth = stackWidth - containerWidth;

    setScrollX((prev) => {
      return Math.min(prev + scrollAmount, maxScrollWidth);
    });
  };

  const selectAreaItem = (e: React.MouseEvent) => {
    setIsDragging(false);
    const folderIdSet = new Set<number>();
    const fileIdSet = new Set<number>();
    const paperRect = e.currentTarget.getBoundingClientRect();

    if (
      mousePosition.startPositionX !== null &&
      mousePosition.startPositionY !== null &&
      mousePosition.endPositionX !== null &&
      mousePosition.endPositionY !== null
    ) {
      const dragRect = {
        left: Math.min(
          mousePosition.startPositionX,
          mousePosition.endPositionX
        ),
        top: Math.min(mousePosition.startPositionY, mousePosition.endPositionY),
        right: Math.max(
          mousePosition.startPositionX,
          mousePosition.endPositionX
        ),
        bottom: Math.max(
          mousePosition.startPositionY,
          mousePosition.endPositionY
        ),
      };
      document.querySelectorAll(".item-component").forEach((el) => {
        const rect = el.getBoundingClientRect();
        const itemLeft = rect.left - paperRect.left;
        const itemRight = rect.right - paperRect.left;
        const itemTop =
          rect.top - paperRect.top + (contentRef.current?.scrollTop ?? 0);
        const itemBottom =
          rect.bottom - paperRect.top + (contentRef.current?.scrollTop ?? 0);

        if (
          itemRight >= dragRect.left &&
          itemLeft <= dragRect.right &&
          itemBottom >= dragRect.top &&
          itemTop <= dragRect.bottom
        ) {
          const id = Number(el.getAttribute("data-id"));
          const isFolder = el.getAttribute("data-type") === "folder";
          isFolder ? folderIdSet.add(id) : fileIdSet.add(id);
        }
      });
    }
    setSelectedFolderIdSet(folderIdSet);
    setSelectedFileIdSet(fileIdSet);
    setMousePosition(initMousePosition);
  };

  return (
    <>
      {folderDetail && (
        <TagFilterWrapper
          onDragStart={(e) => e.preventDefault()}
          hidden={folderDetail.tagList.length === 0}
        >
          <IconButton onClick={scrollLeft}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Box
            ref={containerRef}
            display={"flex"}
            alignItems={"center"}
            sx={{
              width: "calc(100% - 80px)",
              overflowX: "hidden",
            }}
          >
            <Stack
              ref={stackRef}
              direction="row"
              sx={{
                transform: `translateX(-${scrollX}px)`,
                transition: "transform 0.3s ease-in-out",
              }}
            >
              {folderDetail.tagList.map((tag) => {
                const isTagSelected = selectedTagIdSet.has(tag.id);

                return (
                  <Chip
                    key={tag.text}
                    sx={{
                      mr: 1,
                      mb: "3px",
                      bgcolor: isTagSelected ? BorderBlue : null,
                    }}
                    variant={isTagSelected ? "filled" : "outlined"}
                    label={tag.text}
                    onClick={() => toggleSelectedTagId(tag.id)}
                  />
                );
              })}
            </Stack>
          </Box>
          <IconButton onClick={scrollRight}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </TagFilterWrapper>
      )}
      {/** TODO 추후 오른쪽마우스 클릭 이벤트 구현 */}
      <FileAndFolderWrapper
        onContextMenu={(e) => e.preventDefault()}
        ref={contentRef}
        onMouseDown={(e) => {
          const mousePoint = e.target as Element;
          if (!mousePoint.closest(".item-component")) {
            const rect = e.currentTarget.getBoundingClientRect();
            setMousePosition({
              startPositionX: e.clientX - rect.left,
              startPositionY:
                e.clientY - rect.top + (contentRef.current?.scrollTop ?? 0),
              endPositionX: null,
              endPositionY: null,
            });
            setIsDragging(true);
          }
        }}
        onMouseMove={(e) => {
          if (isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            setMousePosition((preState) => ({
              ...preState,
              endPositionX: e.clientX - rect.left,
              endPositionY:
                e.clientY - rect.top + (contentRef.current?.scrollTop ?? 0),
            }));
          }
        }}
        onMouseLeave={(e) => {
          if (isDragging) {
            selectAreaItem(e);
          }
        }}
        onMouseUp={selectAreaItem}
      >
        <DragArea
          style={{
            position: "absolute",
            left: `${Math.min(
              mousePosition.startPositionX ?? 0,
              mousePosition.endPositionX ?? 0
            )}px`,
            top: `${Math.min(
              mousePosition.startPositionY ?? 0,
              mousePosition.endPositionY ?? 0
            )}px`,
            width: `${Math.abs(
              (mousePosition.startPositionX ?? 0) -
                (mousePosition.endPositionX ?? 0)
            )}px`,
            height: `${Math.abs(
              (mousePosition.startPositionY ?? 0) -
                (mousePosition.endPositionY ?? 0)
            )}px`,
            display:
              mousePosition.startPositionX !== null &&
              mousePosition.startPositionY !== null &&
              mousePosition.endPositionX !== null &&
              mousePosition.endPositionY !== null
                ? "block"
                : "none",
          }}
        />
        {viewSize === ViewSizeOption.DETAIL && (
          <DetailViewHeader>
            <CustomText
              width={"50%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"이름"}
            </CustomText>
            <CustomText
              width={"10%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"크기"}
            </CustomText>
            <CustomText
              width={"20%"}
              align="center"
              sx={{ borderRight: `1px solid ${BorderGray}` }}
              type={"subtitle"}
              bold400
            >
              {"생성한 날짜"}
            </CustomText>
            <CustomText width={"20%"} align="center" type={"subtitle"} bold400>
              {"수정한 날짜"}
            </CustomText>
          </DetailViewHeader>
        )}
        {folderAuth && folderDetail && folderDetail.parentFolderId && (
          <ItemComponent
            key={"super-folder"}
            data-id={folderDetail.parentFolderId}
            data-type={"folder"}
            isSelected={false}
            draggable={false}
            margin={getViewSizeStyle(viewSize).margin}
            padding={getViewSizeStyle(viewSize).padding}
            width={getViewSizeStyle(viewSize).width}
            minHeight={getViewSizeStyle(viewSize).minHeight}
            isFullWidth={viewSize === ViewSizeOption.DETAIL}
            onDrop={(e) =>
              dropToTargetFolder(e, folderDetail.parentFolderId!, folderAuth)
            }
            onDragEnter={() => setDragEnterFolderName(t("상위폴더"))}
            onDragOver={(e) => e.preventDefault()}
            onDoubleClick={() =>
              setCurrentFolderId(folderDetail.parentFolderId!)
            }
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/folder.svg`}
              alt={t("상위폴더")}
              width={getViewSizeStyle(viewSize).ImgWidth}
              height={getViewSizeStyle(viewSize).ImgHeight}
            />
            {viewSize !== ViewSizeOption.DETAIL && (
              <CustomTextTooltip
                type={getViewSizeStyle(viewSize).type}
                bold400
                align="center"
                sx={{ cursor: "default" }}
              >
                {t("...")}
              </CustomTextTooltip>
            )}
            {viewSize === ViewSizeOption.DETAIL && (
              <ItemDetailViewWrapper>
                <CustomText width={"50%"}>{t("...")}</CustomText>
                <CustomText width={"10%"}>{""}</CustomText>
                <CustomText width={"20%"}>{""}</CustomText>
                <CustomText width={"20%"}>{""}</CustomText>
              </ItemDetailViewWrapper>
            )}
          </ItemComponent>
        )}
        {folderAuth &&
          folderList &&
          folderList.map((folder) => {
            return (
              <ItemComponent
                key={folder.id}
                className="item-component"
                data-id={folder.id}
                data-type={"folder"}
                draggable
                margin={getViewSizeStyle(viewSize).margin}
                padding={getViewSizeStyle(viewSize).padding}
                width={getViewSizeStyle(viewSize).width}
                minHeight={getViewSizeStyle(viewSize).minHeight}
                isFullWidth={viewSize === ViewSizeOption.DETAIL}
                isSelected={selectedFolderIdSet.has(folder.id)}
                onClick={() =>
                  setSelectedFolderIdSet(new Set<number>([folder.id]))
                }
                onDragStart={(e) => folderMoveStart(e, folder)}
                onDrop={(e) => dropToTargetFolder(e, folder.id, folderAuth)}
                onDragEnter={() => setDragEnterFolderName(folder.koreanName)}
                onDragOver={(e) => e.preventDefault()}
                onDoubleClick={() => setCurrentFolderId(folder.id)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/folder.svg`}
                  alt={folder.koreanName}
                  style={{ opacity: folder.isSecretFolder ? 0.6 : 1 }}
                  width={getViewSizeStyle(viewSize).ImgWidth}
                  height={getViewSizeStyle(viewSize).ImgHeight}
                />
                {viewSize !== ViewSizeOption.DETAIL && (
                  <Box
                    sx={{
                      width: getViewSizeStyle(viewSize).ImgWidth,
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <CustomText
                      type={getViewSizeStyle(viewSize).type}
                      bold400
                      align="center"
                      wordBreak
                      fullWidth
                      sx={{
                        cursor: "default",
                        lineHeight: 1.3,
                        overflowWrap: "break-word",
                      }}
                    >
                      <FolderNameLanguageSwitcher
                        isSecretFolder={folder.isSecretFolder}
                        koreanName={folder.koreanName}
                        englishName={folder.englishName}
                      />
                    </CustomText>
                  </Box>
                )}
                {viewSize === ViewSizeOption.DETAIL && (
                  <ItemDetailViewWrapper>
                    <CustomText width={"50%"}>
                      <FolderNameLanguageSwitcher
                        isSecretFolder={folder.isSecretFolder}
                        koreanName={folder.koreanName}
                        englishName={folder.englishName}
                      />
                    </CustomText>
                    <CustomText width={"10%"}>{""}</CustomText>
                    <CustomText align="right" width={"20%"}>
                      {getDateStringFormat(
                        folder.updatedAt,
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                    </CustomText>
                    <CustomText align="right" width={"20%"}>
                      {getDateStringFormat(
                        folder.createdAt,
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                    </CustomText>
                  </ItemDetailViewWrapper>
                )}
              </ItemComponent>
            );
          })}
        {filteredFileList &&
          filteredFileList.map((file) => {
            let fileIconSrc = "";
            if (
              file.thumbnailUrl !== "" &&
              viewSize !== ViewSizeOption.DETAIL
            ) {
              fileIconSrc = file.thumbnailUrl;
            } else {
              fileIconSrc = getFileIconPath(file.originFileName);
            }

            return (
              <ItemComponent
                key={file.fileId}
                className="item-component"
                data-id={file.fileId}
                data-type={"file"}
                draggable
                margin={getViewSizeStyle(viewSize).margin}
                padding={getViewSizeStyle(viewSize).padding}
                width={getViewSizeStyle(viewSize).width}
                minHeight={getViewSizeStyle(viewSize).minHeight}
                isFullWidth={viewSize === ViewSizeOption.DETAIL}
                isSelected={selectedFileIdSet.has(file.fileId)}
                onDragStart={(e) => fileMoveStart(e, file, fileIconSrc)}
                onDoubleClick={() =>
                  downloadFileWhenDoubleClick(file.fileId, file.originFileName)
                }
                onDragOver={(e) => e.preventDefault()}
                onClick={() =>
                  setSelectedFileIdSet(new Set<number>([file.fileId]))
                }
              >
                <img
                  src={fileIconSrc}
                  alt={file.originFileName}
                  width={getViewSizeStyle(viewSize).ImgWidth}
                  height={getViewSizeStyle(viewSize).ImgHeight}
                />
                {viewSize !== ViewSizeOption.DETAIL && (
                  <Box
                    sx={{
                      width: getViewSizeStyle(viewSize).ImgWidth,
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 4,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    <CustomText
                      type={getViewSizeStyle(viewSize).type}
                      bold400
                      align="center"
                      wordBreak
                      fullWidth
                      sx={{
                        cursor: "default",
                        lineHeight: 1.3,
                        overflowWrap: "break-word",
                      }}
                    >
                      {file.originFileName}
                    </CustomText>
                  </Box>
                )}
                {viewSize === ViewSizeOption.DETAIL && (
                  <ItemDetailViewWrapper>
                    <CustomText
                      width={"50%"}
                      type={getViewSizeStyle(viewSize).type}
                    >
                      {file.originFileName}
                    </CustomText>
                    <CustomText
                      align="right"
                      width={"10%"}
                      type={getViewSizeStyle(viewSize).type}
                    >
                      {fileSizeUnit(file.fileSize)}
                    </CustomText>
                    <CustomText
                      align="right"
                      width={"20%"}
                      type={getViewSizeStyle(viewSize).type}
                    >
                      {getDateStringFormat(
                        file.updatedAt,
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                    </CustomText>
                    <CustomText
                      align="right"
                      width={"20%"}
                      type={getViewSizeStyle(viewSize).type}
                    >
                      {getDateStringFormat(
                        file.createdAt,
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                    </CustomText>
                  </ItemDetailViewWrapper>
                )}
              </ItemComponent>
            );
          })}
      </FileAndFolderWrapper>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={dragEnterFolderName !== ""}
        autoHideDuration={1000}
        onClose={() => setDragEnterFolderName("")}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          <strong>{dragEnterFolderName}</strong>
          {t("(으)로 이동")}
        </Alert>
      </Snackbar>
    </>
  );
}
const TagFilterWrapper = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  border-bottom: 1px solid ${BorderGray};
  padding: 6px;
  width: calc(100% - 12px);
  user-select: none;
`;
export const DragArea = styled.div`
  position: absolute;
  background-color: rgba(0, 123, 255, 0.3);
`;
export const FileAndFolderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 50px);
  overflow-y: auto;
  align-content: flex-start;
  position: relative;
  user-select: none;
`;
export const DetailViewHeader = styled.div`
  margin: 2px;
  padding: 3px 16px;
  display: flex;
  width: 100%;
  user-select: none;
`;
export const ItemComponent = styled.div<{
  isSelected: boolean;
  width: number;
  margin: number;
  minHeight: number;
  padding: number;
  isFullWidth?: boolean;
}>`
  user-select: none;
  display: flex;
  align-items: ${(props) => (props.isFullWidth ? null : "center")};
  flex-direction: ${(props) => (props.isFullWidth ? "row" : "column")};
  width: ${(props) => (props.isFullWidth ? "100%" : null)};
  max-width: ${(props) => (props.isFullWidth ? null : `${props.width}px`)};
  min-height: ${(props) => `${props.minHeight}px`};
  margin: ${(props) =>
    props.isSelected ? `${props.margin - 1}px` : `${props.margin}px`};
  padding: ${(props) =>
    props.isFullWidth ? "6px 16px" : `${props.padding}px`};
  background-color: ${(props) => (props.isSelected ? BoxBlue : null)};
  border: ${(props) => (props.isSelected ? `1px solid ${Border2Blue}` : null)};
  &:hover {
    background-color: ${BoxBlue};
  }
`;
export const ItemDetailViewWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px;
  user-select: none;
`;
