import React from "react";
import { CustomText } from '../text/CustomText';

interface ErrorTextProps {
    message: string | undefined
}
export default function ErrorText({ message }: ErrorTextProps) {
    return (
        <>
            {message &&
                < CustomText type='small' color={'red'} >
                    {message}
                </ CustomText>
            }
        </>
    );
}
