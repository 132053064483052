import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchManagingQuizList } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';
import { useUserStore } from '../../../../stores/user/useUserStore';

const useFetchManagingQuizList = <T = FetchManagingQuizList[]>(
    options?: UseQueryOptions<FetchManagingQuizList[], AxiosError, T>
) => {

  const { getManagingQuizListAPI } = useQuizAPIs();
  const userId = useUserStore(state => state.userId);
  
  return useQuery({
      queryKey: QueryKeys.managingQuizList(userId),
      queryFn: () => getManagingQuizListAPI(),
      ...options
  });
}

export default useFetchManagingQuizList;