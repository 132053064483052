import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchUserStudyInfoList from "../../../api/domains/user/queries/useFetchUserStudyInfoList";
import { TableLoading } from "../../../components/loading/TableLoading";
import { CustomText } from "../../../components/text/CustomText";
import {
  StudyInfoSearchCondition,
  StudyInfoSearchConditionType,
} from "../../../data/enums/SearchCondition";
import {
  BasicFontKR,
  BorderGray,
  BoxPale,
  DividerGray,
} from "../../../styles/GlobalStyle";
import UserStudyInfoDetail from "./UserStudyInfoDetail";
import { FetchUserStudyInfoList } from "../../../api/domains/user/UserType";
import { PageSize } from "../../../data/enums/PageSize";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useDateHooks from "../../../hooks/common/useDateHooks";
import useAdminUserHooks from "../../../hooks/domains/useAdminUserHooks";

//TODO Excel 백엔드 쪽에서 그려서 주면, 컬럼 width나 색상, 제목 달 수 있음. 나중에 하자
export default function UserStudyInfoList() {
  const { t } = useTranslation();

  // 점수 지정 모달
  const [searchCondition, setSearchCondition] =
    useState<StudyInfoSearchConditionType>(StudyInfoSearchCondition.이름);
  const [searchKeyWords, setSearchKeyWords] = useState("");
  const [page, setPage] = useState(1);
  const [expandRow, setExpandRow] = useState<Record<string, boolean>>({});

  /** react-query - data fetching */
  const { data: userStudyInfoList, isLoading } = useFetchUserStudyInfoList();

  /** custom hooks */
  const { getDateStringFormat } = useDateHooks();
  const { downloadExcel, moveToMailSendPage } = useAdminUserHooks();

  const filteredUserStudyInfoList = userStudyInfoList
    ?.map((userStudyInfo) => {
      const regex = getRegExp(searchKeyWords);
      let filteredStudyInfo = { ...userStudyInfo };

      switch (searchCondition) {
        case StudyInfoSearchCondition.이름:
          return userStudyInfo.userName.search(regex) !== -1
            ? userStudyInfo
            : null;

        case StudyInfoSearchCondition.시험명:
          const filteredAttempts = userStudyInfo.attemptHistoryList?.filter(
            (attemptInfo) => attemptInfo.quizName?.search(regex) !== -1
          );

          if (!filteredAttempts || filteredAttempts.length === 0) {
            return null;
          }
          filteredStudyInfo.attemptHistoryList = filteredAttempts;
          return filteredStudyInfo;

        case StudyInfoSearchCondition.부서:
          if (!userStudyInfo.parentTeam) return null;
          return userStudyInfo.parentTeam?.search(regex) !== -1
            ? userStudyInfo
            : null;

        case StudyInfoSearchCondition.입사일:
          if (!userStudyInfo.joined) return null;
          return userStudyInfo.joined.toString().search(regex) !== -1
            ? userStudyInfo
            : null;

        case StudyInfoSearchCondition.과목명:
          const filteredCourses = userStudyInfo.studyHistoryList?.filter(
            (courseInfo) => courseInfo.courseName?.search(regex) !== -1
          );

          if (!filteredCourses || filteredCourses.length === 0) {
            return null;
          }
          filteredStudyInfo.studyHistoryList = filteredCourses;
          return filteredStudyInfo;
        default:
          return filteredStudyInfo;
      }
    })
    .filter(Boolean) as FetchUserStudyInfoList[];

  return (
    <>
      <Grid item xs={12}>
        <ButtonListWrapper>
          <Button
            onClick={() => downloadExcel(filteredUserStudyInfoList)}
            variant="contained"
            color="inherit"
          >
            <CustomText type="body" bold500>
              {t("Excel 다운로드")}
            </CustomText>
          </Button>
          <Button
            onClick={() => moveToMailSendPage(filteredUserStudyInfoList)}
            variant="contained"
            color="info"
          >
            <CustomText type="body" bold500>
              {t("조회 대상 메일 발송")}
            </CustomText>
          </Button>
        </ButtonListWrapper>
        <SearchWrapper>
          <CustomText type="subtitle">
            {`Total: ${
              filteredUserStudyInfoList ? filteredUserStudyInfoList.length : 0
            } 건`}
          </CustomText>
          <SearchIconWrapper>
            <Select
              value={searchCondition}
              onChange={(event) => setSearchCondition(event.target.value)}
              displayEmpty
            >
              <MenuItem value={StudyInfoSearchCondition.이름}>
                <CustomText>{t("이름")}</CustomText>
              </MenuItem>
              <MenuItem value={StudyInfoSearchCondition.시험명}>
                <CustomText>{t("시험명")}</CustomText>
              </MenuItem>
              <MenuItem value={StudyInfoSearchCondition.과목명}>
                <CustomText>{t("과목명")}</CustomText>
              </MenuItem>
              <MenuItem value={StudyInfoSearchCondition.부서}>
                <CustomText>{t("부서")}</CustomText>
              </MenuItem>
              <MenuItem value={StudyInfoSearchCondition.입사일}>
                <CustomText>{t("입사일")}</CustomText>
              </MenuItem>
            </Select>
            <KeyWordWrapper>
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                className="inputField"
                placeholder={t("검색어를 입력하세요.")}
                value={searchKeyWords}
                onChange={(event) => setSearchKeyWords(event.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" sx={{ p: "10px" }}>
                <SearchIcon />
              </IconButton>
            </KeyWordWrapper>
          </SearchIconWrapper>
        </SearchWrapper>
        <TableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("번호")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("부서")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("이름")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body" bold500>
                    {t("이메일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="10%">
                  <CustomText type="body" bold500>
                    {t("입사일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="body" bold500>
                    {t("마지막로그인")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="20%">
                  <CustomText type="body" bold500>
                    {t("수강이력 및 시험이력")}
                  </CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && TableLoading(5, 7)}
              {filteredUserStudyInfoList &&
                filteredUserStudyInfoList.length === 0 && (
                  <TableRow sx={{ borderBottom: `1px solid ${BorderGray}` }}>
                    <TableCell colSpan={6} align="center">
                      <CustomText type="body">
                        {t("조회된 데이터가 없습니다.")}
                      </CustomText>
                    </TableCell>
                  </TableRow>
                )}
              {filteredUserStudyInfoList &&
                filteredUserStudyInfoList
                  .slice((page - 1) * PageSize.FIFTEEN, page * PageSize.FIFTEEN)
                  .map((user, index) => {
                    return (
                      <Fragment key={user.userId}>
                        <TableRow sx={{ border: `1px solid ${BorderGray}` }}>
                          <TableCell align="center">
                            <CustomText type="body">{index + 1}</CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {user.parentTeam ?? "-"}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">{user.userName}</CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">{user.email}</CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {getDateStringFormat(user.joined, "YYYY-MM-DD")}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {getDateStringFormat(
                                user.lastLogIn,
                                "YYYY-MM-DD hh:mm:ss"
                              )}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            {user.studyHistoryList &&
                              user.attemptHistoryList &&
                              (user.studyHistoryList.length > 0 ||
                                user.attemptHistoryList.length > 0) && (
                                <Button
                                  size="small"
                                  sx={{ p: 0 }}
                                  onClick={() =>
                                    setExpandRow((pre) => ({
                                      ...pre,
                                      [user.userId]: !pre[user.userId],
                                    }))
                                  }
                                >
                                  <CustomText type="button">
                                    {expandRow[user.userId]
                                      ? t("간단히")
                                      : t("상세정보")}
                                  </CustomText>
                                  {expandRow[user.userId] ? (
                                    <ExpandLessIcon />
                                  ) : (
                                    <ExpandMoreIcon />
                                  )}
                                </Button>
                              )}
                          </TableCell>
                        </TableRow>
                        {user.studyHistoryList &&
                          user.attemptHistoryList &&
                          expandRow[user.userId] && (
                            <UserStudyInfoDetail
                              studyInfoList={user.studyHistoryList}
                              attemptInfoList={user.attemptHistoryList}
                            />
                          )}
                      </Fragment>
                    );
                  })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item container xs={12} justifyContent={"center"}>
        {filteredUserStudyInfoList && (
          <Pagination
            count={Math.ceil(
              filteredUserStudyInfoList.length / PageSize.FIFTEEN
            )}
            onChange={(_, curPage) => setPage(curPage)}
            page={page}
          />
        )}
      </Grid>
    </>
  );
}
const ButtonListWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  & button {
    margin-left: 10px;
  }
`;
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const SearchIconWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
`;
const KeyWordWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  width: 300px;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
const TableWrapper = styled.div`
  & th,
  td {
    padding: 10px;
  }
  & th {
    background-color: ${BoxPale};
  }
  & td {
    border-bottom: none;
  }
`;
