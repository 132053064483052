import { ContentBlue, ContentGray, ContentGreen, ContentOrange, ContentPale } from '../../styles/GlobalStyle';

const useColorHooks = () => { 
    const colorList = [ContentGray, ContentGreen, ContentPale, ContentOrange, ContentBlue];

    const stringToColor = (userName: string) => {
        let hash = 0;
        for (let i = 0; i < userName.length; i++) {
            hash = userName.charCodeAt(i) + ((hash << 5) - hash);
        }
        const index = Math.abs(hash % colorList.length);
        return colorList[index];
    };

    return {
        stringToColor
    }
}

export default useColorHooks