import { Route, Routes } from "react-router-dom";
import { CourseType } from "../data/enums/CourseType";
import AuthPageWrapper from "../layouts/AuthPageWrapper";
import AdminCourseListPage from "../pages/admin-management-course/AdminCourseListPage";
import AdminEmailPage from "../pages/admin-management-email/AdminEmailPage";
import AdminFilePage from "../pages/admin-management-file/AdminFilePage";
import AdminQuizPage from "../pages/admin-management-quiz/AdminQuizPage";
import AdminUserPage from "../pages/admin-management-user/AdminUserPage";
import PostCoursePage from "../pages/admin-post-course/PostCoursePage";
import PostDocumentPage from "../pages/admin-post-document/PostDocumentPage";
import PostLecturePage from "../pages/admin-post-lecture/PostLecturePage";
import PostQuizPage from "../pages/admin-post-quiz/PostQuizPage";
import ArchivePage from "../pages/archive/ArchivePage";
import CourseDetailPage from "../pages/course-detail/CourseDetailPage";
import DutyCourseDetailPage from "../pages/course-duty-detail/DutyCourseDetailPage";
import CourseListPage from "../pages/course-list/CourseListPage";
import ExamPage from "../pages/exam/ExamPage";
import NotFoundPage from "../pages/layoutPages/NotFoundPage";
import LectureReactPlayer from "../pages/lecture/LectureReactPlayer";
import LoginPage from "../pages/login/LoginPage";
import MainPage from "../pages/main/MainPage";
import MyPage from "../pages/mypage/MyPage";
import NoticePostPage from "../pages/notice-post/NoticePostPage";
import NoticeDetailPage from "../pages/notice/NoticeDetailPage";
import NoticeListPage from "../pages/notice/NoticeListPage";
import QnACreatePage from "../pages/qna/QnaCreatePage";
import QnAListPage from "../pages/qna/QnaListPage";
import QuizListPage from "../pages/quiz-list/QuizListPage";
import QuizScorePage from "../pages/quiz-score/QuizScorePage";
import RecommendBookDetailPage from "../pages/recommend-book-detail/RecommendBookDetailPage";
import ReplyCreatePage from "../pages/reply/ReplyCreatePage";
import SignUpPage from "../pages/sign-up/SignUpPage";
import Urls from "./Urls";
import AccountHelpPage from "../pages/account-help/AccountHelpPage";
import UnAuthPageWrapper from "../layouts/UnAuthPageWrapper";
import PrivacyAgreement from "../pages/agreement-page/PrivacyAgreement";

// TODO FAQ
// const FaqList = lazy(() => import('../pages/faq/FaqListPage'))
// const FaqCreatePage = lazy(() => import('../pages/faq/FaqCreatePage'))
// const FaqModify = lazy(() => import('../pages/faq/FaqModifyPage'))

export default function Router() {
  const coursePathList = [
    Urls.freshmanCourseList,
    Urls.jobCourseList,
    Urls.dutyCourseList,
    Urls.leadershipCourseList,
    Urls.productCourseList,
    Urls.clinicalCourseList,
    Urls.applicationCourseList,
    Urls.otherCourseList,
  ];

  const courseDetailPathList = [
    { url: Urls.freshmanCourseDetail, type: CourseType.신규입사자교육 },
    { url: Urls.jobCourseDetail, type: CourseType.직무교육 },
    { url: Urls.dutyCourseDetail, type: CourseType.법정의무교육 },
    { url: Urls.leadershipCourseDetail, type: CourseType.리더십교육 },
    { url: Urls.productCourseDetail, type: CourseType.ProductTraining },
    { url: Urls.clinicalCourseDetail, type: CourseType.ClinicalTraining },
    { url: Urls.applicationCourseDetail, type: CourseType.ApplicationTraining },
    { url: Urls.otherCourseDetail, type: CourseType.Others },
  ];

  return (
    <Routes>
      {/* 로그인 페이지 */}
      <Route
        path={Urls.login}
        element={
          <UnAuthPageWrapper>
            <LoginPage />
          </UnAuthPageWrapper>
        }
      ></Route>

      {/* 회원가입 페이지 */}
      <Route
        path={Urls.privacyAgreement}
        element={
          <UnAuthPageWrapper>
            <PrivacyAgreement />
          </UnAuthPageWrapper>
        }
      ></Route>

      {/* 회원가입 페이지 */}
      <Route
        path={Urls.signUp}
        element={
          <UnAuthPageWrapper>
            <SignUpPage />
          </UnAuthPageWrapper>
        }
      ></Route>

      {/* 아이디/비밀번호 찾기 페이지 */}
      <Route
        path={Urls.accountHelp}
        element={
          <UnAuthPageWrapper>
            <AccountHelpPage />
          </UnAuthPageWrapper>
        }
      ></Route>

      {/* 메인페이지 */}
      <Route
        path={Urls.main}
        element={
          <AuthPageWrapper>
            <MainPage />
          </AuthPageWrapper>
        }
      ></Route>
      {/* 책 추천 페이지 */}
      <Route
        path={Urls.recommendBookDetail}
        element={
          <AuthPageWrapper>
            <RecommendBookDetailPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 강의 리스트 페이지 */}
      {coursePathList.map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <AuthPageWrapper>
              <CourseListPage />
            </AuthPageWrapper>
          }
        ></Route>
      ))}

      {/* 강의 상세 페이지 */}
      {courseDetailPathList.map((obj) => (
        <Route
          key={obj.url}
          path={obj.url}
          element={
            <AuthPageWrapper>
              <CourseDetailPage courseType={obj.type} />
            </AuthPageWrapper>
          }
        ></Route>
      ))}

      {/* 시험 목록 페이지 */}
      <Route
        path={Urls.quizList}
        element={
          <AuthPageWrapper>
            <QuizListPage />
          </AuthPageWrapper>
        }
      ></Route>
      {/* 시험 결과 확인 페이지 */}
      <Route
        path={Urls.quizScore}
        element={
          <AuthPageWrapper>
            <QuizScorePage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 시험 응시 페이지 */}
      <Route
        path={Urls.exam}
        element={
          <AuthPageWrapper>
            <ExamPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 영상 재생 */}
      <Route
        path={Urls.playLecture}
        element={
          <AuthPageWrapper>
            <LectureReactPlayer />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 공지사항 */}
      <Route
        path={Urls.notice}
        element={
          <AuthPageWrapper>
            <NoticeListPage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.noticeDetail}
        element={
          <AuthPageWrapper>
            <NoticeDetailPage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.noticeCreate}
        element={
          <AuthPageWrapper>
            <NoticePostPage isCreate />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.noticeModify}
        element={
          <AuthPageWrapper>
            <NoticePostPage isCreate={false} />
          </AuthPageWrapper>
        }
      ></Route>

      {/* Qna */}
      <Route
        path={Urls.qna}
        element={
          <AuthPageWrapper>
            <QnAListPage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.replyCreate}
        element={
          <AuthPageWrapper>
            <ReplyCreatePage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.qnaCreate}
        element={
          <AuthPageWrapper>
            <QnACreatePage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 내 강의실*/}
      <Route
        path={Urls.myPage}
        element={
          <AuthPageWrapper>
            <MyPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/** 자료창고 */}
      <Route
        path={Urls.archive}
        element={
          <AuthPageWrapper>
            <ArchivePage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 관리자 페이지 */}
      <Route
        path={Urls.courseManagement}
        element={
          <AuthPageWrapper>
            <AdminCourseListPage />
          </AuthPageWrapper>
        }
      ></Route>
      {/* 강의 관리 */}
      <Route
        path={Urls.courseCreate}
        element={
          <AuthPageWrapper>
            <PostCoursePage isCreate />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.courseModify}
        element={
          <AuthPageWrapper>
            <PostCoursePage isCreate={false} />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.postLecture}
        element={
          <AuthPageWrapper>
            <PostLecturePage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.postDocument}
        element={
          <AuthPageWrapper>
            <PostDocumentPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 수강생 관리 */}
      <Route
        path={Urls.studentManagement}
        element={
          <AuthPageWrapper>
            <AdminUserPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 메일 발송 */}
      <Route
        path={Urls.mailManagement}
        element={
          <AuthPageWrapper>
            <AdminEmailPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 퀴즈 관리 */}
      <Route
        path={Urls.quizManagement}
        element={
          <AuthPageWrapper>
            <AdminQuizPage />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.createQuiz}
        element={
          <AuthPageWrapper>
            <PostQuizPage isCreate />
          </AuthPageWrapper>
        }
      ></Route>
      <Route
        path={Urls.modifyQuiz}
        element={
          <AuthPageWrapper>
            <PostQuizPage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 자료 관리 */}
      <Route
        path={Urls.fileManagement}
        element={
          <AuthPageWrapper>
            <AdminFilePage />
          </AuthPageWrapper>
        }
      ></Route>

      {/* 404 Not Found */}
      <Route
        path="/*"
        element={
          <AuthPageWrapper>
            <NotFoundPage />
          </AuthPageWrapper>
        }
      ></Route>
    </Routes>
  );
}
