import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchCourseDetail from "../../api/domains/course/queries/useFetchCourseDetail";
import useFetchPostLectureList from "../../api/domains/lecture/queries/useFetchPostLectureList";
import TitleDiv from "../../components/atoms/TitleDiv";
import CustomButton from "../../components/button/CustomButton";
import CourseStepper from "../../components/stepper/CourseStepper";
import CustomTableWrapper from "../../components/table/CustomTableWrapper";
import { CustomText } from "../../components/text/CustomText";
import { CourseType } from "../../data/enums/CourseType";
import usePageMove from "../../hooks/common/usePageMove";
import useLectureHooks from "../../hooks/domains/useLectureHooks";
import usePostLectureStore from "../../stores/domains/usePostLectureStore";
import PostLectureItem from "./components/PostLectureItem";

export default function PostLecturePage() {
  const courseId = Number(useParams().id);
  const { t } = useTranslation();
  const { pageMove } = usePageMove();

  const { data: courseDetail } = useFetchCourseDetail(courseId);
  const { data: lectureListData } = useFetchPostLectureList(courseId);

  const isDutyCourse = courseDetail?.courseType === CourseType.법정의무교육;

  const { lectureList, isDirty, appendNewLecture } = usePostLectureStore(
    (state) => ({
      lectureList: state.lectureList,
      isDirty: state.isDirty,
      appendNewLecture: state.appendNewLecture,
    })
  );

  const { handleSubmitLecture, useCreateLectureStore } = useLectureHooks();

  useCreateLectureStore(lectureListData);

  return (
    <Grid container rowSpacing={3} mt={0}>
      <Grid item xs={12}>
        <CourseStepper
          step={1}
          ableToClick={true}
          courseId={courseId}
          isDirty={isDirty}
        />
      </Grid>
      <Grid item xs={12}>
        <TitleDiv title="영상 관리" />
        <Grid container justifyContent="flex-end" pb={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => pageMove(courseId, "course", isDirty)}
          >
            <CustomText type="buttonLarge">{t("이전단계")}</CustomText>
          </Button>
          <Button
            variant="text"
            color="success"
            onClick={() => {
              handleSubmitLecture(courseId);
            }}
          >
            <CustomText type="buttonLarge">{t("저장하기")}</CustomText>
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={() => pageMove(courseId, "document", isDirty)}
          >
            <CustomText type="buttonLarge">{t("다음단계")}</CustomText>
          </Button>
        </Grid>
        <CustomTableWrapper>
          <Table sx={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%" />
                <TableCell align="center" width="5%">
                  <CustomText type="subtitle">{t("차시")}</CustomText>
                </TableCell>
                <TableCell align="center" width={isDutyCourse ? "40%" : "60%"}>
                  <CustomText type="subtitle">{t("차시명")}</CustomText>
                </TableCell>
                {isDutyCourse && (
                  <>
                    <TableCell align="center" width="10%">
                      <CustomText type="subtitle">
                        {t("세움에듀강의")}
                      </CustomText>
                    </TableCell>
                    <TableCell align="center" width="30%">
                      <CustomText type="subtitle">{t("영상 URL")}</CustomText>
                    </TableCell>
                  </>
                )}
                {!isDutyCourse && (
                  <TableCell align="center" width="20%">
                    <CustomText type="subtitle">{t("파일명")}</CustomText>
                  </TableCell>
                )}
                <TableCell align="center" width="10%">
                  <CustomText type="subtitle">{t("삭제하기")}</CustomText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lectureList.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    <CustomText type="subtitle">
                      {t("등록된 영상이 없습니다. 영상을 추가해주세요.")}
                    </CustomText>
                  </TableCell>
                </TableRow>
              )}
              {lectureList.map((lecture, index, arr) => {
                return (
                  <PostLectureItem
                    key={lecture.lectureId}
                    lecture={lecture}
                    isDutyCourse={isDutyCourse}
                    lectureIndex={index}
                    isLastIndex={index === arr.length - 1}
                  />
                );
              })}
            </TableBody>
          </Table>
        </CustomTableWrapper>
        <Grid container justifyContent={"flex-end"}>
          <CustomButton
            margin="10px 0"
            colorType="inbodyRed"
            onClick={() => appendNewLecture()}
          >
            <CustomText type="button">{t("신규 영상 추가")}</CustomText>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
