import { useTranslation } from "react-i18next";
import { CourseType, CourseTypeInterface } from "../../data/enums/CourseType";

const useCourseNameHooks = () => {
  const { t } = useTranslation();

  const getCourseName = (courseType: CourseTypeInterface) => {
    switch (courseType) {
      case CourseType.신규입사자교육:
        return t("신규입사자교육");
      case CourseType.직무교육:
        return t("직무교육");
      case CourseType.법정의무교육:
        return t("법정의무교육");
      case CourseType.리더십교육:
        return t("리더십교육");
      case CourseType.ApplicationTraining:
        return t("Applications training");
      case CourseType.ClinicalTraining:
        return t("Clinical training");
      case CourseType.ProductTraining:
        return t("Product training");
      case CourseType.Others:
        return t("Others");
      default:
        return "";
    }
  };

  return {
    getCourseName,
  };
};

export default useCourseNameHooks;
