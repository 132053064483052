import { CourseTypeInterface } from "../../../data/enums/CourseType";
import useGetApiInstance from "../../config/BasicApi";
import {
  FetchCompleteCourseList,
  FetchCourseDetail,
  FetchCourseList,
  FetchCourseNameList,
  CreateCourse,
  FetchManagingCourseList,
  UpdateCourse,
  FetchMainPageCourseList,
  FetchProgressCourseList,
} from "./CourseType";

const useCourseAPIs = () => {
  const api = useGetApiInstance("course");

  // 관리중인 전체 강의 조회
  const getManagingCourseListAPI = (): Promise<FetchManagingCourseList[]> =>
    api.get("managing").then((res) => res.data.result);

  // 강의 전체 조회
  const getCourseListAPI = (
    courseType?: CourseTypeInterface
  ): Promise<FetchCourseList[]> =>
    api.get("", { params: { courseType } }).then((res) => res.data.result);

  // 강의명 전체 조회
  const getCourseNameListAPI = (): Promise<FetchCourseNameList[]> =>
    api.get("/name").then((res) => res.data.result);

  // 신규 강의 전체 조회
  const getMainPageCourseListAPI = (
    isNewCourseList: boolean,
    language: string
  ): Promise<FetchMainPageCourseList[]> =>
    api
      .get(`/main/${isNewCourseList}/${language}`)
      .then((res) => res.data.result);

  // 강의 상세 조회
  const getCourseDetailAPI = (courseId: number): Promise<FetchCourseDetail> =>
    api.get(`/${courseId}`).then((res) => res.data.result);

  // 수강중인 강의 전체 조회
  const getProgressCourseListAPI = (): Promise<FetchProgressCourseList[]> =>
    api.get(`/progress`).then((res) => res.data.result);

  // 수강완료 강의 전체 조회
  const getCompleteCourseListAPI = (): Promise<FetchCompleteCourseList[]> =>
    api.get(`/complete`).then((res) => res.data.result);

  // 강의 생성
  const createCourseAPI = (createCourse: CreateCourse): Promise<number> =>
    api.post("/create", createCourse).then((res) => res.data.result);

  // 강의 수정
  const modifyCourseAPI = (modifyCourse: UpdateCourse) =>
    api.put("/modify", modifyCourse);

  // 강의 삭제
  const deleteCourseAPI = (courseId: number) => api.delete(`/${courseId}`);

  return {
    getManagingCourseListAPI,
    getCourseListAPI,
    getCourseNameListAPI,
    getMainPageCourseListAPI,
    getCourseDetailAPI,
    getProgressCourseListAPI,
    getCompleteCourseListAPI,
    createCourseAPI,
    modifyCourseAPI,
    deleteCourseAPI,
  };
};

export default useCourseAPIs;
