import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
} from "@mui/material";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomText } from "../../../components/text/CustomText";
import {
  StoragePermission,
  StoragePermissionType,
} from "../../../data/enums/PermissionType";
import useColorHooks from "../../../hooks/common/useColorHooks";
import { useFolderModalStore } from "../../../stores/folder/useFolderModalStore";
import { useFolderStore } from "../../../stores/folder/useFolderStore";
import { BorderGray, BoxBlue, DividerGray } from "../../../styles/GlobalStyle";
import PostFolderUserAuth from "./PostFolderUserAuth";

interface ListFolderUserAuthProps {
  permissionType: StoragePermissionType;
}
export default function ListFolderUserAuth({
  permissionType,
}: ListFolderUserAuthProps) {
  const { t } = useTranslation();
  const { stringToColor } = useColorHooks();
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const folderAuthList = useFolderStore((state) => state.folderAuthList);
  const setAuthModalState = useFolderModalStore(
    (state) => state.setAuthModalState
  );

  const filteredFolderAuthUserList = folderAuthList.filter(
    (authUser) =>
      authUser.permissionType === permissionType &&
      (authUser.email.search(getRegExp(searchKeyWords)) !== -1 ||
        authUser.userName.search(getRegExp(searchKeyWords)) !== -1 ||
        (authUser.team &&
          authUser.team.search(getRegExp(searchKeyWords)) !== -1) ||
        (authUser.parentTeam &&
          authUser.parentTeam.search(getRegExp(searchKeyWords)) !== -1))
  );

  return (
    <ListWrapper>
      <Box
        p={"4px"}
        sx={{ borderBottom: `1px solid ${BorderGray}` }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Button
          onClick={() => setAuthModalState(true, permissionType)}
          startIcon={<AddIcon />}
        >
          <CustomText type={"button"}>
            {permissionType === StoragePermission.MANAGER
              ? t("폴더 관리 권한 편집")
              : t("사용자 권한 편집")}
          </CustomText>
        </Button>
        <KeyWordWrapper>
          <InputBase
            id="folder-owner-search"
            sx={{ ml: 1, flex: 1 }}
            size="small"
            className="inputField"
            placeholder={t("이름, 이메일, 파트, 팀으로 검색")}
            value={searchKeyWords}
            onChange={(event) => setSearchKeyWords(event.target.value)}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: "5px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </KeyWordWrapper>
      </Box>
      <ContentWrapper>
        {filteredFolderAuthUserList.map((user) => (
          <Box
            key={user.userId}
            display={"flex"}
            alignItems={"center"}
            sx={{
              p: "3px 15px",
              position: "relative",
              "&:hover": { bgcolor: BoxBlue },
              borderBottom: `1px solid ${BorderGray}`,
            }}
          >
            <Avatar
              sx={{
                mr: 1,
                width: 40,
                height: 40,
                bgcolor: stringToColor(user.userName),
              }}
            >
              <CustomText type="small">{user.userName.slice(0, 3)}</CustomText>
            </Avatar>
            <Box>
              <CustomText type="body" bold500>
                {user.userName}
              </CustomText>
              <CustomText type="small">{`${user.email}`}</CustomText>
              <CustomText type="small">
                {`${user.parentTeam ?? "-"} / ${user.team ?? "-"} / ${
                  user.jobTitle ?? "-"
                }  / ${user.jobTitle ?? "-"}`}
              </CustomText>
            </Box>
          </Box>
        ))}
      </ContentWrapper>
      <PostFolderUserAuth permissionType={permissionType} />
    </ListWrapper>
  );
}
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const KeyWordWrapper = styled.div`
  display: flex;
  width: 40%;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
`;
const ContentWrapper = styled.div`
  overflow-y: auto;
`;
