import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { BorderGray, InbodyRed } from "../../styles/GlobalStyle";
import MyCompleteCourse from "./components/MyCompleteCourse";
import MyProgressCourse from "./components/MyProgressCourse";

export default function MyPage() {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  return (
    <>
      <PageHeader title="내 강의실" />
      <Box>
        <TabTitle isActive={activeTab === 0} onClick={() => setActiveTab(0)}>
          <CustomText type="subtitle2" bold500>
            {t("학습중인 수업")}
          </CustomText>
        </TabTitle>
        <TabTitle isActive={activeTab === 1} onClick={() => setActiveTab(1)}>
          <CustomText type="subtitle2" bold500>
            {t("학습종료된 수업")}
          </CustomText>
        </TabTitle>
      </Box>
      {activeTab === 0 && <MyProgressCourse />}
      {activeTab === 1 && <MyCompleteCourse />}
    </>
  );
}

const TabTitle = styled.button<{ isActive: boolean }>`
  width: calc(100% / 2);
  border: 1px solid ${BorderGray};
  text-align: center;
  line-height: 45px;
  background-color: ${(props) => (props.isActive ? InbodyRed : "white")};
  color: ${(props) => (props.isActive ? "white" : "black")};
  cursor: pointer;
  height: 45px;
`;
