import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQnaDetail } from '../QnaType';
import useQnaAPIs from '../useQnaAPIs';

const useFetchQnaDetail = <T = FetchQnaDetail>(
    qnaId: number,
    options?: UseQueryOptions<FetchQnaDetail, AxiosError, T>
) => {
    const { getDetailQnaAPI} = useQnaAPIs();

    return useQuery({
        queryKey: QueryKeys.qnaDetail(qnaId),
        queryFn: () => getDetailQnaAPI(qnaId),
        enabled: !isNaN(qnaId) && qnaId > 0,
        ...options
    });
}

export default useFetchQnaDetail;