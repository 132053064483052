import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import { BasicFontKR } from '../../styles/GlobalStyle';

export interface TextCustomProps {
  overflowTooltip?: boolean;
  bold400?: boolean;
  bold500?: boolean;
  bold600?: boolean;
  blur?: boolean;
  children: ReactNode;
  mobile?: boolean;
  fullWidth?: boolean;
  inline?: boolean;
  wordBreak?: boolean;
}

export interface TextType {
  type?: 'small' | 'body' | 'subtitle' | 'subtitle2' | 'title' | 'bigTitle' | 'superTitle' | 'button' | 'buttonLarge'
}
export type CustomTextProps = TextType & TypographyProps & TextCustomProps;

export function CustomText({
  overflowTooltip,
  bold400,
  bold500,
  bold600,
  blur,
  children,
  mobile,
  fullWidth,
  type: textType = 'body',
  inline,
  wordBreak,
  sx,
  ...props }: CustomTextProps) {

  const fontSizeMap = {
    small: mobile ? '10px' : '12px',
    body: mobile ? '12px' : '14px',
    subtitle: mobile ? '14px' : '16px',
    subtitle2: mobile ? '16px' : '18px',
    title: mobile ? '18px' : '20px',
    bigTitle: mobile ? '20px' : '24px',
    superTitle: mobile ? '28px' : '32px',
    button: mobile ? '14px' : '16px',
    buttonLarge: mobile ? '16px' : '18px',
  };

  const fontWeightMap = {
    small: 400,
    body: 400,
    subtitle: 500,
    subtitle2: 500,
    title: 600,
    bigTitle: 600,
    superTitle: 600,
    button: 500,
    buttonLarge: 600,
  };

  const fontSize = fontSizeMap[textType];
  const baseFontWeight = fontWeightMap[textType];

  return (
    <Typography
      sx={{
        ...sx,
        fontSize: fontSize,
        fontWeight: baseFontWeight,
        fontFamily: BasicFontKR,
        whiteSpace: wordBreak ? 'pre-line' : 'nowrap',
        wordBreak: 'break-all',
        ...(fullWidth ? {
          width: '100%'
        } : {}),
        ...(overflowTooltip ? {
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        } : {}),
        ...(bold400 ? {
          fontWeight: 400
        } : {}),
        ...(bold500 ? {
          fontWeight: 500
        } : {}),
        ...(bold600 ? {
          fontWeight: 600
        } : {}),
        ...(blur ? {
          opacity: 0.5
        } : {}),
      }}
      {...props}>
      {children}
    </Typography>
  );
}

