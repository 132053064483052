import styled from "@emotion/styled";
import { Button, Grid, Modal } from "@mui/material";
import { MaskedTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  DrawNodeEventArgs,
  NodeCheckEventArgs,
  TreeViewComponent,
} from "@syncfusion/ej2-react-navigations";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { FetchEmployeeTree } from "../../api/domains/employee/EmployeeType";
import useFetchEmployeeTree from "../../api/domains/employee/queries/useFetchEmployeeTree";
import {
  useAppendIconAndExpandAll,
  useTreeModalHooks,
} from "../../hooks/modals/useTreeModalHooks";
import { BorderGray } from "../../styles/GlobalStyle";
import { CustomText } from "../text/CustomText";
import useFetchUserList from "../../api/domains/user/queries/useFetchUserList";
import useEmailTargetStore, {
  SendType,
} from "../../stores/domains/useEmailTargetStore";

const InitDeptTreeData: FetchEmployeeTree = {
  id: "root",
  name: "(주)인바디",
  subChild: [],
};

interface InbodyTreeModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalType: SendType;
}

export default function InbodyTreeModal({
  isOpen,
  setIsOpen,
  modalType,
}: InbodyTreeModalProps) {
  let treeObj: TreeViewComponent;
  let maskObj: MaskedTextBoxComponent;
  const { t } = useTranslation();

  const { mailTargetList, setValue } = useEmailTargetStore((state) => ({
    mailTargetList: state[modalType],
    setValue: state.setValue,
  }));

  const [employeeTreeData, setEmployeeTreeData] = useState(InitDeptTreeData);

  const { data } = useFetchEmployeeTree();
  const { data: userList } = useFetchUserList();

  useAppendIconAndExpandAll(setEmployeeTreeData, data);

  const { searchNodes } = useTreeModalHooks();

  //checked된 노드들
  const checkedNodes = userList
    ?.filter((user) => Array.from(mailTargetList).includes(user.email))
    .map((user) => user.employeeId ?? "")
    .filter(Boolean);

  console.log(checkedNodes);
  //첫 로딩 checked 표시
  const drawNode = (args: DrawNodeEventArgs) => {
    const nodeId = args.node.dataset.uid;

    if (nodeId && checkedNodes?.includes(nodeId)) {
      let ele: HTMLElement | null = args.node.querySelector(".e-frame.e-icons");
      ele?.classList.add("e-check");
    }
  };

  //Store에 데이터 반영하고 모달 종료
  const handleCloseModal = () => {
    const checkedNodeList = treeObj.getAllCheckedNodes();
    const userIdList = checkedNodeList.filter(
      (userId) => !/^[a-zA-Z]/.test(userId)
    );

    const checkedUserEmailList = userIdList
      .map((userId) => {
        const findUser = userList?.find((user) => user.employeeId === userId);
        if (findUser) {
          return findUser.email;
        }
        return "";
      })
      .filter(Boolean);

    setValue(modalType, checkedUserEmailList);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="inbody-tree-modal"
      aria-describedby="inbody-tree-modal"
    >
      <ModalContent>
        <ModalTitle>
          <CustomText type="title">인바디 조직도</CustomText>
        </ModalTitle>
        <MaskedTextBoxComponent
          cssClass="e-outline"
          placeholder="Search"
          floatLabelType="Never"
          ref={(mask) => {
            maskObj = mask as MaskedTextBoxComponent;
          }}
          change={() => searchNodes(treeObj, maskObj, employeeTreeData)}
        />
        <TreeContent>
          <TreeViewComponent
            cssClass="e-outline"
            fields={{
              dataSource: [{ ...employeeTreeData }],
              text: "name",
              child: "subChild",
              iconCss: "icon",
            }}
            ref={(treeview) => {
              treeObj = treeview as TreeViewComponent;
            }}
            drawNode={drawNode}
            checkedNodes={checkedNodes}
            showCheckBox={true}
            // nodeClicked={nodeClicked}
          />
        </TreeContent>
        <Grid pt={1} container justifyContent={"center"}>
          <Button
            color="inherit"
            variant="contained"
            onClick={handleCloseModal}
          >
            {t("확인")}
          </Button>
        </Grid>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 400px;
  border: 2px solid #000;
  padding: 16px 32px 16px;
`;
const ModalTitle = styled.div`
  color: #4c8df5;
  margin-top: 20px;
  margin-bottom: 10px;
`;
const TreeContent = styled.div`
  height: 700px;
  overflow: auto;
  border: 1px solid ${BorderGray};
`;
