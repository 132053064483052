import { create } from "zustand";

type State = {
  isMenuOpen: boolean;
};

type Action = {
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenuOpen: () => void;
};

const initialState: State = {
  isMenuOpen: true,
};

export const useLayoutStore = create<State & Action>((set) => ({
  ...initialState,

  openMenu: () => set({ isMenuOpen: true }),
  closeMenu: () => set({ isMenuOpen: false }),
  toggleMenuOpen: () =>
    set((state) => {
      return {
        isMenuOpen: !state.isMenuOpen,
      };
    }),
}));
