import { Box, SxProps } from '@mui/material';
import { ReactNode } from "react";
import { BorderGray, BoxBlue, BoxPale } from '../../styles/GlobalStyle';

interface ListTableWrapperProps {
  children: ReactNode;
  pointer?: boolean;
  hoverColor?: boolean;
  thPadding?: string;
  tdPadding?: string;
}

export default function CustomTableWrapper({
  children,
  pointer,
  hoverColor,
  thPadding,
  tdPadding,
  ...props }: ListTableWrapperProps & SxProps) {
  return (
    <Box sx={{
      border: `1px solid ${BorderGray}`,
      '& td': { padding: '8px', cursor: pointer ? 'pointer' : null },
      '& tbody tr:hover': { backgroundColor: hoverColor ? BoxBlue : null },
      '& th': { padding: thPadding ?? '10px 16px', backgroundColor: BoxPale },
      '& tbody td': { padding: tdPadding ?? null },
      '& table': { tableLayout: 'fixed' },
      ...props
    }}>
      {children}
    </Box>
  )
}