import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { DataManager } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  Edit,
  EditSettingsModel,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  Resize,
  SaveEventArgs,
  Search,
  Sort,
  Toolbar,
  ToolbarItems,
} from "@syncfusion/ej2-react-grids";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  FetchQuizResultList,
  UpdateAnswerScore,
} from "../../../api/domains/answer/UserAnswerType";
import useFetchQuizResultList from "../../../api/domains/answer/queries/useFetchQuizResultList";
import useUserAnswerAPIs from "../../../api/domains/answer/useUserAnswerAPIs";
import useFetchManagingQuizList from "../../../api/domains/quiz/queries/useFetchManagingQuizList";
import ToastAlert from "../../../components/alert/ToastAlert";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import useDateHooks from "../../../hooks/common/useDateHooks";
import { BoxPale } from "../../../styles/GlobalStyle";

export default function QuizResultList() {
  const { t } = useTranslation();
  const [selectQuizId, setSelectQuizId] = useState<number>(0);
  const gridRef = useRef<GridComponent | null>(null);

  const { scoredAnswerAPI } = useUserAnswerAPIs();

  const { data: managingQuizList } = useFetchManagingQuizList();
  const { data: attemptList } = useFetchQuizResultList(selectQuizId);

  const { getDateStringFormat } = useDateHooks();

  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (args !== undefined && args.item !== undefined) {
      if (args.item.id?.includes("excel") && gridRef.current !== null) {
        gridRef.current.excelExport();
      }
    }
  };

  const scoreMutation = useMutation((request: UpdateAnswerScore) =>
    scoredAnswerAPI(request)
  );

  // 여기 자꾸 새롭게 만들어짐...
  const actionComplete = (args: SaveEventArgs) => {
    if (args.requestType === "save") {
      const updatedRecord = args.data as FetchQuizResultList;

      let scored = updatedRecord.getScore;

      if (updatedRecord.getScore > updatedRecord.maxScore) {
        scored = updatedRecord.maxScore;
      }

      const request: UpdateAnswerScore = {
        attemptId: updatedRecord.attemptId,
        answerId: updatedRecord.answerId,
        score: scored,
      };

      scoreMutation.mutate(request, {
        onSuccess: () =>
          ToastAlert(t("점수가 정상적으로 입력되었습니다."), "success"),
      });
    }
  };

  const getScoreTemplate = (data: FetchQuizResultList) => {
    return (
      <Grid container justifyContent={"center"}>
        <CustomText type="body" sx={{ mr: 1 }}>
          {data.getScore}
        </CustomText>
        <Tooltip title={t("더블클릭 하면, 점수를 수정할 수 있습니다.")}>
          <EditIcon fontSize="small" />
        </Tooltip>
      </Grid>
    );
  };

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: "Normal",
  };

  const toolbarOptions: ToolbarItems[] = [
    "Update",
    "Cancel",
    "Search",
    "ExcelExport",
  ];

  const groupOptions: GroupSettingsModel = {
    columns: ["userName"],
  };

  const detailTemplate = (data: FetchQuizResultList) => {
    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell width={"15%"} component={"th"} sx={{ bgcolor: BoxPale }}>
              <CustomText type="subtitle" align="center">
                {t("문제")}
              </CustomText>
            </TableCell>
            <TableCell width={"85%"}>
              <CustomText type="body">{data.content}</CustomText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component={"th"} sx={{ bgcolor: BoxPale }}>
              <CustomText type="subtitle" align="center">
                {t("모범답안")}
              </CustomText>
            </TableCell>
            <TableCell>
              <CustomText type="body">{data.answer}</CustomText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component={"th"} sx={{ bgcolor: BoxPale }}>
              <CustomText type="subtitle" align="center">
                {t("제출답안")}
              </CustomText>
            </TableCell>
            <TableCell>
              <CustomText type="body">{data.submitAnswer}</CustomText>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <>
      <Grid item xs={12} mt={3}>
        <CustomTableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="20%" component="th" align="center">
                  <CustomText type="subtitle">{t("시험명")}</CustomText>
                </TableCell>
                <TableCell width="80%">
                  {managingQuizList && (
                    <DropDownListComponent
                      cssClass="e-outline"
                      dataSource={new DataManager(managingQuizList)}
                      placeholder={
                        managingQuizList.length > 0
                          ? t("시험을 선택해주세요.")
                          : t("해당 강의에 시험이 없습니다.")
                      }
                      enabled={managingQuizList.length > 0}
                      value={selectQuizId}
                      fields={{
                        text: "quizName",
                        value: "quizId",
                      }}
                      change={(e) => setSelectQuizId(e.value)}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CustomTableWrapper>
      </Grid>
      <Grid item xs={12}>
        <GridWrapper>
          <GridComponent
            dataSource={new DataManager(attemptList)}
            detailTemplate={detailTemplate}
            editSettings={editOptions}
            allowResizing={true}
            allowPaging={true}
            allowSorting={true}
            allowFiltering={true}
            allowGrouping={true}
            allowExcelExport={true}
            toolbar={toolbarOptions}
            toolbarClick={toolbarClick}
            groupSettings={groupOptions}
            filterSettings={{ type: "Excel" }}
            ref={gridRef}
            actionComplete={actionComplete}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="attemptId"
                isPrimaryKey={true}
                visible={false}
              />
              <ColumnDirective field="questionId" visible={false} />
              <ColumnDirective field="answerId" visible={false} />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="questionOrder"
                allowEditing={false}
                headerText={t("번호")}
                width="10%"
              />
              <ColumnDirective
                clipMode="EllipsisWithTooltip"
                headerTextAlign="Center"
                textAlign="Center"
                field="questionType"
                allowEditing={false}
                headerText={t("문제유형")}
                width="10%"
              />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="userName"
                allowEditing={false}
                headerText={t("성명")}
                width="10%"
              />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="quizStartTime"
                allowEditing={false}
                headerText={t("제출시간")}
                valueAccessor={(_, data) =>
                  getDateStringFormat(
                    (data as FetchQuizResultList).lastSubmitTime,
                    "YYYY-MM-DD hh:mm"
                  )
                }
                width="10%"
              />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="getScore"
                allowFiltering={false}
                template={getScoreTemplate}
                headerText={t("획득점수")}
                width="10%"
              />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="maxScore"
                allowEditing={false}
                allowFiltering={false}
                headerText={t("문제점수")}
                width="10%"
              />
              <ColumnDirective
                headerTextAlign="Center"
                textAlign="Center"
                field="isScored"
                allowEditing={false}
                template={(data: FetchQuizResultList) => (
                  <span>{data.isScored ? t("완료") : t("채점중")}</span>
                )}
                headerText={t("채점여부")}
                width="10%"
              />
            </ColumnsDirective>
            <Inject
              services={[
                DetailRow,
                Resize,
                Edit,
                Toolbar,
                Filter,
                Page,
                Sort,
                Search,
                Group,
                ExcelExport,
              ]}
            />
          </GridComponent>
        </GridWrapper>
      </Grid>
    </>
  );
}

const GridWrapper = styled.div`
  & th.e-headercell.e-fltr-icon.e-centeralign.e-mousepointer {
    padding: 0 10px;
  }
  &
    th.e-grouptopleftcell.e-lastgrouptopleftcell
    > div.e-headercelldiv.e-emptycell {
    padding: 0;
  }
  & div.e-headercelldiv {
    padding: 0 !important;
  }
`;
