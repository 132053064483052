export const CourseType = {
  신규입사자교육: "신규입사자교육",
  직무교육: "직무교육",
  법정의무교육: "법정의무교육",
  리더십교육: "리더십교육",
  ProductTraining: "ProductTraining",
  ApplicationTraining: "ApplicationTraining",
  ClinicalTraining: "ClinicalTraining",
  Others: "Others",
} as const;

export type CourseTypeInterface = (typeof CourseType)[keyof typeof CourseType];
