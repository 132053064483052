import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchUserStudyInfoList } from '../UserType';
import useUserAPIs from '../useUserAPIs';

const useFetchUserStudyInfoList = <T = FetchUserStudyInfoList[]>(
    options?: UseQueryOptions<FetchUserStudyInfoList[], unknown, T>
) => {
    const { getUserStudyInfoListAPI } = useUserAPIs();

    return useQuery({
        queryKey: QueryKeys.userStudyInfoList(),
        queryFn: () => getUserStudyInfoListAPI(),
        refetchOnWindowFocus: false,
        ...options
    });
}

export default useFetchUserStudyInfoList;