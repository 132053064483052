import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../api/config/MsalConfig";
import useUserAPIs from "../../api/domains/user/useUserAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import Urls from "../../routes/Urls";
import { useLoadingStore } from "../../stores/loading/useLoadingStore";
import { useUserStore } from "../../stores/user/useUserStore";
import { FetchUserDto, UserLoginDto } from "../../api/domains/user/UserType";
import { ChangeEvent, useState } from "react";
import { AxiosError } from "axios";

const useLoginHooks = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { ssoLoginAPI, loginAPI } = useUserAPIs();

  const [accountId, setAccountId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const { logIn, logOut } = useUserStore((state) => ({
    logIn: state.logIn,
    logOut: state.logOut,
  }));

  const { closeLoading, openLoading } = useLoadingStore((state) => ({
    closeLoading: state.closeLoading,
    openLoading: state.openLoading,
  }));

  //#region account id change
  const handleAccountIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAccountId(e.target.value);
    if (errorText) {
      setErrorText("");
    }
  };
  //#endregion

  //#region password change
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (errorText) {
      setErrorText("");
    }
  };
  //#endregion

  //#region id, pwd 로그인
  const inputValidationCheck = () => {
    if (accountId.trim() === "") {
      setErrorText(t("아이디를 입력해주세요."));
      return false;
    } else if (password.trim() === "") {
      setErrorText(t("비밀번호를 입력해주세요."));
      return false;
    }
    return true;
  };
  //#endregion

  //#region id, pwd 로그인
  const handleIdLogin = () => {
    setIsSubmit(true);

    if (inputValidationCheck()) {
      idLogin(accountId, password);
    }
  };
  //#endregion

  //#region SSO Login
  const ssoLogin = () => {
    instance
      .acquireTokenPopup(loginRequest)
      .then((response) => {
        openLoading(t("로그인 정보를 불러오는 중입니다."));
        ssoLoginAPI({ msToken: response.accessToken })
          .then((data) => {
            window.sessionStorage.setItem("jwtToken", data.jwtToken);
            logIn(data);
          })
          .catch((err) => {
            sessionStorage.clear();
            logOut();
            ToastAlert(t("임직원으로 등록되지 않은 이메일"), "error");
            navigate(Urls.login);
          })
          .finally(() => {
            closeLoading();
          });
      })
      .catch((err) => {});
  };
  //#endregion

  //#region 아이디 로그인
  const idLogin = (id: string, pwd: string) => {
    const request: UserLoginDto = {
      accountId: id,
      password: pwd,
    };
    loginAPI(request)
      .then((data) => {
        window.sessionStorage.setItem("jwtToken", data.jwtToken);
        logIn(data);
      })
      .catch((err) => {
        sessionStorage.clear();
        logOut();
        ToastAlert(t("일반 로그인 실패(인증)"), "error");
        navigate(Urls.login);
      });
  };
  //#endregion

  return {
    ssoLogin,
    accountId,
    password,
    errorText,
    showPassword,
    setShowPassword,
    isSubmit,
    inputValidationCheck,
    handleIdLogin,
    handleAccountIdChange,
    handlePasswordChange,
  };
};

export default useLoginHooks;
