import { useQuery, UseQueryOptions } from 'react-query';
import { CourseTypeInterface } from '../../../../data/enums/CourseType';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchCourseList } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';
import { StaleTime } from '../../../config/StaleTime';

const useFetchCourseList = <T = FetchCourseList[]>(
    courseType?: CourseTypeInterface,
    options?: UseQueryOptions<FetchCourseList[], unknown, T>
)=> {
    const { getCourseListAPI } = useCourseAPIs();
    
    return useQuery({
        queryKey: QueryKeys.courseList(courseType),
        queryFn: () => getCourseListAPI(courseType),
        staleTime: StaleTime.FIVE,
        ...options
    });
}

export default useFetchCourseList;