import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchQuizTargetList } from '../QuizType';
import useQuizAPIs from '../useQuizAPIs';

const useFetchUserListWithQuizTargetInfo = <T = FetchQuizTargetList[]>(
    quizId: number,
    options?: UseQueryOptions<FetchQuizTargetList[], AxiosError, T>
) => {

    const { getUserListWithQuizTargetInfoAPI} = useQuizAPIs();

    return useQuery({
        queryKey: QueryKeys.quizTargetList(quizId?? 0),
        queryFn: () => getUserListWithQuizTargetInfoAPI(quizId),
        enabled: quizId > 0,
        ...options
    });
}

export default useFetchUserListWithQuizTargetInfo;