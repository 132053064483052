import { create } from "zustand";
import {
  StoragePermission,
  StoragePermissionType,
} from "../../data/enums/PermissionType";

export type FolderModalType = "create" | "update";

interface FolderModalStoreType {
  isFolderModalOpen: boolean;
  folderModalType: FolderModalType;
  setFolderModalState: (isOpen: boolean, modalType: FolderModalType) => void;

  isAuthModalOpen: boolean;
  authModalType: StoragePermissionType;
  setAuthModalState: (
    isOpen: boolean,
    modalType: StoragePermissionType
  ) => void;

  closeAuthModal: () => void;
  closeFolderModal: () => void;
}

const initialState = {
  isFolderModalOpen: false,
  folderModalType: "create" as FolderModalType,

  isAuthModalOpen: false,
  authModalType: StoragePermission.NONE,
};
export const useFolderModalStore = create<FolderModalStoreType>((set) => ({
  ...initialState,

  setFolderModalState: (isOpen, modalType) =>
    set({ isFolderModalOpen: isOpen, folderModalType: modalType }),
  setAuthModalState: (isOpen, modalType) =>
    set({ isAuthModalOpen: isOpen, authModalType: modalType }),

  closeAuthModal: () => set({ isAuthModalOpen: false, authModalType: "NONE" }),
  closeFolderModal: () => set(initialState),
}));
