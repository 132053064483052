import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/button/CustomButton";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { CourseTypeInterface } from "../../data/enums/CourseType";
import getCourseUrlByCourseType from "../../hooks/course/getCourseDetailUrlByCourseType";
import CourseInfo from "./components/CourseInfo";
import DocumentList from "./components/DocumentList";
import LectureList from "./components/LectureList";

interface CourseDetailPageProps {
  courseType: CourseTypeInterface;
}

export default function CourseDetailPage({
  courseType,
}: CourseDetailPageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Grid container direction="row" rowSpacing={5} sx={{ mt: 0 }}>
      <Grid item xs={12}>
        <PageHeader title={t("강의상세보기")} />
      </Grid>
      <Grid item xs={12}>
        <CourseInfo />
      </Grid>
      <Grid item xs={12}>
        <LectureList />
      </Grid>
      <Grid item xs={12}>
        <DocumentList />
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <CustomButton
            rectangle
            colorType="black"
            variant="contained"
            onClick={() =>
              navigate(getCourseUrlByCourseType(courseType, "list"))
            }
          >
            <CustomText type="button">{t("강의목록")}</CustomText>
          </CustomButton>
          <CustomButton
            rectangle
            colorType="white"
            border="1px solid black"
            variant="contained"
            color="inherit"
            onClick={() => navigate(-1)}
          >
            <CustomText type="button">{t("이전으로")}</CustomText>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
