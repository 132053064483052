import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchCourseNameList } from '../CourseType';
import useCourseAPIs from '../useCourseAPIs';


const useFetchCourseNameList = <T = FetchCourseNameList[]>(
    options?: UseQueryOptions<FetchCourseNameList[], unknown, T>
) => {
    const { getCourseNameListAPI} = useCourseAPIs();

    return useQuery({
        queryKey: QueryKeys.courseNameList(),
        queryFn: getCourseNameListAPI,
        ...options
    });
}

export default useFetchCourseNameList;