import useGetApiInstance from '../../config/BasicApi';
import { CreateQuizVersion, FetchQuizVersionList } from './QuizVersionType';


export const useQuizVersionAPIs = () => {
  const api = useGetApiInstance('quiz-version');

  // 퀴즈 버전 리스트 조회
  const getQuizVersionListAPI = (quizId: number): Promise<FetchQuizVersionList[]> => 
    api.get(`list/${quizId}`).then(res => res.data.result);

  // 퀴즈 버전 생성
  const createQuizVersionAPI = (request: CreateQuizVersion): Promise<number> =>
    api.post('create', request).then(res => res.data.result);
  
  
  return {
    getQuizVersionListAPI,
    createQuizVersionAPI,
  }
}

export default useQuizVersionAPIs;