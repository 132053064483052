import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { getRegExp } from "korean-regexp";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useFetchCourseList from "../../api/domains/course/queries/useFetchCourseList";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import { PageSize } from "../../data/enums/PageSize";
import {
  CourseSearchCondition,
  CourseSearchConditionType,
} from "../../data/enums/SearchCondition";
import useCourseNameHooks from "../../hooks/common/useCourseNameHooks";
import { getCourseTypeByPath } from "../../hooks/course/getCourseTypeByPath";
import { BasicFontKR, BorderGray, DividerGray } from "../../styles/GlobalStyle";
import CourseTableItem from "./components/CourseTableItem";

export default function CourseListPage() {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [searchCondition, setSearchCondition] =
    useState<CourseSearchConditionType>("Name");
  const [searchKeyWords, setSearchKeyWords] = useState("");

  const courseType = getCourseTypeByPath(location.pathname);

  const { data: courseList } = useFetchCourseList(courseType);

  const renderCourseList = courseList?.filter((course) => {
    switch (searchCondition) {
      case CourseSearchCondition.NAME:
        return course.courseName.search(getRegExp(searchKeyWords)) !== -1;
      case CourseSearchCondition.PROFESSOR:
        return course.professorName?.search(getRegExp(searchKeyWords)) !== -1;
      default:
        return true;
    }
  });

  const { getCourseName } = useCourseNameHooks();

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={getCourseName(courseType)} />
      </Grid>
      <Grid container justifyContent={"flex-end"}>
        <Select
          size="small"
          value={searchCondition}
          onChange={(event) =>
            setSearchCondition(event.target.value as CourseSearchConditionType)
          }
          displayEmpty
        >
          <MenuItem value={CourseSearchCondition.NAME}>
            <CustomText>{t("강의명")}</CustomText>
          </MenuItem>
          <MenuItem value={CourseSearchCondition.PROFESSOR}>
            <CustomText>{t("강사명")}</CustomText>
          </MenuItem>
        </Select>
        <KeyWordWrapper>
          <InputBase
            sx={{ ml: 1, flex: 1, fontFamily: BasicFontKR, fontSize: "14px" }}
            className="inputField"
            placeholder={t("검색어를 입력하세요.")}
            value={searchKeyWords}
            onChange={(event) => setSearchKeyWords(event.target.value)}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="small"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </KeyWordWrapper>
      </Grid>
      <Grid item xs={12}>
        <Table
          sx={{
            tableLayout: "fixed",
            border: `1px solid ${BorderGray}`,
            "& td": { height: "130px" },
          }}
        >
          <TableBody>
            {renderCourseList && renderCourseList.length === 0 && (
              <TableRow>
                <TableCell align="center" sx={{ height: 130 }}>
                  <CustomText type="subtitle2">
                    {t("조회된 강의가 없습니다.")}
                  </CustomText>
                </TableCell>
              </TableRow>
            )}
            {renderCourseList &&
              renderCourseList
                .slice((page - 1) * PageSize.BASIC, page * PageSize.BASIC)
                .map((course) => {
                  return (
                    <CourseTableItem
                      key={course.courseId}
                      course={course}
                      courseType={course.courseType}
                    />
                  );
                })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <PaginationWrapper id="pagination-wrapper">
          {courseList && (
            <Pagination
              color="standard"
              onChange={(e, page) => setPage(page)}
              count={Math.ceil(courseList.length / PageSize.BASIC)}
            />
          )}
        </PaginationWrapper>
      </Grid>
    </Grid>
  );
}
export const KeyWordWrapper = styled.div`
  margin-left: 3px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 25%;
  padding: 2px 4px;
  border: 1px solid ${DividerGray};
  border-radius: 3px;
  & input::placeholder {
    font-size: 14px;
    font-family: ${BasicFontKR};
  }
`;
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
