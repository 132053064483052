import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FetchNoticeList } from '../NoticeType';
import useNoticeAPIs from '../useNoticeAPIs';

const useFetchNoticeList = <T = FetchNoticeList[]>(
    options?: UseQueryOptions<FetchNoticeList[], AxiosError, T>
) => {
    const { getNoticeListAPI} = useNoticeAPIs();

    return useQuery({
        queryKey: QueryKeys.noticeList(),
        queryFn: () => getNoticeListAPI(),
        ...options
    });
}

export default useFetchNoticeList;