import styled from "@emotion/styled";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchFolderDetail from "../../api/domains/folder/queries/useFetchFolderDetail";
import useFetchFolderPathList from "../../api/domains/folder/queries/useFetchFolderPathList";
import useFetchAllTagTextList from "../../api/domains/tag/queries/useFetchAllTagTextList";
import { CustomText } from "../../components/text/CustomText";
import { StoragePermission } from "../../data/enums/PermissionType";
import useFolderHooks from "../../hooks/domains/useFolderHooks";
import { useFolderHistoryStore } from "../../stores/folder/useFolderHistoryStore";
import { useFolderModalStore } from "../../stores/folder/useFolderModalStore";
import { useFolderStore } from "../../stores/folder/useFolderStore";
import { BorderGray, BoxBlue } from "../../styles/GlobalStyle";
import ListFolderUserAuth from "./components/ListFolderUserAuth";

export default function PostFolderModal() {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [tagInputValue, setTagInputValue] = useState("");

  //Modal Store
  const { isFolderModalOpen, folderModalType, closeFolderModal } =
    useFolderModalStore((state) => ({
      isFolderModalOpen: state.isFolderModalOpen,
      folderModalType: state.folderModalType,
      closeFolderModal: state.closeFolderModal,
    }));

  //History Store
  const currentFolderId = useFolderHistoryStore(
    (state) => state.currentFolderId
  );

  const updateValue = useFolderStore((state) => state.updateValue);

  const {
    koreanName,
    englishName,
    isSecretFolder,
    tagList,
    folderAuthList,
    isSubmit,
    isDirty,
    setTagList,
    resetFolderStore,
  } = useFolderStore((state) => ({
    koreanName: state.koreanName,
    englishName: state.englishName,
    isSecretFolder: state.isSecretFolder,
    tagList: state.tagList,
    folderAuthList: state.folderAuthList,
    isSubmit: state.isSubmit,
    isDirty: state.isDirty,
    setTagList: state.setTagList,
    resetFolderStore: state.resetFolderStore,
  }));

  //Data Fetching and Set
  const { data: folderPathTree } = useFetchFolderPathList(currentFolderId);
  const { data: allTagList } = useFetchAllTagTextList();
  const { data: folderDetail } = useFetchFolderDetail(currentFolderId);

  const { createFolderRequest, updateFolderRequest, useCreateFolderStore } =
    useFolderHooks();

  useCreateFolderStore(folderModalType, isFolderModalOpen, folderDetail);

  return (
    <Drawer
      anchor={"right"}
      open={isFolderModalOpen}
      sx={{ "& .MuiDrawer-paper": { width: "60%" } }}
    >
      <Wrapper>
        <Box
          mt={2}
          sx={{ height: "5%" }}
          display={"flex"}
          alignItems={"center"}
        >
          <CustomText type="title">
            {folderModalType === "create"
              ? t("새 폴더 만들기")
              : t("폴더 수정")}
          </CustomText>
        </Box>
        <Box mt={2} sx={{ height: "35%" }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell width={"15%"}>
                  <CustomText type="subtitle">{t("경로")}</CustomText>
                </TableCell>
                <TableCell width={"85%"}>
                  <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"}>
                    {folderModalType === "create" &&
                      folderPathTree &&
                      folderPathTree.map((folder, index, arr) => {
                        return (
                          <Fragment key={folder.id}>
                            <CustomText type="body">
                              {folder.koreanName}
                            </CustomText>
                            <NavigateNextIcon />
                          </Fragment>
                        );
                      })}
                    {folderModalType === "update" &&
                      folderPathTree &&
                      folderPathTree.slice(0, -1).map((folder) => {
                        return (
                          <Fragment key={folder.id}>
                            <CustomText type="body">
                              {folder.koreanName}
                            </CustomText>
                            <NavigateNextIcon />
                          </Fragment>
                        );
                      })}
                    <CustomText type="body" overflowTooltip>
                      {koreanName}
                    </CustomText>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <CustomText type="subtitle">{t("폴더 종류")}</CustomText>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    row
                    name="folder-type-radio-group"
                    value={isSecretFolder}
                    onChange={(e) =>
                      updateValue({ isSecretFolder: e.target.value === "true" })
                    }
                  >
                    <FormControlLabel
                      value={false}
                      disabled={
                        folderModalType === "update" ||
                        (folderModalType === "create" &&
                          folderDetail?.isSecretFolder)
                      }
                      control={<Radio size="small" />}
                      label={
                        <CustomText type="body" bold500>
                          {t("공개 폴더")}
                        </CustomText>
                      }
                    />
                    <FormControlLabel
                      value={true}
                      disabled={folderModalType === "update"}
                      control={<Radio size="small" />}
                      label={
                        <CustomText type="body" bold500>
                          {t("비공개 폴더")}
                        </CustomText>
                      }
                    />
                  </RadioGroup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <CustomText type="subtitle">
                    {t("폴더명(한글)")} <span style={{ color: "red" }}>*</span>
                  </CustomText>
                </TableCell>
                <TableCell>
                  <TextField
                    id="folder-name"
                    fullWidth
                    value={koreanName}
                    error={isSubmit && !koreanName.trim()}
                    helperText={
                      isSubmit && !koreanName.trim() && t("필수 입력값입니다.")
                    }
                    onChange={(e) =>
                      updateValue({ koreanName: e.target.value })
                    }
                    size="small"
                    placeholder={t("폴더명(한글)을 입력해주세요.")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <CustomText type="subtitle">
                    {t("폴더명(영문)")} <span style={{ color: "red" }}>*</span>
                  </CustomText>
                </TableCell>
                <TableCell>
                  <TextField
                    id="folder-description"
                    fullWidth
                    value={englishName}
                    error={isSubmit && !englishName.trim()}
                    helperText={
                      isSubmit && !englishName.trim() && t("필수 입력값입니다.")
                    }
                    onChange={(e) =>
                      updateValue({ englishName: e.target.value })
                    }
                    size="small"
                    placeholder={t("폴더명(영문)을 입력해주세요.")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <CustomText type="subtitle">{t("사용 가능 태그")}</CustomText>
                </TableCell>
                <TableCell>
                  <Alert severity="info" sx={{ p: "0 16px", mb: 1 }}>
                    <CustomText type="body" bold500>
                      {t(
                        "새로운 태그를 추가하려면, 태그 입력 후 엔터를 눌러주세요."
                      )}
                    </CustomText>
                  </Alert>
                  {allTagList && (
                    <Autocomplete
                      id="tag-auto-complete"
                      multiple
                      size="small"
                      freeSolo
                      options={allTagList?.map((tag) => ({ id: 0, text: tag }))}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        } else {
                          return option.text;
                        }
                      }}
                      limitTags={5}
                      value={tagList}
                      onChange={(e, value) => {
                        const newTagList = value.map((item) => {
                          if (typeof item === "string") {
                            return { id: 0, text: item };
                          } else {
                            return item;
                          }
                        });
                        setTagList(newTagList);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.text === value.text
                      }
                      inputValue={tagInputValue}
                      onInputChange={(_, newInputValue) => {
                        setTagInputValue(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            label={option.text}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box mt={2}>
          <CustomText type="subtitle" p={1}>
            {t("폴더 권한 부여")}
          </CustomText>
          <Box
            sx={{
              flexGrow: 1,
              height: "400px",
              bgcolor: "background.paper",
              display: "flex",
              borderTop: `1px solid ${BorderGray}`,
              borderBottom: `1px solid ${BorderGray}`,
            }}
          >
            <Tabs
              sx={{
                width: "20%",
                borderRight: 1,
                borderColor: "divider",
              }}
              orientation="vertical"
              variant="scrollable"
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              aria-label="Vertical tabs"
            >
              <Tab
                id="tab-manager"
                label={
                  <CustomText type={"body"} color={"black"}>
                    {t("폴더 관리 권한")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </CustomText>
                }
                sx={tabIndex === 0 ? { bgcolor: BoxBlue } : null}
              />
              <Tab
                id="tab-general"
                label={
                  <CustomText type={"body"} color={"black"}>
                    {isSecretFolder
                      ? t("업로드/다운로드 권한")
                      : t("업로드권한")}
                  </CustomText>
                }
                sx={tabIndex === 1 ? { bgcolor: BoxBlue } : null}
              />
            </Tabs>
            <Box sx={{ width: "80%" }}>
              {tabIndex === 0 && (
                <ListFolderUserAuth
                  permissionType={StoragePermission.MANAGER}
                />
              )}
              {tabIndex === 1 && (
                <ListFolderUserAuth
                  permissionType={StoragePermission.UPLOADER}
                />
              )}
            </Box>
          </Box>
        </Box>
        <ButtonWrapper>
          {folderAuthList.filter(
            (auth) => auth.permissionType === StoragePermission.MANAGER
          ).length === 0 ? (
            <Tooltip title={t("1명 이상의 폴더 관리자가 지정되어야 합니다.")}>
              <span>
                <Button
                  sx={{ mr: 2 }}
                  color="success"
                  variant="contained"
                  disabled
                >
                  <CustomText type="button">
                    {folderModalType === "create"
                      ? t("추가완료")
                      : t("수정완료")}
                  </CustomText>
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              sx={{ mr: 2 }}
              color="success"
              variant="contained"
              onClick={() => {
                if (folderModalType === "create") {
                  updateValue({ isSubmit: true });
                  createFolderRequest(currentFolderId);
                } else if (folderModalType === "update") {
                  updateValue({ isSubmit: true });
                  updateFolderRequest(currentFolderId);
                }
              }}
            >
              <CustomText type="button">
                {folderModalType === "create" ? t("추가완료") : t("수정완료")}
              </CustomText>
            </Button>
          )}
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              setTabIndex(0);
              closeFolderModal();
              resetFolderStore();
            }}
          >
            <CustomText type="button">{t("취소")}</CustomText>
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Drawer>
  );
}
const Wrapper = styled.div`
  height: 100%;
  padding: 0 16px;
`;
const ButtonWrapper = styled.div`
  margin-top: 10px;
  padding: 0 20%;
`;
