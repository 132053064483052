import styled from "@emotion/styled";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { ContentGray } from "../styles/GlobalStyle";

interface Props {
  hideDivider?: boolean;
}

export default function Footer({ hideDivider }: Props) {
  return (
    <FooterContainer id="footer">
      {!hideDivider && <Divider />}
      <FooterInfo>
        <FooterText>
          Copyright <CopyrightIcon fontSize="small" />{" "}
          {new Date().getFullYear()} InBody CO., LTD. All right reserved
        </FooterText>
      </FooterInfo>
    </FooterContainer>
  );
}

const Divider = styled.div`
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
`;
const FooterInfo = styled.div`
  margin-top: 15px;
  color: ${ContentGray};
  & svg {
    vertical-align: middle;
  }
`;
const FooterContainer = styled.div`
  width: 100%;
  bottom: 0;
  position: absolute;
  text-align: center;
  padding: 10px;
  height: 70px;
  left: 0;
  bottom: -130px;
`;
const FooterText = styled.span`
  font-size: 13px;
`;
