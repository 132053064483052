import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FolderHistory } from '../FolderType';
import useFolderAPI from '../useFolderAPI';

const useFetchFolderHistoryList = <T = FolderHistory[]>(
  options?:UseQueryOptions<FolderHistory[], AxiosError, T>
) => {
    
  const { getAllFolderHistoryAPI } = useFolderAPI();
  
  return useQuery({
    queryKey: QueryKeys.fileHistoryList(),
    queryFn: () => getAllFolderHistoryAPI(),
    ...options
  });
}

export default useFetchFolderHistoryList;