import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { FetchQnaList } from '../QnaType';
import useQnaAPIs from '../useQnaAPIs';
import { QueryKeys } from '../../../config/QueryKeys';
import { AxiosError } from 'axios';

const useFetchQnaList = <T = FetchQnaList[]>(
    options?: UseQueryOptions<FetchQnaList[], AxiosError, T>
) => {
    const { getQnaListAPI} = useQnaAPIs();

    return useQuery({
        queryKey: QueryKeys.qnaList(),
        queryFn: () => getQnaListAPI(),
        ...options
    });
}

export default useFetchQnaList;