import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  ListItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FetchUserList } from "../../../api/domains/user/UserType";
import { CustomText } from "../../../components/text/CustomText";
import useColorHooks from "../../../hooks/common/useColorHooks";
import useEmailTargetStore, {
  SendType,
} from "../../../stores/domains/useEmailTargetStore";
import { BorderGray, BoxBlue } from "../../../styles/GlobalStyle";

interface MailAutocompleteProps {
  options: FetchUserList[];
  sendType: SendType;
  selectedValue: Set<string>;
}

export default function MailAutocomplete({
  options,
  sendType,
  selectedValue,
}: MailAutocompleteProps) {
  const { t } = useTranslation();

  const { addValue, resetValue, deleteValue } = useEmailTargetStore(
    (state) => ({
      addValue: state.addValue,
      resetValue: state.resetValue,
      deleteValue: state.deleteValue,
    })
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: FetchUserList) =>
      `${option.userName} ${option.team} ${option.jobPosition} ${option.parentTeam}`,
  });

  const { stringToColor } = useColorHooks();

  return (
    <Autocomplete
      sx={{ width: "95%" }}
      multiple
      filterOptions={filterOptions}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      getOptionLabel={(option) => `${option.userName}(${option.email})`}
      renderOption={(props, user) => (
        <ListItem
          {...props}
          key={user.userId}
          sx={{
            p: "3px 15px",
            "&:hover": {
              bgcolor: BoxBlue,
              cursor: "pointer",
            },
            borderBottom: `1px solid ${BorderGray}`,
          }}
        >
          <Avatar
            sx={{
              mr: 1,
              width: 40,
              height: 40,
              bgcolor: stringToColor(user.userName.slice(0, 3)),
            }}
          >
            <CustomText type="small">{user.userName.slice(0, 3)}</CustomText>
          </Avatar>
          <Box>
            <CustomText type="body" bold500>
              {user.userName}
            </CustomText>
            <CustomText type="small">{`${user.email}`}</CustomText>
          </Box>
        </ListItem>
      )}
      options={options}
      value={options.filter((employee) => selectedValue.has(employee.email))}
      onChange={(_, newValue, reason, detail) => {
        const newEmailList = newValue.map((user) => user.email);
        addValue(sendType, newEmailList);
        if (reason === "removeOption" && detail && detail.option.email) {
          deleteValue(sendType, detail.option.email);
        }
        if (reason === "clear") {
          resetValue(sendType);
        }
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.userName}
            key={option.accountId}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label={t("받는 대상 선택")} />
      )}
    />
  );
}
