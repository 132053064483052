import {
  BlobServiceClient,
  BlockBlobParallelUploadOptions,
} from "@azure/storage-blob";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "../../api/config/QueryKeys";
import {
  FetchPostLectureList,
  PostLectureList,
} from "../../api/domains/lecture/LectureType";
import useLectureAPIs from "../../api/domains/lecture/useLectureAPIs";
import ToastAlert from "../../components/alert/ToastAlert";
import usePostLectureStore from "../../stores/domains/usePostLectureStore";
import { isLectureValid } from "../validation/isLectureValid";

const useLectureHooks = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { getLectureSasTokenAPI, postLectureListAPI } = useLectureAPIs();

  const {
    lectureList,
    createPostLectureStore,
    updateLecture,
    setIsSubmit,
    setIsUploading,
    resetPostLectureStore,
  } = usePostLectureStore((state) => ({
    lectureList: state.lectureList,
    createPostLectureStore: state.createStore,
    updateLecture: state.updateLecture,
    setIsSubmit: state.setIsSubmit,
    setIsUploading: state.setIsUploading,
    resetPostLectureStore: state.resetStore,
  }));

  //#region 영상 file duration 얻는 함수 TODO 리펙토링
  /**
   * @public
   * @param uploadFile
   * @returns
   */
  const getDurationFromFile = async (uploadFile: File) => {
    return new Promise((resolve) => {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.src = URL.createObjectURL(uploadFile);
    });
  };
  //#endregion

  //#region 강의 파일 스토리지에 업로드 하는 함수
  /**
   * @private
   * @param data
   * @param courseId
   * @param setValue
   * @returns
   */
  const uploadLectureFileToBlob = async (courseId: number) => {
    const res = await getLectureSasTokenAPI(courseId);
    const blobService = new BlobServiceClient(res);
    const containerClient = blobService.getContainerClient("");

    const uploadPromises = lectureList.map((lecture, index) => {
      if (lecture.lectureFile === null || lecture.uploadFileName === null) {
        return Promise.resolve();
      }

      const fileExtension = lecture.uploadFileName.split(".").pop();
      const mimeType = getVideoMimeType(fileExtension);

      const blockBlobClient = containerClient.getBlockBlobClient(
        lecture.uploadFileName
      );

      var blobUploadOptions: BlockBlobParallelUploadOptions = {
        blockSize: 4 * 1024 * 1024, // 4Mb
        concurrency: 20,
        maxSingleShotSize: 268435456, //0.268GB
        onProgress: (process) => {
          const percentage = Math.round(
            (process.loadedBytes / lecture.lectureFile!.size) * 100
          );
          updateLecture(lecture.lectureId, { progress: percentage });
        },
        blobHTTPHeaders: { blobContentType: mimeType },
      };
      return blockBlobClient.uploadData(
        lecture.lectureFile!,
        blobUploadOptions
      );
    });
    return Promise.all(uploadPromises);
  };
  //#endregion

  //#region 비디오 확장자로부터 mimeType(blob 파일 업로드 정보) 얻는 함수
  const getVideoMimeType = (extension: string | undefined) => {
    if (!extension) return "video/mp4";

    type ExtensionToMimeType = { [key: string]: string };

    const extensionToMimeType: ExtensionToMimeType = {
      mp4: "video/mp4",
      avi: "video/x-msvideo",
      mov: "video/quicktime",
      wmv: "video/x-ms-wmv",
      flv: "video/x-flv",
      mkv: "video/x-matroska",
      webm: "video/webm",
    };
    return extensionToMimeType[extension.toLocaleLowerCase()] || "video/mp4";
  };
  //#endregion

  //#region Lecture 생성 / 수정
  const postLectureQuery = useMutation((request: PostLectureList) =>
    postLectureListAPI(request)
  );

  const handleSubmitLecture = async (courseId: number) => {
    setIsSubmit(true);

    if (
      postLectureQuery.isLoading ||
      courseId === 0 ||
      lectureList.some((lecture) => !isLectureValid(lecture))
    ) {
      return;
    }

    try {
      setIsUploading(true);
      await uploadLectureFileToBlob(courseId);

      const request: PostLectureList = {
        courseId: courseId,
        lecturePostList: lectureList.map((lecture, index) => {
          return {
            lectureId: lecture.isNew ? 0 : lecture.lectureId,
            lectureOrder: index,
            title: lecture.title,
            duration: lecture.duration,
            originFileName: lecture.originFileName,
            uploadFileName: lecture.uploadFileName,
            lectureUrl: lecture.lectureUrl,
          };
        }),
      };
      postLectureQuery.mutate(request, {
        onSuccess: () => {
          ToastAlert(
            t("영상 변경 내용이 정상적으로 저장되었습니다."),
            "success"
          );
          queryClient.invalidateQueries(QueryKeys.lectureList(courseId));
        },
        onError: () => {
          ToastAlert(
            t("영상 업로드 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
            "error"
          );
          queryClient.invalidateQueries(QueryKeys.lectureList(courseId));
        },
      });
    } catch (error) {
      ToastAlert(
        t("영상 업로드 중 에러가 발생했습니다. 관리자에게 문의해주세요."),
        "error"
      );
      queryClient.invalidateQueries(QueryKeys.lectureList(courseId));
    }
  };
  // #endregion

  //#region 데이터 패칭 & 초기값 세팅 & 초기화
  const useCreateLectureStore = (lectureList?: FetchPostLectureList[]) => {
    useEffect(() => {
      if (lectureList) {
        createPostLectureStore(lectureList);
      }
      return () => resetPostLectureStore();
    }, [lectureList]);
  };
  //#endregion

  return {
    getDurationFromFile,
    handleSubmitLecture,

    useCreateLectureStore,
  };
};

export default useLectureHooks;
