import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import { FileListDto } from '../FileType';
import useFileAPI from '../useFileAPI';

const useFetchFileList = <T = FileListDto[]>(
    folderId: number,
    options?:UseQueryOptions<FileListDto[], AxiosError, T>
) => {
    
    const { getFileListAPI } = useFileAPI();
    
    return useQuery({
        queryKey: QueryKeys.fileList(folderId),
        queryFn: () => getFileListAPI(folderId),
        enabled: folderId > 0,
        ...options
    });
}

export default useFetchFileList;