import styled from "@emotion/styled";
import { Button, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomText } from "../../components/text/CustomText";
import { TitleOrange } from "../../styles/GlobalStyle";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper elevation={1} sx={{ p: 6, mt: 10 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Robot>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              width="128"
              height="128"
              fill={TitleOrange}
              id="fi_6872936"
            >
              <g id="_02_Error" data-name="02 Error">
                <g>
                  <path d="M16.3,25a1,1,0,0,0,2,0,1.36,1.36,0,0,1,.465-1.021,3.322,3.322,0,0,0,0-4.62,1.358,1.358,0,0,1,0-2.048A3.29,3.29,0,0,0,19.7,15a1,1,0,0,0-2,0,1.362,1.362,0,0,1-.466,1.024,3.326,3.326,0,0,0,0,4.622,1.356,1.356,0,0,1,0,2.045A3.28,3.28,0,0,0,16.3,25Z"></path>
                  <path d="M24.3,20a1,1,0,0,0,2,0,1.36,1.36,0,0,1,.465-1.021,3.322,3.322,0,0,0,0-4.62,1.358,1.358,0,0,1,0-2.048A3.29,3.29,0,0,0,27.7,10a1,1,0,0,0-2,0,1.362,1.362,0,0,1-.466,1.024,3.326,3.326,0,0,0,0,4.622,1.356,1.356,0,0,1,0,2.045A3.28,3.28,0,0,0,24.3,20Z"></path>
                  <path d="M42,21a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v3H40a1,1,0,0,1-1-1V12a1,1,0,0,0-2,0v2a3,3,0,0,0,3,3h1v3A1,1,0,0,0,42,21Z"></path>
                  <path d="M45,14v4a3,3,0,0,0,6,0V14a3,3,0,0,0-6,0Zm4,0v4a1,1,0,0,1-2,0V14a1,1,0,0,1,2,0Z"></path>
                  <path d="M55,14V12a1,1,0,0,0-2,0v2a3,3,0,0,0,3,3h1v3a1,1,0,0,0,2,0V12a1,1,0,0,0-2,0v3H56A1,1,0,0,1,55,14Z"></path>
                  <path d="M60.8,6.428a1,1,0,1,0-1.6,1.2A13.87,13.87,0,0,1,62,16,14.015,14.015,0,0,1,48,30a13.864,13.864,0,0,1-6.156-1.441,1,1,0,0,0-.716-.064L37,29.674v-4.7a1,1,0,0,0-.2-.6A13.87,13.87,0,0,1,34,16,13.981,13.981,0,0,1,55.465,4.172a1,1,0,0,0,1.07-1.691A15.98,15.98,0,0,0,35,25.3V31a1,1,0,0,0,1.275.961l5.039-1.44A15.8,15.8,0,0,0,48,32,16.019,16.019,0,0,0,64,16,15.851,15.851,0,0,0,60.8,6.428Z"></path>
                  <path d="M19,36H17a7,7,0,0,0,0,14H33a7,7,0,0,0,0-14H23a1,1,0,0,0,0,2H33a5,5,0,0,1,0,10H17a5,5,0,0,1,0-10h2a1,1,0,0,0,0-2Z"></path>
                  <path d="M20.707,40.293a1,1,0,0,0-1.414,0L18,41.586l-1.293-1.293a1,1,0,0,0-1.414,1.414L16.586,43l-1.293,1.293a1,1,0,1,0,1.414,1.414L18,44.414l1.293,1.293a1,1,0,0,0,1.414-1.414L19.414,43l1.293-1.293A1,1,0,0,0,20.707,40.293Z"></path>
                  <path d="M29.293,45.707a1,1,0,0,0,1.414,0L32,44.414l1.293,1.293a1,1,0,0,0,1.414-1.414L33.414,43l1.293-1.293a1,1,0,0,0-1.414-1.414L32,41.586l-1.293-1.293a1,1,0,0,0-1.414,1.414L30.586,43l-1.293,1.293A1,1,0,0,0,29.293,45.707Z"></path>
                  <path d="M47,40H45.962a12.931,12.931,0,0,0-2.171-6.251,1,1,0,0,0-1.66,1.117A10.94,10.94,0,0,1,44,41a1,1,0,0,0,1,1h2a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H46V45a1,1,0,0,0-2,0l.022,6a11.012,11.012,0,0,1-11,11h-16a11.015,11.015,0,0,1-11-11.011l-.022-2A1,1,0,0,0,5,48H3a1,1,0,0,1-1-1V43a1,1,0,0,1,1-1H4v3a1,1,0,0,0,2,0V41A11.013,11.013,0,0,1,17,30H32a1,1,0,0,0,0-2H17A13.017,13.017,0,0,0,4.038,40H3a3,3,0,0,0-3,3v4a3,3,0,0,0,3,3H4.011l.011,1a13.015,13.015,0,0,0,13,13h16a13.017,13.017,0,0,0,13-13.011L46.011,50H47a3,3,0,0,0,3-3V43A3,3,0,0,0,47,40Z"></path>
                </g>
              </g>
            </svg>
          </Robot>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="bigTitle" align="center">
            {t("페이지를 찾을 수 없습니다.")}
          </CustomText>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle" align="center">
            {t("존재하지 않는 주소를 입력하셨거나")}
          </CustomText>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle" align="center">
            {t("요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.")}
          </CustomText>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle" align="center">
            {t(
              "궁금한 점이 있으시면 언제든 인바디 인프라개발팀에 문의해 주시기 바랍니다."
            )}
          </CustomText>
        </Grid>
        <Grid item xs={12}>
          <CustomText type="subtitle" align="center">
            {t("감사합니다.")}
          </CustomText>
        </Grid>
        <Grid item xs={12} container spacing={2} justifyContent={"center"}>
          <Grid item>
            <Button onClick={() => navigate(-1)} variant="outlined">
              <CustomText type="button">{t("이전 페이지")}</CustomText>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => navigate("/")} variant="outlined">
              <CustomText type="button">{t("메인 페이지")}</CustomText>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const Robot = styled.div`
  text-align: center;
`;
