import styled from "@emotion/styled";
import { Box, Grid, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useFetchExamInfo from "../../api/domains/quiz/queries/useFetchQuizFinishTime";
import ToastAlert from "../../components/alert/ToastAlert";
import { CustomText } from "../../components/text/CustomText";
import { BoxGray, FlexCenter } from "../../styles/GlobalStyle";
import ExamForm from "./components/ExamForm";
import useFetchExamQuestionList from "../../api/domains/question/queries/useFetchExamQuestionList";

export default function ExamPage() {
  const { t } = useTranslation();
  const quizVersionId = Number(useParams().id);
  const navigate = useNavigate();

  const [isQuizStart, setIsQuizStart] = useState(false);
  const [quizFinishDate, setQuizFinishDate] = useState<Date>();
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");

  /** timer set */
  useEffect(() => {
    if (quizFinishDate) {
      const timer = setInterval(() => {
        const now = new Date();
        const diff = quizFinishDate.getTime() - now.getTime() - 2;
        const hours = Math.max(Math.floor((diff / (1000 * 60 * 60)) % 24), 0)
          .toString()
          .padStart(2, "0");
        const minutes = Math.max(Math.floor((diff / 1000 / 60) % 60), 0)
          .toString()
          .padStart(2, "0");
        const seconds = Math.max(Math.floor((diff / 1000) % 60), 0)
          .toString()
          .padStart(2, "0");
        setSeconds(seconds);
        setMinutes(minutes);
        setHours(hours);

        if (hours === "00" && minutes === "00" && seconds === "00") {
          clearInterval(timer);
          ToastAlert(t("시험이 종료되었습니다."), "info").then(() =>
            navigate(-1)
          );
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [quizFinishDate]);

  const { data: questionWithUserAnswerList } = useFetchExamQuestionList(
    quizVersionId,
    {
      onSuccess: () => setIsQuizStart(true),
      onError: () => {
        ToastAlert(t("응시가 불가능한 시험입니다."), "error");
        navigate(-1);
      },
    }
  );
  const { data: examInfo, isLoading } = useFetchExamInfo(quizVersionId, {
    enabled: isQuizStart,
    onSuccess: (data) => {
      setQuizFinishDate(new Date(data.quizFinishTime));
    },
  });

  const isArgent = () => {
    return hours === "00" && Number(minutes) <= 5;
  };

  return (
    <Grid container direction={"column"} spacing={5}>
      <Grid item xs={12}>
        <AlertInfoBox>
          <CustomText type="title" align="center">
            {t("시험명")} : {examInfo?.quizName}
          </CustomText>
          {examInfo && (
            <CustomText type="subtitle">
              {t("남은 시험 시간")} : {hours}시 {minutes}분 {seconds}초
            </CustomText>
          )}
          {isLoading && <Skeleton width={300} height={50} />}
          <Box sx={{ display: isArgent() ? null : "none" }}>
            <CustomText color={"red"} type="subtitle">
              {t("시험 종료")}
              {` ${minutes} 분 `}
              {`${seconds}`}
              {t("초 전입니다. 답안저장 버튼을 눌러주세요.")}
            </CustomText>
          </Box>
        </AlertInfoBox>
      </Grid>
      <Grid item xs={12}>
        {examInfo && questionWithUserAnswerList && (
          <ExamForm
            attemptId={examInfo.attemptId}
            questionWithSubmitAnswerList={questionWithUserAnswerList}
          />
        )}
      </Grid>
    </Grid>
  );
}
const AlertInfoBox = styled.div`
  padding: 10px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  width: 100%;
  background-color: ${BoxGray};
  transform: translate(-50%, 0%);
  ${FlexCenter}
  flex-direction: column;
`;
