import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { QueryKeys } from '../../../config/QueryKeys';
import useTagAPI from '../useTagAPI';
import { StaleTime } from '../../../config/StaleTime';
import { TagListDto } from '../TagType';

const useFetchTagListInFolder = <T = TagListDto[]>(
    folderId: number,
    options?:UseQueryOptions<TagListDto[], AxiosError, T>
) => {
    
    const { getTagListInFolderAPI } = useTagAPI();
    
    return useQuery({
        queryKey: QueryKeys.tagListInfolder(folderId),
        queryFn: () => getTagListInFolderAPI(folderId),
        refetchOnWindowFocus: false,
        staleTime: StaleTime.FIVE,
        ...options
    });
}

export default useFetchTagListInFolder;