import { create } from "zustand";
import { CourseDomain } from "../../api/domains/course/CourseType";

type State = Pick<
  CourseDomain,
  | "courseName"
  | "description"
  | "professorId"
  | "courseType"
  | "courseOpenDate"
  | "courseCloseDate"
> & {
  isDirty: boolean;
  isSubmit: boolean;
};

type Action = {
  createStore: (fetchedState: Partial<State>) => void;
  updateValue: (newState: Partial<State>) => void;

  setIsSubmit: (isSubmit: boolean) => void;
  resetStore: () => void;
};

export const swapItem = <T>(list: T[], index1: number, index2: number): T[] => {
  const newList = [...list];
  [newList[index1], newList[index2]] = [newList[index2], newList[index1]];
  return newList;
};

const baseInitialState: State = {
  courseName: "",
  description: "",
  professorId: 0,
  courseType: "신규입사자교육",
  courseOpenDate: null,
  courseCloseDate: null,
  isDirty: false,
  isSubmit: false,
};

const usePostCourseStore = create<State & Action>((set) => ({
  ...baseInitialState,

  createStore: (fetchedState) => {
    set({
      ...baseInitialState,
      ...fetchedState,
      isDirty: false,
    });
  },

  updateValue: (newState) =>
    set((state) => {
      const updatedState = { ...state, ...newState };
      return { ...updatedState, isDirty: true };
    }),

  setIsSubmit: (isSubmit) =>
    set((state) => ({
      ...state,
      isSubmit: isSubmit,
    })),

  resetStore: () => {
    set(baseInitialState);
  },
}));

export default usePostCourseStore;
