import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { FolderTreeDto } from "../../../api/domains/folder/FolderType";
import useFetchFolderAuthTree from "../../../api/domains/folder/queries/useFetchFolderAuthTree";
import useFetchFolderPathList from "../../../api/domains/folder/queries/useFetchFolderPathList";
import FolderNameLanguageSwitcher from "../../../components/FolderNameLanguageSwitcher";
import { CustomText } from "../../../components/text/CustomText";
import { useFileSearchStore } from "../../../stores/file/useFileSearchStore";
import { useFolderHistoryStore } from "../../../stores/folder/useFolderHistoryStore";
import { BorderBlue, BoxBlue, BoxGray } from "../../../styles/GlobalStyle";

export interface ContextMenuProps {
  x: number;
  y: number;
  showContextMenu: boolean;
}

export default function ArchiveDirectory() {
  // const [contextMenuOption, setContextMenuOption] = useState<ContextMenuProps>({
  //   x: 0,
  //   y: 0,
  //   showContextMenu: false,
  // });

  const { data: folderTree } = useFetchFolderAuthTree();

  return (
    <PageWrapper
      onContextMenu={(e) => {
        e.preventDefault();
        // setContextMenuOption({
        //   x: e.pageX,
        //   y: e.pageY,
        //   showContextMenu: true,
        // });
      }}
    >
      {folderTree && (
        <FolderComponent
          folder={folderTree}
          // setContextMenuOption={setContextMenuOption}
        />
      )}
      {/* <ArchiveContextMenu
        {...contextMenuOption}
        setContextMenuOption={setContextMenuOption}
      /> */}
    </PageWrapper>
  );
}

interface FolderComponentProps {
  folder: FolderTreeDto;
  // setContextMenuOption: Dispatch<SetStateAction<ContextMenuProps>>;
}
const FolderComponent = ({
  folder,
}: // setContextMenuOption,
FolderComponentProps) => {
  const [isOpen, setIsOpen] = useState(folder.level < 2);

  const resetFileSearchStore = useFileSearchStore(
    (state) => state.resetFileSearchStore
  );
  const { currentFolderId, setCurrentFolderId } = useFolderHistoryStore(
    (state) => ({
      currentFolderId: state.currentFolderId,
      setCurrentFolderId: state.setCurrentFolderId,
    })
  );

  const { data: folderPathList } = useFetchFolderPathList(currentFolderId);

  useEffect(() => {
    if (
      folderPathList &&
      folderPathList.some((folderPath) => folderPath.id === folder.id)
    ) {
      setIsOpen(true);
    }
  }, [currentFolderId, folderPathList, folder.id]);

  const toggleFolder = () => {
    setIsOpen(!isOpen);
  };

  const handleDirectoryClick = () => {
    setCurrentFolderId(folder.id);
    resetFileSearchStore();
  };
  return (
    <>
      {folder.parentFolderId !== null && (
        <FolderWrapper
          folderLevel={folder.level}
          selected={folder.id === currentFolderId}
          onDoubleClick={() => setIsOpen((state) => !state)}
        >
          <IconButton
            size="small"
            sx={{
              mr: "5px",
              visibility: folder.subFolderList.length === 0 ? "hidden" : null,
            }}
            onClick={toggleFolder}
          >
            {isOpen ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
          <FolderName onClick={() => handleDirectoryClick()}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/folder.svg`}
              alt={folder.koreanName}
              style={{
                opacity: folder.isSecretFolder ? 0.6 : 1,
                paddingRight: 10,
              }}
              width={18}
              height={18}
            />
            <CustomText type="subtitle" sx={{ userSelect: "none" }}>
              <FolderNameLanguageSwitcher
                koreanName={folder.koreanName}
                englishName={folder.englishName}
                isSecretFolder={folder.isSecretFolder}
              />
            </CustomText>
          </FolderName>
        </FolderWrapper>
      )}
      {isOpen &&
        folder.subFolderList.map((subFolder) => (
          <FolderComponent
            key={subFolder.id}
            folder={subFolder}
            // setContextMenuOption={setContextMenuOption}
          />
        ))}
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

const FolderWrapper = styled.div<{ folderLevel: number; selected: boolean }>`
  display: flex;
  align-items: center;
  margin: 5px;
  border-radius: 3px;
  padding-left: ${(props) => `${(props.folderLevel - 2) * 10}px`};
  background-color: ${(props) => (props.selected ? BoxGray : null)};
  &:hover {
    background-color: ${BoxBlue};
    border: 2px solid ${BorderBlue};
    margin: 3px;
  }
`;
const FolderName = styled.div`
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  width: 100%;
`;
