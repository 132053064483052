import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ToastAlert from '../../components/alert/ToastAlert';

const useHandlePostRequest = () => { 
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleSuccess = (
        message: string,
        invalidKey: string[],
        navigateUrl: string,
    ) => {
        ToastAlert(message, 'success');
        queryClient.invalidateQueries(invalidKey);
        navigate(navigateUrl);
    }

    const handleError = (message: string) => {
        ToastAlert(message, 'error')
    }

    return {
        handleSuccess,
        handleError,
    }
}

export default useHandlePostRequest;