import styled from "@emotion/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ClearIcon from "@mui/icons-material/Clear";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, IconButton, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../../../api/config/QueryKeys";
import useFetchFolderPathList from "../../../api/domains/folder/queries/useFetchFolderPathList";
import FolderNameLanguageSwitcher from "../../../components/FolderNameLanguageSwitcher";
import { CustomText } from "../../../components/text/CustomText";
import { useFileSearchStore } from "../../../stores/file/useFileSearchStore";
import { useFolderHistoryStore } from "../../../stores/folder/useFolderHistoryStore";
import { Border2Gray, BorderGray, BoxGray } from "../../../styles/GlobalStyle";

export default function ArchiveHeader() {
  const { t } = useTranslation();
  const [isShortenedPathDisplay, setIsShortenedPathDisplay] = useState(false);
  const folderPathRef = useRef<HTMLDivElement>(null);

  const {
    currentFolderId,
    backStack,
    forwardStack,
    setCurrentFolderId,
    goBack,
    goForward,
  } = useFolderHistoryStore((state) => ({
    currentFolderId: state.currentFolderId,
    backStack: state.backStack,
    forwardStack: state.forwardStack,
    setCurrentFolderId: state.setCurrentFolderId,
    goBack: state.goBack,
    goForward: state.goForward,
  }));

  const {
    searchWords,
    isSearchActive,
    searchWordSnapShot,
    setIsSearchActive,
    setSearchWords,
    setSearchWordSnapShot,
    resetFileSearchStore,
  } = useFileSearchStore((state) => ({
    searchWords: state.searchWords,
    isSearchActive: state.isSearchActive,
    searchWordSnapShot: state.searchWordSnapShot,
    setIsSearchActive: state.setIsSearchActive,
    setSearchWords: state.setSearchWords,
    setSearchWordSnapShot: state.setSearchWordSnapShot,
    resetFileSearchStore: state.resetFileSearchStore,
  }));

  const { data: folderPathList } = useFetchFolderPathList(currentFolderId);

  //#region 상위폴더로 이동
  const moveToSuperFolder = () => {
    if (!folderPathList) return;
    const currentFolder = folderPathList.find(
      (folder) => folder.id === currentFolderId
    );

    if (!currentFolder) return;
    const superFolder = folderPathList.find(
      (folder) => folder.level === currentFolder.level - 1
    );

    if (superFolder) {
      setCurrentFolderId(superFolder.id);
    } else if (currentFolder.level === 2) {
      setCurrentFolderId(Number(process.env.REACT_APP_ROOT_FOLDER_ID));
    }
  };
  //#endregion

  const queryClient = useQueryClient();

  //#region 새로고침(폴더,파일,디렉토리)
  const refresh = () => {
    queryClient.invalidateQueries(QueryKeys.subFolderList(currentFolderId));
    queryClient.invalidateQueries(QueryKeys.fileList(currentFolderId));
    queryClient.invalidateQueries(QueryKeys.folderAuthTree());
  };
  //#endregion

  useEffect(() => {
    setTimeout(() => {
      if (folderPathRef.current) {
        Array.from(folderPathRef.current.childNodes).forEach((node) => {
          if (node instanceof HTMLElement) {
            node.classList.remove("hidden");
          }
        });

        const containerMaxWidth = folderPathRef.current.offsetWidth - 110;

        let currentWidth = 0;
        let overFlowFlag = false;

        Array.from(folderPathRef.current.childNodes)
          .reverse()
          .forEach((node) => {
            if (node instanceof HTMLElement) {
              const nodeWidth = node.offsetWidth;
              currentWidth += nodeWidth;

              if (
                containerMaxWidth < currentWidth &&
                !node.classList.contains("basicPath")
              ) {
                node.classList.add("hidden");
                overFlowFlag = true;
              }
            }
          });
        setIsShortenedPathDisplay(overFlowFlag);
      }
    }, 100);
  }, [currentFolderId]);

  return (
    <Wrapper>
      <IconButtonList>
        <IconButton
          onClick={() => goBack()}
          disabled={backStack.length === 0}
          sx={{ borderRadius: 0 }}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          onClick={() => goForward()}
          disabled={forwardStack.length === 0}
          sx={{ borderRadius: 0 }}
        >
          <ArrowForwardIcon />
        </IconButton>
        <IconButton
          onClick={() => moveToSuperFolder()}
          disabled={
            currentFolderId === Number(process.env.REACT_APP_ROOT_FOLDER_ID)
          }
          sx={{ borderRadius: 0 }}
        >
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton onClick={() => refresh()} sx={{ borderRadius: 0 }}>
          <RefreshIcon />
        </IconButton>
      </IconButtonList>
      <FolderPathWrapper ref={folderPathRef}>
        <PathWrapper
          className={"basicPath"}
          onClick={() =>
            setCurrentFolderId(Number(process.env.REACT_APP_ROOT_FOLDER_ID))
          }
        >
          <IconButton color="inherit" size="small" sx={{ borderRadius: 0 }}>
            <DesktopWindowsOutlinedIcon />
          </IconButton>
        </PathWrapper>
        {isShortenedPathDisplay && (
          <PathWrapper className={"basicPath"}>
            <IconButton color="inherit" size="small" sx={{ borderRadius: 0 }}>
              <MoreHorizIcon />
              <NavigateNextIcon />
            </IconButton>
          </PathWrapper>
        )}
        {!isSearchActive &&
          folderPathList &&
          folderPathList.map((folder) => {
            return (
              <PathWrapper
                key={folder.id}
                onClick={() => setCurrentFolderId(folder.id)}
              >
                <Button color="inherit" sx={{ p: "6px" }}>
                  <CustomText type="body" mr={"3px"}>
                    <FolderNameLanguageSwitcher
                      koreanName={folder.koreanName}
                      englishName={folder.englishName}
                    />
                  </CustomText>
                  <NavigateNextIcon />
                </Button>
              </PathWrapper>
            );
          })}
        {isSearchActive && (
          <SearchWordsWrapper>
            <CustomText type="body">
              {t("로 검색한 결과", { words: searchWordSnapShot })}
            </CustomText>
          </SearchWordsWrapper>
        )}
      </FolderPathWrapper>
      <SearchWrapper>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: isSearchActive && (
              <IconButton size="small" onClick={resetFileSearchStore}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          placeholder={t("찾으시려는 파일명을 입력 후 엔터를 눌러주세요.")}
          inputProps={{ style: { padding: "10px" } }}
          value={searchWords}
          onChange={(e) => setSearchWords(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && searchWords.trim().length > 0) {
              setIsSearchActive(true);
              setSearchWordSnapShot(searchWords);
            }
          }}
        />
      </SearchWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${BoxGray};
  padding: 6px;
  display: flex;
`;
const IconButtonList = styled.div`
  width: 15%;
  display: flex;
  justify-content: space-between;
  margin: 0 16px;
`;
const FolderPathWrapper = styled.div`
  width: 55%;
  padding-left: 18px;
  border: 1px solid ${Border2Gray};
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  overflow: hidden;
  & > .hidden {
    display: none;
  }
`;
const SearchWordsWrapper = styled.div`
  padding: 0 8px;
`;
const PathWrapper = styled.div`
  padding: 0 2px;
  &:hover {
    border: 1px solid ${BorderGray};
    border-radius: 5px;
    padding: 0 1px;
  }
`;
const SearchWrapper = styled.div`
  width: 30%;
  background-color: #ffffff;
  border-radius: 5px;
  margin: 0 16px;
  display: flex;
  align-items: center;
`;
