import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { StoragePermissionType } from "../../../../data/enums/PermissionType";
import { QueryKeys } from "../../../config/QueryKeys";
import useFolderAPI from "../useFolderAPI";

const useFetchFolderAuthCheck = <T = StoragePermissionType>(
  folderId: number,
  options?: UseQueryOptions<StoragePermissionType, AxiosError, T>
) => {
  const { folderAuthCheckAPI } = useFolderAPI();

  return useQuery({
    queryKey: QueryKeys.folderAuthCheck(folderId),
    queryFn: () => folderAuthCheckAPI(folderId),
    ...options,
  });
};

export default useFetchFolderAuthCheck;
