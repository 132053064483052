import { create } from "zustand";

interface FileSearchStoreType {
  searchWords: string;
  searchWordSnapShot: string;
  isSearchActive: boolean;

  setSearchWords: (searchWords: string) => void;
  setIsSearchActive: (isSearchActive: boolean) => void;
  setSearchWordSnapShot: (searchWordSnapShot: string) => void;
  resetFileSearchStore: () => void;
}

const initialState = {
  searchWords: "",
  searchWordSnapShot: "",
  isSearchActive: false,
};

export const useFileSearchStore = create<FileSearchStoreType>((set) => ({
  ...initialState,

  setSearchWords: (searchWords) => set({ searchWords }),
  setIsSearchActive: (isSearchActive) => set({ isSearchActive }),
  setSearchWordSnapShot: (searchWordSnapShot) => set({ searchWordSnapShot }),

  resetFileSearchStore: () => set(initialState),
}));
