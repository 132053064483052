import useGetApiInstance from "../../config/BasicApi";
import {
  CreateUser,
  FetchProfessorList,
  FetchUserList,
  FetchUserStudyInfoList,
  FetchUserDto,
  SSOLoginDto,
  UpdateUserRole,
  UserLoginDto,
} from "./UserType";

const useUserAPIs = () => {
  const api = useGetApiInstance("user");

  /**
   * Id 사용 여부 조회
   */
  const getIsAccountIdAvailableAPI = (accountId: string): Promise<boolean> =>
    api.get(`idcheck/${accountId}`).then((res) => res.data.result);

  /**
   * 이메일 사용 여부 조회
   */
  const getIsEmailAvailableAPI = (email: string): Promise<boolean> =>
    api.get(`emailcheck/${email}`).then((res) => res.data.result);

  /**
   * 일반 회원가입
   */
  const signUpAPI = (request: CreateUser) =>
    api.post("register", request).then((res) => res.data.result);

  /**
   * 일반 로그인
   */
  const loginAPI = (userLogin: UserLoginDto): Promise<FetchUserDto> =>
    api.post("/login", userLogin).then((res) => res.data.result);

  /**
   * SSO 로그인
   * @param msToken SSOLoginDto
   * @returns UserInfoDto
   */
  const ssoLoginAPI = (msToken: SSOLoginDto): Promise<FetchUserDto> =>
    api.post("/sso-login", msToken).then((res) => res.data.result);

  /**
   * 모든 강사(관리자 포함) 조회
   * @returns FetchProfessorList[]
   */
  const getProfessorListAPI = (): Promise<FetchProfessorList[]> =>
    api.get(`professor`).then((res) => res.data.result);

  /**
   * 유저 권한 변경
   * @param request updateUserRole
   * @returns boolean
   */
  const changeUserRoleAPI = (request: UpdateUserRole): Promise<boolean> =>
    api.post("modify-role", request).then((res) => res.data.result);

  /**
   * 모든 사용자 조회
   * @requires admin
   * @returns FetchUserList[]
   */
  const getUserListAPI = (): Promise<FetchUserList[]> =>
    api.get(`list`).then((res) => res.data.result);

  /**
   * 모든 사용자 학습정보 조회
   * @returns FetchUserStudyInfoList[]
   */
  const getUserStudyInfoListAPI = (): Promise<FetchUserStudyInfoList[]> =>
    api.get("user-study-info-list").then((res) => res.data.result);

  return {
    getIsAccountIdAvailableAPI,
    getIsEmailAvailableAPI,
    signUpAPI,
    loginAPI,
    ssoLoginAPI,
    getProfessorListAPI,
    changeUserRoleAPI,
    getUserListAPI,
    getUserStudyInfoListAPI,
  };
};

export default useUserAPIs;
