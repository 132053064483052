import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FetchAttemptHistory,
  FetchStudyHistory,
} from "../../../api/domains/user/UserType";
import CustomTableWrapper from "../../../components/table/CustomTableWrapper";
import { CustomText } from "../../../components/text/CustomText";
import CustomTextTooltip from "../../../components/text/CustomTextTooltip";
import {
  BorderGray,
  InbodyRed,
  TitleGreen,
  TitleRed,
} from "../../../styles/GlobalStyle";
import useDateHooks from "../../../hooks/common/useDateHooks";

interface UserStudyInfoDetailProps {
  studyInfoList: FetchStudyHistory[];
  attemptInfoList: FetchAttemptHistory[];
}

export default function UserStudyInfoDetail({
  studyInfoList,
  attemptInfoList,
}: UserStudyInfoDetailProps) {
  const { t } = useTranslation();

  const { getDateStringFormat } = useDateHooks();

  return (
    <TableRow>
      <TableCell colSpan={7} style={{ padding: 0 }}>
        {(attemptInfoList.length > 0 || studyInfoList.length > 0) && (
          <TableContainer
            component={Paper}
            elevation={1}
            sx={{ p: "10px 16px", mb: 1, width: "auto" }}
          >
            {studyInfoList.length > 0 && (
              <>
                <CustomText
                  m={"10px 0"}
                  pl={1}
                  type="subtitle"
                  sx={{ borderLeft: `5px solid ${InbodyRed}` }}
                >
                  {t("강의 정보")}
                </CustomText>
                <CustomTableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="50%">
                          <CustomText type="body" bold500>
                            {t("강의명")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="20%">
                          <CustomText type="body" bold500>
                            {t("수료여부")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="30%">
                          <CustomText type="body" bold500>
                            {t("진도율")}
                          </CustomText>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studyInfoList.map((studyInfo, index) => (
                        <TableRow
                          key={index}
                          sx={{ borderBottom: `1px solid ${BorderGray}` }}
                        >
                          <TableCell>
                            <CustomTextTooltip type="body" pl={2}>
                              {studyInfo.courseName}
                            </CustomTextTooltip>
                          </TableCell>
                          <TableCell align="center">
                            {studyInfo.isCompleted ? (
                              <CustomText
                                type="body"
                                bold500
                                color={TitleGreen}
                              >
                                {t("수료")}
                              </CustomText>
                            ) : (
                              <CustomText type="body" bold500 color={TitleRed}>
                                {t("미수료")}
                              </CustomText>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {`${studyInfo.completePercentage} %`}
                            </CustomText>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableWrapper>
              </>
            )}
            {attemptInfoList.length > 0 && (
              <>
                <CustomText
                  m={"10px 0"}
                  pl={1}
                  type="subtitle"
                  sx={{ borderLeft: `5px solid ${InbodyRed}` }}
                >
                  {t("시험 정보")}
                </CustomText>
                <CustomTableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" width="20%">
                          <CustomText type="body" bold500>
                            {t("강의명")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="20%">
                          <CustomText type="body" bold500>
                            {t("시험명")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="15%">
                          <CustomText type="body" bold500>
                            {t("객관식점수")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="15%">
                          <CustomText type="body" bold500>
                            {t("주관식점수")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="10%">
                          <CustomText type="body" bold500>
                            {t("총점수")}
                          </CustomText>
                        </TableCell>
                        <TableCell align="center" width="20%">
                          <CustomText type="body" bold500>
                            {t("마지막제출시간")}
                          </CustomText>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attemptInfoList.map((attemptInfo, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            <CustomTextTooltip type="body">
                              {attemptInfo.courseName}
                            </CustomTextTooltip>
                          </TableCell>
                          <TableCell align="center">
                            <CustomTextTooltip type="body">
                              {attemptInfo.quizName}
                            </CustomTextTooltip>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {`${attemptInfo.objectiveGetScore ?? 0}/${
                                attemptInfo.objectiveMaxScore
                              }`}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {`${attemptInfo.subjectiveGetScore ?? 0}/${
                                attemptInfo.subjectiveMaxScore
                              }`}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {(attemptInfo.objectiveGetScore ?? 0) +
                                (attemptInfo.subjectiveGetScore ?? 0)}
                            </CustomText>
                          </TableCell>
                          <TableCell align="center">
                            <CustomText type="body">
                              {getDateStringFormat(
                                attemptInfo.lastSubmitTime,
                                "YYYY-MM-DD hh:mm:ss"
                              )}
                            </CustomText>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CustomTableWrapper>
              </>
            )}
          </TableContainer>
        )}
      </TableCell>
    </TableRow>
  );
}
