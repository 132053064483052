import styled from "@emotion/styled";
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { QueryKeys } from "../../api/config/QueryKeys";
import useFetchNoticeDetail from "../../api/domains/notice/queries/useFetchNoticeDetail";
import useNoticeAPIs from "../../api/domains/notice/useNoticeAPIs";
import { CustomConfirmAlert } from "../../components/alert/CustomConfirmAlert";
import CustomButton from "../../components/button/CustomButton";
import PageHeader from "../../components/templates/PageHeader";
import { CustomText } from "../../components/text/CustomText";
import Urls from "../../routes/Urls";
import {
  BasicFontKR,
  BorderGray,
  BoxPale,
  FlexCenter,
} from "../../styles/GlobalStyle";

export default function NoticeDetailPage() {
  const navigate = useNavigate();
  const noticeId = Number(useParams().id);
  const { deleteNoticeAPI } = useNoticeAPIs();
  const { t } = useTranslation();

  const { isLoading, data: noticeDetail } = useFetchNoticeDetail(noticeId);

  const queryClient = useQueryClient();
  const noticeDeleteQuery = useMutation((noticeId: number) =>
    deleteNoticeAPI(noticeId)
  );

  const deleteNoticeHandle = () => {
    CustomConfirmAlert(
      t("공지를 삭제하시겠습니까?"),
      "warning",
      t("삭제하기"),
      t("취소하기")
    ).then((result) => {
      if (result.isConfirmed) {
        noticeDeleteQuery.mutate(noticeId, {
          onSuccess: () => {
            Swal.fire({
              position: "center",
              heightAuto: false,
              icon: "success",
              title: t("공지가 삭제 되었습니다."),
              showConfirmButton: false,
            });
            queryClient.invalidateQueries(QueryKeys.noticeList());
            navigate(Urls.notice);
          },
          onError: () => {
            Swal.fire({
              position: "center",
              heightAuto: false,
              icon: "error",
              title: t("공지 삭제 중 에러가 발생했습니다."),
              showConfirmButton: false,
            });
            queryClient.invalidateQueries(QueryKeys.noticeDetail(noticeId));
          },
        });
      }
    });
  };

  return (
    <Grid container rowSpacing={2} mt={0}>
      <Grid item xs={12}>
        <PageHeader title={t("공지사항")} />
      </Grid>
      <Grid item xs={12}>
        <TableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="center" component={"th"} width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("작성자")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  <CustomText type="body">{t("관리자")}</CustomText>
                </TableCell>
                <TableCell align="center" component={"th"} width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("작성일")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  {isLoading && <Skeleton animation="wave" variant="text" />}
                  <CustomText type="body">{noticeDetail?.createdAt}</CustomText>
                </TableCell>
                <TableCell align="center" component={"th"} width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("조회수")}
                  </CustomText>
                </TableCell>
                <TableCell align="center" width="15%">
                  {isLoading && <Skeleton animation="wave" variant="text" />}
                  <CustomText type="body">{noticeDetail?.viewCount}</CustomText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component={"th"} width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("제목")}
                  </CustomText>
                </TableCell>
                <TableCell className="notice-title" width="85%" colSpan={5}>
                  {isLoading && <Skeleton animation="wave" variant="text" />}
                  <CustomText type="body">{noticeDetail?.title}</CustomText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" component={"th"} width="15%">
                  <CustomText type="subtitle" bold600>
                    {t("내용")}
                  </CustomText>
                </TableCell>
                <TableCell className="notice-content" width="85%" colSpan={5}>
                  {isLoading && <Skeleton animation="wave" variant="text" />}
                  {noticeDetail && (
                    <NoticeContent
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(noticeDetail.content),
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>
      </Grid>
      <Grid item xs={12}>
        <ButtonWrapper>
          <CustomButton
            colorType="gray"
            onClick={() =>
              navigate(Urls.noticeModify.replace(":id", String(noticeId)))
            }
          >
            <CustomText type="button">{t("수정하기")}</CustomText>
          </CustomButton>
          <CustomButton colorType="black" onClick={() => navigate(Urls.notice)}>
            <CustomText type="button">{t("목록으로")}</CustomText>
          </CustomButton>
          <CustomButton colorType="inbodyRed" onClick={deleteNoticeHandle}>
            <CustomText type="button">{t("삭제하기")}</CustomText>
          </CustomButton>
        </ButtonWrapper>
      </Grid>
    </Grid>
  );
}
const TableWrapper = styled.div`
  & th {
    border: 1px solid ${BorderGray};
    background-color: ${BoxPale};
  }
  & td {
    border: 1px solid ${BorderGray};
  }
  & td.notice-title {
    padding-left: 20px;
  }
  & td.notice-content {
    padding: 40px 0;
    padding-left: 20px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
  ${FlexCenter}
`;
const NoticeContent = styled.div`
  font-family: ${BasicFontKR};
  font-size: 14px;
`;
